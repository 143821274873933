.group {
  &-overview {
    max-width: 1024px;
    margin: auto;
    padding: 16px 0;
  }
  .org-chart {
    &__node {
      margin: 16px 8px 0 8px;
      &-name {
        cursor: pointer;
        &-leaf {
          padding: 4px 8px;
        }
        &--filter {
          background: rgba(72, 165, 249, 0.4);
        }
      }
    }
    &__level-labels {
      padding: 0 8px;
      margin-top: 7px;
      display: inline-block;
      width: 150px;
      div {
        margin: 16px 8px 0 8px;
        padding: 4px 8px;
        line-height: 17px;
        vertical-align: middle;
        font-weight: $bold;
      }
    }
  }
  &-company-filter {
    &__counter {
      background: $raspberry-red;
      color:$white;
      border-radius: 50px;
      padding: 4px 8px;
      font-size: 10px;
      display: inline-block;
      position: relative;
      right: 16px;
      top: -12px;
    }
    .wethod-filter-select__list {
      padding-bottom: 16px;
    }
  }
}

@import "org-chart";
@import "reports";
@import "normalizer";
@import "revenues";
