$production-plan-months: 12;

.production-plan {
  .wethod-section-actions {
    padding: 16px 0;
  }

  .wethod-table__content {
    border: none;
  }

  &__bu-select {
    display: inline-block;
    vertical-align: middle;
    margin-left: 16px;

    .wethod-filter-select__button {
      line-height: 30px;
    }

    .wethod-filter-select__list {
      z-index: 3;
    }
  }

  .wethod-table__header {
    border: 1px solid $concrete-gray;
  }
  //&__modal{
  //  max-width: 450px;
  //}

  &__modal {
    max-width: 450px;
    line-height: 16px;

    //p {
    //  margin-bottom: 8px;
    //}
    &-description {
      color: $dusty-gray;
      margin: 10px 0;
    }
  }

  &__actions {
    display: flex;
    justify-content: space-between;

    &--right {
      margin-left: auto; //Keep the actions on the right, whether there are others on the left or not
    }

    .wethod-export-button {
      @include scaleToMobile() {
        visibility: hidden;
      }
    }
  }

  &__type-switcher {
    margin-left: 16px;
    vertical-align: middle;
    display: inline-block;
  }

  &__probability-label {
    position: absolute;
    border: 1px solid $black;
    border-radius: 4px;
    display: inline-block;
    line-height: initial;
    font-size: 11px;
    top: -28px;
    padding: 0 4px;
    left: 0;
    color:$black;
  }

  &-project {
    overflow: visible !important;

    &__options {
      line-height: normal;
      font-size: 12px;
      text-align: left;
      white-space: nowrap;
      max-height: 50vh;
      overflow-y: auto;

      a {
        color: initial;
      }

      &--border-bottom {
        border-bottom: 1px solid #ECECEC;
      }
    }

    &--mirror {
      opacity: 0.8;
      height: 80px;
      background:$white;
      line-height: 98px;
      border: none !important;
      border-radius: 4px;
      position: fixed;
      left: -999px;
      display: inline-block;

      .production-plan-project__month--editable {
        width: 57px;
      }
    }

    &__handle {
      display: inline-block;
      position: absolute;
      left: 8px;
      cursor: move;
      transition: .3s;
      background-color:$white;
      padding: 0 4px;
      line-height: initial;
      bottom: -8px;
      width: 40px;
      height: 14px;
      border-radius: 50px;
      border: 1px solid $bright-sky-blue;
      opacity: 0;

      span {
        display: inline-block;
        margin-top: -10px;
      }
    }

    &:hover {
      .production-plan-project__month-handle, .production-plan-project__actions-button--edit, .production-plan-project__handle {
        opacity: 1;
      }
    }

    &--first-of-probability {
      margin-top: 40px !important;
      position: relative;
    }

    .production-plan-project__month {
      line-height: 98px;
    }

    &.wethod-table__row {
      height: 98px;
      line-height: 98px;
      margin-top: 8px;
      border-radius: 4px;
      border: 1px solid $concrete-gray !important;
    }

    &-row {
      background:$white;
      border-bottom: 1px solid rgba(155, 155, 155, 0.28);
      height: 60px;
      line-height: 60px;
      transform: translate3d(0, 0, 0); // enable GPU calculation on change year to avoid scroll blocking
    }

    &__info {
      width: 20%;
      display: inline-block;
      vertical-align: top;
      color: inherit;

      &--nav {
        vertical-align: middle !important;
      }

      &-link {
        &:hover {
          .production-plan-project__info-content {
            color: inherit;
          }
        }
      }

      &-content {
        width: 100%;
        line-height: initial;
        display: inline-block;
        vertical-align: middle;
        color:$black;

        &--disabled {
          color: $dusty-gray;
        }
      }

      &-name {
        font-weight: $semiBold;
        font-size: 12px;
        width: 100%;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        display: block;
      }

      &-client {
        width: 100%;
        font-size: 11px;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        display: block;
      }

      &-pm {
        color: $dusty-gray;
        width: 100%;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        font-size: 11px;
        display: block;
      }

      &-label {
        margin-top: 4px;
        width: 100%;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        font-size: 11px;
        display: block;
      }

      &:hover {
        color: $bright-sky-blue;
      }

      &:visited {
        color: inherit;
      }
    }

    &__month {
      width: 5.6%;
      font-size: 12px;
      text-align: center !important;
      font-weight: $normal;
      position: relative;
      border-bottom: 2px rgba(255, 255, 255, 0);
      transition: .3s;
      height: 100%;

      &--header {
        user-select: none;
        text-overflow: unset;
      }

      &--mirror {
        opacity: 0.8;
        height: 80px;
        background:$white;
        line-height: 98px;
        border: none !important;
        border-radius: 4px;
        position: fixed;
        left: -999px;
      }

      &--source {
        opacity: 0.2;
      }

      &--target-over {
        border: 2px dashed darkgrey;
        border-radius: 4px;
        transition: border 0s;
      }

      &-handle {
        opacity: 0;
        display: inline-block;
        position: absolute;
        left: 13px;
        top: -3px;
        cursor: move;
        transition: .3s;
      }

      &--editable {
        padding: 0 4px !important;
        overflow: visible !important;
      }

      &-value {
        &--production {
          font-size: 10px;
          position: absolute;
          bottom: 6px;
          line-height: initial;
          width: 100%;
          text-align: center;
          left: 0;
          color: #999;
        }

        .wethod-input {
          border-radius: 3px;
          padding: 5px;
          display: inline-block;
          width: 100%;
          line-height: 17px;
          border: 1px solid #ECECEC;
          background: transparent;
        }

        input[type=text] {
          text-align: center;
        }

        &-content {
          display: inline-block;
          line-height: initial;
        }
      }

      &-year {
        position: absolute;
        top: -19px;
        color: rgba(0, 0, 0, 0.38);
        font-size: 11px;
        width: 100%;
        left: 0;
      }

      &-delta {
        color: orange;
        font-size: 10px;
        position: absolute;
        line-height: initial;
        height: initial;
        width: 100%;
        top: 18px;
        text-align: center;
        left: 0;

        span {
          background:$white;
          padding: 0 4px;
          border-radius: 50px;
        }
      }
    }

    &__actions {
      display: inline-block;
      vertical-align: middle;
      width: 12.8%;
      padding-right: 0 !important;
      position: relative;
      overflow: visible !important;

      &-buttons {
        display: inline-block;
        width: calc(100% - 52px);
        text-align: center;
        transition: .4s;
      }

      &-button {
        &--got-it {
          div {
            margin: auto;
          }

          &:hover {
            div {
              background-position-x: -48px;
            }
          }
        }

        &--edit {
          opacity: 0;
          transition: 0s !important;
          border: none !important;
          padding: 0 14px !important;

          &:hover {
            background: rgba(0, 0, 0, 0.1) !important;
            color:$black !important;
          }
        }
      }

      &--feedback {
        text-transform: uppercase;
        color: $bright-sky-blue;
        font-size: 10px;
      }
    }

    &__total {
      font-size: 12px;
      display: inline-block;
      width: 52px;
      text-align: right;
      position: relative;

      &-value {
        &--production {
          font-size: 10px;
          position: absolute;
          bottom: 9px;
          line-height: initial;
          width: 100%;
          left: 0;
          color: #999;
          font-weight: $normal;
        }
      }
    }

    &__edit-button {
      padding: 3px !important;
    }
  }

  &__navigator {
    float: none;
    right: 0;
    left: -16px;
    font-weight: $normal;

    .navigation__today {
      padding: 0 34px;
      text-transform: capitalize;
    }
  }
}
