.wethod {
  &-body {
    &--with-app-banner {
      .program__body {
        .wethod-section {
          min-height: auto;
        }
      }
    }
  }
}

.program {
  &__content {
    background: $alabaster-white;
    margin-top: -1px;
  }

  &__body {
    top: 0;
    z-index: 750 !important;
    background-color: $maldivian-sand-white;

    .wethod-section {
      min-height: auto;
      height: 100%;
      position: absolute;
      width: 100%;
    }

    .pipelineHead, .grid {
      position: relative;
    }
  }

  &__actions {
    padding: 12px 0 !important;

    .wethod-icon-button {
      width: 34px;
      height: 34px;

      &:hover {
        .wethod-icon {
          background-position-x: -64px;
        }
      }
    }
  }
}

.projectCharterSelector {
  margin: 10px auto;
  width: 155px;

  a {
    font-size: 12px;
  }
}

.pcPushMarginLeft {
  margin-left: 15px !important
}

.pcPushPaddingLeft {
  padding-left: 15px !important
}

.noPipelineProgram {
  text-transform: uppercase;
  font-weight: $semiBold;
  color: $steel-gray;
  padding-left: 15px !important;
}

.noPipelineProgramFound {
  height: 60px;
  background: $white;
  margin-top: 8px;
  text-transform: uppercase;
  font-weight: $semiBold;
  color: $steel-gray;

  .table {
    width: 100%;
    height: 60px;
    padding: $gutter $gutter $gutter 15px;

    &.pcPushPaddingLeft {
      padding-left: 15px !important
    }
  }
}

.pipelineProgramList {
  float: left;
  width: 100%;
  margin: 8px 0;

  .table {
    display: table !important;
  }

  li {
    height: 60px;
    position: relative;
    float: left;
    position: relative;
    background: $white;
    border: 1px solid $concrete-gray;

    a {
      display: block;
      height: 100%;
      color: rgba(0, 0, 0, .87);
    }

    .projectStripe {
      z-index: 100;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      width: 4px;
      background: #E6E6E6;
    }

    &.tentative {
      .projectStripe {
        &::before {
          content: "";
          width: 100%;
          height: 100%;
          z-index: 100;
          position: absolute;
          background: rgba(255, 255, 255, 0.6);
        }
      }
    }
  }

  .icon {
    margin: 0 !important;
  }

  .resourcLink {
    color: $bright-sky-blue;

    &:hover {
      text-decoration: underline;
    }
  }

  a.resourceTypeSelectLabel {
    color: $steel-gray;
  }

  .selectWrap {
    padding: 19px $gutter;
    line-height: 1.3;
    position: relative;

    .mdl-textfield {
      top: 0px;
      font-size: $baseFont !important;
      width: 100%;

      padding: 2px 0;

      .mdl-textfield__input {
        border: none;
      }

      .mdl-textfield__label {
        height: 19px;
        top: 2px;
        line-height: 1.3;
        display: block;
      }

      &.mdl-textfield--floating-label.is-focused .mdl-textfield__label,
      &.mdl-textfield--floating-label.is-dirty .mdl-textfield__label {
        top: -15px;
        color: $bright-sky-blue !important;
        font-size: 10px !important;
      }

    }
  }

  .alignButton {
    margin-top: 12px;
  }

  .table {
    width: 100%;
    height: 60px;
    padding: $gutter $gutter * 2;

    &.pcPushPaddingLeft {
      padding-left: 15px !important
    }
  }

  .projectEmptyRow {
    float: left;
    width: 100%;
    height: $rowH;
    background: $white;
    border-bottom: 1px solid $concrete-gray;
    line-height: 1.3;
  }
}

//icona link pipeline
.circularSpriteModules {
  display: table;
  width: 30px;
  height: 30px;

  cursor: pointer;

  background: $bright-sky-blue;
  border: 1px solid $bright-sky-blue;
  border-radius: 50%;
  text-align: center;

  .modulePipeline {
    display: table-cell;
    vertical-align: middle;
    @include retina-image($iconsPath + modules_icons, 128px 32px);
    width: 16px;
    height: 16px;
    margin: 6px;
    background-position: -16px 16px;
  }

  &:hover {
    opacity: .7;
  }
}
