.budget-creation {
  .section-header {
    padding-top: 16px;
  }

  &__modal {
    .wethod-modal2__body {
      width: 360px;
      min-height: 200px;

      ul {
        list-style: none;
        padding: 0;
      }
    }

    .search-input {
      border-bottom: 1px solid $concrete-gray;
      border-radius: 0;
      padding-top: 5px;
      padding-bottom: 5px;
    }

    &-footer {
      padding: 16px 24px;
      text-align: right;

      & > *:not(:last-child) {
        margin-right: 16px;
      }
    }

    &--select {
      .wethod-modal2__body {
        padding: 0;
        display: flex;
        flex-direction: column;
        max-height: 420px;
      }
    }
  }

  &__list {
    overflow: auto;
    flex: 1;

    &-empty {
      color: $steel-gray;
      font-weight: $semiBold;
      text-transform: uppercase;
      font-size: 12px;
      line-height: 48px;
      padding: 0 15px;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 138px;
    }
  }

  &__loader {
    margin: auto;
    display: block;
  }
}
