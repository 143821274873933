.widget-revenue-clock {
  &__body {
    overflow: hidden;
  }
  &__chart {
    height: 185px;
    &-container {
      position: relative;
    }
  }
  &__target {
    position: absolute;
    top: calc(50% - 24px); // 24px is target's height / 2
    width: 100%;
    text-align: center;
    left: 0;
    &-title {
      color: #878B8E;
      font-size: 10px;
    }
    &-value {
      color: #878B8E;
      font-size: 24px;
      font-weight: $light;
      margin-top: 8px;
    }
  }
  &__details {
    &-item {
      display: inline-block;
      width: calc(33.33% + 1px);
      padding: 10px 8px;
      position: relative;
      top: 0;
      transition: .2s;
      margin-left: -1px;
      border-top: 1px solid $concrete-gray;
      &:not(:last-child) {
        border-right: 1px solid $concrete-gray;
      }
      &:not(:first-child) {
        border-left: 1px solid $concrete-gray;
      }
      &:hover {
        top: -8px;
      }
      &-title {
        color: $dusty-gray;
        font-size: 10px;
        line-height: 15px;
        &--production-value {
          color: rgba(78, 216, 141, 1);
        }
        &--revenue-pipeline {
          color: $bright-sky-blue;
        }
      }
      &-value {
        font-size: 16px;
        line-height: 24px;
      }
    }
  }
}
