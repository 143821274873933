.client {
  &-widget {
    margin-bottom: 16px;
  }

  &-trends {
    margin-top: 8px;
  }

  &-trend {
    width: calc(50% - 4px);
    margin-bottom: 8px;
    display: inline-flex;

    &--projects, &--average-project-value, &--average-profitability {
      margin-right: 4px;
    }

    &--proposals, &--conversion-rate {
      margin-left: 4px;
    }
  }

  &-info {
    padding: 12px 16px;
    background: $white;
    border: 1px solid $concrete-gray;;
    border-radius: 12px;

    &__pic {
      width: 50px;
      height: 50px;
      line-height: 50px;
      display: inline-block;
      margin-right: 25px;
      vertical-align: middle;

      .wethod-profile-pic__placeholder {
        font-size: 20px;
      }
    }

    &__description {
      display: inline-block;
      width: calc(100% - (32px + 50px + 25px)); //button width + pic width + pic margin
      vertical-align: middle;
    }

    &__name {
      font-size: 18px;
      font-weight: $semiBold;
    }

    &__secondary {
      margin-top: 4px;
    }

    &__group {
      font-size: 16px;
    }

    &__other {
      font-size: 16px;
      color: $dusty-gray;
    }

    &__button {
      display: inline-block;
      cursor: pointer;
      border-radius: 50%;
      vertical-align: middle;

      &:hover {
        background: rgba(0, 0, 0, 0.1);
        transition: 0.3s;
      }
    }
  }

  &-content {
    margin-top: 16px;
    padding-bottom: 30px;

    &__aside {
      display: inline-block;
      width: 30%;
      vertical-align: top;
    }

    &__main {
      display: inline-block;
      width: calc(70% - 16px);
      margin-left: 16px;
      vertical-align: top;

      &-title {
        display: inline-block;
        font-size: 18px;
        font-weight: $semiBold;
        margin-bottom: 28px;
      }

      &-description {
        font-weight: $light;
      }

      &-section {
        margin-top: 40px;
      }
    }

    &__add-button {
      display: inline-block;
      float: right;
    }
  }

  &-contacts {
    &__column {
      &--actions {
        width: 9%;
      }

      &--name {
        width: 20%;
      }

      &--surname {
        width: 20%;
      }

      &--role {
        width: 20%;
      }

      &--email {
        width: 31%;
      }
    }
  }

  &-projects {
    &__column {
      &--name {
        width: calc(40% - 64px);

        &-content {
          display: inline-block;
          line-height: normal;
          vertical-align: middle;
        }
      }

      &--label {
        display: block;
      }

      &--managers {
        display: block;
        font-size: 10px;
        color: $dusty-gray;
      }

      &--value {
        width: 20%;
        text-align: right;
      }

      &--revenues {
        width: 17%;
        text-align: right;
      }

      &--actions {
        width: 64px;
        text-align: right;
      }

      &--probability {
        width: 16%;
        text-align: right;
      }

      &--feedback {
        width: 24%;
        text-align: right;
      }
    }
  }

  &-revenues-report {
    background: $white;
    border-radius: 12px;
    border: 1px solid $concrete-gray;
    padding: 8px;
    height: 270px;
    margin-top: 8px;

    &__header {
      margin: 16px 8px 24px 8px;
      display: flex;
      justify-content: space-between;
      align-items: center;

      &-title {
        color: $dusty-gray;
      }
    }

    &__total {
      font-size: 18px;
      margin-top: 4px;
      display: inline-block;
    }

    &__chart {
      height: 180px;
    }
  }

  &-sold-value-report {
    background: $white;
    border-radius: 12px;
    border: 1px solid $concrete-gray;
    padding: 8px;
    margin-top: 8px;
    height: 240px;

    &__chart {
      height: 150px;
    }

    &__header {
      margin-top: 16px;
      margin-left: 16px;
      margin-bottom: 24px;
    }
  }

  &-kpi {
    &__time-filter {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      height: 56px;
      border-radius: 12px;
      border: 1px solid $concrete-gray;
      padding: 10px 16px;
      background-color: $white;

      &-item {
        font-size: 14px;
        padding: 4px 12px;
        border: none;
        margin: 0px 4px;
        cursor: pointer;
        font-weight: $light;
        outline: none;
        transition: .3s;
        width: 70px;
        background: rgba(0, 0, 0, 0.035);
        border-radius: 50px;

        &:hover:not(.client-kpi__time-filter-item--selected) {
          background: rgba(0, 0, 0, 0.1) !important;
        }

        &--selected {
          background: $bright-sky-blue !important;
          color: $white;
        }
      }
    }
  }
}
