.section-header {
  @include print() {
    display: none;
  }

  &__content {
    @include scaleToExtraSmall() {
      display: none;
    }
  }

  &__grid {
    @extend .fluid;
    @extend .grid;
    @extend .table;
    height: inherit;

    &--big {
      max-width: 1440px !important;
    }
  }

  &__title-grid {
    @extend .cell;
    @extend .col_8;
  }

  .section {
    &-title {
      &__parent {
        font-size: 12px;
        font-weight: $light;
        line-height: 17px;
        text-transform: uppercase;
      }

      &__current {
        font-size: 24px;
        line-height: 28px;
        text-transform: capitalize;
        display: inline-block;
        vertical-align: middle;
      }
    }
  }

  &__helper-modal {
    padding: 0 !important;
  }

  &__helper-alt {
    @extend .cell;
    @extend .col_8;
    padding-right: 0 !important;
    position: relative;
    text-align: right;
    @include scaleToExtraSmall() {
      display: none;
    }
    @include scaleToSmall() {
      display: none;
    }
  }

  &__helper-button-grid {
    padding: 3px 0 3px 10px !important;
    @include scaleToExtraSmall() {
      display: none;
    }
    @include scaleToSmall() {
      display: none;
    }
    @include print() {
      display: none;
    }
    @extend .cell;
    @extend .col_1;

    .helper-button__content {
      text-align: center;
      font-size: 18px;
      line-height: 31px;
      border: 1px solid $black;
      border-radius: 50%;
      font-weight: $light;

      @include transition(transform 0.2s linear);

      &:hover {
        cursor: pointer;
        background: $black;
        color: $white;

        .helper-button__icon {
          background-position-x: - 64px;
        }
      }
    }
  }

  &__bottom-spacer {
    height: 24px;
    @include scaleToExtraSmall() {
      display: none;
    }
  }
}

.helper-modal {
  &__wrapper {
    max-width: 1440px;
    position: relative;
    margin: auto;
    width: 100%;
  }

  &__header {
    padding: 16px 24px !important;
  }

  &__tabs {
    white-space: nowrap;
    transition: .4s;
    position: relative;
    left: 0;

    &--second-visible {
      left: -360px;
    }
  }

  &__body {
    padding: 12px 24px !important;
    max-height: 500px;
    overflow: auto;
    display: inline-block;
    white-space: normal;
    width: 100%;
    vertical-align: top;

    &--second {
      padding: 24px !important;
    }
  }

  &__subtitle {
    font-size: 16px;
    font-weight: $semiBold;
    letter-spacing: 0.2px;
    line-height: 22px;
    margin-top: 40px;
    margin-bottom: 16px;
  }

  &__container {
    background: $white;
    text-align: left;
    display: inline-block;
    max-width: 100%;
    position: absolute;
    transition: .2s;
    width: 0;
    max-height: 0;
    opacity: 0;
    overflow: hidden;
    float: right;
    top: 0;
    right: 0;
    border: 1px solid $concrete-gray;
    border-radius: 12px;
    box-shadow: 0px 2px 20px rgb(0 0 0 / 10%);
  }

  &__list {
    padding: 0 !important;
    margin: 0 !important;
  }

  &__item {
    display: block;
    margin: 12px 0 !important;
    border-radius: 6px;
    border: 1px solid #D2D2D2;

    a {
      color: #333;
      display: block;
      cursor: pointer;
    }

    &-arrow {
      opacity: 0.6;
      display: inline-block;
      vertical-align: middle;
    }

    &-label {
      display: inline-block;
      vertical-align: middle;
      width: calc(100% - 87px);
      margin: 0 8px;
    }

    &:hover {
      background: #ececec;
    }
  }

  &__quick-link {
    a {
      padding: 13px 8px;
    }

    &-icon {
      display: inline-block;
      vertical-align: middle;
    }

    &-title {
      font-size: 14px;
      font-weight: $semiBold;
      line-height: 19px;
      color: initial;
      display: block;
    }

    &-subtitle {
      color: $dusty-gray;
      font-size: 12px;
      line-height: 17px;
      display: block;
    }
  }

  &__how-to-link {
    a {
      padding: 7px 8px;
    }

    &-title {
      font-size: 14px;
      line-height: 19px;
      color: initial;
      display: block;
    }

    .helper-modal__item-label {
      width: calc(100% - 52px);
    }
  }

  &__button {
    position: relative;

    &-icon {
      position: absolute;
      display: inline-block;
      left: 0;
      top: 2px;
    }

    &:hover {
      .helper-modal__button-icon {
        background-position-x: -49px;
      }
    }
  }

  &__paragraph {
    margin: 16px 0;
    padding: 0 2px;
  }
}

.shaded {
  content: "";
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 900;
  background: rgba(204, 204, 204, 0.3);
}

.section-bookmark {
  opacity: 80%;

  &__checkmark {
    display: inline-block;
    vertical-align: middle;
    margin-right: 8px;
  }

  &__label {
    display: inline-block;
    cursor: pointer;

    &-text {
      opacity: 0;
      display: inline-block;
      width: 0;
      height: 0;
      overflow: hidden;
      transition: width 0s linear 0.3s, height 0s linear 0.3s, opacity 0.3s linear;

      &-container {
        display: inline-block;
        vertical-align: middle;
        line-height: 10px;
      }
    }

    &:hover {
      /* A .section-bookmark__label-text-container NOT preceded by a checked input */
      input[type=checkbox]:not(:checked) ~ .section-bookmark__label-text-container {
        .section-bookmark__label-text--hint-save {
          width: auto;
          height: auto;
          opacity: 1;
        }
      }

      /* A .section-bookmark__label-text-container preceded by a checked input */
      input[type=checkbox]:checked ~ .section-bookmark__label-text-container {
        .section-bookmark__label-text--hint-remove {
          width: auto;
          height: auto;
          opacity: 1;
        }
      }
    }
  }

  input[type=checkbox] {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;

    & + .section-bookmark__checkmark {
      @extend .wethod-icon-medium-bookmark-empty;
      @extend .wethod-icon-medium-bookmark-empty--black;
    }

    &:checked + .section-bookmark__checkmark {
      @extend .wethod-icon-medium-bookmark-full;
      @extend .wethod-icon-medium-bookmark-full--black;
    }
  }
}

