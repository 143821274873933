.suppliers {
  max-width: 1440px !important;
  padding: 0 24px;

  &__bu-select {
    float: left;
  }

  .wethod-table__content {
    display: block;
  }

  &__actions {
    position: relative;
    height: 142px;
    padding: 22px 0;

    .wethod-button:not(.wethod-multiple-select-filter__apply-button) {
      float: right;
      line-height: 30px;
    }
  }

  &__column {
    &--name {
      width: calc(21% - 4px);
      padding-left: 12px;
    }

    &--email {
      width: 17%;
    }

    &--tag {
      width: 26%;

      &-medium {
        width: 41%;
      }

      &-large {
        width: 56%;
      }

    }

    &--rating {
      width: 15%;
    }

    &--status {
      width: 15%;
      text-align: center;
    }

    &--actions {
      width: 6%;
      text-align: right;
    }
  }

  &__sidebar {
    &-body {
      padding: 24px 0;
    }

    &--multiple-input {
      &-row {
        display: table;
        width: 100%;
      }

      &-element {
        display: table-cell;
        vertical-align: middle;
      }
    }
  }

  &__modal {
    font-size: 14px;

    .wethod-modal2__body {
      line-height: 1.5;

      ul {
        list-style: none;
      }

      li {
        text-align: left;
      }
    }

    .wethod-modal-list {
      li:hover {
        background-color: transparent;
      }
    }

    .wethod-search-select__list {
      max-height: 130px;
    }
  }

  &__orders {
    &--header {
      padding: 0 24px;
      color: $dusty-gray;
      display: block;
    }

    &--project {
      width: 300px;
      display: inline-block;
    }

    &--value {
      width: 80px;
      display: inline-block;
      text-align: right;
    }
  }

  &__status {
    &--not-editable {
      cursor: initial;
      pointer-events: none;
    }
  }

  &__tags {
    &-list {
      padding-left: 45px
    }

    &-select {
      padding: 10px;
      width: 270px;
      max-height: 330px;
      overflow: hidden;
    }

    &-results {
      padding: 5px 5px 0;
      line-height: 1.5;
      max-height: 275px;
      overflow: auto;

      &--empty {
        color: $dusty-gray;
        text-align: center;
        display: block;
      }
    }

    &-category {
      &:not(:last-child) {
        margin-bottom: 7px;
      }

      &--title {
        display: block;
        font-weight: $semiBold;
      }
    }

    &-menu {
      .material-input__helper-text {
        display: none;
      }

      label {
        display: block;

        input[type="checkbox"] {
          vertical-align: middle;
          margin-right: 7px;
        }
      }

      .search-input {
        border: 1px solid #ECECEC;
        border-radius: 4px;
        line-height: 14px;
        margin-bottom: 5px;
        padding-right: 10px;
      }
    }
  }

  &__duplicate {
    &-list {
      max-height: 40vh;
      width: 500px;
      overflow: auto;
      border: 1px solid $concrete-gray;
      border-radius: 8px;


      @include scaleToExtraSmall() {
        width: 100%;
      }
    }

    border-bottom: 1px solid $concrete-gray;
    padding: 8px 16px;

    &:last-child {
      border-bottom: none;
    }

    > * {
      display: inline-block;
      vertical-align: middle;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }

    &-name {
      width: 50%;
    }

    &-email {
      width: 50%;
    }
  }

  &__province {
    .material-input__input {
      text-transform: capitalize;

      input {
        text-transform: capitalize;
      }
    }
  }
}

.recordList__record__hints__list {
  .financeOrder__supplier-hint-list {
    .recordList__record__hints__list__empty {
      color: initial;

      a {
        padding: 0;
        color: $bright-sky-blue;
      }

      p {
        line-height: 16px;

        &:first-child {
          margin-bottom: 16px;
        }
      }
    }
  }
}

@import "supplier-detail";
