@import 'bourbon/bourbon';
@import 'settings';

$bigFont: 30px;
$rowHeight: 60px;
$borderSize: 1px;
$fontColor: #353245;
$borderColor: #bfc0c6;
$actionColor: $bright-sky-blue;
$errorColor: $raspberry-red;

/* GENERIC */

html, body, #appBody {
  //height: auto !important;
  //min-height: 100% !important;
}

.settings {
  &-feedback {
    color: $bright-sky-blue;

    &--error {
      color: $colorError;
    }
  }
}

.hiddenRow {
  display: none;
}

.autoClip {
  overflow: auto;
}

.spacedBox {
  padding-top: 20px;
  padding-bottom: 20px;
}

.bottomSpace {
  padding-bottom: 40px;
}

.topMargin {
  margin-top: 20px;
}

.titleSettings {
  line-height: 1.3;
  display: inline-block;

  &.highlight {
    color: $actionColor;
  }
}

.contentTitle {
  @extend .titleSettings;
  font-size: 16px;
  padding: 8px 0 0 30px;
}

.headTitle {
  @extend .titleSettings;
  font-weight: $light;
  font-size: $bigFont;
  margin: 30px 0;
}

.fillHeight {
  height: auto;
}

.standardInput {
  outline: none;
  background-color: transparent;
  border: none;
  border-bottom: 1px solid $borderColor;
  font-size: 12px;
  padding: 0 0 25px 10px;
  width: 100%;
  height: 37px; // 25padding + 12height
}

.paddingTop {
  padding-top: 20px;
}

.hint {
  opacity: 0.3;
}

/* ACCOUNT */

.fluid .notificationLabel {
  font-size: 14px;
  text-align: right;
  margin-top: 10px;
  padding-left: 10px;

  &.errorMessage {
    color: $errorColor;
  }

  &.correctMessage {
    color: green;
  }

  &.feedbackMessage {
    color: $actionColor;
  }
}

.inputUl {
  margin: 40px 0;

  li {
    margin-top: 25px;
  }
}

.standardInputAccount {
  @extend .standardInput;
  height: 40px;
  padding: 0 0 20px 10px;
}

.accountAccordion {
  list-style: none;
  display: block;

  li {
    display: block;
    margin-bottom: 3px;
    //height
    .header {
      @extend .table;
      width: 100%;
      height: 30px;
      text-align: center;
      background: $bright-sky-blue;
      color: $white;
      text-transform: uppercase;
    }
  }
}

.importHeaderTitle {
  @extend .titleSettings;
  font-weight: $light;
  font-size: 30px;
  margin: 30px 0;
}

.newInvitation {
  display: none;
}

.empIconContainer {
  position: absolute;
  font-size: 8px;
  padding: 0 !important;
  text-transform: uppercase;
  font-weight: $semiBold;
  bottom: 3px;
  left: 9px;
  width: 600px;

  .empIconItem {
    color: #ccc;
    padding: 0;
    float: left;
    width: auto;
    margin-right: 10px;
    display: none;

    &--error {
      color: $errorColor;
    }
  }
}

.spriteActionInvitation {
  @include retina-image($iconsPath + edit_icons, 150px 60px);
}

.spriteDelete {
  @include retina-image($iconsPath + delete_icon, 30px 30px);
}

.spriteDeleteInvitation {
  @extend .spriteDelete
}

.spriteDeleteCompany {
  @extend .spriteDelete
}

.icon {
  width: 30px;
  height: 30px;
  margin: 10px 0 0 0;
  cursor: pointer;
}

.iconInvitation {
  @extend .icon;
  margin: 12px 0 0 0;
}

.iconCompany {
  @extend .icon;
  margin: 8px 0 0 0;
}

.iconCompanyDelete {
  @extend .icon;
  margin: 8px 0 8px 0;
}

.moreAction {
  background-position: 30px 0;

  &:hover {
    background-position: 30px 30px;
  }
}

select.settingSelect {
  display: inline-block;
  border: none;
  background-color: $cream-white;
  font-size: 12px;
  border-radius: 0;
  margin: 14px 0;
  padding: 5px !important;
  @include appearance(none);
}

.itemRow {
  border-bottom: solid 1px $borderColor;

  select {
    @extend .settingSelect;
  }

  span {
    display: inline-block;
    padding: 18px 5px;
  }

  .roundButton {
    margin: 8px 0;

    span {
      display: inline-block;
      padding: 0;
    }
  }
}

.actualRow {
  @extend .itemRow;

  select {
    width: 100%;
    display: inline-block;
    border: none;
    background-color: $concrete-gray;
    font-size: 12px;
    border-radius: 0;
    margin: 9px 0;
    padding: 5px !important;

    @include appearance(none);
  }

  .autocompleteTag {
    position: relative;
    width: 9.7%;

    input[type="text"] {
      width: 100%;
      padding: 4px;
      margin-top: 10px;
      border: none;
      outline: none;
      background: $concrete-gray;

      &:disabled {
        opacity: .3;
      }
    }
  }

  .inputNewTag {
    margin-top: 0 !important;
  }
}

.fluid {
  .actualRowInput {
    @extend .itemRow;
    float: left;
    width: 100%;

    input[type="text"] {
      width: 100%;
      background: $concrete-gray;
      border: none;

      &:disabled {
        opacity: .3;
      }
    }

    &__tag-header {
      margin-left: 65.5%;
      width: 30%;

      li {
        width: 33%;
      }
    }
  }

  .actualRowInputWithJobTitle {
    @extend .itemRow;
    float: left;
    width: 100%;

    input[type="text"] {
      width: 100%;
      background: $concrete-gray;
      border: none;

      &:disabled {
        opacity: .3;
      }
    }

    &__tag-header {
      margin-left: 75%;
      width: 30%;

      li {
        width: 33%;
      }
    }
  }
}

.invitationRow {
  @extend .itemRow;

  input {
    display: inline-block;
    margin: 11px 0;
    padding: 5px;
    border: none;
    background-color: $cream-white;
    font-size: 12px;
  }

  .levelSelect, .roleSelect {
    width: 100%;
    margin: 12px 0;
  }
}

.disabled {
  opacity: 0.25 !important;
}

.tamModalStructure {
  @extend .modalStructure;
}

// COMPANY
.companyLevelRow {
  @extend .itemRow;

  input {
    display: inline-block;
    margin: 11px 0;
    padding: 5px;
    border: none;
    background-color: inherit;
    font-size: 12px;
    outline: none;
  }

  .levelNumberInput {
    width: 100%;
    text-align: right;
    padding-right: 45%;
  }

  .levelCheckboxCenter {
    margin-top: 17px;
    font-size: 20px;
  }

  .notDeletable {
    opacity: 1;
    //opacity: 0.4;
  }
}

.companyPT__add-button {
  margin: 20px 0;
}

.companyPTRow {
  @extend .itemRow;

  &__cell {
    &--color {
      position: sticky;
      left: 0;
      background: $alabaster-white;

      &.picker-visible {
        z-index: 1;
      }
    }

    &--type {
      position: sticky;
      left: 100px;
      background: $alabaster-white;
      box-shadow: rgba(0, 0, 0, 0.15) 6px 0 6px -2px;
    }

    &--action {
      position: sticky;
      right: 0;
      background: $alabaster-white;
      box-shadow: rgba(0, 0, 0, 0.15) -6px 0px 6px -2px;

      .roundButton {
        width: 60px;
        float: right;
      }
    }
  }

  &:not(.hiddenRow) {
    display: flex;
    position: relative;
  }

  border-bottom: none;

  li {
    flex-shrink: 0;
    border-bottom: solid 1px #bfc0c6;
  }

  .col_1 {
    width: 100px;
  }

  .col_2 {
    width: 200px;
  }

  input {
    display: inline-block;
    margin: 11px 0;
    padding: 5px;
    border: none;
    background-color: inherit;
    font-size: 12px;
    outline: none;
    max-width: 100%;
  }

  .textCenter {
    //width: 10%;
  }

  .PTCheckboxCenter {
    margin-top: 20px;
    font-size: 20px;
  }

  &__delete {
    text-align: right;
  }

  .roundButton {
    padding: 9px 16px !important;
    margin: 12px 0;
  }

  &.hiddenRow {
    .companyPTRow__delete {
      width: 4%;
    }
  }
}

.companyDARow {
  @extend .itemRow;

  input {
    width: 100%;
    display: inline-block;
    margin: 11px 0;
    padding: 5px;
    border: none;
    background-color: inherit;
    font-size: 12px;
    outline: none;
  }

  //Hide arrows from number input
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input[type=number] {
    -moz-appearance: textfield;
    text-align: right;
  }

  input[percentage] + span::after {
    content: '%';
  }

  textarea {
    border: none;
    display: inline-block;
    margin: 11px 0;
    padding: 5px;
    background-color: inherit;
    font-size: 12px;
    outline: none;
  }
}

.companyProjectReviewRow {
  @extend .itemRow;

  &:first-of-type {
    border-top: solid 1px $borderColor;
  }

  &-spaced {
    border-top: solid 1px $borderColor;
    margin-top: 60px;
  }

  a {
    display: inline-block;
    vertical-align: top;
    margin: 11px !important;

    span {
      margin: 0;
      line-height: 11px;
    }
  }

  span {
    vertical-align: top;
    line-height: normal;
    margin: 11px 15px 11px 0;
  }

  input {
    display: inline-block;
    padding: 5px;
    border: none;
    background-color: inherit;
    font-size: 12px;
    outline: none;
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input[type=number] {
    -moz-appearance: textfield;
  }

  textarea {
    border: none;
    display: inline-block;
    margin: 11px 15px 11px 0;
    padding: 5px;
    background-color: inherit;
    font-size: 12px;
    outline: none;
  }
}

.companyProjectReviewJOC {
  margin-top: 40px;
  margin-bottom: 40px;

  &__description {
    opacity: 0.5;
    line-height: 18px;
  }

  &__col {
    border: none !important;
  }

  &__row {
    border-bottom: solid 1px #bfc0c6;
  }

  &__num-input {
    width: 100%;
    text-align: right;
  }

  &__feedback {
    color: $bright-sky-blue;
    font-size: 11px;
    position: absolute;
    bottom: 5px;
    padding-bottom: 0 !important;
    left: 3px;
  }
}

.companyPTRow.companyProjectReviewJOC {
  &__row {
    cursor: auto;
    width: auto !important;
  }
}

.companyLabelRow {
  @extend .itemRow;

  input {
    width: 100%;
    display: inline-block;
    margin: 11px 0;
    padding: 5px;
    border: none;
    background-color: inherit;
    font-size: 12px;
    outline: none;
  }

  input[type="radio"] {
    margin: 18px 0;
  }
}

.companyExpenseRow {
  input {
    margin-top: 16px;
  }

  input[type="number"] {
    width: 55%;
  }

  &--archived li > span:not(.company__timesheet-option-description) {
    text-decoration: line-through;
  }
}

.companyOpportunityStatusDescription {
  line-height: 1.5;
}

.companyOpportunityStatusRow {
  @extend .itemRow;

  input {
    width: 100%;
    display: inline-block;
    margin: 11px 0;
    padding: 5px;
    border: none;
    background-color: inherit;
    font-size: 12px;
    outline: none;
  }

  &--draggable:not(.hiddenRow) {
    cursor: move;
  }
}

.companyTopBorderRow {
  border-bottom: none;
  border-top: 1px solid $borderColor;
}

.companyHeaderTitle {
  @extend .titleSettings;
  font-weight: $light;
  font-size: 24px;
  margin: 30px 0;
}

.companyLevelsTitle {
  @extend .titleSettings;
  font-size: 18px;
}

.companyPTColorWrap {
  padding-top: 12px;
  padding-left: 8px;

  .companyPTColor {
    display: inline-block;
    width: 24px;
    height: 24px;
    padding: 0;
    border-radius: 50%;
    background: #dedede;
    cursor: pointer;
  }

  li {
    border-bottom: none;
  }

  .tooltip {
    width: 118px;
    min-width: 0;

    .PTColorPicker {
      display: inline-block;
      width: 24px;
      height: 24px;
      padding: 0;
      border-radius: 50%;
      background: #dedede;
      cursor: pointer;
    }
  }

  .isVisible {
    display: inline-block !important;
  }
}

.companyContactTitle {
  @extend .titleSettings;
  font-size: 18px;
}

.companyContactRow {
  border-bottom: solid 1px #bfc0c6;

  span {
    display: inline-block;
    margin: 2px;
  }

  input {
    height: 25px;
    border: none;
    display: inline-block;
    margin: 11px 0;
    padding: 5px;
    background-color: inherit;
    font-size: 12px;
    outline: none;
  }
}

.companyContactRow:last-of-type {
  border-bottom: none;
}

// IMPORT
#importHeader {
  padding-bottom: 20px;
  border-bottom: 2px solid #DDD;
  margin-bottom: 20px;
}

.levelRate {
  margin: 30px 0 20px 0;

  li {
    display: inline-block;
    text-transform: capitalize;
    text-align: center;
  }

  .importLevelHeader {
    color: $actionColor;
  }

  .importLevelInput {
    input {
      width: 100%;
      height: 25px;
      text-align: center;
      background-color: #efefef;
      border: none;
    }
  }

  .levelPercentSum {
    margin-top: 32px;
    font-size: 14px;
    color: $actionColor;
    text-align: center;
    display: inline-block;
  }

  .error {
    color: red;
  }

  .correct {
    color: green;
  }
}

.importTypeSelect {
  @extend .settingSelect;
  margin-top: 22px !important;
}

#importForm {
  margin-bottom: 20px;
}

.importExplain {
  color: $dusty-gray;
  float: left;
  display: block;
  margin-top: 8px;
  margin-right: 20px;
  line-height: 1.3;
}

.notificationBox {
  margin-top: 10px;
}

//MODAL
.companyLevelModalStructure {
  @extend .modalStructure;
}

.companyPTModalStructure {
  @extend .modalStructure;
  min-height: 0;

  .companyModalProjects {
    padding: 18px 6px;

    ::-webkit-scrollbar {
      -webkit-appearance: none;
      width: 7px;
    }

    ::-webkit-scrollbar-thumb {
      border-radius: 4px;
      background-color: rgba(0, 0, 0, .5);
      -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, .5);
    }

    ul {
      list-style: none;
      margin: 0;
      max-height: 147px;
      overflow: hidden;
      overflow-y: scroll;

      li {
        padding: 5px;
      }
    }
  }

  .companyModalPTSelect {
    padding: 18px 6px;

    span {
      margin-top: 7px;
    }

    select {
      @extend .settingSelect;
      margin: 0 0 0 5px;
    }
  }

  .fillModal {
    height: 100%;
    width: 100%;
  }
}

//ACCOUNT
.deleteAccountLAbel {
  color: $dusty-gray;
  float: left;
  display: block;
  margin-top: 8px;
  margin-right: 20px;
  line-height: 1.3;
}

// WON LOST CONTACTS
.wonLostContact {

  .companyWonLostTitle {
    @extend .titleSettings;
    font-size: 18px;
  }

  .companyWonLostRow {
    border-bottom: solid 1px #bfc0c6
  }

  .companyWonLostRow:last-of-type {
    border-bottom: none;
  }

  .companyWonLostInput {
    height: 25px;
    border: none;
    display: inline-block;
    margin: 11px 0;
    padding: 5px;
    background-color: inherit;
    font-size: 12px;
    outline: none;
  }
}

.companyCurrencyRow {
  line-height: 49px;
}

.companySection > .grid {
  padding-left: 9px;
  padding-right: 9px;
}

.companyMultiRowHeader {
  span {
    &:first-child {
      padding-top: 12px;
    }

    padding: 3px 5px;
  }
}
