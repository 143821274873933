@import "planning/planning_actions";
@import "planning/planning_navigation";
@import "planning/production-plan";

$profile-image-width: 54px;

.section {
  @extend .grid;
  @include scaleToExtraSmall() {
    overflow: hidden;
  }
}

.top {
  @extend .table-header--fixed;
}

.actions {
  margin: 16px 0;
  @include scaleToExtraSmall() {
    margin: 10px 0;
  }
}

.planning {
  &-row {
    &--no-employees {
      height: 54px;
      background: $white;
      width: 1024px;
      text-transform: uppercase;
      font-weight: $semiBold;
      color: $steel-gray;
      text-align: center;
      line-height: 54px;
      position: absolute;

      @include scaleToExtraSmall() {
        width: 100vw;
      }
    }
  }

  &__loading {
    @extend .miniLoadingWrap;
    margin: 16px auto !important;
  }

  &__content {
    width: 1024px;
    overflow-x: scroll;
    overflow-y: hidden;
    margin-top: 16px;

    &--projects {
      @extend .planning__content;
      min-height: 530px;
      @include scaleToExtraSmall() {
        padding-top: 80px;
      }
    }

    &--big {
      padding-top: 235px;
      @include scaleToExtraSmall() {
        padding-top: 228px;
      }
    }
  }

  &__employee {
    margin-bottom: 15px;
    border-radius: 30px 0 0 30px;
    @include scaleToExtraSmall() {
      margin-bottom: 1px;
    }
  }

  &__project {
    @extend .planning__employee;
    margin-bottom: 1px;
  }

  &__teammates {
    .employee__info {
      color: #2b2e38;
    }

    .employee__week {
      @extend .employee__week;
      border-bottom: 2px solid rgba(155, 155, 155, 0.5);
      @include scaleToExtraSmall() {
        border-bottom: none;
      }
    }
  }

  &__current-user {
    .employee__level {
      color: rgba(54, 143, 245, 0.7)
    }

    .planning__employees {
      .employee__info {
        color: $bright-sky-blue;
      }
    }
  }

  &__header, &__employees {
    width: 1024px;
    margin: 0 auto;
  }

  &__employees {
    .employee__info {
      color: #2b2e38;
    }
  }

  &__header {
    @extend .noSelect;
    overflow-x: scroll;
    float: left;
    background: $white;
    box-shadow: 0 5px 7px -3px rgba(0, 0, 0, 0.3);
    @include scaleToExtraSmall() {
      box-shadow: none;
    }

    &::-webkit-scrollbar {
      display: none;
    }
  }

  &__project-select, &__filter-people {
    float: left;
    position: relative;

    .button__icon {
      display: block;
      position: absolute;
      top: 0;
      right: 0;
      transition-duration: 0s;
    }
  }

  &__filter-people {
    margin-left: 13px;
    @include scaleToExtraSmall() {
      margin-left: 8px;
    }
  }

  &__button-link {
    border: none;
    outline: none;
    background: transparent;
    cursor: pointer;
    color: $bright-sky-blue;
  }

  &-conversion-modal {
    padding-bottom: 0;

    .wethod-modal2__container {
      margin-top: 62px;
    }

    .wethod-modal2__body {
      width: 700px;
      padding: 24px;
    }

    .budget-table-header__total-label {
      position: relative;
    }

    .budget-table-header__days, .budget-area__days, .budget-task__days {
      width: 10%;
    }

    .budget-task__days-new {
      width: 100%;

    }
    .budget-table-header__total-days {
      width: 14%;
    }

    .budget-table-header__levels, .budget-table-header__total-levels, .budget-area__levels, .budget-task__levels {
      width: 70%;
      &--horizontal-scroll {
        overflow-x: auto;
        display: flex;
        scrollbar-width: none;
      }
    }

    .budget-area__levels-new, .budget-task__levels-new {
      width: 64%;
      display: flex;
    }

    .budget-table-header__total-levels-new {
      width: 64%;
      display: inline-block;
      &--horizontal-scroll {
        overflow-x: auto;
        display: flex;
        scrollbar-width: none;
      }
    }
    .budget-table-header__levels-new {
      width: 64%;
      display: inline-block;
      &--horizontal-scroll {
        overflow-x: auto;
        display: flex;
        scrollbar-width: none;
      }
      &--horizontal-scroll:hover {
        overflow-x: auto;
        display: flex;
        scrollbar-width: thin;
      }
    }

    &__areas {
      overflow-y: auto;
      max-height: 360px;

      &--grid {
        display: grid;
        grid-template-columns: 18% 64% 18%;
      }
      &-column-left {
        grid-column: 1;
        z-index: 7;
      }
      &-column-center {
        grid-column: 2;
        max-width: 528px;
      }
      &-column-right {
        grid-column: 3;
        z-index: 6;
      }
    }

    &__feedback {
      text-align: left;
      color: $bright-sky-blue;
      font-size: 12px;
      margin-left: 16px;

      &--error {
        color: $raspberry-red;
      }
    }

    &__conversion {
      position: absolute;
      top: 3px;
      width: 100%;
      font-size: 10px;
      line-height: 12px;

      &--label {
        color: $bright-sky-blue;
      }

      &--positive {
        color: $garden-green;
      }

      &--negative {
        color: $raspberry-red;
      }
    }
  }
}

.planning-show-shadow-left {
  box-shadow: rgba(0, 0, 0, .15) 6px 0 6px -2px;
  @include scaleToMobile() {
    box-shadow: none;
  }
}

.planning-show-shadow-right {
  box-shadow: rgba(0, 0, 0, .15) -6px 0px 6px -2px;
  @include scaleToMobile() {
    box-shadow: none;
  }
}

.planning--disabled {
  background-color: #DFDFDF !important;
}

.disabled--profile-image {
  width: $profile-image-width;
  height: $profile-image-width;
  background-color: rgba(255, 255, 255, 0.7);
  border-radius: 50%;
  position: absolute;
}

.header__name, .employee__details {
  float: left;
  background: $white;
  position: absolute;
  z-index: 2; // prevent 'opaque' child to be show on top
}

.header__weeks, .employee__weeks {
  display: inline-block;
  white-space: nowrap;
  width: 874px;
  margin-left: 150px;
  @include scaleToExtraSmall() {
    //margin-left: 102px;
  }
}

.header__week, .employee__week {
  display: inline-block;
  border-right: 1px solid #ebeef1;
  width: 25%;
}

.header__days, .employee__days {
  white-space: nowrap;
  margin-left: 0;
  width: 100%;
}

.month-name, .header__days, .month-name--left, .month-name--right {
  font-size: 12px;
  line-height: 17px;
}

.header__day, .employee__day {
  width: 20%;
  display: inline-block;
  vertical-align: bottom;
}

.header__day, .employee__half-day {
  text-align: center;
}

.header {
  &__edge-placeholder {
    width: 151px;
    height: 60px;
    background: $white;
    background: linear-gradient(to right, #fff, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.7),
            rgba(255, 255, 255, 0.6), rgba(255, 255, 255, 0));
    float: left;
    position: absolute;
    z-index: 10;
    @include scaleToExtraSmall() {
      //width: 60px;
    }
  }

  &__weeks {
    display: block;
  }

  &__week {
    height: 60px;
    background: $white;
    border: none;
  }

  &__days {
    color: $dusty-gray;
    text-align: center;
    float: left;
    border-right: 1px solid #ebeef1;
  }

  &__day {
    float: left;
    line-height: 30px;
    height: 30px;
    position: relative;
  }

  &--today {
    z-index: 2;
    color: $white;
    position: relative;
  }

  &__today-highlighter {
    width: 30px;
    height: 30px;
    background-color: $bright-sky-blue;
    position: absolute;
    border-radius: 50%;
    z-index: 1;
    left: 15%;
  }
}

.employee {
  &__details {
    height: $profile-image-width;
    width: 150px;
    border-radius: 30px 0 0 30px;
    float: none;
    @include scaleToExtraSmall() {
      width: 102px;
      border-radius: 0;
    }

    &:hover {
      background: #edecec;
    }
  }

  &__details-mask {
    background: $alabaster-white;
    width: 26px;
    height: $profile-image-width;
    position: absolute;

    &--big {
      @extend .employee__details-mask;
      height: $profile-image-width + 2;
      @include scaleToExtraSmall() {
        height: $profile-image-width;
      }
    }

    @include scaleToExtraSmall() {
      display: none;
    }
  }

  &__image {
    float: left;
    height: $profile-image-width;
    border-radius: 50%;
    width: $profile-image-width;
    position: relative;
    z-index: 1;
    font-size: 20px;
    line-height: 52px;

    &--big {
      @extend .employee__image;
      height: $profile-image-width + 2;
      width: $profile-image-width + 2;
      @include scaleToExtraSmall() {
        height: $profile-image-width;
        width: $profile-image-width;
      }

      .wethod-avatar {
        width: 56px;
        height: 56px;
        line-height: 56px;
      }
    }

    @include scaleToExtraSmall() {
      display: none;
    }

    &-background {
      width: $profile-image-width;
      height: $profile-image-width;
      background-color: $white;
      border-radius: 50%;
      position: absolute;
      z-index: -1;

      &--big {
        @extend .employee__image-background;
        height: $profile-image-width + 2;
        width: $profile-image-width + 2;
        @include scaleToExtraSmall() {
          height: $profile-image-width;
          width: $profile-image-width;
        }
      }
    }

    &-placeholder {
      background: $bright-sky-blue;
      color: $white;
      height: $profile-image-width;
      border-radius: 50%;
      width: $profile-image-width;
      line-height: $profile-image-width;
      text-align: center;
      font-size: 20px;
      text-transform: uppercase;

      &--big {
        @extend .employee__image-placeholder;
        height: $profile-image-width + 2;
        width: $profile-image-width + 2;
        @include scaleToExtraSmall() {
          height: $profile-image-width;
          width: $profile-image-width;
        }
      }
    }
  }

  &__info {
    height: inherit;
    margin-left: 60px;
    padding: 3px 0;
    position: relative;
    @include scaleToExtraSmall() {
      margin-left: 5px;
    }
  }

  &__name, &__area, &__level {
    font-size: 12px;
    line-height: 16px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  &__okr {
    position: absolute;
    bottom: 0;
    left: -1px;
    width: 100%;

    &-quarter {
      width: calc(25% - 4px);
      height: 1px;
      display: inline-block;
      vertical-align: bottom;
      margin: 0 2px;
      float: left;

      &--draft {
        background: rgba(0, 0, 0, 0.2);
      }

      &--discussed {
        background: $bright-sky-blue;
      }

      &--checked {
        background: $garden-green;
      }
    }
  }

  &__name {
    font-weight: $bold;
  }

  &__level {
    text-transform: uppercase;
    color: #999;
  }

  &__days {
    height: $profile-image-width;
  }

  &__half-day {
    @extend .noSelect;
    white-space: normal;
    padding: 1px;
    display: block;
    height: 27px;
    font-size: 10px;
    line-height: 13px;
    color: $white;
    text-decoration: none;
    text-overflow: ellipsis;
    cursor: pointer;
    position: relative;
  }

  &__half-day:hover {
    background: #97caf8 !important;
  }
}

.month-name {
  &--left, &--right {
    height: 30px;
    color: #505050;
    text-transform: uppercase;
    padding: 10px 12px 3px 12px;
  }

  &--left {
    float: left;
  }

  &--right {
    float: right;
  }
}

.section-mask {
  position: fixed;
  width: 100%;
  height: 100%;
  background: $white;
  z-index: 1000;
  opacity: 0.5;
  left: 0;
  @include scaleToExtraSmall() {
    top: 108px;
  }
}

.spanElipse {
  overflow: hidden;
  text-overflow: ellipsis;
  height: 100%;
  width: 100%;
  display: block;
}

@import "planning/projects/planning-projects";
@import "planning/planning-international";
