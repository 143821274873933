.kudos {
  max-width: 1440px !important;
  padding: 0 24px;

  @include scaleToExtraSmall() {
    max-width: 1024px !important;
    padding: 0;
  }

  .wethod-section-actions {
    padding: 16px 0;
  }

  &__title {
    font-size: 18px;
    line-height: 24px;
    padding: 24px 0;
  }

  &__multiple-table {
    padding-bottom: 24px;

    .wethod-table {
      &:first-child {
        width: 25%;
      }
      &:last-child {
        width: 75%;
      }
    }
  }

  &__column {
    &-criterion {
      white-space: pre-line;
    }
    &-name {
      width: 25%;
      display: inline-flex;
      align-items: center;
      height: 100%;
    }
    &-project {
      width: 11%;
    }
    &-review {
      width: 11%;
    }
    &-trend {
      width: 35%;
      height: 100%;

      display: inline-flex;
      justify-content: space-around;
    }
    &-avg {
      width: calc(18% - 68px);
      &-header {
        width: 18%;
        padding-left: 2%;
        text-align: left;
      }
    }
    &-link {
      width: 68px;
    }
    &--right {
      text-align: right;
    }
  }

  &__criterion-row {
    position: relative;
    height: unset;
    cursor: pointer;
    transition: .2s;
    padding: 16px 0;
    line-height: normal;

    &:hover {
      background-color: #EBEBEB;
    }

    &--selected {
      background-color: #FAFAFA;

      &::before {
        content: "";
        position: absolute;
        top: 0;
        height: 100%;
        width: 4px;
        background-color: $bright-sky-blue;
      }
    }
  }

  &__employee {
    &-row {
      height: 64px;
      line-height: 64px;
      background-color: #FAFAFA;
    }
    &-pic {
      height: 48px;
      width: 48px;
      line-height: 48px;
      font-size: 18px;
      margin-right: 24px;
    }
    &-info {
      overflow: hidden;
      flex: 1;

      & * {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        line-height: 1.3;
        font-size: 11px;
      }
      & >:first-child {
        font-size: 14px;
        font-weight: $semiBold;
      }
      & >:last-child {
        color: $dusty-gray;
        text-transform: uppercase;
      }
    }
  }
}
