.review-changelog {
  max-width: 1440px !important;
  padding: 0 24px;

  &__canvas-link {
    color: $black !important;
    text-decoration: none;
  }

  &-more-button {
    padding: 3px !important;
    transition: 0s !important;
    border: none !important;

    &:hover {
      background: rgba(0, 0, 0, 0.1) !important;
      color:$black !important;
    }
  }

  @include scaleToExtraSmall() {
    max-width: 1024px !important;
    padding: 0;
  }

  .wethod-section-actions {
    padding: 16px 0;
  }

  &__actions {
    display: flex;

    .planning-navigation {
      left: -32px;
      float: none;
    }
  }

  &__buttons {
    text-align: center;
    li:nth-child(n+3):last-child {
      border-top: 1px solid $concrete-gray;
    }
  }

  &__column {
    &-project {
      width: 32%;
    }
    &-employee {
      width: 18%;
    }
    &-date {
      width: 24%;
    }
    &-total {
      width: 16%;
      text-align: right;

      &--completed {
        color: $garden-green;
      }
      &--missing {
        color: #D94F6B;
      }
    }
    &-options {
      width: 10%;
      text-align: right;

      .wethod-icon-button {
        border: none;

        &:hover {
          background: rgba(0, 0, 0, 0.1);
        }
      }
    }
  }

  &__modal {
    font-size: 14px;

    b {
      font-weight: $bold;
    }

    .wethod-modal2__body {
      line-height: 20px;
    }

    .wethod-modal2__container {
      max-width: 520px;
    }
  }
}
