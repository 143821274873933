@import 'company-metadata';
@import 'rule-manager';

.account {
  &__integration {
    &-name {
      display: inline-block;
      line-height: 30px;
    }

    &-actions {
      display: inline-block;
      vertical-align: middle;
      margin-left: 8px;
    }
  }
}

.fluid {
  .settings {
    &-actual-team {
      &__level {
        width: 11%;
      }

      &__role {
        width: 11%;
      }

      &__job-title {
        width: 11%;
      }

      &__tag {
        width: 10%;
      }

      &__business-unit-header {
        display: inline-block;
        line-height: 21px;
      }
    }
  }
}

.companyPTRow {
  cursor: move;
  width: fit-content !important;
  /* Draggabilly adds is-dragging */
  &.is-dragging,
  &.is-positioning-post-drag {
    opacity: 0.5;
    z-index: 2;
  }
}

.companyHeaderLayout {
  padding-top: 16px;
}

.accountHead, .permissionsSectionHeader, .ruleManagerHeader {
  .section-header {
    padding-top: 16px;
  }
}
