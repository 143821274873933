$hover-color: rgba(158,158,158,0.2);

.overview-ga {
  float: right;
  text-align: right;
  &__title {
    text-transform: capitalize;
  }
  &__content {
    font-size: 24px;
  }
}

.ga {
  &__feedback {
    top: -18px;
    position: relative;
    float: left;
    width: 100%;
  }
  &-table {
    &__header-left, &__header-center, &__header-right {
      float: left;
      text-transform: capitalize;
      display: table;
      height: inherit;
    }
    &-left, &-center, &-right {
      height: inherit;
    }
    &__header-left, &-left, &-overview-left {
      padding-left: 20px;
      @include span(35 of 100);
    }
    &-left-editable {
      @extend .ga-table-left;
      &:hover {
        background-color: $hover-color;
      }
    }
    &__header-center, &-center, &-overview-center {
      @include span(51 of 100);
    }
    &__header-right, &-right, &-overview-right {
      @include span(14 of 100);
      text-align: right;
      padding-right: 20px;
    }
  }
  &__header-cell {
    @extend .cell;
    width: span(25 of 100);
    padding-left: 10px;
    &--end {
      @extend .ga__header-cell;
      width: span(17 of 100);
    }
    &--gross {
      @extend .ga__header-cell;
      width: span(17 of 100);
      text-align: right;
      padding-right: 26px;
    }
    &--bu {
      @extend .ga__header-cell;
      width: span(17 of 100);
    }
  }
  &__content {
    padding-top: 74px;
  }
  &__cell {
    @include span(15 of 100);
    padding-left: 10px;
    margin-right: span(10 of 100);
    &--date-input {
      height: 60px;
      &:hover {
        background-color: $hover-color;
      }
      &-end {
        @extend .ga__cell--date-input;
        @include span(15 of 100);
        margin-right: 0;
        .mdl-textfield__input {
          border-bottom: none;
          padding-top: 6px !important;
        }
        .mdl-textfield__label {
          margin-top: 6px !important;
        }
      }
    }
    .mdl-textfield__input {
      border-bottom: none;
      padding-top: 6px !important;
    }
    .mdl-textfield__label {
      left: 10px;
      margin-top: 6px !important;
    }
    &--gross {
      @include span(16 of 100);
      margin-right: 0;
      padding-right: 10px;
      @extend .ga__cell;
      .mdl-textfield__input, .mdl-textfield__label {
        text-align: right;
      }
      .mdl-textfield__label {
        padding-right: 22px;
      }
      &:hover {
        background-color: $hover-color;
      }
    }
    &--bu {
      width: 17%;
      margin-left: 15px;
      padding-left: 10px;
      float: left;
      margin-right: 0;
    }
  }
  &__row-actions {
    @include span(40 of 100);
    padding-top: 14px;
    padding-left: span(17 of 100);
    .segment__delete {
      margin-top: 0;
    }
    .segment-action__save {
      width: auto;
      margin-left: 12px;
    }
  }
  &--with-bu {
    .ga__row-actions {
      width: 24%;
      padding-left: 1%;
    }
  }
}