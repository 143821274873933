.project-status {
  &-alert {
    .wethod-modal2__body {
      padding: 0 32px 32px 32px;
    }

    @include scaleToExtraSmall() {
      .wethod-modal2__container {
        width: 100%;
      }
    }
  }

  &-areas {
    width: 630px;
    font-size: 14px;
    line-height: 22px;

    .material-input-button {
      background-color: $white;
    }

    @include scaleToExtraSmall() {
      width: 100%;
    }

    &__risk {
      width: 226px;
      opacity: 0;
      transition: .4s;

      &--visible {
        opacity: 1;
      }
    }

    &__header {
      display: flex;
      justify-content: space-between;
      align-items: baseline;
      margin: 24px 0;
    }

    .wethod-input {
      background-color: #F2F3F5;
      padding: 5px;
      border: none;
      width: 50%;
    }

    .wethod-table-light {
      &__body {
        .wethod-table-light__row {
          height: 60px;
          line-height: 60px;
          position: relative;
        }
      }

      &__cell {
        width: 25%;

        @include scaleToExtraSmall() {
          width: 33%;
        }

        &:nth-child(2) {
          @include scaleToExtraSmall() {
            display: none;
          }
        }
      }

      &__row {
        padding: 0;

        &--header {
          border: none;
          padding-bottom: 12px !important;
        }

        &--total {
          border-bottom: 1px solid $dusty-gray;
          border-top: 1px solid $dusty-gray;
          padding: 3px 0;
        }

        &:hover {
          .project-status__last-week {
            max-height: 11px;
            overflow: visible;
            white-space: nowrap;
          }
        }
      }
    }

    &__row {
      &--off-area {
        > * {
          vertical-align: middle;
        }

        .project-status-areas__col {
          &--name {
            > span {
              display: flex;
              flex-direction: column;

              > * {
                overflow: hidden;
                text-overflow: ellipsis;
                line-height: initial;
              }

              span:last-child {
                opacity: 0.5;
                text-transform: lowercase;
                font-size: 12px;
              }
            }
          }
        }
      }
    }
  }

  &-month-production {
    &__operator {
      vertical-align: middle;

      &--actual {
        font-weight: $semiBold;
      }
    }

    &__separator {
      vertical-align: middle;
      font-size: 24px;
      font-weight: 100;
    }
  }

  &-pm {
    .project-status__column--pm {
      display: none;
    }

    .project-status__column--project {
      width: 28%;
    }

    .project-status__column--actions {
      width: 21%;
    }
  }

  &__header {
    padding: 22px 0;
  }

  &__actions {
    display: flex;
    justify-content: space-between;

    .wethod-filter-select {
      display: none;
    }

    @include scaleToExtraSmall() {
      padding: 0 10px;

      .wethod-tab-switcher {
        display: none;
      }
      .wethod-filter-select {
        display: block;
      }
    }
  }

  &__edit-area {
    &-button {
      padding-right: 12px;
      padding-left: 12px;
      margin-right: 8px;
    }

    &-indicator {
      display: inline-block;
      width: 8px;
      height: 8px;
      border-radius: 50%;
      margin-right: 8px;
      background-color: $bright-sky-blue;
    }

    &-label {
      display: none;

      position: absolute;
      top: 0;
      right: 0;
      border: 1px solid $bright-sky-blue;
      border-radius: 0 0 4px 4px;
      padding: 0 7px;
      background-color: rgba(72, 165, 249, 0.1);
      line-height: 1.5;
      font-size: 10px;
      color: $bright-sky-blue;

      @include scaleToExtraSmall() {
        display: block;
      }
    }
  }

  &__type-select {
    display: flex;
    line-height: 32px;

    &--text {
      width: calc(100% - 32px);
      margin-right: 4px;
      font-size: 14px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }

  &__search {
    &--keyword input {
      color: $garden-green;
      font-weight: $bold;
    }
  }

  &-risk {
    &__indicator {
      transform: scale(0);
      transition: .5s;
      width: 28px;
      height: 28px;
      display: inline-block;
      vertical-align: middle;
      border-radius: 100%;
      cursor: pointer;
    }

    &__wrapper {
      display: inline-block;
    }
  }

  &__column {
    &-body {
      @include scaleToExtraSmall() {
        position: relative;
        z-index: 2;
        height: 100%;
      }
    }

    &--risk {
      text-align: center;
      width: 6%;

      @include scaleToExtraSmall() {
        width: calc(20% + 3px);
      }

      &-visible {
        .project-status-risk__indicator {
          transform: scale(1);
        }
      }
    }

    &--tag {
      vertical-align: middle;
    }

    &--color {
      width: 1%;
      height: 100%;
      padding-left: 0 !important;
      z-index: 3;

      @include scaleToExtraSmall() {
        width: 2%;
      }
    }

    &--month-production {
      width: 13%;
      text-align: center;

      &-header {
        text-overflow: initial;
        overflow: visible;
        white-space: initial;
        line-height: 16px;
        vertical-align: middle;
      }
    }

    &--project {
      width: 20%;
      height: 100%;

      &-info {
        display: flex;
        flex-direction: column;
        justify-content: center;
        line-height: 1.5;
        height: 100%;

        span {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          font-size: 11px;
        }

        span:nth-child(1) {
          font-size: 14px;
          font-weight: $semiBold;
        }

        span:nth-child(3) {
          color: $dusty-gray;
        }
      }

      @include scaleToExtraSmall() {
        transition: .4s;
        margin-left: 0;
        width: 28%;
      }
    }

    &--pm {
      width: 11%;
    }

    &--days-left, &--progress {
      width: 11%;
      text-align: center;
      white-space: normal;
      height: 100%;
      .wethod-input {
        width: 70%;
      }

      @include scaleToExtraSmall() {
        width: 24%;

        .wethod-input {
          width: 80%;
          line-height: 30px;
        }
      }
    }

    &--last-progress {
      width: 9%;
      text-align: center;
      white-space: normal;
    }

    &--info-tooltip {

      &__centered {
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
      }
        @include scaleToExtraSmall() {
          width: 24%;
        }
    }

    &--no-budget {
      width: 31%;
      font-size: 10px;
      color: #909090;
      white-space: normal;
      text-align: center;
      @include scaleToExtraSmall() {
        width: 48%;
      }
    }

    &--actions {
      width: 18%;
      text-align: right;

      .wethod-open-sidebar-button {
        margin-left: 26px;

        @include scaleToExtraSmall() {
          margin-left: 8px;
          background: none;
          border: 1px solid $white;
        }

        &__icon {
          margin-bottom: 3px;
          @include scaleToExtraSmall() {
            margin-bottom: 18px;
            background-position-x: -64px !important;
          }
        }
      }

      @include scaleToExtraSmall() {
        background: $bright-sky-blue;
        //position: absolute;
        //left: 0;
        width: 100% !important;
        text-align: right;
        .wethod-button {
          color: $white;
        }
      }
    }
  }

  &__row {
    background: $white;
    border-bottom: 1px solid rgba(155, 155, 155, 0.28);
    height: 60px;
    line-height: 60px;
    position: relative;

    @include scaleToExtraSmall() {
      height: 80px;
      line-height: 80px;
    }

    &--show-actions {
      .project-status__column--project {
        @include scaleToExtraSmall() {
          margin-left: -100%;
        }
      }
    }

    &--empty {
      text-transform: uppercase;
      font-size: 12px;
      font-weight: $semiBold;
      color: $steel-gray;
      text-align: center;
      @include scaleToExtraSmall() {
        line-height: 20px;
        padding: 6px 0;
        height: auto;
        white-space: pre-line;
      }
    }

    &--tag {
      height: 46px;
      line-height: 46px;
      background: transparent;
    }

    &:hover {
      .project-status__last-week {
        max-height: 11px;
        overflow: visible;
        white-space: nowrap;
      }
    }
  }

  &__projects {
    float: left;
    width: 100%;
    margin-bottom: 24px;
  }

  &__tag {
    border: 1px solid $black;
    border-radius: 4px;
    font-size: 10px;
    line-height: 10px;
    text-transform: uppercase;
    padding: 4px;
  }

  &__last-week {
    margin-top: 3px;
    font-size: 10px;
    transition: .2s;
    overflow-y: hidden;
    max-height: 0;
    @include scaleToExtraSmall() {
      display: none;
    }
  }

  &__days-left {
    vertical-align: middle;
    display: inline-block;
    line-height: initial;
    width: 100%;
    position: relative;

    input {
      text-align: center;
    }
  }

  &__feedback {
    position: absolute;
    line-height: initial;
    font-size: 10px;
    color: $bright-sky-blue;
    width: 100%;
    white-space: nowrap;

    &--desktop-hidden {
      display: none;

      @include scaleToExtraSmall() {
        display: block;
      }
    }

    &--warning {
      color: $sunset-orange;
    }
  }

  &__sidebar {
    &-date {
      padding: 20px 0;
      font-size: 16px;
      font-weight: $bold;
    }

    &-areas {
      width: 100%;
      font-size: 11px;
      line-height: 1.3;

      thead {
        tr:first-child {
          font-weight: $semiBold;
          border-top: 1px solid $concrete-gray;
        }
      }
    }

    &-loading {
      padding: 50px;
      text-align: center;
    }

    &-statistic {
      padding: 10px 0;

      &-title {
        font-size: 12px;
        color: $dusty-gray;
        line-height: 1.4;
      }

      &-value {
        font-size: 21px;
        line-height: 1.4;
        display: block;
      }
    }

    &-project {
      &-title {
        font-size: 12px;
        font-weight: $semiBold;
        text-transform: uppercase;
        color: $dusty-gray;
        margin: 20px 0;
      }
    }

    &-duration {
      span:last-child {
        font-size: 12px;
        color: $dusty-gray;
        margin-left: 6px;
      }
    }
  }

  .planning-navigation {
    left: -32px;
    @include scaleToExtraSmall() {
      right: -32px;
    }
  }

  .navigation {
    &__prev, &__next {
      @include scaleToExtraSmall() {
        display: block;
      }
    }

    &__today {
      @include scaleToExtraSmall() {
        padding: 0 32px;
      }
    }
  }

  @include scaleToExtraSmall() {
    .wethod-table__search {
      display: none;
      height: 0;
    }
  }
}
