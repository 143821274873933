//MODAL

$modal-rule: (
        columns: 12,
        container: 341px,
        gutters : 0,
        gutter-position : inside,
        global-box-sizing: border-box,
);

//overlay da applicare alla pagina
.messageModalPageOverlay {
  &::before {
    content: "";
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 900;
    background: rgba(#ccc, 0.3);
  }
}

//modal
.messageModal {
  display: none;
  position: fixed;
  z-index: 950;
  width: 341px;
  color: $black;
  background: $white;
  border: 1px solid $concrete-gray;
  box-shadow: 0px 2px 20px rgb(0 0 0 / 10%);
  border-radius: 12px;

  @include scaleToSmall() {
    @include with-layout($modal-rule) {
      @include span(10);
    }
  }
  @include scaleToExtraSmall() {
    @include with-layout($modal-rule) {
      @include span(10);
    }
  }

  //modal header
  .messageModal__header {
    padding: 16px;
    border-bottom: 1px solid $concrete-gray;

    .messageModal__header__title {
      @include with-layout($modal-rule) {
        @include span(11);
      }
      font-size: 14px;
      text-transform: capitalize;
    }

    .messageModal__header__closeButton {
      @include with-layout($modal-rule) {
        @include span(1);
      }
      text-align: center;
    }
  }

  //modal body
  .messageModal__body {
    position: relative;
    min-height: 170px;
    clear: both;
    padding: 16px 16px 69px;

    //testo
    .messageModal__body__text {
      float: left;
      width: 100%;
      line-height: 1.5;
      margin-bottom: 8px;

      b {
        font-weight: $semiBold;
      }
    }

    //textarea
    .messageModal__body__textarea {
      float: left;
      width: 100%;
      height: 100px;
      padding: 8px;
      margin-bottom: 8px;

      line-height: 1.5;
      border: 1px solid $morning-fog-gray;
      resize: none;
      outline: none;
    }

    //textarea
    .messageModal__body__input {
      width: 100%;
      padding: 8px;
      margin-bottom: 8px;

      line-height: 1.5;
      border: 1px solid $morning-fog-gray;
      outline: none;
    }

    //subtitle
    .messageModal__body__subtitle {
      margin: 8px 0;
      color: $bright-sky-blue;
    }

    //permette di costruire una lista
    .messageModal__body__list {
      float: left;
      width: 100%;
      max-height: 100px;
      padding-bottom: 8px;
      margin-bottom: 8px;
      border-bottom: 1px solid $concrete-gray;

      overflow: hidden;
      overflow-y: scroll;

      //stile scroll
      &::-webkit-scrollbar {
        width: 7px;
      }

      &::-webkit-scrollbar-track {
        width: 7px;
        -webkit-box-shadow: inset 0 0 6px rgba(255, 255, 255, 0);
      }

      &::-webkit-scrollbar-thumb {
        border-radius: 4px;
        background-color: rgba(0, 0, 0, .2);
      }

      li {
        float: left;
        width: 100%;
        padding-left: 16px;
        margin-bottom: 8px;
      }
    }

    //componente autocomplete del modal
    .messageModal__body__autocomplete {
      position: relative;
      float: left;
      width: 100%;
      margin: 8px 0;

      input {
        @include with-layout($modal-rule) {
          @include span(10);
          margin-left: span(1);
        }
        padding: 12px 8px;
        border: none;
        border-bottom: 1px solid $bright-sky-blue;
        outline: none;
      }

      .messageModal__body__autocomplete__loading, .messageModal__body__autocomplete__notFound {
        @include with-layout($modal-rule) {
          @include span(10);
          margin-left: span(1);
        }
        position: absolute;
        bottom: 39px;
        left: 0;
        padding: 16px;
        background: $white;
        outline: 5px solid rgba(72, 165, 249, 0.3);
        text-align: center;
        color: $steel-gray
      }

      .messageModal__body__autocomplete__hintsList {
        @include with-layout($modal-rule) {
          @include span(10);
          margin-left: span(1);
        }
        position: absolute;
        bottom: 39px;
        left: 0;
        max-height: 150px;
        background: $white;
        outline: 5px solid rgba(72, 165, 249, 0.3);

        overflow: hidden;
        overflow-y: scroll;

        //stile scroll
        &::-webkit-scrollbar {
          width: 7px;
        }

        &::-webkit-scrollbar-track {
          width: 7px;
          -webkit-box-shadow: inset 0 0 6px rgba(255, 255, 255, 0);
        }

        &::-webkit-scrollbar-thumb {
          border-radius: 4px;
          background-color: rgba(0, 0, 0, .2);
        }

        li {
          width: 100%;

          a {
            display: block;
            padding: 6px 8px;
            line-height: 1.3;
            color: $black;

            &:hover {
              color: $bright-sky-blue;
            }
          }
        }
      }
    }

    .messageModal__body__buttons {
      position: absolute;
      left: 0;
      bottom: 16px; //24px;
      width: 100%;
      padding: 0 16px;

      .messageModal__body__buttons__buttonLeft {
        @include with-layout($modal-rule) {
          @include span(4);
          margin-right: span(2);
          margin-left: span(1);
        }
      }

      .messageModal__body__buttons__buttonRight {
        @include with-layout($modal-rule) {
          @include span(4);
        }
      }

      .messageModal__body__buttons__buttonCenter {
        @include with-layout($modal-rule) {
          @include span(4);
          margin-left: span(4);
        }
      }

    }

  }

  .messageModal__feedback {
    clear: both;
    position: relative;
    top: -3px;
    height: 16px;
    padding: 0 16px;
    font-size: 11px;

    &.messageModal__feedback--success {
      color: $bright-sky-blue;
    }

    &.messageModal__feedback--error {
      color: $raspberry-red;
    }
  }

  &__button-as-link {
    color: $bright-sky-blue;
    border: none;
    cursor: pointer;
    outline: none;
    background-color: transparent;
    font-weight: $semiBold;
  }

  &__link-to-share {
    position: absolute;
    left: -9999px;
  }
}

.modal {
  &-wrapper {
    @extend .pipelinemodalStructure;
  }

  &__title {
    @extend .modalHead;
    @extend .table;

    padding: 16px;
    color: $black;

    &-content {
      @extend .col_15;
      @extend .no_padding;
      @extend .left;
      @extend .cell;
      @extend .title;
      text-transform: uppercase;
    }
  }

  &__close {
    @extend .no_padding;
    @extend .left;
  }

  &__body {
    @extend .bodyWrapper;

    &-content {
      @extend .body;
      &--tours {
        display: flex;
        flex-direction: column;
        align-items: center;
        a {
          margin: 4px 0;
        }
      }
    }

    &-placeholder {
      text-align: center;
      margin-top: 20px;
      color: #878B8E;
    }
  }

  &__actions {
    @extend .col_16;
    @extend .left;
    margin-top: 20px;

    &-wrapper {
      @extend .roundButton;
      @extend .standardAction;
      @extend .col_7;
      margin: auto;
      &-button {
        width: auto !important;
        margin: 0 8px;
      }
    }

    &-button {
      @extend .text;
    }
    &-container {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      align-items: center;
    }
    &-link {
      width: 100%;
      margin-top: 16px;
      text-align: center;
    }
  }

  &__feedback {
    @extend .statusWrapper;

    &--error {
      color: $raspberry-red;
    }
  }
}
