.cell-input {
  display: table-cell;
}

.table__input {
  width: 100%;
  height: 100%;
  background-color: transparent;
  text-align: right;
  border: none;
  outline: none;
  //padding-right: 5px;
  font-weight: $bold;
}

.table__input:hover {
  background-color: rgba(158, 158, 158, 0.2);
}

.exportByDate {
  display: flex;
  align-items: center;
}
