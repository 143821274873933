.type-delete {
  &__name {
    @extend .event-type__name;
    color: $raspberry-red !important;
  }
  &__icon {
    background-image: url(/img/icon/recycle_bin.svg);
    width: 24px;
    height: 24px;
    background-repeat: no-repeat;
    background-size: 18px;
    background-position: 4px 2px;
    float: left;
  }
}

.event-type {
  width: 100%;
  float: left;
  cursor: pointer;
  &--delete {
    @extend .event-type;
    border-top: 1px solid #ECECEC;
    margin-top: 4px;
    padding-top: 4px;
    &:hover {
      color:$white;
      background: $raspberry-red !important;
      .type-delete {
        &__name {
          @extend .event-type__name;
          color: white !important;
        }
        &__icon {
          @extend .type-delete__icon;
          background-position: 4px -17px;
        }
      }
    }
  }
  &:hover {
    background: #ECECEC;
  }
  &__name {
    text-transform: uppercase;
    color: $bright-sky-blue;
    line-height: 24px;
    text-align: center;
    width: calc(100% - 48px);
    float: left;
  }
  &__icon {
    background-image: url(/img/icon/event_types.svg);
    width: 24px;
    height: 24px;
    background-repeat: no-repeat;
    background-size: 128px;
    background-position-y: 1px;
    float: left;
  }
}

.icon {
  &--Meeting {
    background-position-x: -42px;
  }
  &--Event {
    background-position-x: 1px;
  }
  &--Other {
    background-position-x: -62px;
  }
  &--Closing {
    background-position-x: -21px;
  }
  &--Travel {
    background-position-x: -106px;
  }
  &--Ship {
    background-position-x: -84px;
  }
}

.icon-light {
  background-position-y: -21px;
}

.type-select {
  float: left;
  width: 100%;
  position: relative;
  &__current {
    float: left;
    width: 100%;
    border: 1px solid $bright-sky-blue;
    border-radius: 100px;
    margin-bottom: 8px;
    padding: 2px 5px 1px 5px;
    cursor: pointer;
    &:hover {
      background: $bright-sky-blue;
      color:$white;
      .event-type {
        &__name {
          @extend .event-type__name;
          color:$white;
        }
        &__icon {
          @extend .event-type__icon;

          background-position-y: -20px;
        }
      }
    }
  }
  &__dropdown {
    float: left;
    position: absolute;
    top: 35px;
    width: 100%;
    background:$white;
    border: 1px solid #ECECEC;
    padding: 8px 5px;
  }
}
