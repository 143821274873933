.widgetSparklineProd{

  .widgetSparklineProd__head {
  	height: 39px;

  	.title {
  		padding: 17px 45px 0 20px;
  		color: #878B8E;
  		white-space: nowrap;
  		overflow: hidden;

  		.settings {
  			display: none;
  		}
  		&:hover {
  			.settings {
  				display: block;
  			}
  		}
  	}
  }

  .widgetSparklineProd__list {
  	overflow: hidden;
  	overflow-y: scroll;
    display: block;
    height: 276px;

    .widgetSparklineProd__list__item {
      position: relative;
      display: block;
      width: 100%;
      cursor: pointer;
      &:hover{
        .sparklineProdList__list__overlay {
          display: block;
        }
      }
    }

    .sparklineProdList__list__overlay {
      display: none;
      width: 100%;
      height: 40px;
      position: absolute;
      top: 0;
      left: 0;
      background: $bright-sky-blue;
      opacity: 0.95;

      .sparklineProdList__list__overlay__message {
        padding: 14px 20px;
        color: $white;
        display: inline-block;
      }

      .sparklineProdList__list__overlay__icon {
        float: right;
        padding: 11px 20px;

        .moduleReport {
      		background-position: -80px -16px;
      	}
      }
    }
  }

  .widgetSparklineProd__empty {
    width: 100%;
    height: 276px;
    text-align: center;
    text-transform: uppercase;
    font-size: 12px;
    color: #878B8E;
  }
}
