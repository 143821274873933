.demographics {
  max-width: 1440px !important;
  padding: 0 24px 30px;

  @include scaleToExtraSmall() {
    max-width: 1024px !important;
    padding: 0;
  }

  .wethod-section-actions {
    padding: 16px 0;
  }

  &__widgets {
    display: grid;
    grid-template-columns: repeat(auto-fill, 330px);
    grid-gap: 18px;
    justify-content: center;
  }

  &__chart {
    height: 100%;
    width: 100%;
    padding: 16px;

    &--in-out {
      display: flex;
      flex-direction: column;

      &>:first-child {
        height: 24%;
      }
      &>:last-child {
        height: 76%;
      }
    }

    &--disabled {
      opacity: 0.5;
    }
  }

  &__total {
    display: inline-block;
    border: 1px solid #CACACA;
    border-radius: 2px;
    padding: 8px 12px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 12%);
    font-size: 14px;

    &--in {
      color: $garden-green;
      margin-right: 16px;
    }

    &--out {
      color: $raspberry-red;
    }
  }

  &__modal {
    font-size: 14px;

    .wethod-modal2__container {
      width: 420px;
    }

    .wethod-modal2__body {
      div:first-child {
        margin-bottom: 48px;
      }

      p {
        margin-bottom: 32px;
      }
    }

    .wethod-input {
      width: 90px;
      margin: 0 16px;

      input {
        text-align: right;
      }
    }
  }

  .wethod-icon-button {
    width: 32px;
    height: 32px;

    &:hover {
      background-color: rgba(0, 0, 0, 0.1);
      color:$black;
    }

    &.disabled {
      &:hover {
        cursor: initial;
      }
    }
  }
}
