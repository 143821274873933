//definisco lo style della struttura del layout
.financeSupplier {

  .financeSupplier__fixedContent {
    top: 0;
    z-index: 750 !important;
    position: fixed;
    width: 100%;
    float: left;
    background: $maldivian-sand-white;
  }
  .financeSupplier__fixedContentPush {
    clear: both;
  }

  .financeSupplier__associatedResource {
    height: 150px;
    overflow: hidden;
    li {
      margin: 2px 0;
    }
  }

  //definisco la dimansione dei vari campi
  .financeSupplier__name {
    @include span(4);
  }
  .financeSupplier__email {
    @include span(4);
  }
  .financeSupplier__delete {
    @include span(4);

    .financeSupplier__delete__button {
      float: right;
      width: 30px;
      height: 30px;
      margin: 15px 20px 0 0;

      .financeSupplier__delete__button__icon {
        width: 30px;
        height: 30px;
        @include retina-image($iconsPath + delete_icon, 30px 30px);
      }

    }
  }
  .financeSupplier__cancellInsert {
    @include span(2);

    .financeSupplier__cancellInsert__button {
      @include span(8);

      float: right;
      margin-top: 16px;
    }
  }
  .financeSupplier__insert {
    @include span(2);

    .financeSupplier__insert__button {

      @include span(8);
      margin-right: 20px;

      float: right;
      margin-top: 16px;

    }
  }

  //modal
  .financeSupplier__modal__header {
    clear: both;
    float: left;
    width: 100%;
    height: 30px;
    margin: 8px 0 3px;
    background: $bright-sky-blue;
    color: $white;

    .financeSupplier__modal__header__recordName {
      display: table;
      height: 30px;
      padding: 0 6px;

      span {
        display: table-cell;
        vertical-align: middle;
      }

      &.financeSupplier__modal__header__recordName--large {
        float: left;
        width: 70%;
        padding: 6px;
      }

      &.financeSupplier__modal__header__recordName--small {
        float: left;
        width: 30%;
        padding: 6px 19px 6px 6px;
        text-align: right;
      }

    }
  }

  .financeSupplier__modal__list__smallField {
    float: left;
    width: 30%;
    padding: 6px 3px;
    text-align: right;
  }
  .financeSupplier__modal__list__largeField {
    float: left;
    width: 70%;
    padding: 6px;
    margin-left: -16px;
  }
  .financeSupplier__supplier-buttons {
    padding-top: 16px;
  }

}
