.wethod-sidebar--transition {
  &-enter {
    transform: translateX(360px);
    &-active {
      transition: 0.5s;
      transform: translateX(0px);
    }
    &-done {
      transform: translateX(0px);
    }
  }

  &-exit {
    transform: translateX(0px);
    &-active {
      transition: 0.5s;
      transform: translateX(360px);
    }
    &-done {
      transform: translateX(360px);
    }
  }
}

.wethod-sidebar {
  width: 360px;
  height: 100%;
  font-size: 14px;
  display: flex;
  flex-direction: column;
  position: fixed;
  right: 0;
  border: 1px solid $concrete-gray;
  border-radius: 12px 0 0 12px;
  box-shadow: 0px 2px 20px rgb(0 0 0 / 10%);
  overflow: hidden;

  @include scaleToExtraSmall() {
    width: 100vw;
    max-width: 450px;
    height: calc(100% - 48px);
    top: 48px;
  }

  &__overlay {
    @extend .wethod-section-overlay;

    z-index: 901 !important;
    top: 0;
    left: -100vw;
    width: 200vw;

    &--animate {
      animation: shake 0.3s;
      animation-iteration-count: 1;

      .sidebar__header-actions .wethod-button {
        background-color: $bright-sky-blue;

        &--no-border {
          margin: 0;
          padding: 0 7px;
        }
      }

      @keyframes shake {
        0% {
          transform: translate(0.5px, 0.5px) rotate(0deg);
        }
        10% {
          transform: translate(-0.5px, -1px) rotate(-0.25deg);
        }
        20% {
          transform: translate(-1px, 0px) rotate(0.25deg);
        }
        30% {
          transform: translate(1px, 1px) rotate(0deg);
        }
        40% {
          transform: translate(0.5px, -0.5px) rotate(0.25deg);
        }
        50% {
          transform: translate(-0.5px, 1px) rotate(-0.25deg);
        }
        60% {
          transform: translate(-1px, 0.5px) rotate(0deg);
        }
        70% {
          transform: translate(1px, 0.5px) rotate(-0.25deg);
        }
        80% {
          transform: translate(-0.5px, -0.5px) rotate(0.25deg);
        }
        90% {
          transform: translate(0.5px, 1px) rotate(0deg);
        }
        100% {
          transform: translate(0.5px, -1px) rotate(-0.25deg);
        }
      }
    }
  }

  &__header {
    display: flex;
    justify-content: space-between;
    padding: 0 16px;
    height: 61px;
    line-height: 61px;
    color: $black;
    background-color: $white;
    border-bottom: 1px solid $concrete-gray;

    &-title {
      flex: 1;
      font-size: 16px;
      letter-spacing: 0.1px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    &-feedback {
      text-align: center;
    }

    &-actions {
      min-width: 100px;

      .disabled {
        pointer-events: none;
      }

      .wethod-button {
        margin: 0 8px;
        padding: 0 17px;
        font-size: 14px;
        color: $black;

        &--no-border {
          border: none;
          margin: 0;
          padding: 0 7px;
        }
      }

      .wethod-icon {
        margin: 14px 0;
        float: right;
        cursor: pointer;

        &:hover {
          border-radius: 100px;
          background-color: $concrete-gray;
        }
      }
    }
  }

  &__body {
    flex: 1;
    overflow: auto;
    padding: 0 11px 10px;
    background-color: $white;
  }

  &__actions-bar {
    padding: 0 10px;
    height: 64px;
    line-height: 64px;
    text-align: right;
    background-color: #ECECEC;

    &--error {
      height: 50px;
      line-height: 50px;
      background-color: #D94F6B;
      text-align: left;
      color: $white;
    }

    &--alert {
      padding-top: 10px;
      padding-bottom: 10px;
      height: auto;
      min-height: 50px;
      line-height: 1.5;
      background-color: $sunset-orange;
      text-align: left;

      .wethod-button {
        border: 1px solid $black;
        color: $black;

        &:hover {
          color: $sunset-orange;
          background-color: $black;
        }
      }
    }
  }

  &__picture {
    display: block;
    padding: 24px 114px;
  }

  &__delete-button {
    display: block;
    margin: 25px auto;
    font-size: 14px;
  }

  &__link-button {
    display: inline-block;
    background-color: transparent;
    vertical-align: middle;
    margin: 8px;
    width: 33px;
    height: 33px;
    border: none;
    border-radius: 50%;
    cursor: pointer;

    .wethod-icon {
      display: block;
    }

    &:hover {
      background-color: $bright-sky-blue;
      border: none;

      .wethod-icon {
        background-position-x: -64px;
      }
    }

    &.wethod-icon:hover {
      background-position-x: -64px;
    }
  }

  &__subtitle {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 12px 0;
    font-size: 12px;
    font-weight: $semiBold;
    text-transform: uppercase;
    color: $dusty-gray;
  }

  &__multiple-input {
    &-row {
      display: flex;
      align-items: flex-start;
      width: 100%;

      &--space-bottom {
       margin-bottom: 8px;
      }
    }
  }

  b {
    font-weight: $bold;
  }
}
