.bubble {
  position: absolute;
  float: left;
  left: -200%;
  z-index: 1000;
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.3);
  &__content {
    background:$white;
    width: 225px;
    position: relative;
    float: left;
  }
  &__prong {
    &--top {
      content: "";
      position: absolute;
      top: 100%;
      left: 110px;
      margin-left: -8px;
      border-width: 8px;
      border-style: solid;
      border-color: white transparent transparent transparent;
    }
    &--bottom {
      content: "";
      position: absolute;
      bottom: 100%;
      left: 110px;
      margin-left: -8px;
      border-width: 8px;
      border-style: solid;
      border-color: transparent transparent white transparent;
    }
  }
}

.bubbleContainer {
  .highcharts-container {
    background:$white;
  }
}
