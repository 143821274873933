.reportProjectChangelog {
  overflow: auto; // prevent margin collapse with fixedcontentPush
  .section-header {
    padding-top: 16px;
  }

  .recordList__record__content__text--rightSpacing {
    padding-right: 25px;
  }

  .recordList__record__content__text--spacingRevenues {
    padding-right: 45px;
  }

  .reportProjectChengelog__fixedContent {
    top: 0;
    position: fixed;
    z-index: 750;
    width: 100%;
    background: $alabaster-white;
    margin-top: 2px;
  }

  .reportHeader {
    .reportHeader__title {
      @include scaleToExtraSmall() {
        font-size: 21px;
      }
    }
  }

  .reportProjectChengelog__project {
    @include span(2);
    @include scaleToExtraSmall() {
      @include span(4);
    }
  }

  .reportProjectChengelog__who {
    @include span(2);
    @include scaleToExtraSmall() {
      @include span(4);
    }
  }

  .reportProjectChengelog__when {
    @include span(2);
    @include scaleToExtraSmall() {
      display: none;
    }
  }

  .reportProjectChengelog__valueFrom {
    @include span(1);
    text-align: right;

    @include scaleToExtraSmall() {
      display: none;
    }
  }

  .reportProjectChengelog__valueTo {
    @include span(1);
    text-align: right;
    @include scaleToExtraSmall() {
      display: none;
    }
  }

  .reportProjectChengelog__probFrom {
    @include span(1);
    text-align: right;
    @include scaleToExtraSmall() {
      display: none;
    }
  }

  .reportProjectChengelog__probTo {
    @include span(1);
    text-align: right;
    @include scaleToExtraSmall() {
      display: none;
    }
  }

  .reportProjectChengelog__revenuesDelta {
    @include span(2);
    text-align: right;
    @include scaleToExtraSmall() {
      @include span(4);
    }
  }
}
