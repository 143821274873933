@import '../../../../../scss/colors';
@import '../../../../../scss/settings';
@import "scss/breakpoints";

.time-off {
  max-width: 1440px !important;
  padding: 0 24px;

  @include scaleToMobile() {
    max-width: 1024px !important;
    padding: 0;
  }

  .wethod-section-actions {
    display: flex;
    flex-direction: column;
    padding: 16px 0;
  }

  &__actions {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .wethod-export-button {
      margin-left: 36px;
    }
  }

  &__year {
    margin-right: auto;
    margin-left: -64px;
  }

  &__modal {
    .wethod-modal2__container {
      width: 450px;
    }

    &-file-section {
      .wethod-icon-button {
        margin: 0 5px;
        padding: unset;
      }

      button[aria-label="Download"] {
        display: none;
      }

      .wethod-sidebar__subtitle {
        justify-content: center;
      }

      ul {
        margin: unset;
        padding: unset;
      }

      li {
        margin: unset;
      }
    }

    &-error {
      li {
        line-height: 1.5;
      }

      &-body {
        height: 300px;
        overflow: auto;
      }

      &-list {
        list-style: initial !important;
      }
    }
  }

  &-item {
    &__subheader {
      border-top: 1px solid $concrete-gray;
      height: 40px;
      line-height: 40px;
      font-weight: $semiBold;
      font-size: 12px;
      white-space: pre-line;

      .wethod-table__cell {
        display: inline-flex;
        align-items: center;
        text-align: center;
        justify-content: center;

        height: 100%;
        padding: 0 7px;
        white-space: pre-line;
        line-height: initial;
      }
    }
    &__user {
      width: 13%;
    }
    &__hours-group {
      width: 29%;
      height: 100%;

      display: inline-flex;
      flex-direction: column;
      line-height: initial;
      align-items: center;
      justify-content: center;

      span:last-child {
        font-weight: $normal;
        font-size: 12px;
      }
    }
    &__cell {
      width: 5.8%;
      text-align: right;

      &:nth-child(5n - 3) {
        background-color: $spring-wood-white;
      }

      &--error {
        color: $raspberry-red;
      }
    }
  }

  .wethod-table__cell:not(:first-child) {
    border-left: 1px solid $concrete-gray;
  }
}
