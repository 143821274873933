.economics {
  &__row {
    &-bu-label {
      font-size: 10px;
      color: gray;
      margin-bottom: -6px;

      &--payroll {
        position: absolute;
        top: 8px;
      }
    }
  }

  &-section {
    &__area-title {
      font-weight: $semiBold;
      color: #adb0b7;
      margin: 10px 0;
      text-transform: uppercase;
      font-size: 16px;
    }
  }
}

.hoverable {
  cursor: pointer;
  //background: rgba(216, 216, 216, 0.3);
  -webkit-transition: 0.2s ease-in-out;
  transition: 0.2s ease-in-out;
}

.show-border-bottom {
  border-bottom-width: 1px !important;
  border-bottom-style: solid !important;
}

.mdl-textfield {
  .mdl-textfield__label {
    display: inline-block;
    text-transform: capitalize;
  }
}

.table {
  &-header {
    &--fixed {
      top: 0;
      position: fixed;
      z-index: 750;
      width: 1024px;
      float: left;
      background: $alabaster-white;
      @include scaleToExtraSmall() {
        width: 100%;
      }
    }
  }

  &-body {
    &--scrollable {
      margin-top: 47px;
    }
  }

  &__header-left, &__header-center, &__header-right {
    float: left;
    text-transform: capitalize;
    display: table;
    height: inherit;

    .orderDirectionIcon {
      text-transform: none;
      float: left;
    }
  }

  &__header-left, &-left, &-overview-left {
    padding-left: 12px;
    @include span(17 of 100);
  }

  &__header-center, &-center, &-overview-center {
    @include span(72 of 100);
    text-align: right;

    .header__month {
      padding: 0 8px;
      width: span(1 of 12);
    }
  }

  &__header-right, &-right, &-overview-right {
    @include span(11 of 100);
    text-align: right;
    padding: 0 12px 0 15px;
  }

  &-overview {
    &__wrapper {
      @extend .recordList__record;

      &-separator {
        //margin-bottom: 10px;
      }
    }

    &-left, &-center, &-right {
      font-weight: $bold;
      float: left;
      text-transform: uppercase;
      height: inherit;

      &__content {
        height: inherit;
        width: 100%;
        display: table;
      }
    }

    &-left {
      color: $bright-sky-blue;

      &__content {
        width: 90%;
        padding: 0 !important;
        display: flex;
        align-items: center;
        justify-content: space-between;

        label {
          word-break: break-word;
          display: block;
          padding-right: 10px;
        }
      }
    }

    &__input {
      height: 60px;
      text-align: right;
      border: none;
      font-size: 15px;
      outline: none;
      width: 66px;
      background-color: rgba(158, 158, 158, 0.2);
      padding-right: 12px;
    }
  }

  &-left, &-center, &-right {
    float: left;
    text-transform: capitalize;
    height: inherit;

    &__content {
      height: inherit;
      width: 100%;
      display: table;
    }
  }

  &-left {
    &__content {
      color: $black;
    }
  }

  &__sub-area {
    &-title {
      @extend .clear;
      @extend .grid;
      @extend .listheader;
      @extend .table;
      text-transform: uppercase;
      padding-left: 20px;
    }
  }

  &__row {
    &--expandable {
      clear: both;
      position: relative;
      width: 100%;
      background: $white;
      border-bottom: 1px solid $concrete-gray;
      min-height: 60px;

      &:hover {
        @extend .hoverable;
      }
    }
  }
}

.row {
  &--preview {
    .recordList__record {
      background: transparent;
      border-bottom: none;
    }
  }

  &--details {
    clear: both;
    position: relative;
    width: 100%;
    background: $white;
    border-bottom: 1px solid $concrete-gray;
    border-left: 1px solid $concrete-gray;
    border-right: 1px solid $concrete-gray;
    float: left;

  }

  &-details {
    &__content {
      //padding-top: 15px;
      padding-bottom: 15px;
      width: 100%;
      float: left;
    }
  }
}

.segment {
  &__total {
    text-align: right;
    line-height: 48px;
  }

  &__inputs {
    @include span(87 of 100);

    .mdl-select-bu {
      width: 12%;
    }
  }

  &__input-wrapper {
    @include span(11 of 100);
    margin-right: span(14 of 100);

    .mdl-textfield__label {
      display: inline-block;
      text-transform: capitalize;
    }
  }

  &__input {
    &--taxes {
      margin-right: span(8 of 100);
    }
  }

  &__delete {
    width: 30px;
    height: 30px;
    margin: 9px 4px;
    float: left;

    img {
      width: 30px;
      height: 30px;
      background-image: url('/img/icon/delete_icon.png');
    }
  }

  &-action {
    &__save {
      @extend .roundButton;
      @extend .okAction;
      @extend .left;
      width: 102px;
    }
  }
}

.segments {
  &__feedback {
    padding-left: 20px;
    top: -18px;
    position: relative;
    float: left;
    width: 100%;
  }

  &__actions {
    padding-left: 20px;
    @include span(21 of 100);
  }

  &__entries {
    padding-right: 20px;
    @include span(79 of 100);
  }
}

.actions {
  &-important {
    &__wrapper {
      @extend .pageSection;
      padding: 15px 0;

      .left {
        & > div {
          display: inline-block;
          vertical-align: middle;
        }
      }
    }
  }
}

.input {
  &__tooltip {
    margin-top: 8px;
    z-index: 700;
    position: absolute;
    min-width: 100%;
    max-height: 162px;
    overflow: auto;
    background: $white;
    color: $black;
    padding: 3px;
    border: none;
    outline: 5px solid rgba(72, 165, 249, 0.3);

    li {
      height: 30px;

      .tooltipLink {
        color: initial;
        width: 100%;
        height: 100%;
        display: block;

        &:hover {
          color: $bright-sky-blue;
        }
      }
    }

    .cell {
      @extend .cell;
      line-height: 1.3;
    }
  }
}

.distribution {
  &-wrapper {
    position: relative;
    width: 100%;
    background: $maldivian-sand-white;
    height: 8px;
    margin-top: 26px;
  }

  &-segment {
    position: absolute;
    height: 8px;
    float: left;
  }
}

.arrow {
  &--centered {
    padding-top: 0;
  }
}

a {
  &.table-left {
    &:hover {
      background: rgba(158, 158, 158, 0.2);
    }
  }
}

@import "revenues";
@import "payroll";
@import "general_admin";
@import "resume";
@import "investments";
@import "order_plan";
