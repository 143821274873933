$primaryColor: $black;
$secondaryColor:$white;
$accentColor: $concrete-gray;

.button {
  line-height: 30px;
  text-transform: uppercase;
  cursor: pointer;
  color: $black;
  background-color: transparent;
  padding: 0 32px;
  border-radius: 100px;
  border: 1px solid $black;
  outline: none;
  transition: 0.15s ease-in-out;
  position: relative;
  float: left;
  @include scaleToExtraSmall() {
    padding: 0 26px;
  }

  &--active, &:hover, &--selected {
    color: $secondaryColor;
    background-color: $primaryColor;
    .button__icon {
      background-position-x: -64px;
    }
  }

  &__label {
    display: block;
    @include scaleToExtraSmall() {
      display: none;
    }

    &--mobile {
      display: none;
      @include scaleToExtraSmall() {
        display: block;
      }
    }
  }

  &--selected {
    border-color: $garden-green;
    background-color: $garden-green;
    color: $secondaryColor;

    &:hover {
      border-color: $garden-green;
      background-color: $garden-green;
      color: $secondaryColor;
    }
  }
}

.dropdown {
  font-size: 12px;
  color: #505050;
  background-color: $white;
  max-height: 430px;
  margin-top: 40px;
  box-shadow: 0 11px 41px -9px rgba(0, 0, 0, 0.75);
  left: 0;
  z-index: 50;
  float: left;
  position: absolute;
  width: auto;
  @include scaleToExtraSmall() {
    position: fixed;
    width: 100%;
  }

  &__input {
    outline: none;
    height: 40px;
    padding: 10px 15px;
    border: none;
    border-bottom: 1px solid $accentColor;
    width: 300px;
    @include scaleToExtraSmall() {
      width: 100%;
    }

    &::placeholder {
      color: $dusty-gray;
      font-weight: lighter;
    }
  }

  &__list {
    overflow: hidden;
    overflow-y: scroll;
    position: relative;
    max-height: 370px;
    margin: 0;
    padding: 0;
    list-style-type: none;
  }

  &__item {
    display: inline-block;
    width: 300px;
    padding: 10px 15px;
    text-transform: capitalize;
    cursor: pointer;

    &:hover {
      color: $primaryColor;
    }
  }

  &__title {
    font-weight: $semiBold;
    margin: 0;
    text-transform: capitalize;
  }

  &__subtitle {
    margin: 0;
  }

  &__message {
    padding: 10px 15px;
    margin: 0;
  }

  &__switch {
    border-bottom: 1px solid $accentColor;
    height: 60px;
    width: 288px;
    overflow: hidden; //prevent margin collapse
    @include scaleToExtraSmall() {
      width: 100%;
    }
  }
}

.button__icon {
  width: 12px;
  height: 6px;
  position: absolute;
  top: 13px;
  right: 10px;
}

.button {
  .button_icon {
    background-position-y: -6px;
  }
}

.switch {
  margin: 12px auto;
  width: 258px;
  color: $black;

  &__button {
    display: inline-block;
    padding: 8px 18px;
    border: 1px solid $primaryColor;
    border-right: none;
    cursor: pointer;
    text-transform: uppercase;

    &--first {
      border-top-left-radius: 30px;
      border-bottom-left-radius: 30px;
    }

    &--last {
      border-right: 1px solid $primaryColor;
      border-top-right-radius: 30px;
      border-bottom-right-radius: 30px;
    }

    &--active, &:hover {
      color: $secondaryColor;
      background-color: $primaryColor;
    }
  }
}

.filter {
  &__block {
    padding: 15px;
    border-bottom: 1px solid $accentColor;
    font-weight: $semiBold;
  }

  &__title {
    color: $primaryColor;
    font-size: 16px;
  }

  &__item {
    margin: 8px 0;
    cursor: pointer;
    font-weight: initial;

    &--active {
      color: $primaryColor;

      &::after {
        content: "\00a0 \2713";
      }
    }

    &:hover {
      color: $primaryColor;
    }
  }

  &__clear {
    text-decoration: underline;
    margin-left: 15px;
    cursor: pointer;
    position: absolute;
    width: 65px;
    top: 10px;
    @include scaleToExtraSmall() {
      display: none;
    }
  }
}

.icon-arrow-down {
  background: url("/img/icon/dropdown_arrow.svg") no-repeat center;
  background-size: 12px 12px;
  background-position-y: 0;
}

.availability {
  width: 1024px;
  margin-top: 16px;
  float: left;

  height: 76px;
  background-color: $secondaryColor;
  border-left: 5px solid $dusty-gray;
  font-size: 14px;
  color: #505050;
  position: relative;
  @include scaleToExtraSmall() {
    height: 105px;
    width: 100%;
    font-size: 12px;
    margin-top: 10px;
  }

  &__client {
    @include scaleToExtraSmall() {
      margin-top: 8px;
      display: inline-block;
    }

    &-pm-separator {
      @include scaleToExtraSmall() {
        display: none;
      }
    }
  }

  &__data {
    @include scaleToExtraSmall() {
      display: inline-block;
      margin-top: 10px;
    }
  }

  &__info, &__data {
    height: 38px;
    padding: 12px;
    @include scaleToExtraSmall() {
      padding: 12px 5px;
    }
  }

  &__projectName {
    font-weight: $semiBold;
    text-transform: uppercase;
    @include scaleToExtraSmall() {
      width: 100%;
      float: left;
    }
  }

  &__close {
    font-size: 12px;
    position: absolute;
    right: 12px;
    top: 12px;
    color: $dusty-gray;
    text-transform: uppercase;
    cursor: pointer;
  }

  &__level {
    margin-right: 25px;
    @include scaleToExtraSmall() {
      margin-right: 18px;
      width: 19%;
      float: left;
      margin-bottom: 10px;
      display: inline-block;
    }
  }

  &__hours--active {
    color:$black;
    font-weight: $bold;
  }

  &__hours--negative {
    color: $raspberry-red;
    font-weight: $bold;
  }

  &__level-name {
    text-transform: uppercase;
  }
}

.planning {
  &__search {
    float: right;
    position: relative;
    right: -50px;
    width: 286px;
    &--skills input {
      color: $garden-green;
      font-weight: $bold;
    }
    @include scaleToExtraSmall() {
      display: none;
    }
  }
}
