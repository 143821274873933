.overview-payroll {
  float: right;
  text-align: right;

  &__title {
    text-transform: capitalize;
  }

  &__content {
    font-size: 24px;
  }
}

.payroll {
  &-section {
    padding-bottom: 36px;
    padding-top: 8px;

    .pipeEmptyRow {
      text-align: center;
    }
  }
}

.placeholder {
  &-preview {
    &__name {
      input.mdl-textfield__input {
        border-bottom: 0;
        padding-top: 6px !important;
      }

      label.mdl-textfield__label {
        margin-top: 6px !important;
      }
    }
  }

  &__overview {
    &-month {
      &--past {
        color: $raspberry-red;
        text-decoration: line-through;
      }
    }
  }
}

.payroll {
  &__content {
    padding-top: 74px;
  }

  &--with-bu {
    .segment__input-wrapper {
      margin-right: 7%;
    }
  }
}

.table {
  &-overview {
    &-center {
      &--align-center {
        .cell {
          text-align: center;
        }
      }
    }
  }

  &__header {
    &-center {
      text-align: center;
    }
  }
}

.segment {
  &__loading {
    line-height: 27px;
    color: $steel-gray;
  }
}

[data-region="payrollModal"] {
  .clients-modal__actions {
    .roundButton {
      width: 30%;
      display: inline-block;
      margin: 0 8px;
    }
  }
}
