@import 'report-production-value';
@import 'report-project-changelog';
@import 'report-projects-overview';
@import 'report-timesheet-roadrunner';
@import 'report-backlog';

.reportHeader {
  padding: 15px 0;
  @include scaleToExtraSmall() {
    padding: 12px 5px 0;
  }
  @include scaleToSmall() {
    padding: 12px 5px 0;
  }

  .reportHeader__title {
    font-size: 30px;
    font-weight: $bold;
  }

}

.reportSubTitle {
  font-size: 24px;
  font-weight: $bold;
}

.reportHeader__yearSelection {
  width: 15%;
  float: right;
  font-weight: $semiBold;
  @include scaleToExtraSmall() {
    width: auto;
  }
  @include scaleToSmall() {
    width: auto;
  }

  .reportHeader__year__prev, .reportHeader__year__next {
    float: left;
    border: 1px solid $concrete-gray;
  }

  .reportHeader__year__label {
    display: inline-block;
    width: calc(100% - 60px);
    margin-top: 10px;
    text-align: center;
    @include scaleToExtraSmall() {
      padding: 0 24px;
    }
    @include scaleToSmall() {
      padding: 0 24px;
    }
  }
}

.report-detail__label-week, .report-detail__label-week--days-left, .report-detail__label-week--worked-hours {
  position: absolute;
  background: rgba(255, 255, 255, 0.8);
  z-index: 3;
  left: -999999px;
  padding: 8px 8px;
  border-radius: 2px;
  box-shadow: 0 1px 5px grey;
  font-family: "Lucida Grande", "Lucida Sans Unicode", Arial, Helvetica, sans-serif;
  font-size: 11px;

  &::before {
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -9px;
    border-width: 9px;
    border-style: solid;
  }

  &::after {
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -7px;
    border-width: 7px;
    border-style: solid;
    border-color: white transparent transparent transparent;
  }
}

.report-detail__wasted-hours-info{
  visibility: hidden;
  & div {
    position: relative;
    width: 180px;
    right: 20px;
    padding: 2px;
  }
}

.report-detail__info-icon-hover:hover + .report-detail__wasted-hours-info{
  visibility: visible;
  & div {
    position: relative;
    width: 180px;
    right: 20px;
    background: rgba(55, 57, 58, 0.9);
    border-radius: 5px;
    padding: 2px;
  }
}

#prjReportContainer {
  @include scaleToExtraSmall() {
    padding: 0 16px;
  }
  @include scaleToSmall() {
    padding: 0 16px;
  }
}

#prjReportContent, #prjReportSidebar, #reportInvoicesContainer, #reportOrdersContainer {
  @include scaleToExtraSmall() {
    padding: 0;
    width: 100%;
    margin-bottom: 10px;
  }
  @include scaleToSmall() {
    padding: 0;
    width: 100%;
    margin-bottom: 10px;
  }
}

#prjReportContent {
  @include scaleToExtraSmall() {
    margin-top: 150px;
  }
  @include scaleToSmall() {
    margin-top: 150px;
  }
}

#prjReportSidebar {
  @include scaleToExtraSmall() {
    position: absolute;
    width: calc(100% - 32px);
  }
  @include scaleToSmall() {
    position: absolute;
    width: calc(100% - 32px);
  }
}

.actualProgressChartWrapper {
  @include scaleToExtraSmall() {
    margin: 0 -9px;
    width: calc(100% + 15px) !important;
    padding: 0 !important;
  }
  @include scaleToSmall() {
    margin: 0 -9px;
    width: calc(100% + 15px) !important;
    padding: 0 !important;
  }
}

.hoursDetailsContainer {
  @include scaleToExtraSmall() {
    width: 100% !important;
    padding: 0 !important;
  }
  @include scaleToSmall() {
    width: 100% !important;
    padding: 0 !important;
  }
}

.headingInfos {
  @include scaleToExtraSmall() {
    text-align: center;
  }
  @include scaleToSmall() {
    text-align: center;
  }
}

.report-detail {
  &__delta-baseline, &__delta-forecast {
    @include scaleToExtraSmall() {
      display: none;
    }
    @include scaleToSmall() {
      display: none;
    }
  }
}

.hoursUsersRows {
  @include scaleToExtraSmall() {
    width: 100% !important;
    margin: 0 !important;
  }
  @include scaleToSmall() {
    width: 100% !important;
    margin: 0 !important;
  }
}

.hours-users__last-week {
  @include scaleToExtraSmall() {
    display: none;
  }
  @include scaleToSmall() {
    display: none;
  }
}

.hours-users__current-week {
  @include scaleToExtraSmall() {
    width: 62.5% !important;
  }
  @include scaleToSmall() {
    width: 62.5% !important;
  }
}

.reportSectionHeader {
  .section-header__bottom-spacer {
    @include scaleToExtraSmall() {
      height: 47px !important;
    }
    @include scaleToSmall() {
      height: 47px !important;
    }
  }
}

.report {
  &-revenue-pipeline-graph {
    .miniLoadingWrap {
      padding-top: 30%;
    }
  }
}

.reportInvoice {
  .section-header {
    padding-top: 16px;
  }
}

.hours-users {
  &__last-week {
    position: relative;

    &-calendar {
      &-button {
        position: absolute !important;
        left: -20px;

        &:hover {
          .wethod-icon-calendar {
            background-position-x: -64px;
          }
        }
      }

      &-icon {
        position: relative;
        top: -2px;
        left: -2px;
        transform: scale(0.95);
      }
    }
  }

  &__modal {
    &-date {
      &-select {
        input {
          border: none;
          width: 50%;
          margin: 8px 16px;
          text-align: center;
        }

        &--to {
          input {
            margin-left: 31px;
          }
        }

        label {
          color: #999;
        }
      }
    }
  }
}
