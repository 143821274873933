.backlog {
  padding: 22px 10px 30px;

  &__actions {
    margin-bottom: 22px;
    width: 100%;
    height: 32px;
  }

  &-trend {
    background:$white;
    border-radius: 4px;
    border: 1px solid $concrete-gray;
    margin-bottom: 8px;
    height: 140px;

    &--production {
      width: 100%;

      .backlog-trend__overview {
        width: 30%;
      }

      .backlog-trend__report {
        width: 70%;
      }
    }

    &__overview {
      display: inline-block;
      width: 43%;
      vertical-align: top;
      padding: 16px;
      color: $dusty-gray;
    }

    &__report {
      display: inline-block;
      width: 57%;
      vertical-align: middle;

      &-chart {
        height: 140px;
      }
    }

    &__title {
      font-size: 14px;
      margin-bottom: 7px;
    }

    &__description {
      font-size: 11px;
      line-height: 15px;
    }
  }

  &__tips-container {
    width: 30%;
    background:$white;
    border-radius: 4px;
    border: 1px solid $concrete-gray;
    height: 436px;
    float: left;
    opacity: 0.6;
  }

  &__one-year-trends {
    width: calc(70% - 8px);
    float: left;
    margin-right: 8px;
  }
}
