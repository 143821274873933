.resume {
  &__content {
    [data-region="tableHeader"], [data-region="resumeContent"] {
      float: left;
      width: 100%;
    }

    [data-region="resumeContent"] {
      margin-bottom: 50px;
    }
  }

  &-budget {
    &__header {
      height: 45px;
      margin-bottom: 3px;
      background: $morning-fog-gray;
      color: $black;
      font-weight: $semiBold;
      overflow-y: hidden;
      padding-right: 8px;

      &-button-icon {
        right: -2px;
        top: 1px;
        position: relative;
      }

      .resume-budget__projected-col {
        padding-right: 8px;
      }

      .cell {
        display: inline-block;
        height: inherit;
        text-align: right;
      }

      &-subtitle {
        margin: 2px 0;
        font-size: 9px;
        font-weight: 100;
        opacity: 0.7;
      }

      &-cell {
        &--primary {
          transition: .3s ease-in-out;

          &-disabled {
            height: inherit;
            line-height: 45px;
            padding: 0 !important;
          }
        }

        &--secondary, &--tertiary, &--quaternary {
          height: inherit;
          line-height: 45px;

          .wethod-icon-button {
            border-color: $black;
          }
        }
      }
    }

    &-col {
      display: inline-block;
      vertical-align: middle;
      float: left;

      &:last-child {
        border-right: 1px solid $concrete-gray;
      }

      &:first-child {
        border-left: 1px solid $concrete-gray;
      }
    }

    &__cell {
      height: 40px;
      line-height: 40px;
      border-bottom: 1px solid $concrete-gray;
      padding-left: 20px;
      background: $white;
      padding-right: 8px;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;

      &--overview {
        color: $bright-sky-blue;
        font-weight: $bold;
        text-transform: uppercase;
      }

      &--investment {
        margin-top: 14px;
        border-top: 1px solid $concrete-gray;
      }

      input[type=text] {
        width: 100%;
        text-align: right;
        padding: 4px;
        border-radius: 3px;
        border: none;
        background: #F2F3F5;
      }
    }

    &__budget-col {
      width: 11%;
      text-align: right;
      float: left;

      &:hover {
        .resume-budget__header-cell--primary {
          margin-top: -45px;
        }

        .resume-budget__cell {
          background: rgba(158, 158, 158, 0.2);
        }
      }
    }

    &__forecast {
      &-col {
        height: inherit;
        overflow-y: hidden;

        .resume-budget__header-cell {
          &--primary {
            padding: 4px 0;

            &-no-subtitle {
              padding: 16px 0;
            }
          }
        }
      }

      &-content-col {
        width: 15%;
        text-align: right;

        &:hover {
          .resume-budget__header-cell--primary {
            margin-top: -45px;
          }

          .resume-budget__cell {
            background: rgba(158, 158, 158, 0.2);
          }
        }
      }
    }

    &__delta {
      &-col {
        width: 33.33%;
      }

      &-content-col {
        width: 9%;
        text-align: right;

        .resume-budget__content {
          color: darkgrey;
        }

        .resume-budget__header-cell--primary {
          padding: 10px 0;
        }
      }
    }

    &__projected-col {
      width: 11%;
      display: inline-block;
      text-align: right;
      float: left;
      height: inherit;
      line-height: 45px;

      .resume-budget__content {
        background-color: rgba(72, 165, 249, 0.1);
      }

      .resume-budget__cell {
        background-color: rgba(72, 165, 249, 0.1);
      }
    }

    &__name-col {
      width: 21%;
      float: left;
    }
  }

  &-monthly {
    float: left;
    width: 100%;

    .economics-section__area-title {
      margin-top: 24px;
    }

    .recordsHeader {
      margin: 0;
    }
  }
}

.recordsHeader--resume-budget {
  height: 45px;
}

.recordList__record {
  &--investment {
    &-payroll {
      margin-top: 14px;
    }
  }

  &--ebitda {
    margin-bottom: 14px;
  }
}

.probability-filter-label {
  margin-right: 8px;
  color: #757575;
}
