@import "scss/colors";

.app-nav {
  &__overlay {
    background: rgba(255, 255, 255, 0.5);
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100vh;
  }

  &__first-level {
    background: $maldivian-sand-white;
    font-size: 14px;
    position: relative;
    z-index: 2;

    &-notification {
      background: $raspberry-red;
      color: $white;
      border-radius: 50%;
      width: 18px;
      height: 18px;
      display: inline-block;
      font-size: 10px;
      line-height: 18px;
      text-align: center;
      font-weight: $normal;
      margin-left: 8px;
    }

    &-content {
      display: flex;
      align-items: center;
      justify-content: space-between;
      max-width: 1440px;
      padding: 0 24px;
      margin: auto;
      height: 60px;
    }

    &-left {
      margin-right: 48px;
      display: flex;
    }

    &-center {
      display: flex;
      align-items: center;
      flex-grow: 2;
      height: inherit;
      justify-content: space-between;
    }

    &-right {
      margin-left: 48px;
      display: flex;
      height: inherit;
      margin-right: -3px;

      .wethod-profile-pic {
        width: 32px;
        height: 32px;
        margin: 0;
        line-height: 30px;
        font-size: 14px;
      }
    }

    &-items {
      display: flex;
      height: 100%;
    }

    &-item {
      padding-left: 8px;
      padding-right: 16px;
      transition: .3s;
      display: flex;
      align-items: center;
      margin-top: 8px;
      margin-left: 8px;

      &--current {
        font-weight: $bold;
      }

      &--clicked {
        background: $cream-white;
        border-radius: 8px 8px 0 0;
      }

      a {
        font-size: inherit;
        line-height: inherit;
        background: no-repeat;
        border: none;
        outline: none;
        cursor: pointer;
        font-weight: inherit;
        margin-top: -8px;
        color: inherit;
        padding-right: 8px;
        padding-left: 4px;
        border-radius: 50px;
        transition: 0.3s;

        span {
          vertical-align: middle;
        }
      }

      &--profile-pic {
        padding: 0;

        a {
          padding: 0 4px;
        }

        &:hover {
          a {
            background: none !important;
          }
        }

        &.app-nav__first-level-item--clicked {
          border-radius: 50px 50px 0 0;
        }
      }

      &:hover {
        a {
          background: $cream-white;
        }
      }

      &-icon {
        display: inline-block;
        margin-right: 4px;
      }

      &-label {
        transition: .3s;
        padding: 6px 8px;
        border-radius: 50px;

        &:hover {
          background: $concrete-gray;
        }
      }
    }
  }

  &__second-level {
    background: $cream-white;
    border-radius: 0 0 8px 8px;
    font-size: 14px;
    line-height: 18px;
    box-shadow: 0 2px 4px 0 #CACACA;
    position: relative;
    z-index: 2;

    &-content {
      padding: 0 16px;
      font-size: 14px;
      line-height: 18px;
      display: flex;
      flex-wrap: wrap;
      position: relative;
      max-width: 1440px;
      padding-left: 132px;
      margin: auto;
    }
  }

  &__area {
    width: 20%;
    flex-shrink: 0;
    margin: 32px 0;

    &-title {
      font-weight: $bold;
      text-transform: uppercase;
      margin-bottom: 18px;
      position: relative;

      &-icon {
        display: inline-block;
        position: absolute;
        top: -9px;
        margin-left: 8px;
      }
    }

    &-section-list {
      &--blocked {
        opacity: 0.3;
      }
    }
  }

  &__section {
    margin: 16px 0;
    display: flex;
    gap: 0 8px;

    a {
      color: $black;
      display: inline-block;
      transition: .3s;
    }

    &:hover a {
      font-weight: $bold;
    }

    &-label {
      &--new {
        background: $black;
        padding: 0 6px;
        border-radius: 6px;
      }
    }
  }

  &-mobile {
    background: $maldivian-sand-white;
    font-size: 14px;
    position: relative;
    z-index: 2;

    &--menu-open {
      .app-nav-mobile-menu {
        top: 60px;
      }

      #hamburger {
        @extend .open;
      }
    }

    &__back-button {
      transform: scale(1.5) rotate(-180deg);
    }

    &-header {
      border-bottom: 1px solid $cream-white;
      padding: 0 16px;
      height: 60px;
      position: relative;
      z-index: 2;
      background: $maldivian-sand-white;

      #hamburger {
        width: 24px;
        height: 15px;

        span {
          height: 2px;
          background: rgba(0, 0, 0, 0.87);

          &:nth-child(1) {
            top: 0;
          }

          &:nth-child(2), &:nth-child(3) {
            top: 7px;
          }

          &:nth-child(4) {
            top: 14px;
          }
        }
      }

      &__content {
        display: flex;
        justify-content: space-between;
        align-items: center;

        &-center {
          flex-grow: 2;
          padding: 0 24px;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
        }

        &-left {
          display: flex;
        }
      }

      &__current-page {
        font-size: 20px;
        font-weight: 500;
        line-height: 24px;
      }
    }

    &-menu {
      height: calc(100vh - 60px);
      position: absolute;
      width: 100%;
      background: $maldivian-sand-white;
      display: flex;
      top: -100vh;
      transition: .5s;
    }

    &-tab {
      min-height: 100%;
      padding: 0 16px;
      width: 100%;
      position: relative;
      transition: .5s;
      flex-shrink: 0;
      margin-left: 0;
      overflow: auto;
    }

    &-item {
      &__button {
        color: inherit !important;
        font-size: 18px;
        font-weight: $semiBold;
        line-height: 21px;
        border: none;
        height: 72px;
        border-bottom: 1px solid $cream-white;
        background: none;
        width: 100%;
        text-align: left;
        display: flex;
        justify-content: space-between;
        align-items: center;
        outline: none;

        &--logout {
          font-weight: $normal;
        }
      }

      &__label {
        flex-grow: 2;
      }

      &__icon {
        display: inline-block;
        margin-right: 24px;
      }

      &__icon-dropdown {
        display: inline-block;
      }

      &__panel {
        max-height: 0;
        overflow: hidden;
        transition: .3s;
      }
    }

    &-area {
      color: $blackboard-black;
      font-size: 16px;
      line-height: 56px;
      border-bottom: 1px solid $cream-white;
      display: flex;
      justify-content: space-between;
      align-items: center;

      &__icon {
        display: inline-block;
      }
    }

    &-section {
      color: $blackboard-black;
      font-size: 16px;
      line-height: 56px;
      border-bottom: 1px solid $cream-white;

      &__content {
        color: inherit !important;
        height: 100%;
        width: 100%;
        display: inline-block;
      }
    }
  }
}
