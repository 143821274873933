
$extraSmall   : max-width 768px;
$smallDevice  : min-width 600px max-width 768px;
$mediumDevice : min-width 768px max-width 950px;
$largeDevice  : max-width 950px;

@mixin scaleToExtraSmall($max:nth($extraSmall,2)) {
  @media (max-width: $max) {
    @content
  }
  @media (max-device-width : $max) {
    @content
  }
}

@mixin scaleToSmall($min:nth($smallDevice,2),$max:nth($smallDevice,4)) {
  @media (min-width: $min) and (max-width: $max) {
    @content
  }
  @media (max-device-width : $max) {
    @content
  }
}

@mixin scaleToMedium($min:nth($mediumDevice,2),$max:nth($mediumDevice,4)) {
  @media (min-width: $min) and (max-width: $max) {
    @content
  }
}

@mixin print() {
  @media print {
    @content
  }
}

