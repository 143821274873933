.chargeability {
  max-width: 1440px !important;
  padding: 0 24px 24px;

  @include scaleToExtraSmall() {
    max-width: 1024px !important;
    padding: 0;
  }

  .wethod-section-actions {
    padding: 16px 0;
  }

  &__title {
    display: flex;
    margin-right: auto;
    flex-direction: column;
    line-height: 1.2;
    span:first-child {
      font-size: 16px;
      font-weight: $semiBold;
    }
    span:not(first-child) {
      font-size: 11px;
    }
  }

  &__level-row {
    position: relative;
    cursor: pointer;
    transition: .2s;
    height: 52px;
    line-height: 52px;

    &:hover {
      background-color: #EBEBEB;
    }

    &--selected {
      background-color: #FAFAFA;

      &::before {
        content: "";
        position: absolute;
        height: 100%;
        width: 4px;
        background-color: $bright-sky-blue;
      }
    }
  }

  &__tooltip {
    max-width: 300px;
    line-height: 1.5;
    white-space: pre-wrap;
    top: 20%;
    left: 87%;

    .tooltipReact {
      width: 300px;
      line-height: 1.5;
      white-space: pre-wrap;
      top: -12px;
      left: 50%;
      @include scaleToExtraSmall() {
        right: 12px;
        left: unset;
      }
    }
  }

  &__employee {
    &-row {
      height: 64px;
      line-height: 64px;
      background-color: #FAFAFA;
    }
    &-pic {
      height: 48px;
      width: 48px;
      line-height: 48px;
      font-size: 18px;
      margin-right: 24px;
    }
    &-info {
      overflow: hidden;
      flex: 1;

      & * {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        line-height: 1.3;
        font-size: 11px;
      }
      & >:first-child {
        font-size: 14px;
        font-weight: $semiBold;
      }
      & >:last-child {
        color: $dusty-gray;
      }
    }
  }

  &__column {
    &-level {
      width: 17%;
    }
    &-trend {
      width: 48%;
      height: 100%;

      display: inline-flex;
      justify-content: space-around;
    }
    &-target {
      width: 15%;
      text-align: center;
    }
    &-avg {
      width: calc(20% - 56px);
      text-align: center;
    }
    &-name {
      width: 32%;
      display: inline-flex;
      align-items: center;
      height: 100%;
    }
    &-link {
      width: 56px;
      text-overflow: unset;
    }
  }

  &__chart {
    height: 100%;
    width: 100%;
    padding-top: 3px;
    padding-bottom: 3px;
  }

  &__value {
    font-weight: $semiBold;

    &--positive {
      color: $garden-green;
    }
    &--negative {
      color: $raspberry-red;
    }
  }

  &__button {
    &:hover {
      background-color: rgba(0, 0, 0, 0.1);
      color:$black;

      .wethod-icon {
        background-position-x: -32px !important;
      }
    }
  }

  &__multiple-table {
    .wethod-table {
      width: 50%;
    }
  }

  &__footer {
    padding: 0 16px;
    border: 1px solid $concrete-gray;
    border-top: none;
    background-color: $white;
    line-height: 30px;
  }

  &__legend {
    display: inline-block;
    width: 15px;
    vertical-align: middle;
    margin-right: 8px;
    border-bottom: 1px dashed #F8A01C;
  }

  &__loading {
    border: 1px solid $concrete-gray;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.multiple-table {
  &-list {
    border: 1px solid $concrete-gray;

    .wethod-table {
      display: inline-table;
      height: 100%;
      vertical-align: top;

      &:first-child {
        .wethod-table__body {
          border-right: 1px solid $concrete-gray;
          background-color: $white;
        }
      }
      &:last-child {
        .wethod-table__body {
          background-color: #FAFAFA;
        }
      }
    }
    .wethod-table__body {
      height: calc(100% - 57px);

      .wethod-table__row {
        border-bottom: 1px solid $concrete-gray;
      }
    }
    .wethod-table__content {
      border: none;
      height: 100%;
    }
  }
}
