$priority-width: 5px;

@keyframes hop {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.07);
  }
  100% {
    transform: scale(1);
  }
}

.alerts-engine {
  width: 768px;
  margin: auto;
  padding-top: 16px;

  &__header {
    float: left;
    padding-bottom: 18px;
  }

  &__footer {
    height: 54px;
    float: left;
    width: 100%;
  }

  &__accordion {
    width: calc(100% - 62px);
    float: left;
    overflow-y: hidden;
    transition: 400ms;
    cursor: pointer;

    &-button {
      z-index: 2;
      float: left;
      width: 100%;
      padding: 12px 32px 12px 36px;
      position: relative;
      border: 1px solid $concrete-gray;
      background-color:$white;
    }

    &-panel {
      cursor: default;
      position: relative;
      float: left;
      width: calc(100% - #{$priority-width});
      margin-left: #{$priority-width};
      background-color: #74B1F5;
      box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.05);
      color:$white;
      max-height: 0;
      padding: 0 32px;
      transition: 400ms ease-in-out;
      margin-bottom: 6px;
    }

    &--disabled {
      @extend .alerts-engine__accordion;
      opacity: 0.5;

      .alerts-engine {
        &__accordion {
          &-panel {
            @extend .alerts-engine__accordion-panel;
            background-color: rgba(128, 128, 128, 0.9);
          }
        }

        &__alert {
          &-priorities {
            display: block;

            &:hover {
              width: $priority-width;
            }
          }
        }

        &__priority {
          &--selected {
            height: 100%;
          }
        }
      }
    }
  }

  &__radio-group{
    .wethod-radio-group {
      .wethod-radio {
        .wethod-radio__icon--empty, .wethod-radio__icon--full {
          fill: $white;
        }
      }
    }

    &--disabled {
      opacity: 0.5;
      pointer-events: none;
    }
  }

  &__enabled-button {
    float: left;
    padding-top: 17px;
  }

  &__alert {
    float: left;
    width: 100%;
    margin-top: 1px;
    line-height: 24px;
    font-size: 12px;

    &-priorities {
      display: flex;
      width: $priority-width;
      height: 48px;
      position: absolute;
      transition: 300ms;
      left: 0;
      top: 0;

      &:hover {
        width: 120px;

        .alerts-engine__priority {
          flex-grow: 1;

          &:hover {
            flex-grow: 3;
          }
        }
      }
    }

    &-name {
      float: left;
      width: calc(100% - 18px - 18px - 32px);
      font-weight: $light;
      color: #2C2F39;
      text-transform: capitalize;
    }

    &-message {
      float: left;
      width: 80%;
      margin: 15px 0;
      font-weight: $light;
    }

    &-info {
      float: left;
      width: 20%;
      color: white !important;
      text-decoration: underline;
      text-align: right;
      margin: 15px 0;
      position: absolute;
      bottom: 0;
      right: 32px;
    }
  }

  &__priority {
    transition: 300ms;

    &--selected {
      flex-grow: 1;
      animation: hop 200ms;
    }
  }

  &__panel-gear {
    float: right;
    border: none;
    outline: none;
    cursor: pointer;
    margin: 5px 0 0 0;
    height: 15px;
    width: 21px;
    background: url("/img/icon/alerts-engine_show-settings.svg") no-repeat;
    background-size: 21px 15px;
  }

  &__chunk-param {
    margin: 3px 8px;
    border: 1px solid$white;
    border-radius: 15px;
    background-color: transparent;
    width: 50px;
    color:$white;
    text-align: center;
    outline: none;
    font-weight: $light;
    height: 21px;
    line-height: 21px;

    &::selection { /* WebKit/Blink Browsers */
      background: #519EF7;
    }

    &::-moz-selection { /* Gecko Browsers */
      background: #519EF7;
    }
  }
}
