//REPORT PRODUCT VALUE
$report-production-value-rule: (
        columns: 24,
        container: 1024px,
        gutters: .17,
        gutter-position : after,
        global-box-sizing: border-box,
);

//definisco lo style della struttura del layout
.productionValue {

  .reportHeader {
    height: 60px;
    @include scaleToExtraSmall() {
      height: 42px;
    }
    @include scaleToSmall() {
      height: 42px;
    }
  }

  @include with-layout($report-production-value-rule) {
    @include container;
  }

  .productionValue__fullPageChart {
    @include container;
    height: 400px;
    margin-top: 12px;
  }

  .productionValue__detailChartClinet, .productionValue__detailChartProject {
    display: none;
    margin: 0;
    background: $white;
    border: 1px solid $concrete-gray;

    .productionValue__detailChartClinet__header {
      float: left;
      width: 100%;
      margin-top: 16px;

      .productionValue__detailChartClinet__header__title {
        @include with-layout($report-production-value-rule) {
          @include span(7);
        }

        padding-left: 15px;
        font-size: 17px;
        font-weight: $bold;
        text-transform: uppercase;
      }

      .productionValue__detailChartClinet__header__titleType {
        @include with-layout($report-production-value-rule) {
          //@include span(5 last);
        }
        padding: 0 13px 0 0;
        text-align: right;
        font-size: 17px;
        color: $bright-sky-blue;
        text-transform: uppercase;
      }
    }

    .productionValue__detailChart__clientName {
      @include with-layout($report-production-value-rule) {
        @include span(6);
      }
      line-height: 1.3;
      padding-left: 16px;
      padding-right: 0;
      overflow-wrap: break-word;
    }

    .productionValue__detailChart__projectName {
      @include with-layout($report-production-value-rule) {
        @include span(6);
      }
      line-height: 1.3;
      padding-left: 16px;
      padding-right: 0;
      overflow-wrap: break-word;
    }

    .productionValue__detailChart__product {
      @include with-layout($report-production-value-rule) {
        @include span(3);
      }
      line-height: 1.3;
      text-align: right;
      padding-right: 0;

      &.productionValue__detailChart__lost_production {
        padding-right: 13px;
      }
    }

    .productionValue__detailChart__production_plan {
      @include with-layout($report-production-value-rule) {
        @include span(4);
      }
      line-height: 1.3;
      text-align: right;
      padding-right: 0;
    }

    .productionValue__detailChart__production_plan_week {
      @include with-layout($report-production-value-rule) {
        @include span(4);
      }
      line-height: 1.3;
      text-align: right;
      padding-right: 0;
    }

    .productionValue__detailChart__production_delta {
      @include with-layout($report-production-value-rule) {
        @include span(4);
      }
      line-height: 1.3;
      text-align: right;
      padding-right: 0;
    }

    .productionValue__detailChart__production {
      @include with-layout($report-production-value-rule) {
        @include span(4);
      }
      line-height: 1.3;
      text-align: right;
      padding-right: 0;
    }

    .productionValue__detailChart__roadrunner {
      @include with-layout($report-production-value-rule) {
        @include span(2 last);
      }
      line-height: 1.3;
      padding-right: 10px;
      text-align: right;
    }

    .productionValue__detailChart__list {
      float: left;
      width: 100%;
      height: 251px;
      border-bottom: 1px solid $bright-sky-blue;
      overflow: hidden;
      overflow-y: auto;

      //stile scroll
      &::-webkit-scrollbar {
        width: 7px;
      }

      &::-webkit-scrollbar-track {
        width: 7px;
        -webkit-box-shadow: inset 0 0 6px rgba(255, 255, 255, 0);
      }

      &::-webkit-scrollbar-thumb {
        border-radius: 4px;
        background-color: rgba(0, 0, 0, .2);
      }

      li {
        float: left;
        width: 100%;
        padding: 10px 0;
        border-bottom: 1px solid $concrete-gray;
      }
    }
  }

  .productionValue__detailChartClinet {
    @include with-layout($report-production-value-rule) {
      @include span(12);
    }

    @include scaleToSmall() {
      @include with-layout($report-production-value-rule) {
        @include span(24 last);
      }
    }

    .recordsHeader {
      height: 38px;
      line-height: 38px;

      .recordsHeader__recordName {
        height: 38px;
      }
    }
  }

  .productionValue__detailChartProject {
    @include with-layout($report-production-value-rule) {
      @include span(12 last);
    }

    @include scaleToSmall() {
      @include with-layout($report-production-value-rule) {
        @include span(24 last);
      }
    }
    @include scaleToExtraSmall() {
      margin-top: 10px;
    }
    @include scaleToSmall() {
      margin-top: 10px;
    }

    .recordsHeader {
      height: 38px;
      line-height: 38px;

      .recordsHeader__recordName {
        height: 38px;
      }
    }
  }

  .prodValue__detailProjWeekly__switchButton {
    @extend .switchButton;
  }

  .prodValue__detailProjWeekly {
    border: 1px solid $concrete-gray;

    .recordsHeader {
      height: 38px;
    }

    .recordsHeader__recordName {
      height: 38px;
      line-height: 14px;
    }

    @include with-layout($report-production-value-rule) {
      @include span(6);
      margin: 10px 0 60px 0;
      background: $white;
      float: left;
      clear: both;
      width: 100%;

      .weeklyDetailTotal {
        margin: 10px 20px;
        display: inline-block;
        float: right;
      }

      .prodValue__detailProjWeekly__header {
        float: left;
        width: 100%;
        margin-top: 16px;
        @include scaleToSmall() {
          @include with-layout($report-production-value-rule) {
            position: relative;
          }
        }
      }

      .prodValue__detailProjWeekly__header__title {
        @include span(19%);
        padding-left: 15px;
        margin-top: 6px;
        font-size: 17px;
        font-weight: $bold;
        text-transform: uppercase;
        @include scaleToSmall() {
          @include with-layout($report-production-value-rule) {
            @include span(full);
          }
        }
      }

      .prodValue__detailProjWeekly__label {
        @include span(55%);
        float: none;
        margin: auto;
        @include scaleToSmall() {
          @include with-layout($report-production-value-rule) {
            @include span(full);
            width: 60%;
            margin: auto;
            float: none;
          }
        }
      }

      .prodValue__detailProjWeekly__titleType {
        @include span(19% last);
        padding: 0 13px 0 0;
        margin-top: 6px;
        text-align: right;
        font-size: 17px;
        color: $bright-sky-blue;
        text-transform: uppercase;
        @include scaleToSmall() {
          @include with-layout($report-production-value-rule) {
            @include span(full);
            position: absolute;
          }
        }
      }

      // override page css class
      .recordList__record {
        height: 36px;
        border-right: 0;
        border-left: 0;

        .recordList__record__content {
          height: 36px;
        }
      }

      .prodValue__detailProjWeekly__list {
        float: left;
        width: 100%;
        height: 361px;
        border-bottom: 1px solid $bright-sky-blue;
        overflow: hidden;
        overflow-y: scroll;

        //stile scroll
        &::-webkit-scrollbar {
          width: 7px;
        }

        &::-webkit-scrollbar-track {
          width: 7px;
          -webkit-box-shadow: inset 0 0 6px rgba(255, 255, 255, 0);
        }

        &::-webkit-scrollbar-thumb {
          border-radius: 4px;
          background-color: rgba(0, 0, 0, .2);
        }
      }

      .prodValue__detailProjWeekly__projectName {
        @include span(3 of 12);
      }
      .prodValue__detailProjWeekly__extCost {
        @include span(1 of 12);
      }
      .prodValue__detailProjWeekly__timesheet {
        @include span(1 of 12);
      }
      .prodValue__detailProjWeekly__deltaPS {
        @include span(1 of 12);
      }
      .prodValue__detailProjWeekly__product {
        @include span(1 of 12);
      }
      .prodValue__detailProjWeekly__estimated_prodHour {
        @include span(1 of 12);
      }
      .prodValue__detailProjWeekly__roadrunner {
        @include span(1 of 12);
      }
      .prodValue__detailProjWeekly__lost_production {
        @include span(1 of 12);
      }
      .prodValue__detailProjWeekly__prodHour {
        @include span(1 of 12);

        .higher {
          color: $garden-green;
        }

        .lower {
          color: $raspberry-red;
        }
      }
      .prodValue__detailProjWeekly__report_icon {
        @include span(1 of 12 last);

        .iconButton {
          margin: auto;
        }
      }
    }

    @include scaleToSmall() {
      @include with-layout($report-production-value-rule) {
        //@include span(12);

        // Make all buttons rounded.
        .prodValue__detailProjWeekly__switchButton {
          li {
            border-radius: 30px;
            border: 1px solid $bright-sky-blue;
            width: 100%;
            margin-top: 8px;

            &:first-child {
              border-radius: 30px;
              margin-top: 16px;
            }

            &:last-child {
              border-radius: 30px;
            }
          }
        }

        .prodValue__detailProjWeekly__projectName {
          @include span(6 of 12);
        }
        .prodValue__detailProjWeekly__extCost, .prodValue__detailProjWeekly__timesheet,
        .prodValue__detailProjWeekly__deltaPS, .prodValue__detailProjWeekly__estimated_prodHour,
        .prodValue__detailProjWeekly__roadrunner, .prodValue__detailProjWeekly__lost_production {
          display: none;
        }
        .prodValue__detailProjWeekly__product {
          @include span(2 of 12);
        }
        .prodValue__detailProjWeekly__prodHour {
          @include span(2 of 12);
        }
        .prodValue__detailProjWeekly__report_icon {
          @include span(2 of 12 last);
        }
      }
    }
  }

  // This class is used to show weeks info on projects
  // and clients details
  .productionValue__monthly_label {
    display: none;
  }

  &.is_monthly .productionValue__monthly_label {
    display: inline-block;
  }

  .modalWrapAction {
    margin-top: 32px;
  }

  .exportModule {
    clear: none;
    margin: 10px 20px 0 0;
    cursor: pointer;
  }

  .month-label {
    width: 150px;
    margin: 0 auto;
    display: block;
    padding: 5px;
    cursor: pointer;
    outline: none;
  }

  .month-input {
    width: 140px;
    position: absolute;
    left: 105px;
    margin-top: 4px;
  }
}

.weekly-header {
  &__label {
    padding-left: 6px;
  }

  &__order-icon {
    padding-top: 11px;

    li.ascIcon, li.descIcon {
      display: none;
    }
  }
}

.header {
  &--clickable {
    &:hover, &.active {
      background: $concrete-gray;
      cursor: pointer;

      .asc {
        li.ascIcon {
          display: block;
        }
      }

      .desc {
        li.descIcon {
          display: block;
        }
      }
    }

    .weekly-header {
      &__order-icon {
        text-align: right;
        display: none;
      }
    }

    &.active:hover {
      .weekly-header {
        &__label {
          display: none;
        }

        &__order-icon {
          display: block;
        }
      }
    }
  }
}

.production-value-report {
  &__resume-left {
    width: calc(80% - 10px);
    float: left;
    margin-right: 10px;
    @include scaleToExtraSmall() {
      width: 100%;
      float: right;
      margin-right: 0;
    }
    @include scaleToSmall() {
      width: 100%;
      float: right;
      margin-right: 0;
    }

    .highcharts-title {
      @include scaleToExtraSmall() {
        font-size: 16px;
      }
      @include scaleToSmall() {
        width: 100%;
        float: right;
        margin-right: 0;
      }
    }

    .highcharts-subtitle {
      @include scaleToExtraSmall() {
        display: none;
      }
      @include scaleToSmall() {
        display: none;
      }
    }
  }

  &__resume-right {
    padding-top: 40px;
    width: 20%;
    float: left;
    height: 410px;
    background-color: $white;
    border: 1px solid $concrete-gray;
    @include scaleToExtraSmall() {
      width: 100%;
      margin-bottom: 10px;
      height: 190px;
      padding-top: 0;
    }
    @include scaleToSmall() {
      width: 100%;
      margin-bottom: 10px;
      height: 190px;
      padding-top: 0;
    }
  }

  &__rri, &__production {
    width: 100%;
    height: 200px;
    margin-bottom: 10px;
    border: 1px solid $concrete-gray;
  }

  &__target-clock {
    height: 200px;
    @include scaleToExtraSmall() {
      width: 40%;
      float: left;
      height: 190px;
    }
    @include scaleToSmall() {
      width: 40%;
      float: left;
      height: 190px;
    }

    &-labels {
      position: relative;
      @include scaleToExtraSmall() {
        position: absolute;
        float: left;
        width: 40%;
        height: 190px;
      }
      @include scaleToSmall() {
        position: absolute;
        float: left;
        width: 40%;
        height: 190px;
      }
    }

    &-data {
      margin-top: -25px;
      @include scaleToExtraSmall() {
        width: 60%;
        float: left;
        margin-top: 15px;
      }
      @include scaleToSmall() {
        width: 60%;
        float: left;
        margin-top: 15px;
      }
    }
  }
}

.target-clock {
  &-data {
    &__label {
      text-transform: uppercase;
      font-size: 10px;
    }

    &__row {
      margin-top: 16px;
      text-align: center;
      padding: 0 10px;
      transition: transform .2s ease-in;
    }

    &__value {
      margin-top: 4px;
      font-size: 18px;
      //font-weight: 100;
    }
  }

  &-labels {
    &__name {
      z-index: 20;
      position: absolute;
      top: 70px;
      left: 0;
      width: 100%;
      text-align: center;
      color: #878B8E;
      font-size: 10px;
      @include scaleToExtraSmall() {
      }
      @include scaleToSmall() {

      }
    }

    &__value {
      z-index: 20;
      position: absolute;
      top: 85px;
      left: 0;
      width: 100%;
      text-align: center;
      color: #878B8E;
      font-size: 30px;
      font-weight: $light;
      @include scaleToExtraSmall() {
      }
      @include scaleToSmall() {
      }
    }
  }
}

.zoom {
  transform: scale(1.2);
}



