$white: #FFF;
$maldivian-sand-white: #F5F1EA;
$cream-white: #EAE3D8;
$alabaster-white: #FCFCFC;
$dark-alabaster-white: #FAFAFA;
$spring-wood-white: #FCFBF9;

$black: #000;
$blackboard-black: #3C3C3B;
$etna-stone-black: #212121;

$dusty-gray: #9B9B9B;
$morning-fog-gray: #F0EFED;
$concrete-gray: #E0E0E0;
$steel-gray: #ABB2B9;
$placeholder-gray: #7D7D7D;
$nobel-gray: #777777;

$bright-sky-blue: #48A5F9;

$raspberry-red: #DB4D69;

$garden-green: #5BD87C;

$sunset-orange: #FFB948;

$amethyst-violet: #A24BFB;

$wethod-blue: #009FE3;

$dolphin-light-blue: #CEDFE5;
