
.moduleName {
  font-size: 18px;
  line-height: 1.3;
  margin: 6px 0;
  color: $bright-sky-blue;
  text-transform: capitalize;
}

.wrapPermissionModule {
  margin-bottom: 12px;
}

.roleInfo {
  padding: 8px 0 0 0;
  color: #878B8E;
  font-size: 12px;
}

.deleteNewRole {
  display: inline-block;
  margin-top: 8px;
}

.newRole {
  background: $white;
  height: 60px;
  margin-bottom: 12px;

  input[type=text] {
    border: none;
    width: 100%;
    padding: 18px 6px;
    line-height: 1.3;
    outline: 0;
    outline: none;
  }
  .roundButton {
    margin-top: 9px;
  }

  .iconStatus {
    bottom: 3px;
  }
}

.roleMessage {
  display: none;
  position: absolute;
  bottom: 5px;
  left: 18px;
  width: auto;
  height: 15px;
  color: $linkColor;
  &.error {
    color: $errorColor;
  }
}

.displayRole {
  .table {
    height: 43px;
    width: 100%;
  }
}

.permissionFooter {
  margin-top: 12px;
}

.wrapPermissionButtons {
  margin: 24px 0;
}

.permissionTable {
  table-layout: fixed;
  td, th {
    height: 43px;
    // overflow-x: scroll;
    vertical-align: middle;
    &.borderCell {
      text-align: center;
      //border-left: 1px solid #ccc;
    }
  }

  tr.rowSeparetor {
    border-bottom: 1px solid #ccc;
  }

  input[type=checkbox] {
    font-size: 25px;
    cursor: pointer;
  }

  .headerApp {
    .permissionHeader {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      padding: 0 3px;
    }

    .permissionHeader:hover {
      overflow: visible;
      white-space: initial;
      color: $bright-sky-blue;
      width: 10%;
    }
  }
}
