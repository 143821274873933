@import "scss/colors";

$daily-hour-width: 44px;
$weekly-hour-width: 12px;

.planning {
  &__recurring-mode {
    &-button {
      float: left;
      position: relative;
      margin-right: 16px;
      font-size: 12px;
      padding-right: 16px !important;

      &-icon {
        position: absolute;
        left: 5px;
        top: 3px;
      }

      &:hover {
        .planning__recurring-mode-button-icon {
          @extend .wethod-icon-medium-repeat--white;
        }
      }

      &--enabled {
        @extend .button--active;

        .planning__recurring-mode-button-icon {
          @extend .wethod-icon-medium-repeat--white;
        }
      }
    }
  }

  &__filter-people {
    margin-right: 16px;
    @include scaleToExtraSmall() {
      margin: 0;
    }
  }

  &__project-select {
    @include scaleToExtraSmall() {
      display: none;
    }
  }

  &-people {
    max-width: 1440px !important;
    padding: 0 24px;
    margin-top: -1px !important;
    @include scaleToExtraSmall() {
      max-width: 1024px !important;
      padding: 0;
    }

    &__content {
      @include scaleToExtraSmall() {
        padding-top: 16px;
      }
    }

    &__loader-container {
      display: flex;
      justify-content: space-around;
      padding: 16px 0;
    }

    &__modal-multi-choice {
      min-width: 300px;

      &-modal {
        .wethod-modal2__body {
          padding: 0 32px;
        }
      }

      &-item {
        color: $black !important;
        border-bottom: 1px solid $concrete-gray;
        padding: 20px 32px;
        margin: 0 -32px;
        transition: .3s;
        cursor: pointer;
        display: flex;
        justify-content: space-between;
        align-items: center;

        &:hover {
          background: #F2F3F5;
        }

        .wethod-icon-arrow-right {
          margin: 0 -5px 0 24px;
        }
      }
    }

    .wethod-modal2 {
      display: flex;
      align-items: center;
      justify-content: center;

      &__container {
        margin: 0;
        overflow: hidden;
      }
    }

    &--disabled {
      &:after {
        content: '';
        display: block;
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        z-index: 10;
      }
    }

    // OLD PLANNING
    .employee {
      &__info {
        &--not-teammate {
          opacity: 0.3;
        }
      }

      &__details {
        width: 100%;
      }
    }

    a.employee__details {
      color: $black;
    }

    .filter__clear {
      text-decoration: underline;
      margin-right: 15px;
      margin-left: 0;
      cursor: pointer;
      position: absolute;
      top: 8px;
      left: -81px;
      width: auto;
    }

    font-size: 14px;

    .section-header {
      margin-top: 16px;
      @include scaleToExtraSmall() {
        margin-top: 0;
      }
    }

    &__allocation {
      &-details {
        max-height: calc(100vh - 232px);
        overflow: auto;
        padding: 32px;
        width: 400px;

        @include scaleToExtraSmall() {
          width: 100%;
          height: 100%;
          max-height: none;
        }

        &-actions {
          padding: 0 32px 32px 32px;
          margin-top: 16px !important;
        }

        &-projects {
          max-height: calc(100vh - 500px);
          overflow-y: initial;
          margin: 0 -32px;
          @include scaleToExtraSmall() {
            max-height: calc(100% - 150px);
            overflow-y: auto;
          }
        }

        &-date {
          margin: 24px 0;
          display: flex;
          align-items: center;

          &--week {
            align-items: end;

            .planning-people__allocation-details-label:first-of-type {
              margin-bottom: 16px;
            }

            &-what {
              font-size: 12px;
              letter-spacing: 0.1px;
              line-height: 17px;
              color: $dusty-gray;
            }
          }

          &-icon {
            margin-left: -8px;
            margin-right: 23px;
          }
        }

        &-footer {
          height: 48px;
          display: flex;
          justify-content: flex-end;
          color: $white;
          align-items: center;
          background-color: $bright-sky-blue;
          cursor: pointer;
          padding: 0 32px;
          transition: .3s;

          &-icon {
            background-position-x: -64px;
            margin-right: -16px;
            margin-left: 24px;
          }

          &:hover {
            background: #0485f9;
          }
        }

        .employee {
          &__image {
            @include scaleToExtraSmall() {
              display: block;
            }
          }

          &__info {
            @include scaleToExtraSmall() {
              margin-left: 60px !important;
            }
          }
        }
      }

      &-item {
        position: relative;
        overflow: hidden;

        &-content {
          display: flex;
          align-items: center;
          width: 400px;
          cursor: pointer;
          padding: 12px 32px;
        }

        &-error {
          width: 100%;
          padding: 12px 32px;
          background: $raspberry-red;
          color: $white;
          font-size: 12px;
          height: 80px;
          line-height: 56px;
          text-align: center;
          position: absolute;
          top: 100%;
          transition: .4s;

          &--visible {
            top: 0;
          }
        }

        .employee__level {
          text-transform: none;
        }

        &:hover {
          background: #F2F3F5;
        }

        &-hours {
          width: 48px;
          flex-shrink: 0;
        }

        &-info {
          display: flex;
          align-items: center;
          width: calc(100% - 64px - 20px); // total width - padding - hours' width
          flex-shrink: 0;

          &-content {
            width: calc(100% - 56px); // total width - type's width
          }
        }

        &-type {
          width: 6px;
          border-radius: 50px;
          align-self: stretch;
          margin-right: 12px;
          flex-shrink: 0;
        }

        .employee__name, .employee__area {
          margin-bottom: 4px;
        }
      }
    }

    &__actions {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 10px;

      .dropdown {
        right: 0;
        left: auto;
      }
    }

    &__project {
      &-info {
        padding: 12px 16px;
        background: $white;
        border: 1px solid $concrete-gray;
        border-radius: 4px;
        margin-bottom: 15px;

        &-allocation-request-button {
          color: $black !important;
        }

        &-action {
          display: inline-block;
          margin: 0 6px;
          cursor: pointer;
          position: relative;

          .tooltipReact {
            opacity: 0;
            left: -10px;
            top: -26px;
          }

          &--button {
            display: inline-block;
            margin: 0 6px;
            outline: none;
            border: none;
            border-radius: 50%;
            background-color: transparent;
            cursor: pointer;
            transition: .3s;

            &:hover {
              background-color: rgba(0, 0, 0, 0.1);
            }
          }

          &--close {
            margin: 0 0 0 12px;
          }

          &--canvas {
            transform: scale(0.9);
            @include scaleToExtraSmall() {
              display: none;
            }

            .tooltipReact {
              transform: scale(1.1);
            }
          }

          &--budget, &--project-report, &--pipeline {
            @include scaleToExtraSmall() {
              display: none;
            }
          }

          &:hover {
            .tooltipReact {
              opacity: 1;
            }
          }

          &:before {
            content: '';
            background: rgba(0, 0, 0, 0.1);
            height: 100%;
            display: block;
            width: 100%;
            border-radius: 50%;
            transition: .3s;
            opacity: 0;
          }

          &:hover:before {
            opacity: 1;
          }
        }

        &-details {
          display: flex;
          justify-content: space-between;
          align-items: center;

          &--right {
            display: flex;
            justify-content: space-evenly;
            align-items: center;
          }
        }

        &-name {
          font-weight: $semiBold;
          @include scaleToExtraSmall() {
            margin-bottom: 8px;
            display: inline-block;
          }
        }

        &-client {
          color: $dusty-gray;
          font-size: 12px;
          @include scaleToExtraSmall() {
            display: none;
          }
        }

        &-pm {
          color: $dusty-gray;
          font-size: 12px;
          margin-left: 24px;
          @include scaleToExtraSmall() {
            display: none;
          }

          &:before {
            content: 'PM:';
            font-weight: $semiBold;
            color: $black;
            margin-right: 4px;
          }
        }

        &-jo {
          color: $dusty-gray;
          font-size: 12px;
          margin-left: 24px;
          @include scaleToExtraSmall() {
            display: none;
          }

          &:before {
            content: 'Job Order:';
            font-weight: $semiBold;
            color: $black;
            margin-right: 4px;
          }
        }

        &-availability {
          display: flex;
          margin-top: 12px;
          overflow-x: auto;
          overflow-y: hidden;
          // Avoids that x-scrollbar hides level availabilities
          padding-bottom: 12px;
          flex-grow: 2;

          &-container {
            display: flex;
            align-items: center;
            gap: 8px;
          }

        }

        &-level {
          &-availability {
            margin-right: 30px;
            flex-shrink: 0;

            &-name {
              color: $dusty-gray;
              font-size: 12px;
              text-transform: uppercase;
            }

            &-amount {
              font-size: 12px;
              margin-top: 4px;

              &--not-zero {
                font-weight: $semiBold;
              }
            }
          }
        }
      }
    }

    &__menu {
      text-align: left;
      white-space: nowrap;

      a {
        color: initial;
      }

      &-element {
        &--hidden-desktop {
          display: none;
          @include scaleToExtraSmall() {
            display: block;
          }
        }
      }

      .planning-people__menu-element {
        &--pipeline, &--budget, &--report {
          display: none;
          @include scaleToExtraSmall() {
            display: block;
          }
        }

        &--canvas {
          display: none;
        }
      }

      &--approval-needed {
        .planning-people__menu-element {
          &--pipeline, &--budget, &--report {
            display: block;
          }

          &--canvas {
            display: block;
            @include scaleToExtraSmall() {
              display: none;
            }
          }
        }
      }
    }

    &-details {
      .wethod-modal2 {
        &__body {
          padding: 0;
          @include scaleToExtraSmall() {
            height: calc(100% - 56px);
            position: relative;
          }
        }

        &__container {
          @include scaleToExtraSmall() {
            height: 100%;
            width: 100%;
          }
        }
      }

      @include scaleToExtraSmall() {
        padding: 0 !important;
      }

      .profile-contact-info__actions {
        @include scaleToExtraSmall() {
          position: absolute;
          bottom: 0;
          width: 100%;
        }
      }

      &--week {
        .planning-people__allocation-details-projects {
          @include scaleToExtraSmall() {
            max-height: calc(100% - 214px) !important;
          }
        }
      }
    }

    &-repeat {
      .wethod-modal2__container {
        position: relative;
      }

      &--disabled {
        .wethod-modal2__container:after {
          content: '';
          display: block;
          background: rgba(0, 0, 0, 0);
          width: 100%;
          height: 100%;
          position: absolute;
          top: 0;
          z-index: 2;
        }
      }

      &-error {
        &__container {
          .wethod-modal2__container {
            position: relative;
          }

          &--disabled {
            .wethod-modal2__container:after {
              content: '';
              display: block;
              background: rgba(0, 0, 0, 0);
              width: 100%;
              height: 100%;
              position: absolute;
              top: 0;
              z-index: 2;
            }
          }
        }

        &__message {
          &-list {
            margin-top: 16px;
            margin-bottom: 32px;
            overflow: auto;
            max-height: 200px;

            &-item {
              margin: 8px 0;
            }
          }
        }
      }

      .planning-people__allocation-item {
        // repeat's allocation items don't need to be clicked
        &:hover {
          background: $white;
        }

        &-content {
          cursor: initial;
        }
      }

      .wethod-modal2__body {
        padding: 0;
      }

      &-form {
        &--single {
          display: flex;
          align-items: center;
          margin: 0 24px;

          .planning-people-repeat-pattern {
            margin: 0;
          }
        }
      }

      .material-input-button {
        background: $white;
      }

      .wethod-menu {
        ul {
          margin: 0;
          padding: 0;
        }
      }

      &-mode {
        margin-bottom: 8px;
        display: flex;

        &__icon {
          margin-left: -8px;
          margin-right: 23px;
          margin-top: -4px;
        }

        &__form {
          &-title {
            color: #999;
            margin-bottom: 16px;
          }
        }
      }

      &-granularity {
        margin-left: 8px;
      }

      &-pattern {
        margin-left: 8px;
        margin-top: -12px;
        display: inline-block;
        width: 52%;
      }

      &-date-end {
        display: inline-block;
        width: 45%;

        .wethod-date-picker {
          .wethod-day-picker {
            left: auto !important;
            right: 0;
          }
        }
      }
    }
  }

  &-calendar {
    height: calc(100vh - 282px);
    width: 100%;

    @include scaleToExtraSmall() {
      height: calc(100vh - 219px);
    }

    .virtual-grid {
      border: 1px solid $concrete-gray;
      border-left: none;
      overflow: auto;
      position: relative;

      &__empty-state {
        background: $white;
        position: absolute;
        display: flex;
        justify-content: center;
        align-items: center;
        border-left: 1px solid $concrete-gray;
        text-transform: uppercase;
        font-weight: $semiBold;
        color: $steel-gray;
        width: 100%;
        height: 100%;
      }

      &__scroller {
        position: relative;
      }

      &__window {
        position: absolute;
        transform: translateZ(0);

        &--header {
          position: sticky;
          z-index: 2;
        }

        &--left-column {
          position: sticky;
          z-index: 3;
        }

        &-loader {
          background: $white;
          position: absolute;
          opacity: 0.7;
          display: flex;
          justify-content: center;
          align-items: center;
          transition: .3s;
          z-index: 2;

          &--left {
            top: 0;
            left: 0;
            width: 100px;
            height: 100%;
          }

          &--right {
            top: 0;
            right: 0;
            width: 100px;
            height: 100%;
          }

          &--top {
            top: 0;
            left: 0;
            width: 100%;
          }

          &--bottom {
            bottom: 0;
            left: 0;
            width: 100%;
          }
        }
      }
    }

    .row {
      display: flex;
      flex-direction: row;

      &--nav {
        position: sticky;
        top: 0;
        z-index: 4;
        left: 0;
        border-left: 1px solid $concrete-gray;
        border-bottom: 1px solid $concrete-gray;
      }

      &__item {
        flex-shrink: 0;
        flex-grow: 0;
      }
    }

    &__plan-block {
      color: $white;
      font-size: 10px;
      line-height: 11px;
      margin: 2px 4px;
      height: calc(100% - 4px);
      width: calc(100% - 8px);
      overflow: hidden;
      text-overflow: ellipsis;
      transition: .5s ease-in-out;
    }

    .planning-navigation {
      float: none;
      right: 0;

      .planning__arrow-icon {
        top: 0;
        left: 0;
      }
    }

    .navigation {
      &__next, &__prev {
        &:hover {
          background: $black;

          .planning__arrow-icon {
            background-position-x: -64px;
          }
        }
      }

      &__today {
        color: $black;
        text-transform: capitalize;
        border-color: $black;

        &:hover {
          background: $black;
          color: $white;
        }
      }
    }

    &--project-selected {
      height: auto;

      .virtual-grid {
        max-height: calc(100vh - 386px) !important;
        @include scaleToExtraSmall() {
          max-height: calc(100vh - 323px) !important;
        }
      }

      .planning-calendar__track {
        &:after {
          content: none !important;
        }
      }
    }

    &__header {
      height: 57px;
      margin-bottom: 12px;
      position: sticky;
      top: 0;
      display: flex;
      border-bottom: 1px solid $concrete-gray;
      align-items: flex-end;
      background: $maldivian-sand-white;
      z-index: 2;
      font-size: 12px;

      &-month {
        position: absolute;
        top: -27px;
        white-space: nowrap;
        left: 6px;
        color: $black;
      }

      &-day {
        &-label {
          &-name {
            font-size: 11px;
            margin-bottom: 3px;
            color: $dusty-gray;
          }
        }
      }

      &-item {
        text-align: center;
        padding: 5px 6px;
        margin-top: -6px;
        position: relative;
        flex-grow: 1;

        &--today {
          background: $black;
          color: $white;
          border-radius: 4px 4px 0 0;
        }
      }

      &-track {
        display: flex;
        border-right: 1px solid #cecece;
        //padding: 7px 0;
        flex-shrink: 0;
        justify-content: space-around;

        &--actions {
          height: 100%;
          border: none;
          display: block;
          padding: 0;
          width: 0 !important;

          &-content {
            width: 220px;
            justify-content: center;
            align-items: center;
            height: 100%;
            display: flex;
            background: $maldivian-sand-white;
            @include scaleToExtraSmall() {
              width: 110px;
            }
          }
        }
      }
    }

    &__track {
      border-right: 1px solid $concrete-gray;
      width: 50px;
      flex-shrink: 0;
      height: 100%;
      display: flex;
      position: relative;
    }

    &__rows {
      width: 100%;
      flex-shrink: 0;

      &-wrapper {
        display: flex;
        position: absolute;
        top: 0;
        left: 0;
      }
    }

    &__row {
      &:not(:last-of-type) {
        margin-bottom: 10px;
      }

      height: 54px;
      width: 100%;
      background: $white;
      display: flex;

      &-day {
        width: 20%;
        position: relative;

        &--holiday, &--weekend-highlight {
          background: repeating-linear-gradient(45deg, $white, $white 2px, $morning-fog-gray 1px, $morning-fog-gray 6px);
        }
      }
    }

    &__editor {
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      position: absolute;
      display: flex;

      .planning-calendar__row-day:hover:after {
        content: none !important;
      }

      &-block {
        height: 50%; // just for 'day'
        cursor: pointer;

        .planning-calendar__hour {
          height: 25% !important; // just for 'day'
        }

        &:hover {
          background: rgba(72, 165, 249, 0.3);
        }
      }
    }

    &__people {
      width: 0;
      position: sticky;
      left: 0;
      z-index: 2;

      &-content {
        width: 220px;
        box-shadow: 2px 0 11px 0 #CCCBCC;
        position: sticky;
        left: 0;
        flex-shrink: 0;
        z-index: 2;
        @include scaleToExtraSmall() {
          width: 110px;
        }
      }

      &-header {
        height: 57px;
        margin-bottom: 12px;
        background: #ebeef1 !important;
        z-index: 3;
      }
    }

    &__person {
      background: $white;

      &--current {
        .employee {
          &__name, &__area, &__level {
            color: $bright-sky-blue !important;
          }
        }
      }
    }

    &__hour {
      transition: .5s ease-in-out;

      &--disabled {
        background: $concrete-gray !important;
      }

      &--capacity {
        background: repeating-linear-gradient(45deg, $white, $white 2px, $morning-fog-gray 1px, $morning-fog-gray 6px);
      }

      &--block {
        position: absolute;
        width: 100%;
        bottom: 0;
      }
    }

    // Hourly calendar
    &--hour {
      .planning {
        &-calendar {
          &__editor-block {
            width: 25%;
          }

          &__track, &__header-track {
            width: $daily-hour-width * 4;
          }

          &__track {
            &:hover:after {
              content: '';
              background: $bright-sky-blue;
              display: block;
              height: 100%;
              top: 0;
              position: absolute;
              width: 100%;
              cursor: pointer;
              opacity: 0.3;
            }
          }

          &__entry {
            width: $daily-hour-width;
            height: 28px;
          }

          &__hour {
            height: 50%;
            width: 25%;
            color: $white;
            font-size: 10px;
            line-height: 12px;
            padding: 2px 4px;
            overflow: hidden;
            text-overflow: ellipsis;
          }

          &__row-day {
            width: 100%;
            display: flex;
            flex-wrap: wrap;
            align-content: baseline;
            position: relative;
          }
        }
      }
    }

    // Daily calendar
    &--day {
      .planning {
        &-calendar {
          &__track, &__header-track {
            width: $daily-hour-width * 5;
          }

          &__entry {
            width: $daily-hour-width;
            height: 28px;
          }

          &__hour {
            height: (100% / 8);
            width: 100%;
          }

          &__row-day {
            width: 20%;
            position: relative;

            &:hover:after {
              content: '';
              background: $bright-sky-blue;
              display: block;
              height: 100%;
              top: 0;
              position: absolute;
              width: 100%;
              cursor: pointer;
              opacity: 0.3;
            }
          }

          &__header-month {
            top: -16px;
          }
        }
      }
    }

    // Weekly calendar
    &--week {
      .planning {
        &-calendar {
          &__editor-block {
            width: 100%;
            height: 100%;
          }

          &__track, &__header-track {
            width: $weekly-hour-width * 5;
          }

          &__track {
            position: relative;

            &:hover:after {
              content: '';
              background: $bright-sky-blue;
              display: block;
              height: 100%;
              top: 0;
              position: absolute;
              width: 100%;
              cursor: pointer;
              opacity: 0.3;
            }
          }

          &__entry {
            width: $weekly-hour-width;
            height: 7px;
          }

          &__hour {
            height: (100% / 8);
            width: 100%;
          }

          &__row-day {
            width: 20%;
          }
        }
      }
    }

    // International calendar
    &--international {
      &.planning {
        &-calendar {
          &--day {
            .planning-calendar {
              &__track, &__header-track {
                width: $daily-hour-width * 7;
              }
            }
          }

          &--week {
            .planning-calendar {
              &__track, &__header-track {
                width: $weekly-hour-width * 7;
              }
            }
          }
        }
      }
    }
  }
}
