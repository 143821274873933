.knowledge {
  max-width: 1440px !important;
  padding: 0 24px;

  @include scaleToExtraSmall() {
    max-width: 1024px !important;
    padding: 0;
  }

  .wethod-section-actions {
    padding: 16px 0;
  }

  &__title {
    font-size: 18px;
    line-height: 24px;
    padding: 24px 0;
  }

  &__multiple-table {
    padding-bottom: 24px;

    &--language {
      .wethod-table {
        width: 50%;
      }
    }
    &--tags {
      .wethod-table:first-child {
        width: 30%;
      }
      .wethod-table:last-child {
        width: 70%;
      }
      .wethod-table__body {
        height: calc(100% - 98px);
      }
    }
  }

  &__column {
    &-language {
      width: 40%;
      text-transform: capitalize;
    }
    &-distribution {
      width: 60%;
      height: 100%
    }
    &-employee {
      &-lang {
        width: 40%;
      }
      &-tag {
        width: 35%;
        vertical-align: middle;
      }
    }
    &-level {
      width: calc(60% - 68px);
      height: 100%;
      display: inline-flex;
      justify-content: space-around;

      &-label {
        flex: 1;
        text-align: center;

        &:first-child {
          flex: 2;
        }
      }
    }
    &-link {
      width: 68px;
    }
    &-tag {
      white-space: pre-line;
      overflow: auto;
      padding: 0 11px;
    }
    &-emp-tag {
      width: calc(65% - 68px);
      white-space: pre-wrap;
      position: relative;
      padding-right: 55px; // allow counter to be shown
    }
  }

  &__language {
    &-row {
      position: relative;
      cursor: pointer;
      transition: .2s;

      &:hover {
        background-color: #EBEBEB;
      }

      &--selected {
        background-color: #FAFAFA;

        &::before {
          content: "";
          position: absolute;
          height: 100%;
          width: 4px;
          background-color: $bright-sky-blue;
        }
      }
    }
    &-chart {
      display: flex;
      align-items: center;
      justify-content: space-around;
      width: 100%;
    }
    &-level {
      display: flex;
      justify-content: center;
      align-items: center;
      flex: 1;

      &:first-child {
        flex: 2;
      }

      &--selected {
        width: 8px;
        height: 8px;
        border-radius: 50%;
        background-color: $black;
      }
    }
  }

  &__employee {
    &-row {
      height: 64px;
      line-height: 64px;
      background-color: #FAFAFA;

      &--expanded {
        height: unset;
        .knowledge__column-emp-tag {
          padding-top: 15px;
          padding-bottom: 15px;
          padding-right: 0;
          line-height: normal;
        }
      }
    }

    &-info {
      display: inline-flex;
      align-items: center;
      height: 100%;
      width: 100%;
      vertical-align: middle;
    }
  }

  &__tags {
    &-row {
      height: unset;
      padding: 6px 0;
      line-height: normal;
    }
    &-counter {
      position: absolute;
      top: 15px;
      right: 0;
      line-height: normal;
    }
  }

  &__search {
    &-row {
      height: unset;
      padding-right: 0 !important;
      border-top: 1px solid $concrete-gray;
      line-height: normal;
      background: white;
      &--first {
        border-right: 1px solid $concrete-gray;
      }
    }
    &-cell {
      padding: 0 !important;
      width: 100%;

      .wethod-table__search {
        padding-left: 16px;
        height: unset;
        line-height: 40px;
        border: none;
        border-radius: 0;
      }
    }
  }

  &__button {
    width: 32px;
    height: 32px;

    &:hover {
      background-color: rgba(0, 0, 0, 0.1);
      color: black;
    }
  }

  &__distribution {
    display: flex;
    align-items: center;

    &-chart {
      height: 14px;
      background-color: $bright-sky-blue;
      min-width: 2px;
    }
    &-label {
      width: 50px;
      padding-left: 8px;
      font-size: 11px;
    }
  }
}
