.trend {
  background: $white;
  border-radius: 4px;
  border: 1px solid $concrete-gray;
  min-height: 80px;

  &__overview {
    display: inline-block;
    width: 35%;
    vertical-align: middle;
    padding: 16px 0 16px 16px;
  }

  &__report {
    display: inline-block;
    width: 65%;
    vertical-align: middle;

    &-chart {
      height: 78px;
    }
  }

  &__title {
    color: $dusty-gray;
    margin-bottom: 4px;
  }

  &__total {
    font-size: 18px;
    line-height: 21px;
  }

  .wethod-widget__loading {
    height: auto;
    width: 100%;
  }
}
