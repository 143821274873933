$rowH: 61px;
$linkColorActive: $white;
$linkColorHover: $white;
//menu
$menuBackground: #2b2e38;
$bgColorActive: #131418;
$bgColorHover: #2b2e38;

//mennu second level
$menu2Background: rgba(20, 21, 25, 1);

#logo {
  text-align: left;
  img {
    //width:75%;
    width: 144px;
  }
  &.col_3 {
    padding: 3px 0 !important;
  }

  @include scaleToSmall() {
    &.col_3 {
      padding-left: 27px !important;
    }
  }

  @include scaleToExtraSmall() {
    &.col_3 {
      padding-left: 6px !important;
    }
  }
}

.navUsername {
  text-transform: uppercase;
}

.firstLevelMenuWrap {
  position: relative;
}

.secondLevelMenuWrap {
  position: relative;
  display: none;
  width: 100%;
  background: $menu2Background;
  padding-bottom: 24px;
  min-height: 61px;
}

.reportMenu {
  min-width: 454px
}

.fridayMenu {
  margin-left: 59%;
}

.pipelineMenu {
  margin-left: 40.5%;
}

.planningMenu {
  margin-left: 49.5%;
}

.userMenu {
  width: 100%;
}

.appsMenu {
  margin-left: 77%;
}

.userFriday {
  width: 100%;
}

//MENU ICON
.spriteMenu {
  margin: -3% 6px -3% 0;
  background-size: 144px 32px;
  //background:url(../img/icon/menu_icons.png);
  @include retina-image($iconsPath + menu_icons, 144px 32px);
}

.iconMenu {
  width: 16px;
  height: 16px;
}

.menuDashboard {
  background-position: 0 0;
}

.menuPipeline {
  background-position: -16px 0;
}

.menuPlanning {
  background-position: -32px 0;
}

.menuTimesheet {
  background-position: -48px 0;
}

.menuPStatus {
  background-position: -64px 0;
}

.menuReport {
  background-position: -80px 0;
}

.menuFinance {
  background-position: -96px 0;
}

.menuUser {
  background-position: -112px 0;
}

.menuTimeline {
  background-size: 180px 40px;
  background-position: -160px 0;
  height: 20px;
  bottom: -2px;
  position: relative;
  & + i {
    position: relative;
    top: -2px;
  }
}

//MESSAGE buttonTextEL
.stopWidth {
  max-width: 1200px;
  margin: auto;
}

.systemMessageBox {
  display: none;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 750;
  width: 100%;
  height: 50px;
  background: $garden-green;
  color: $white;
  letter-spacing: .5px;
  line-height: 1.3;
  padding: 0 15px;
  .table {
    height: 50px;
  }

  .messageBarLoading {
    display: none;
    text-align: right;
  }
}

//MOBILE
.mobileUserMenu {
  width: 100%;
}

.mobileMenuWrap {
  z-index: 900;
  display: none;
  position: absolute;
  top: $rowH;
  left: 0;
  width: 100%;
  overflow: hidden;
  background: $menu2Background;

  .mobileMenuSlider {
    position: relative;
    height: 100%;
    top: 0;
    left: 0;
  }
  .mobileMenu {
    float: left;
    height: 100%;
  }

  ul {
    height: 100%;
  }
}

.playground {
  .mobileMenuWrap {
    top: 100px;
  }
}

* {
  margin: 0;
  padding: 0;
}

//hamburger icon
#hamburger {
  width: 35px;
  height: 21px;
  position: relative;
  margin: 18px auto;

  @include transform(rotate(0deg));

  @include transition(all 0.5s ease-in-out);

  cursor: pointer;

  span {
    display: block;
    position: absolute;
    height: 3px;
    width: 100%;
    background: $white;
    border-radius: 3px;
    opacity: 1;
    left: 0;

    @include transform(rotate(0deg));

    @include transition(all 0.25s ease-in-out);

    &:nth-child(1) {
      top: 0px;
    }
    &:nth-child(2), &:nth-child(3) {
      top: 9px;
    }
    &:nth-child(4) {
      top: 18px;
    }
  }

  &.open {
    span {
      &:nth-child(1) {
        top: 9px;
        width: 0%;
        left: 50%;
      }
      &:nth-child(2) {

        @include transform(rotate(45deg));
      }
      &:nth-child(3) {

        @include transform(rotate(-45deg));
      }
      &:nth-child(4) {
        top: 9px;
        width: 0%;
        left: 50%;
      }

    }

  }

}

@import 'section-header';
