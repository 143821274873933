@import "scss/colors";

//font
$fontFamily: 'Rubik', sans-serif, 'helvetica';
$upperCaseFont: 11px;

$light: 300;
$normal: 400;
$semiBold: 600;
$bold: 700;

//link color
$linkColor: $bright-sky-blue;

//path
$imgPath: '../../../img/';
$iconsPath: $imgPath + 'icon/';
