.org-chart {
  position: relative;
  display: inline-block;
  width: calc(100% - 150px);
  vertical-align: top;
  overflow-x: auto;
  &--dragging {
    .org-chart__node-button--add {
      opacity: 1;
    }
  }
  &__title {
    color: $bright-sky-blue;
    font-weight: $semiBold;
    margin-bottom: 4px;
    font-size: 16px;
  }
  &__subtitle {
    color: #adb0b7;
    font-size: 14px;
    margin-bottom: 16px;
  }
  &__company-item {
    display: inline-block;
    padding: 7px 16px;
    border-radius: 3px;
    margin: 4px;
    border: 1px dashed$black;
    cursor: pointer;
    background:$white;
    &.gu-transit {
      display: none;
    }
    &.gu-mirror {
      opacity: 0.5;
      transform: rotate(0);
    }
  }
  &__companies {
    &-list {
      background:$white;
      border-radius: 3px;
      padding: 8px;
      box-shadow: 0 2px 3px 0 rgba(155, 155, 155, 0.3);
    }
    &-empty-view {
      padding: 12px 16px;
      color: #adb0b7;
      display: inline-block;
    }
  }
  &__level {
    text-align: center;
    &-labels {
      padding: 0 8px;
      margin-top: 19px;
      display: inline-block;
      width: 150px;
      .wethod-input {
        margin: 24px 0;
        line-height: 27px;
        vertical-align: middle;
        font-weight: $bold;
        padding: 0;
        box-shadow: 0 2px 3px 0 rgba(155,155,155,0.3);
      }
    }
    &-label {
      margin: 33px 0;
      line-height: 27px;
      vertical-align: middle;
      font-weight: $bold;
      padding: 0 16px;
    }
    &--root {
      position: absolute;
      left: 0;
      top: 0;
      min-width: 100%;
      white-space: nowrap;
    }
  }
  &__node {
    display: inline-block;
    margin: 32px 8px;
    vertical-align: top;
    &-name {
      display: inline-block;
      border: 1px solid$black;
      border-radius: 3px;
      position: relative;
      background:$white;
      .wethod-input {
        padding: 12px 0;
        background:$white;
        input {
          text-align: center;
        }
      }
      &-static {
        display: inline-block;
        position: fixed;
        left: -99999px;
        top: -99999px;
        visibility: hidden;
        padding: 12px 16px;
      }
      &-leaf {
        display: inline-block;
        line-height: 17px;
        padding: 12px 16px;
      }
      &:hover {
        button {
          opacity: 1;
        }
      }
    }
    &-button {
      position: absolute;
      display: inline-block;
      width: 20px;
      height: 20px;
      border-radius: 100px;
      background-color: $bright-sky-blue;
      box-shadow: 0 2px 2px 0 rgba(72, 59, 59, 0.5);
      color:$white;
      font-weight: $light;
      transition: .2s;
      border: none;
      outline: none;
      cursor: pointer;
      opacity: 0;
      z-index: 2;
      &--delete {
        top: -10px;
        right: -10px;
      }
      &--add {
        bottom: -10px;
        right: calc(50% - 10px);
        &-over {
          transform: scale(3);
          border-radius: 3px;
          width: 40px;
          right: calc(50% - 20px);
        }
      }
      &:hover {
        transform: scale(1.2);
      }
    }
  }
}
