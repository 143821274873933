.search-input {
  border-radius: 30px;
  padding: 0 20px 0 10px;
  background: $white;
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  border: 1px solid $concrete-gray;

  input {
    width: 100%;
    padding: 7px 10px;
    margin: 0;
    border: none;
    outline: none;
  }

  &__loading {
    position: absolute;
    right: 5px;

    img {
      background-size: contain;
      width: 20px;
      height: 20px;
      background-image: url("../img/icon/mini_logo_wait.png");
      animation: logoRotation 1.3s ease;
      animation-delay: 0s;
      animation-iteration-count: infinite;
    }
  }
}
