.budget-changelog {
  max-width: 1440px !important;
  padding: 0 24px;

  @include scaleToExtraSmall() {
    max-width: 1024px !important;
    padding: 0;
  }

  .wethod-section-actions {
    padding: 16px 0;
  }

  .wethod-table__row--header {
    &:hover { //Allow overflow of tooltip in header cells
      overflow: visible;
    }
  }

  &__column {
    &-joc {
      width: 4px;
      height: 100%;
      padding: 0 !important;
    }

    &-project {
      width: 12.5%;
    }

    &-probability {
      width: 6%;
    }

    &-person {
      width: 8.6%;
    }

    &-value {
      width: 6.8%;
    }

    &-margin {
      width: 5.3%;

      &--header {
        display: inline-flex;
        flex-direction: column;
        justify-content: center;
        height: 100%;
        vertical-align: middle;
        line-height: initial;

        span:last-child {
          font-size: 10px;
          font-weight: initial;
        }
      }
    }

    &-version-margin {
      width: 5.8%;
    }

    &-date {
      width: 9%;
      height: 100%;
    }

    &-version {
      width: 5.2%;
    }

    &-notes {
      width: 3.3%;
    }

    &-link {
      width: 4%;
    }

    &--center {
      text-align: center;
    }

    &--small {
      position: relative;
      padding-left: 12px;
      padding-right: 6px;

      .wethod-table__cell-icon-sort {
        position: absolute;
        top: 16px;
        left: -6px;
        margin: 0;
      }
    }

    &--tooltip {
      position: relative;
      overflow: visible;

      .tooltipReact {
        opacity: 0;
        line-height: 20px;
        left: -60px;
        top: unset;
        bottom: 50px;
        width: 215px;
        white-space: pre-line;
      }

      &:hover {
        .tooltipReact {
          opacity: 1;
        }
      }
    }
  }

  &__color-bar {
    height: 100%;
    width: 4px;
  }

  &__delta {
    &--positive {
      color: #59DA94;
    }

    &--negative {
      color: #D94F6B;
    }
  }

  &__project {
    height: 100%;
    position: relative;
    background: inherit;

    &:hover {
      overflow: initial;

      > * {
        position: absolute;
        z-index: 1;
        box-shadow: 0 0 15px 0 rgba(155, 155, 155, 0.3);
        height: 80%;
        max-width: 650px;
        margin: 5px;
        padding: 0 8px 0;
      }
    }
  }

  &__multirow-info {
    display: flex;
    flex-direction: column;
    justify-content: center;
    line-height: 1.5;
    height: 100%;
    background: inherit;

    span {
      overflow: hidden;
      text-overflow: ellipsis;

      &:last-child {
        font-size: 12px;
        color: $dusty-gray;
      }
    }
  }

  &__button {
    width: 32px;
    height: 32px;
    transition: 0s;
    border: none;

    &:hover {
      background-color: rgba(0, 0, 0, 0.1);
      color: $black;
    }

    &--disabled {
      pointer-events: none;
    }

    .wethod-icon {
      display: block;
    }
  }

  &__modal {
    font-size: 14px;

    .wethod-modal2__body {
      min-width: 350px;
      max-width: 520px;
      min-height: 200px;
      max-height: 350px;

      line-height: 20px;
    }
  }

  &__actions {
    display: flex;
  }
}
