@import "scss/colors";

.profile {
  line-height: 17px;
  padding-bottom: 50px;
  @include scaleToExtraSmall() {
    padding: 5%;
  }

  &-working-connections {
    @include scaleToExtraSmall() {
      display: none;
    }

    .profile-card__placeholder {
      margin: 50px auto;
      display: inline-block;
      width: 100%;
      text-align: center;
    }

    &__collection {
      max-height: calc(100vh - 580px);
      overflow: auto;
    }

    &__projects {
      width: 655px;

      .wethod-modal2-list__header {
        padding: 16px 8px;
      }

      .wethod-modal2-list__header-item {
        padding: 0 8px;
      }

      &-item {
        border: 1px solid #D2D2D2;
        border-bottom: none;
        color: #333;
        padding: 16px 8px;
        position: relative;
        display: block;

        &:visited {
          color: inherit !important;
        }

        &:last-child {
          border-bottom: 1px solid #D2D2D2;
        }

        &-col {
          display: inline-block;
          vertical-align: middle;
          padding: 0 8px;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;

          &-name {
            width: 25%;
          }

          &-pm, &-account, &-client {
            width: 15%;
          }

          &-value {
            width: 15%;
            text-align: right;
          }

          &-hours {
            width: 15%;
            text-align: right;
          }
        }
      }
    }

    &__header {
      &:after {
        @extend .wethod-clearfix;
      }

      margin-top: 40px;
      margin-bottom: 16px;
      margin-left: 12px;
      margin-right: 12px;

      &-col {
        width: 33%;
        float: left;
        font-weight: $semiBold;
        text-transform: uppercase;

        span {
          display: inline-block;
          width: 152px;
          text-align: center;
        }

        &--project-type {
          text-align: center;
        }

        &--client {
          text-align: right;
          float: right;
        }
      }
    }
  }

  &-trends {
    .wethod-tab-switcher {
      float: right;
    }

    &__header {
      margin-top: 24px;
      margin-bottom: 16px;

      &:after {
        @extend .wethod-clearfix;
      }
    }

    &__legend {
      float: left;

      &-item {
        display: inline-block;
        margin-right: 24px;

        div {
          display: inline-block;
        }

        &-image {
          width: 32px;
          height: 2px;
          line-height: 17px;
          vertical-align: middle;

          &--negative {
            background: $raspberry-red;
          }

          &--positive {
            background: $garden-green;
          }
        }

        &-name {
          margin-left: 8px;
        }
      }
    }

    &__meaning {
      float: right;
      opacity: 0.5;
    }

    &__item {
      border: 1px solid $concrete-gray;
      border-radius: 6px;
      width: calc(50% - 8px);
      display: inline-block;
      padding: 16px;
      margin-bottom: 16px;

      @include scaleToExtraSmall() {
        width: 100%;
      }

      &--chargeability {
        margin-left: 16px;

        @include scaleToExtraSmall() {
          margin-left: 0;
        }
      }

      &--keep-the-lights-off {
        margin-left: 16px;

        @include scaleToExtraSmall() {
          margin-left: 0;
        }
      }

      &-header {
        &-title {
          font-weight: $semiBold;
          text-transform: uppercase;
        }

        &-subtitle {
          margin-top: 4px;
        }
      }

      &-content {
        &-legend {
          width: 25%;
          display: inline-block;
          vertical-align: bottom;
          font-size: 10px;
          line-height: 20px;
          padding: 4px;

          &-row {
            &:before {
              content: '';
              display: inline-block;
              width: 8px;
              height: 8px;
              border-radius: 50%;
              margin-right: 10px;
            }

            &--positive:before {
              background: $garden-green;
            }

            &--neutral:before {
              background: $sunset-orange;
            }

            &--negative:before {
              background: $raspberry-red;
            }

            &--avg:before {
              border-top: 1px dashed;
              width: 16px;
              border-radius: 0;
              margin-bottom: -4px;
            }
          }
        }

        &-report {
          height: 100px;
          width: 75%;
          display: inline-block;
          vertical-align: middle;
        }
      }
    }
  }

  &-objectives {
    @include scaleToExtraSmall() {
      button {
        display: none;
      }
    }

    &__filters {
      float: right;

      .planning-navigation {
        display: inline-block;
        vertical-align: middle;
        float: none;
      }

      .wethod-filter-select {
        display: inline-block;
        position: relative;
        vertical-align: middle;
        margin-right: -40px;

        &__list {
          width: 109px;
        }
      }

      @include scaleToExtraSmall() {
        .wethod-filter-select {
          margin-right: 15px;
        }
      }
    }

    &__quarter {
      h3 {
        color: $bright-sky-blue;
        font-size: 12px;
        font-weight: $semiBold;
        line-height: 17px;
        text-transform: uppercase;
        margin: 24px 0;
        display: inline-block;
      }

      &-add-objective {
        margin-bottom: 16px;
      }

      &-status {
        display: inline-block;
        text-transform: uppercase;
        @include scaleToExtraSmall() {
          display: none;
        }

        &-current {
          font-size: 10px;
          font-weight: $bold;
          opacity: 0.4;
          margin-left: 27px;
          margin-right: 8px;
        }

        button {
          font-size: 9px;
          text-transform: uppercase;
          padding: 0 6px;
          line-height: 17px;
          margin-right: 8px;
        }
      }
    }

    &__item {
      border: 1px solid #D2D2D2;
      border-radius: 6px;
      margin-bottom: 16px;

      &-title {
        font-size: 12px;
        font-weight: $bold;
      }

      &-header {
        border-bottom: 1px solid #D2D2D2;
        border-radius: 6px;
        padding: 16px;

        &:hover {
          button {
            opacity: 1;
          }
        }
      }

      &-edit {
        border: none;
        padding: 3px;
        margin-left: 14px;
        opacity: 0;

        &:hover {
          background: rgba(0, 0, 0, 0.1);
        }
      }

      &-progress {
        &-amount {
          font-size: 16px;
          font-weight: $semiBold;
          letter-spacing: 0.5px;
        }
      }

      &--editable {
        .profile-objectives {
          &__key-result {
            padding: 0;
            margin: 16px 0;

            &-name-col {
              width: 80%;
              display: inline-block;

              @include scaleToExtraSmall() {
                width: 72%;
              }
            }

            &-target-col {
              width: calc(13% - 16px);
              display: inline-block;
              margin-left: 16px;


              @include scaleToExtraSmall() {
                width: 22%;
              }
            }
          }

          &__item {
            &-title {
              font-size: 12px;
              font-weight: $bold;
              width: calc(720px - 110px);
              display: inline-block;
              margin-top: 16px;

              @include scaleToExtraSmall() {
                width: calc(100vw - 66px);
              }
            }

            &-add {
              margin: 24px 0;
            }
          }
        }
      }
    }

    &__key-result {
      padding: 8px 16px;
    }
  }

  &-objective {
    &__col {
      &-title {
        display: inline-block;
        width: 60%;
        vertical-align: middle;
      }

      &-progress {
        display: inline-block;
        width: 35%;
        vertical-align: middle;

        &-slider {
          display: inline-block;
          width: 75%;
          vertical-align: middle;
        }

        &-amount {
          display: inline-block;
          vertical-align: middle;
          text-align: right;
          margin-left: 10px;
          width: calc(25% - 10px);
        }
      }
    }
  }

  &-sponsor {
    &__list {
      &-item {
        border: 1px solid #D2D2D2;
        color: #333;
        border-radius: 6px;
        padding: 16px;
        margin: 16px auto;
        position: relative;
        display: block;

        &--link {
          cursor: pointer;

          &:hover {
            background: rgba(0, 0, 0, 0.05);
          }

          &:visited {
            color: #333;
          }
        }

        &-name {
          font-weight: $bold;
        }

        &-level {
          text-transform: uppercase;
        }

        &-pic {
          width: 50px;
          height: 50px;
          font-size: 20px;
          line-height: 48px;
          display: inline-block;
          vertical-align: middle;
        }

        &-info {
          display: inline-block;
          vertical-align: middle;
          margin-left: 16px;
          position: relative;
        }

        &-period {
          display: inline-block;
          position: absolute;
          right: 16px;
          bottom: 16px;
          opacity: 0.5;
        }

        .employee__okr {
          width: 90px;
          bottom: -7px;
          left: -2px;
        }
      }

      &--current {
        .profile-sponsor {
          &__list {
            &-item {
              border: 1px solid $bright-sky-blue;
            }
          }
        }
      }

      &-button {
        padding: 0 2px;
      }
    }

    &--editable {
      @include scaleToExtraSmall() {
        width: calc(100vw - 66px);
        min-width: inherit;
      }

      .profile-sponsor {
        &__list {
          &-item {
            min-width: 400px;

            @include scaleToExtraSmall() {
              min-width: initial;
            }

            &-delete {
              margin: 0;
            }

            &-end {
              color: #4A4A4A;
              border: none;
              padding: 1px 16px;
              position: relative;
              top: 1px;

              &:hover {
                background: rgba(0, 0, 0, 0.1);
              }
            }

            &-actions {
              position: absolute;
              right: 10px;
              top: 10px;
            }
          }
        }

        &__collection {
          min-width: 400px;

          @include scaleToExtraSmall() {
            min-width: initial;
          }
        }
      }
    }

    &__add {
      margin-bottom: 24px;
    }
  }

  &-portfolio {
    &__work {
      font-size: 12px;
      margin-bottom: 8px;

      &-name {
        display: inline-block;
        width: calc(100% - 89px);
        font-weight: $semiBold;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
      }

      .wethod-button {
        display: inline-block;
        margin-left: 16px;
      }

      &-add {
        margin-bottom: 24px;
      }
    }

    &--editable {
      min-width: 611px;

      @include scaleToExtraSmall() {
        width: calc(100vw - 66px);
        min-width: inherit;
      }

      .wethod-modal2-list__header {
        margin-bottom: 8px;

        &-item {
          &.profile-portfolio__item-link {
            margin-left: 16px;
          }
        }
      }

      .profile-portfolio {
        &__item-url {
          width: 295px;
          display: inline-block;
          margin-left: 16px;
        }

        &__item-name {
          width: 300px;
          display: inline-block;
        }

        &__header {
          &-item {
            padding: 0 16px;
            font-weight: $normal;
          }
        }
      }
    }
  }

  &-languages {
    display: table;

    &__loader {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 12.5px 0 12.5px 0;
    }

    &__item {
      display: table-row;

      & > span {
        display: table-cell;
        padding-top: 8px;
        padding-bottom: 8px;
      }

      &-language {
        font-weight: $semiBold;
      }

      &-level {
        padding-left: 16px;
      }
    }

    &--editable {
      min-width: 361px;

      @include scaleToExtraSmall() {
        width: calc(100vw - 66px);
        min-width: inherit;
      }

      .profile-languages {
        &__item-level {
          width: 100px;
          display: inline-block;
          margin-left: 16px;
        }

        &__item-language {
          width: 200px;
          display: inline-block;
        }

        &__header {
          &-item {
            padding: 0 16px;
            font-weight: $normal;
          }
        }
      }

      .wethod-modal2-list__header-item {
        &.profile-languages__item-level {
          margin-left: 32px;
        }
      }
    }

    &__add {
      margin-bottom: 24px;

      &--disabled {
        @extend .disabled;
        pointer-events: none !important;
      }
    }
  }

  &-bio, &-year-resolution {
    line-height: 20px;
    white-space: pre-line;

    &--editable {
      textarea {
        width: 450px;
        min-height: 150px;
        border-radius: 3px;
        border: 1px solid rgb(210, 210, 210);
        padding: 8px 16px;
        margin-top: 0;
        margin-bottom: 0;
        height: 150px;

        @include scaleToExtraSmall() {
          width: calc(100vw - 66px);
          min-width: inherit;
        }
      }
    }
  }

  &-year-resolution {
    &__content {
      font-weight: $semiBold;
      border: 1px solid $bright-sky-blue;
      border-radius: 6px;
      padding: 16px;
    }
  }

  &__skills {
    padding-right: 32px;

    &--editable {
      min-width: 500px;

      @include scaleToExtraSmall() {
        width: calc(100vw - 66px);
        min-width: inherit;
      }
    }
  }

  &__interests {
    padding-right: 32px;

    &--editable {
      min-width: 500px;

      @include scaleToExtraSmall() {
        width: calc(100vw - 66px);
        min-width: inherit;
      }
    }
  }

  &-pic-picker {
    width: 100px;
    height: 100px;
    background: $white;
    position: relative;
    border-radius: 50%;
    margin-right: 32px;

    &--editable {
      max-width: 470px;

      .wethod-image-input {
        &__preview {
          width: 100px;
          height: 100px;
          border-radius: 50%;
          margin: 24px auto;
        }
      }
    }

    &:hover {
      button {
        opacity: 1;
      }
    }

    &__image {
      padding: 1px;
    }

    .wethod-profile-pic__placeholder {
      font-size: 40px;
      line-height: 98px;
    }

    &__actions {
      bottom: 0;
      position: absolute;
      width: 100%;

      button {
        padding: 4px;
        width: 32px;
        height: 32px;
        border-radius: 50%;
        border: none;
        box-shadow: 0 1px 2px 1px rgba(72, 59, 59, 0.3);
        cursor: pointer;
        outline: none;
        transition: .2s;
        opacity: 0;

        &:hover {
          transform: scale(1.1);
        }
      }

      @include scaleToExtraSmall() {
        display: none;
      }
    }

    &__action {
      &--edit {
        float: right;
      }

      &--delete {
        float: left;
      }
    }
  }

  &-basic-info {
    float: left;
    width: 50%;
    line-height: 19px;
    margin-top: 21px;

    @include scaleToExtraSmall() {
      width: 100%;
    }

    &__name {
      font-size: 18px;
      line-height: 22px;
    }

    &__level {
      text-transform: uppercase;
    }

    &__job-title {
      margin: 0;
      font-weight: bold;
    }

    &__column {
      float: left;

      &--data {
        padding: 20px 0;
        width: calc(100% - 140px);
      }
    }
  }

  &-card {
    float: left;
    width: 50%;

    &--100 {
      width: 100%;
    }

    @include scaleToExtraSmall() {
      width: 100%;
      margin-top: 25px;
    }

    &:hover {
      button {
        opacity: 1;
      }
    }

    &__placeholder {
      opacity: 0.5;
    }

    &__header {
      &:after {
        @extend .wethod-clearfix;
      }

      margin-bottom: 8px;

      &-button {
        background: $alabaster-white;
        padding: 2px;
        opacity: 0;
        border-color: #4A4A4A;
        top: -3px;
        left: 8px;

        &:hover {
          background: #4A4A4A;
          border-color: #4A4A4A;

          div {
            background-position-x: -48px;
          }
        }

        @include scaleToExtraSmall() {
          pointer-events: none;
          display: none;

          &:hover {
            display: none;
          }
        }
      }

      h2 {
        font-size: 18px;
        line-height: 24px;
        display: inline-block;
      }
    }
  }

  &__row {
    margin-top: 30px;

    &--first {
      margin-top: 16px;
    }

    @include scaleToExtraSmall() {
      margin-top: 0;
    }

    &:after {
      @extend .wethod-clearfix;
    }
  }

  &__contact {
    &-info {
      .profile-card__header {
        button {
          left: -4px;
        }
      }

      @include scaleToExtraSmall() {
        .profile-card__header {
          display: none;
        }

        .profile-contact-info {
          &__column {
            width: 100%;
          }
        }
      }

      &-modal {
        .wethod-modal2__body {
          overflow: auto;
        }
      }
    }
  }

  &-contact {
    &-info {
      &__feedback {
        color: $bright-sky-blue;
      }

      &__actions {
        text-align: right;
        margin-top: 32px;

        button, a {
          margin-left: 8px;
        }

        a {
          display: inline-block;
          font-size: 12px;
        }
      }

      &:after {
        @extend .wethod-clearfix;
      }

      &__input {
        margin-top: 24px;
        width: 340px;

        @include scaleToExtraSmall() {
          width: calc(100vw - 66px);
          min-width: inherit;
        }

        &:after {
          @extend .wethod-clearfix;
        }

        &:last-of-type {
          margin-top: 12px;
        }

        &--error {
          .wethod-input {
            border: 1px solid #D94F6B;
          }
        }

        .wethod-input {
          float: left;
          width: calc(100% - 65px);
          border-radius: 0 3px 3px 0;
          background: transparent;
          border: 1px solid $concrete-gray;
          padding: 10px;
        }

        .material-input {
          display: inline;

          .wethod-input {
            padding: 10px;
            border: 1px solid $concrete-gray;
            line-height: 17px;

            input {
              font-size: 12px;
            }
          }

          &__label {
            display: none;
          }

          &__helper-text {
            left: 55px;
          }
        }

        button {
          line-height: 17px;
          text-align: left;

          > * {
            margin: 0 5px;
            text-transform: capitalize;
          }
        }

        .wethod-menu__item {
          text-transform: capitalize;
        }

        &-helper-text {
          display: block;
          padding-left: 70px;
          font-size: 11px;
          color: $dusty-gray;

          &--error {
            color: #D94F6B;
          }
        }

        ul {
          margin-bottom: 0;
          padding: 0;
        }
      }

      &__icon {
        float: left;
        border: 1px solid $concrete-gray;
        padding: 2px 16px;
        padding-top: 3px;
        border-radius: 30px 0 0 30px;
        border-right: none;
      }

      &__placeholder {
        color: $dusty-gray;
      }

      &__gender {
        color: $black;
      }

      &__column {
        float: left;
        width: 50%;
      }

      &__item {
        display: block;
        padding: 4px 0;
        padding-right: 16px;

        &:before {
          content: '';
          display: inline-block;
          vertical-align: middle;
          margin-right: 4px;
          position: relative;
          left: -4px;
        }

        &--email {
          &:before {
            @extend .wethod-icon-medium-mail;
            @extend .wethod-icon-medium-mail--black;
          }
        }

        &--telephone {
          &:before {
            @extend .wethod-icon-medium-phone;
            @extend .wethod-icon-medium-phone--black;
          }
        }

        &--linkedin {
          &:before {
            @extend .wethod-icon-medium-linkedin;
            @extend .wethod-icon-medium-linkedin--black;
          }
        }

        &--birthday {
          &:before {
            @extend .wethod-icon-medium-birthday;
            @extend .wethod-icon-medium-birthday--black;
          }
        }

        &--gender {
          text-transform: capitalize;

          &:before {
            @extend .wethod-icon-medium-gender;
            @extend .wethod-icon-medium-gender--black;
          }
        }
      }

      a {
        color: inherit;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;

        &:hover {
          color: $bright-sky-blue;
        }
      }
    }
  }

  &-kudos {
    .profile-card__placeholder {
      margin: 50px auto;
      display: inline-block;
      width: 100%;
      text-align: center;
    }

    &__widgets {
      display: flex;
      justify-content: space-between;
      align-items: baseline;
      margin-top: 16px;

      @include scaleToExtraSmall() {
        flex-direction: column;
      }
    }

    &__subheader {
      color: $dusty-gray;
      font-size: 11px;
      padding: 2px 16px;
    }

    &__legend {
      padding: 5px 16px;

      & > * {
        display: flex;
        justify-content: space-between;
        align-items: center;
        line-height: 26px;
      }
    }

    &__chart {
      margin: 2px 0;
    }

    &__widget {
      width: 309px;
      background-color: transparent !important;
      border: none !important;
      overflow: visible !important;

      @include scaleToExtraSmall() {
        width: 100%;
        margin-bottom: 16px;
      }

      .wethod-widget__header {
        background-color: $white;
        border-radius: 4px 4px 0 0;
        border: 1px solid $concrete-gray;
      }

      .wethod-widget__body {
        min-height: 260px;
        height: auto;
        position: relative;

        & > * {
          background-color: $white;

          &:not(:first-child) {
            border-radius: 4px;
            border: 1px solid $concrete-gray;
          }

          &:first-child {
            border: 1px solid $concrete-gray;
            border-top: none;
            border-radius: 0 0 4px 4px;
          }
        }
      }

      .wethod-widget__empty {
        position: absolute;
        height: 100%;
        width: 100%;
        border: 1px solid $concrete-gray;
      }

      &-notes {
        &-action-button {
          border: none;

          &:hover {
            background-color: #ECECEC;
          }
        }

        &-content {
          position: absolute;
          width: 100%;
          height: 100%;
          border: 1px solid $concrete-gray;
        }

        &-body {
          padding: 10px 16px 0;
          height: calc(100% - 40px);
          overflow: auto;
          white-space: pre-line;
        }

        &-footer {
          height: 40px;
          padding: 10px 16px;
          color: $dusty-gray;
        }
      }
    }
  }
}
