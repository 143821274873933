.widget-alerts {
  &__item {
    list-style: none;
    padding: 12px 16px;
    border-bottom: 1px solid $concrete-gray;
    font-size: 14px;
    position: relative;
    background:$white;
    cursor: pointer;

    &:hover {
      .widget-alerts__item-description {
        opacity: 1;
      }
    }

    &-priority {
      display: inline-block;
      vertical-align: middle;
      width: 5%;
      text-align: left;
      margin-right: 2px;

      &-status {
        width: 8px;
        height: 8px;
        display: inline-block;
        border-radius: 50%;
      }
    }

    &-info {
      display: inline-block;
      vertical-align: middle;
      padding: 0 8px;
      width: 94%;
      position: relative;
      color:$black;
      height: 32px;
    }

    &-project {
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      line-height: 20px;
      display: block;
    }

    &-type {
      color: $dusty-gray;
      font-size: 12px;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      display: block;
      text-transform: capitalize;

      &--center {
        vertical-align: middle;
        line-height: 32px;
      }
    }

    &-description {
      opacity: 0;
      padding: 12px 16px;
      background: $bright-sky-blue;
      color:$white;
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      font-size: 12px;
      line-height: 16px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      width: 100%;

      &-message {
        width: calc(100% - 40px);
      }
    }

    &-support {
      position: absolute;
      right: 16px;
      padding: 8px;
      border-radius: 50%;
      transition: 0.2s;

      &-icon {
        display: inline-block;
        transition: none;
      }

      &:hover {
        background:$white;

        .widget-alerts__item-support-icon {
          background-position-x: 0;
        }
      }
    }
  }
}
