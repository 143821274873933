.widget-invoices {
  &__item {
    list-style: none;
    padding: 12px 16px;
    border-bottom: 1px solid $concrete-gray;
    font-size: 14px;
    position: relative;
    background: $bright-sky-blue;
    &-currency {
      color: $dusty-gray;
      font-size: 12px;
      font-weight: $normal;
    }
    &:hover {
      .widget-invoices__item-overview {
        left: -153px;
      }
    }
    &-actions {
      color:$white;
      text-align: right;
    }
    &-action {
      display: inline-block;
      border: 1px solid$white;
      border-radius: 50%;
      cursor: pointer;
      vertical-align: middle;
      background-color: transparent;
      outline: none;
      &:not(:last-child) {
        margin-right: 8px;
      }
      &:hover {
        background-color:$white;
        .wethod-icon {
          background-position-x: 0;
        }
      }
      &--create {
        color:$white;
        background-color: transparent;

        &:hover {
          color: $bright-sky-blue;
          background-color:$white;
        }
      }
    }
    &-overview {
      position: absolute;
      top: 0;
      left: 0;
      transition: .3s;
      background:$white;
      width: 100%;
      height: 100%;
      padding: 12px 16px;
    }
    &-priority {
      display: inline-block;
      vertical-align: middle;
      width: 5%;
      text-align: left;
      margin-right: 2px;
      &-status {
        width: 8px;
        height: 8px;
        display: inline-block;
        border-radius: 50%;
        &--alert {
          background: $raspberry-red;
        }
        &--warning {
          background: $sunset-orange;
        }
      }
    }
    &-info {
      display: inline-block;
      vertical-align: middle;
      padding: 0 8px;
      width: 49%;
      position: relative;
    }
    &-client {
      color: $dusty-gray;
      font-size: 12px;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
    &-project {
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      line-height: 20px;
    }
    &-error {
      color: $raspberry-red;
      font-size: 9px;
      position: absolute;
      left: 8px;
    }
    &-value {
      display: inline-block;
      vertical-align: middle;
      font-weight: $semiBold;
      padding: 0 8px;
      width: 31%;
      text-align: right;
      &-amount {
        line-height: 20px;
      }
    }
    &-date {
      text-transform: uppercase;
      color: #CCCBCC;
      font-weight: $light;
      display: inline-block;
      vertical-align: middle;
      padding: 0 8px;
      width: 13%;
    }
  }

}
