.investments {
  &-col {
    &--expenses, &--expenses-payroll, &--expenses-external, &--bu, &--percentage, &--value {
      width: 20% !important;
    }
    &--value {
      background-color: rgba(72, 165, 249, 0.1);
    }
    &--percentage {
      &:hover {
        background-color: rgba(158, 158, 158, 0.2);
      }
      .table__input:hover {
        background-color: unset;
      }
    }
  }
  .table__header-center, .table-center {
    width: 83%;
  }
  .table-center span {
    display: inline-block;
    line-height: 60px;
    padding: 0 16px;
    float: left;
    height: 60px;
  }
  .table-left {
    &__content {
      position: relative;
      &-subtitle {
        font-size: 10px;
        color: grey;
        position: absolute;
        display: inline-block;
        text-transform: none;
        bottom: 6px;
        left: 0;
      }
    }
  }
  .table__input {
    width: 100%;
    font-weight: 500;
  }
  .recordList {
    margin-bottom: 50px;
  }
  .table-overview {
    font-weight: $bold;
    .table-left__content {
      text-transform: uppercase;
      color: $bright-sky-blue;
    }
  }
}
