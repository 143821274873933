$icon-size: 32px;
$icon-size-medium: 24px;
$icon-size-small: 16px;
$how-many-icons: 90;
$how-many-versions: 5;
.wethod-icon {
  width: $icon-size;
  height: $icon-size;
  background-image: url("../img/icon/icons.svg");
  background-size: $icon-size * $how-many-versions $icon-size * $how-many-icons;

  &-notes {
    @extend .wethod-icon;
    background-position-y: - ( $icon-size );

    &--done {
      background-position-x: 0;
      background-position-y: 0;
    }

    &--black {
      background-position-x: - ( $icon-size );
    }

    &--disabled {
      opacity: 0.5;
      cursor: default;
    }
  }

  &-checked {
    @extend .wethod-icon;
    background-position-x: 0;
    background-position-y: - ( $icon-size * 27);

    &--white {
      background-position-x: - ( $icon-size * 2);
    }

    &--black {
      background-position-x: - ( $icon-size);
    }
  }

  &-overtime {
    @extend .wethod-icon;
    background-position-y: - ( $icon-size * 2);
  }

  &-night-shift {
    @extend .wethod-icon;
    background-position-y: - ( $icon-size * 3);
  }

  &-travel {
    @extend .wethod-icon;
    background-position-y: - ( $icon-size * 4);
    background-position-x: 0;

    &--white {
      background-position-x: - ( $icon-size * 2);
    }

    &--black {
      background-position-x: - ( $icon-size );
    }
  }

  &-ordinary-work {
    @extend .wethod-icon;
    background-position-y: - ( $icon-size * 5);
  }

  &-heart {
    @extend .wethod-icon;
    background-position-y: - ( $icon-size * 6);
    background-position-x: 0;

    &--white {
      background-position-x: - ( $icon-size * 2);
    }

    &--black {
      background-position-x: - ( $icon-size );
    }
  }

  &-heart-full {
    @extend .wethod-icon;
    background-position-y: - ( $icon-size * 7);
    background-position-x: 0;

    &--white {
      background-position-x: - ( $icon-size * 2);
    }

    &--black {
      background-position-x: - ( $icon-size );
    }
  }

  &-pm {
    @extend .wethod-icon;
    background-position-x: -$icon-size;
    background-position-y: - ( $icon-size * 19);
  }

  &-contingency {
    @extend .wethod-icon;
    background-position-x: -$icon-size;
    background-position-y: - ( $icon-size * 20);
  }

  &-discount {
    @extend .wethod-icon;
    background-position-x: -$icon-size;
    background-position-y: - ( $icon-size * 21);
  }

  &-forward {
    @extend .wethod-icon;
    background-position-x: 0;
    background-position-y: - ( $icon-size * 16);
    transform: rotate(-90deg);

    &--white {
      background-position-x: - ( $icon-size* 2);
    }

    &--black {
      background-position-x: - ( $icon-size );
    }
  }

  &-back {
    @extend .wethod-icon;
    background-position-x: 0;
    background-position-y: - ( $icon-size * 16);
    transform: rotate(90deg);

    &--white {
      background-position-x: - ( $icon-size* 2);
    }

    &--black {
      background-position-x: - ( $icon-size );
    }
  }

  &-pipeline {
    @extend .wethod-icon;
    background-position-x: 0;
    background-position-y: - ( $icon-size * 10);

    &--black {
      background-position-x: - ( $icon-size );
    }
  }

  &-budget {
    @extend .wethod-icon;
    background-position-x: 0;
    background-position-y: - ( $icon-size * 9);

    &--red {
      background-position-x: - ( $icon-size * 4);
    }

    &--green {
      background-position-x: - ( $icon-size * 3);
    }

    &--white {
      background-position-x: - ( $icon-size * 2);
    }

    &--black {
      background-position-x: - ( $icon-size );
    }
  }

  &-project-report {
    @extend .wethod-icon;
    background-position-x: 0;
    background-position-y: - ( $icon-size * 11);

    &--black {
      background-position-x: - ( $icon-size );
    }
  }

  &-planning {
    @extend .wethod-icon;
    background-position-x: 0;
    background-position-y: - ( $icon-size * 12);

    &--black {
      background-position-x: - ( $icon-size );
    }
  }

  &-program {
    @extend .wethod-icon;
    background-position-x: 0;
    background-position-y: - ( $icon-size * 22);
  }

  &-share {
    @extend .wethod-icon;
    background-position-x: 0;
    background-position-y: - ( $icon-size * 15);

    &--black {
      background-position-x: - ( $icon-size );
    }
  }

  &-edit {
    @extend .wethod-icon;
    background-position-x: 0;
    background-position-y: - ( $icon-size * 25);

    &--black {
      background-position-x: - ( $icon-size );
    }

    &--white {
      background-position-x: - ( $icon-size * 2);
    }
  }

  &-arrow-right {
    @extend .wethod-icon;
    background-position-x: 0;
    background-position-y: - ( $icon-size * 14);

    &--black {
      background-position-x: - ( $icon-size );
    }

    &--white {
      background-position-x: - ( $icon-size * 2);
    }
  }

  &-arrow-left {
    @extend .wethod-icon;
    background-position-x: 0;
    background-position-y: - ( $icon-size * 14);
    transform: rotate(-180deg);

    &--black {
      background-position-x: - ( $icon-size );
    }

    &--white {
      background-position-x: - ( $icon-size * 2);
    }
  }

  &-collapse {
    @extend .wethod-icon;
    background-position-x: 0;
    background-position-y: - ( $icon-size * 14);
    transform: rotate(135deg);

    &--black {
      background-position-x: - ( $icon-size );
    }
  }

  &-expand {
    @extend .wethod-icon;
    background-position-x: 0;
    background-position-y: - ( $icon-size * 14);
    transform: rotate(-45deg);

    &--black {
      background-position-x: - ( $icon-size );
    }
  }

  &-arrow-up {
    @extend .wethod-icon;
    background-position-x: -$icon-size;
    background-position-y: - ( $icon-size * 14);
    transform: rotate(-90deg);

    &--black {
      background-position-x: - ( $icon-size );
    }
  }

  &-arrow-down {
    @extend .wethod-icon;
    background-position-x: -$icon-size;
    background-position-y: - ( $icon-size * 14);
    transform: rotate(90deg);

    &--black {
      background-position-x: - ( $icon-size );
    }
  }

  &-ps-correction {
    @extend .wethod-icon;
    background-position-x: 0;
    background-position-y: - ( $icon-size * 30);

    &--white {
      background-position-x: - ( $icon-size * 2);
    }

    &--black {
      background-position-x: - ( $icon-size );
    }
  }

  &-delete {
    @extend .wethod-icon;
    background-position-x: 0;
    background-position-y: - ( $icon-size * 28);

    &--white {
      background-position-x: - ( $icon-size * 2);
    }

    &--black {
      background-position-x: - ( $icon-size );
    }
  }

  &-phone {
    @extend .wethod-icon;
    background-position-x: 0;
    background-position-y: - ( $icon-size * 38);

    &--white {
      background-position-x: - ( $icon-size * 2);
    }

    &--black {
      background-position-x: - ( $icon-size );
    }
  }

  &-mail {
    @extend .wethod-icon;
    background-position-x: 0;
    background-position-y: - ( $icon-size * 37);

    &--white {
      background-position-x: - ( $icon-size * 2);
    }

    &--black {
      background-position-x: - ( $icon-size );
    }
  }

  &-skype {
    @extend .wethod-icon;
    background-position-x: 0;
    background-position-y: - ( $icon-size * 43);

    &--white {
      background-position-x: - ( $icon-size * 2);
    }

    &--black {
      background-position-x: - ( $icon-size );
    }
  }

  &-linkedin {
    @extend .wethod-icon;
    background-position-x: 0;
    background-position-y: - ( $icon-size * 44);

    &--white {
      background-position-x: - ( $icon-size * 2);
    }

    &--black {
      background-position-x: - ( $icon-size );
    }
  }

  &-meeting {
    @extend .wethod-icon;
    background-position-x: 0;
    background-position-y: - ( $icon-size * 35);

    &--white {
      background-position-x: - ( $icon-size * 2);
    }

    &--black {
      background-position-x: - ( $icon-size );
    }
  }

  &-event {
    @extend .wethod-icon;
    background-position-x: 0;
    background-position-y: - ( $icon-size * 32);

    &--white {
      background-position-x: - ( $icon-size * 2);
    }

    &--black {
      background-position-x: - ( $icon-size );
    }
  }

  &-ship {
    @extend .wethod-icon;
    background-position-x: 0;
    background-position-y: - ( $icon-size * 33);

    &--white {
      background-position-x: - ( $icon-size * 2);
    }

    &--black {
      background-position-x: - ( $icon-size );
    }
  }

  &-other {
    @extend .wethod-icon;
    background-position-x: 0;
    background-position-y: - ( $icon-size * 34);

    &--white {
      background-position-x: - ( $icon-size * 2);
    }

    &--black {
      background-position-x: - ( $icon-size );
    }
  }

  &-closing {
    @extend .wethod-icon;
    background-position-x: 0;
    background-position-y: - ( $icon-size * 36);

    &--white {
      background-position-x: - ( $icon-size * 2);
    }

    &--black {
      background-position-x: - ( $icon-size );
    }
  }

  &-timeline {
    @extend .wethod-icon;
    background-position-x: 0;
    background-position-y: - ( $icon-size * 40);

    &--white {
      background-position-x: - ( $icon-size * 2);
    }

    &--black {
      background-position-x: - ( $icon-size );
    }
  }

  &-percentage {
    @extend .wethod-icon;
    background-position-x: 0;
    background-position-y: - ( $icon-size * 41);

    &--red {
      background-position-x: - ( $icon-size * 4);
    }

    &--green {
      background-position-x: - ( $icon-size * 3);
    }

    &--white {
      background-position-x: - ( $icon-size * 2);
    }

    &--black {
      background-position-x: - ( $icon-size );
    }
  }

  &-compare {
    @extend .wethod-icon;
    background-position-x: 0;
    background-position-y: - ( $icon-size * 42);

    &--white {
      background-position-x: - ( $icon-size * 2);
    }

    &--black {
      background-position-x: - ( $icon-size );
    }
  }

  &-multicurrency {
    @extend .wethod-icon;
    background-position-x: 0;
    background-position-y: - ( $icon-size * 45);

    &--white {
      background-position-x: - ( $icon-size * 2);
    }

    &--black {
      background-position-x: - ( $icon-size );
    }
  }

  &-discard {
    @extend .wethod-icon;
    background-position-x: 0;
    background-position-y: - ( $icon-size * 26);

    &--white {
      background-position-x: - ( $icon-size * 2);
    }

    &--black {
      background-position-x: - ( $icon-size);
    }
  }

  &-plus {
    @extend .wethod-icon;
    background-position-x: 0;
    background-position-y: - ( $icon-size * 26);
    transform: rotate(45deg);

    &--white {
      background-position-x: - ( $icon-size * 2);
    }

    &--black {
      background-position-x: - ( $icon-size);
    }
  }

  &-learning-center {
    @extend .wethod-icon;
    background-position-x: 0;
    background-position-y: - ( $icon-size * 46);

    &--white {
      background-position-x: - ( $icon-size * 2);
    }

    &--black {
      background-position-x: - ( $icon-size );
    }
  }

  &-tours {
    @extend .wethod-icon;
    background-position-x: 0;
    background-position-y: - ( $icon-size * 47);

    &--white {
      background-position-x: - ( $icon-size * 2);
    }

    &--black {
      background-position-x: - ( $icon-size );
    }
  }

  &-support {
    @extend .wethod-icon;
    background-position-x: 0;
    background-position-y: - ( $icon-size * 48);

    &--white {
      background-position-x: - ( $icon-size * 2);
    }

    &--black {
      background-position-x: - ( $icon-size );
    }
  }

  &-playground {
    @extend .wethod-icon;
    background-position-x: 0;
    background-position-y: - ( $icon-size * 49);

    &--white {
      background-position-x: - ( $icon-size * 2);
    }

    &--black {
      background-position-x: - ( $icon-size );
    }
  }

  &-search {
    @extend .wethod-icon;
    background-position-x: 0;
    background-position-y: - ( $icon-size * 50);

    &--white {
      background-position-x: - ( $icon-size * 2);
    }

    &--black {
      background-position-x: - ( $icon-size );
    }
  }

  &-building {
    @extend .wethod-icon;
    background-position-x: 0;
    background-position-y: - ( $icon-size * 52);

    &--white {
      background-position-x: - ( $icon-size * 2);
    }

    &--black {
      background-position-x: - ( $icon-size );
    }
  }

  &-calendar {
    @extend .wethod-icon;
    background-position-x: 0;
    background-position-y: - ( $icon-size * 53);

    &--white {
      background-position-x: - ( $icon-size * 2);
    }

    &--black {
      background-position-x: - ( $icon-size );
    }
  }

  &-description {
    @extend .wethod-icon;
    background-position-x: 0;
    background-position-y: - ( $icon-size * 54);

    &--white {
      background-position-x: - ( $icon-size * 2);
    }

    &--black {
      background-position-x: - ( $icon-size );
    }
  }

  &-internet {
    @extend .wethod-icon;
    background-position-x: 0;
    background-position-y: - ( $icon-size * 55);

    &--white {
      background-position-x: - ( $icon-size * 2);
    }

    &--black {
      background-position-x: - ( $icon-size );
    }
  }

  &-location {
    @extend .wethod-icon;
    background-position-x: 0;
    background-position-y: - ( $icon-size * 56);

    &--white {
      background-position-x: - ( $icon-size * 2);
    }

    &--black {
      background-position-x: - ( $icon-size );
    }
  }

  &-draggable {
    @extend .wethod-icon;
    background-position-y: - ( $icon-size * 51);

    &--black {
      background-position-x: - ( $icon-size );
    }
  }

  &-crm {
    @extend .wethod-icon;
    background-position-y: - ( $icon-size * 52);

    &--white {
      background-position-x: - ( $icon-size * 2);
    }

    &--black {
      background-position-x: - ( $icon-size );
    }
  }

  &-canvas {
    @extend .wethod-icon;
    background-position-x: 0;
    background-position-y: - ( $icon-size * 57);
    transform: scale(0.8);

    &--white {
      background-position-x: - ( $icon-size * 2);
    }

    &--black {
      background-position-x: - ( $icon-size );
    }
  }

  &-more {
    @extend .wethod-icon;
    background-position-x: 0;
    background-position-y: - ( $icon-size * 58);

    &--white {
      background-position-x: - ( $icon-size * 2);
    }

    &--black {
      background-position-x: - ( $icon-size );
    }
  }

  &-options {
    @extend .wethod-icon;
    background-position-x: 0;
    background-position-y: - ( $icon-size * 58);
    transform: rotate(90deg);

    &--white {
      background-position-x: - ( $icon-size * 2);
    }

    &--black {
      background-position-x: - ( $icon-size );
    }
  }

  &-download {
    @extend .wethod-icon;
    background-position-x: 0;
    background-position-y: - ( $icon-size * 59);

    &--white {
      background-position-x: - ( $icon-size * 2);
    }

    &--black {
      background-position-x: - ( $icon-size );
    }
  }

  &-upload {
    @extend .wethod-icon;
    transform: rotate(-90deg);

    background-position-x: 0;
    background-position-y: - ( $icon-size * 75);

    &--white {
      background-position-x: - ( $icon-size * 2);
    }

    &--black {
      background-position-x: - ( $icon-size );
    }
  }

  &-kanban {
    @extend .wethod-icon;
    background-position-x: 0;
    background-position-y: - ( $icon-size * 60);

    &--white {
      background-position-x: - ( $icon-size * 2);
    }

    &--black {
      background-position-x: - ( $icon-size );
    }
  }

  &-spreadsheet {
    @extend .wethod-icon;
    background-position-x: 0;
    background-position-y: - ( $icon-size * 61);

    &--white {
      background-position-x: - ( $icon-size * 2);
    }

    &--black {
      background-position-x: - ( $icon-size );
    }
  }

  &-dropdown {
    @extend .wethod-icon;
    background-position-x: 0;
    background-position-y: - ( $icon-size * 16);
    transition: .2s;

    &--black {
      background-position-x: - ( $icon-size );
    }

    &--expanded {
      transform: rotate(-180deg);
    }
  }

  &-gantt {
    @extend .wethod-icon;
    background-position-x: 0;
    background-position-y: - ( $icon-size * 62);

    &--white {
      background-position-x: - ( $icon-size * 2);
    }

    &--black {
      background-position-x: - ( $icon-size );
    }
  }

  &-home {
    @extend .wethod-icon;
    background-position-x: 0;
    background-position-y: - ( $icon-size * 66);

    &--white {
      background-position-x: - ( $icon-size * 2);
    }

    &--black {
      background-position-x: - ( $icon-size );
    }
  }

  &-bookmark-empty {
    @extend .wethod-icon;
    background-position-x: 0;
    background-position-y: - ( $icon-size * 68);

    &--white {
      background-position-x: - ( $icon-size * 2);
    }

    &--black {
      background-position-x: - ( $icon-size );
    }
  }

  &-bookmark-full {
    @extend .wethod-icon;
    background-position-x: 0;
    background-position-y: - ( $icon-size * 69);

    &--white {
      background-position-x: - ( $icon-size * 2);
    }

    &--black {
      background-position-x: - ( $icon-size );
    }
  }

  &-blocked {
    @extend .wethod-icon;
    background-position-x: 0;
    background-position-y: - ( $icon-size * 67);

    &--white {
      background-position-x: - ( $icon-size * 2);
    }

    &--black {
      background-position-x: - ( $icon-size );
    }
  }

  &-desk {
    @extend .wethod-icon;
    background-position-x: 0;
    background-position-y: - ( $icon-size * 70);

    &--white {
      background-position-x: - ( $icon-size * 2);
    }

    &--black {
      background-position-x: - ( $icon-size );
    }
  }

  &-star {
    @extend .wethod-icon;
    background-position-x: 0;
    background-position-y: - ( $icon-size * 71);

    &--white {
      background-position-x: - ( $icon-size * 2);
    }

    &--black {
      background-position-x: - ( $icon-size );
    }
  }

  &-info {
    @extend .wethod-icon;
    background-position-x: 0;
    background-position-y: - ( $icon-size * 72);

    &--white {
      background-position-x: - ( $icon-size * 2);
    }

    &--black {
      background-position-x: - ( $icon-size );
    }
  }

  &-save {
    @extend .wethod-icon;
    background-position-x: 0;
    background-position-y: - ( $icon-size * 73);

    &--white {
      background-position-x: - ( $icon-size * 2);
    }

    &--black {
      background-position-x: - ( $icon-size );
    }
  }

  &-incoming {
    @extend .wethod-icon;
    background-position-x: 0;
    background-position-y: - ( $icon-size * 74);

    &--white {
      background-position-x: - ( $icon-size * 2);
    }

    &--black {
      background-position-x: - ( $icon-size );
    }
  }

  &-outcoming {
    @extend .wethod-icon;
    background-position-x: 0;
    background-position-y: - ( $icon-size * 75);

    &--white {
      background-position-x: - ( $icon-size * 2);
    }

    &--black {
      background-position-x: - ( $icon-size );
    }
  }

  &-bed {
    @extend .wethod-icon;
    background-position-x: 0;
    background-position-y: - ( $icon-size * 76);

    &--white {
      background-position-x: - ( $icon-size * 2);
    }

    &--black {
      background-position-x: - ( $icon-size );
    }
  }

  &-train {
    @extend .wethod-icon;
    background-position-y: - ( $icon-size * 77);

    &--white {
      background-position-x: - ( $icon-size * 2);
    }

    &--black {
      background-position-x: - ( $icon-size );
    }
  }

  &-car {
    @extend .wethod-icon;
    background-position-x: 0;
    background-position-y: - ( $icon-size * 78);

    &--white {
      background-position-x: - ( $icon-size * 2);
    }

    &--black {
      background-position-x: - ( $icon-size );
    }
  }

  &-asterisk {
    @extend .wethod-icon;
    background-position-x: 0;
    background-position-y: - ( $icon-size * 81);

    &--white {
      background-position-x: - ( $icon-size * 2);
    }

    &--black {
      background-position-x: - ( $icon-size );
    }
  }

  &-external {
    @extend .wethod-icon;
    background-position-x: 0;
    background-position-y: - ( $icon-size * 79);

    &--white {
      background-position-x: - ( $icon-size * 2);
    }

    &--black {
      background-position-x: - ( $icon-size );
    }
  }

  &-repeat {
    @extend .wethod-icon;
    background-position-x: 0;
    background-position-y: - ( $icon-size * 80);

    &--white {
      background-position-x: - ( $icon-size * 2);
    }

    &--black {
      background-position-x: - ( $icon-size );
    }
  }

  &-birthday {
    @extend .wethod-icon;
    background-position-x: 0;
    background-position-y: - ( $icon-size * 82);

    &--white {
      background-position-x: - ( $icon-size * 2);
    }

    &--black {
      background-position-x: - ( $icon-size );
    }
  }

  &-gender {
    @extend .wethod-icon;
    background-position-x: 0;
    background-position-y: - ( $icon-size * 83);

    &--white {
      background-position-x: - ( $icon-size * 2);
    }

    &--black {
      background-position-x: - ( $icon-size );
    }
  }

  &-eye {
    &--opened {
      @extend .wethod-icon;
      background-position-x: 0;
      background-position-y: - ( $icon-size * 85);
    }

    &--closed {
      @extend .wethod-icon;
      background-position-x: 0;
      background-position-y: - ( $icon-size * 84);
    }

    &--white {
      background-position-x: - ( $icon-size * 2);
    }

    &--black {
      background-position-x: - ( $icon-size );
    }
  }

  &-filter {
    @extend .wethod-icon;
    background-position-x: 0;
    background-position-y: - ( $icon-size * 86);

    &--black {
      background-position-x: - ( $icon-size );
    }
  }

  &-filter-applied {
    @extend .wethod-icon;
    background-position-x: 0;
    background-position-y: - ( $icon-size * 87);

    &--black {
      background-position-x: - ( $icon-size );
    }
  }

  &-folder {
    @extend .wethod-icon;
    background-position-x: 0;
    background-position-y: - ( $icon-size * 89);

    &--black {
      background-position-x: - ( $icon-size );
    }
  }

  &--inline {
    display: inline-block;
  }
}

.wethod-icon-medium {
  width: $icon-size-medium;
  height: $icon-size-medium;
  background-image: url("../img/icon/icons.svg?v=1297345074981203799");
  background-size: $icon-size-medium * $how-many-versions $icon-size-medium * $how-many-icons;

  &-project-report {
    @extend .wethod-icon-medium;
    background-position-x: 0;
    background-position-y: - ( $icon-size-medium * 11);

    &--black {
      background-position-x: - ( $icon-size-medium );
    }
  }

  &-budget {
    @extend .wethod-icon-medium;
    background-position-x: 0;
    background-position-y: - ( $icon-size-medium * 9);

    &--red {
      background-position-x: - ( $icon-size-medium * 4);
    }

    &--green {
      background-position-x: - ( $icon-size-medium * 3);
    }

    &--white {
      background-position-x: - ( $icon-size-medium * 2);
    }

    &--black {
      background-position-x: - ( $icon-size-medium );
    }
  }

  &-pipeline {
    @extend .wethod-icon-medium;
    background-position-x: 0;
    background-position-y: - ( $icon-size-medium * 10);

    &--black {
      background-position-x: - ( $icon-size-medium );
    }
  }

  &-program {
    @extend .wethod-icon-medium;
    background-position-x: 0;
    background-position-y: - ( $icon-size-medium * 22);

    &--white {
      background-position-x: - ( $icon-size-medium * 2);
    }

    &--black {
      background-position-x: - ( $icon-size-medium );
    }
  }

  &-phone {
    @extend .wethod-icon-medium;
    background-position-x: 0;
    background-position-y: - ( $icon-size-medium * 38);

    &--white {
      background-position-x: - ( $icon-size-medium * 2);
    }

    &--black {
      background-position-x: - ( $icon-size-medium );
    }
  }

  &-skype {
    @extend .wethod-icon-medium;
    background-position-x: 0;
    background-position-y: - ( $icon-size-medium * 43);

    &--white {
      background-position-x: - ( $icon-size-medium * 2);
    }

    &--black {
      background-position-x: - ( $icon-size-medium );
    }
  }

  &-linkedin {
    @extend .wethod-icon-medium;
    background-position-x: 0;
    background-position-y: - ( $icon-size-medium * 44);

    &--white {
      background-position-x: - ( $icon-size-medium * 2);
    }

    &--black {
      background-position-x: - ( $icon-size-medium );
    }
  }

  &-mail {
    @extend .wethod-icon-medium;
    background-position-x: 0;
    background-position-y: - ( $icon-size-medium * 37);

    &--white {
      background-position-x: - ( $icon-size-medium * 2);
    }

    &--black {
      background-position-x: - ( $icon-size-medium );
    }
  }

  &-pm {
    @extend .wethod-icon-medium;
    background-position-x: -$icon-size-medium;
    background-position-y: - ( $icon-size-medium * 19);

    &--white {
      background-position-x: - ( $icon-size-medium * 2);
    }

    &--black {
      background-position-x: - ( $icon-size-medium );
    }
  }

  &-notes {
    transition: .4s;
    @extend .wethod-icon-medium;
    background-position-y: - ( $icon-size-medium );

    &--done {
      background-position-x: 0;
      background-position-y: 0;
    }

    &--disabled {
      opacity: 0.5;
      cursor: default;
    }
  }

  &-chart {
    @extend .wethod-icon-medium;
    background-position-y: - ( $icon-size-medium * 18 );

    &--disabled {
      opacity: 0.5;
      cursor: default;
    }

    &--black {
      background-position-x: - ( $icon-size-medium );
    }
  }

  &-contingency {
    @extend .wethod-icon-medium;
    background-position-x: -$icon-size-medium;
    background-position-y: - ( $icon-size-medium * 20);
  }

  &-discount {
    @extend .wethod-icon-medium;
    background-position-x: -$icon-size-medium;
    background-position-y: - ( $icon-size-medium * 21);
  }

  &-arrow-up {
    @extend .wethod-icon-medium;
    background-position-x: -$icon-size-medium;
    background-position-y: - ( $icon-size-medium * 14);
    transform: rotate(-90deg);
  }

  &-arrow-down {
    @extend .wethod-icon-medium;
    background-position-x: -$icon-size-medium;
    background-position-y: - ( $icon-size-medium * 14);
    transform: rotate(90deg);
  }

  &-expand {
    @extend .wethod-icon-medium;
    background-position-x: 0;
    background-position-y: - ( $icon-size-medium * 14);
    transform: rotate(-45deg);

    &--black {
      background-position-x: - ( $icon-size-medium );
    }

    &--white {
      background-position-x: - ( $icon-size-medium * 2);
    }
  }

  &-delete {
    @extend .wethod-icon-medium;
    background-position-x: 0;
    background-position-y: - ( $icon-size-medium * 28);

    &--white {
      background-position-x: - ( $icon-size-medium * 2);
    }

    &--black {
      background-position-x: - ( $icon-size-medium);
    }
  }

  &-edit {
    @extend .wethod-icon-medium;
    background-position-x: 0;
    background-position-y: - ( $icon-size-medium * 25);

    &--white {
      background-position-x: - ( $icon-size-medium * 2);
    }

    &--black {
      background-position-x: - ( $icon-size-medium);
    }
  }

  &-discard {
    @extend .wethod-icon-medium;
    background-position-x: 0;
    background-position-y: - ( $icon-size-medium * 26);

    &--white {
      background-position-x: - ( $icon-size-medium * 2);
    }

    &--black {
      background-position-x: - ( $icon-size-medium);
    }
  }

  &-plus {
    @extend .wethod-icon-medium;
    background-position-x: 0;
    background-position-y: - ( $icon-size-medium * 26);
    transform: rotate(45deg);

    &--white {
      background-position-x: - ( $icon-size-medium * 2);
    }

    &--black {
      background-position-x: - ( $icon-size-medium);
    }
  }

  &-checked {
    @extend .wethod-icon-medium;
    background-position-x: 0;
    background-position-y: - ( $icon-size-medium * 27);

    &--white {
      background-position-x: - ( $icon-size-medium * 2);
    }

    &--black {
      background-position-x: - ( $icon-size-medium);
    }
  }

  &-dropdown {
    @extend .wethod-icon-medium;
    background-position-x: 0;
    background-position-y: - ( $icon-size-medium * 16);
    transition: .2s;

    &--black {
      background-position-x: - ( $icon-size-medium );
    }

    &--expanded {
      transform: rotate(-180deg);
    }
  }

  &-timeline {
    @extend .wethod-icon-medium;
    background-position-x: 0;
    background-position-y: - ( $icon-size-medium * 40);

    &--white {
      background-position-x: - ( $icon-size-medium * 2);
    }

    &--black {
      background-position-x: - ( $icon-size-medium );
    }
  }

  &-back {
    @extend .wethod-icon-medium;
    background-position-x: 0;
    background-position-y: - ( $icon-size-medium * 16);
    transform: rotate(90deg);

    &--white {
      background-position-x: - ( $icon-size-medium * 2);
    }

    &--black {
      background-position-x: - ( $icon-size-medium );
    }
  }

  &-forward {
    @extend .wethod-icon-medium;
    background-position-x: 0;
    background-position-y: - ( $icon-size-medium * 16);
    transform: rotate(-90deg);

    &--white {
      background-position-x: - ( $icon-size-medium * 2);
    }

    &--black {
      background-position-x: - ( $icon-size-medium );
    }
  }

  &-search {
    @extend .wethod-icon-medium;
    background-position-x: 0;
    background-position-y: - ( $icon-size-medium * 50);

    &--white {
      background-position-x: - ( $icon-size-medium * 2);
    }

    &--black {
      background-position-x: - ( $icon-size-medium );
    }
  }

  &-draggable {
    @extend .wethod-icon-medium;
    background-position-y: - ( $icon-size-medium * 51);

    &--black {
      background-position-x: - ( $icon-size-medium);
    }
  }

  &-canvas {
    @extend .wethod-icon-medium;
    background-position-x: 0;
    background-position-y: - ( $icon-size-medium * 57);

    &--white {
      background-position-x: - ( $icon-size-medium * 2);
    }

    &--black {
      background-position-x: - ( $icon-size-medium );
    }
  }

  &-more {
    @extend .wethod-icon-medium;
    background-position-x: 0;
    background-position-y: - ( $icon-size-medium * 58);

    &--white {
      background-position-x: - ( $icon-size-medium * 2);
    }

    &--black {
      background-position-x: - ( $icon-size-medium );
    }
  }

  &-draft {
    @extend .wethod-icon-medium;
    background-position-x: 0;
    background-position-y: - ( $icon-size-medium * 58);
    transform: rotate(90deg);

    &--white {
      background-position-x: - ( $icon-size-medium * 2);
    }

    &--black {
      background-position-x: - ( $icon-size-medium );
    }
  }

  &-options {
    @extend .wethod-icon-medium;
    background-position-x: 0;
    background-position-y: - ( $icon-size-medium * 58);
    transform: rotate(90deg);

    &--white {
      background-position-x: - ( $icon-size-medium * 2);
    }

    &--black {
      background-position-x: - ( $icon-size-medium );
    }
  }

  &-download {
    @extend .wethod-icon-medium;
    background-position-x: 0;
    background-position-y: - ( $icon-size-medium * 59);

    &--white {
      background-position-x: - ( $icon-size-medium * 2);
    }

    &--black {
      background-position-x: - ( $icon-size-medium );
    }
  }

  &-kanban {
    @extend .wethod-icon-medium;
    background-position-x: 0;
    background-position-y: - ( $icon-size-medium * 60);

    &--white {
      background-position-x: - ( $icon-size-medium * 2);
    }

    &--black {
      background-position-x: - ( $icon-size-medium );
    }
  }

  &-spreadsheet {
    @extend .wethod-icon-medium;
    background-position-x: 0;
    background-position-y: - ( $icon-size-medium * 61);

    &--white {
      background-position-x: - ( $icon-size-medium * 2);
    }

    &--black {
      background-position-x: - ( $icon-size-medium );
    }
  }

  &-gantt {
    @extend .wethod-icon-medium;
    background-position-x: 0;
    background-position-y: - ( $icon-size-medium * 62);

    &--white {
      background-position-x: - ( $icon-size-medium * 2);
    }

    &--black {
      background-position-x: - ( $icon-size-medium );
    }
  }

  &-support {
    @extend .wethod-icon-medium;
    background-position-x: 0;
    background-position-y: - ( $icon-size-medium * 48);

    &--white {
      background-position-x: - ( $icon-size-medium * 2);
    }

    &--black {
      background-position-x: - ( $icon-size-medium );
    }
  }

  &-bookmark-empty {
    @extend .wethod-icon-medium;
    background-position-x: 0;
    background-position-y: - ( $icon-size-medium * 68);

    &--white {
      background-position-x: - ( $icon-size-medium * 2);
    }

    &--black {
      background-position-x: - ( $icon-size-medium );
    }
  }

  &-bookmark-full {
    @extend .wethod-icon-medium;
    background-position-x: 0;
    background-position-y: - ( $icon-size-medium * 69);

    &--white {
      background-position-x: - ( $icon-size-medium * 2);
    }

    &--black {
      background-position-x: - ( $icon-size-medium );
    }
  }

  &-blocked {
    @extend .wethod-icon-medium;
    background-position-x: 0;
    background-position-y: - ( $icon-size-medium * 67);

    &--white {
      background-position-x: - ( $icon-size-medium * 2);
    }

    &--black {
      background-position-x: - ( $icon-size-medium );
    }
  }

  &-star {
    @extend .wethod-icon-medium;
    background-position-x: 0;
    background-position-y: - ( $icon-size-medium * 71);

    &--white {
      background-position-x: - ( $icon-size-medium * 2);
    }

    &--black {
      background-position-x: - ( $icon-size-medium );
    }
  }

  &-info {
    @extend .wethod-icon-medium;
    background-position-x: 0;
    background-position-y: - ( $icon-size-medium * 72);

    &--white {
      background-position-x: - ( $icon-size-medium * 2);
    }

    &--black {
      background-position-x: - ( $icon-size-medium );
    }
  }

  &-repeat {
    @extend .wethod-icon-medium;
    background-position-x: 0;
    background-position-y: - ( $icon-size-medium * 80);

    &--white {
      background-position-x: - ( $icon-size-medium * 2);
    }

    &--black {
      background-position-x: - ( $icon-size-medium );
    }
  }

  &-birthday {
    @extend .wethod-icon-medium;
    background-position-x: 0;
    background-position-y: - ( $icon-size-medium * 82);

    &--white {
      background-position-x: - ( $icon-size-medium * 2);
    }

    &--black {
      background-position-x: - ( $icon-size-medium );
    }
  }

  &-gender {
    @extend .wethod-icon-medium;
    background-position-x: 0;
    background-position-y: - ( $icon-size-medium * 83);

    &--white {
      background-position-x: - ( $icon-size-medium * 2);
    }

    &--black {
      background-position-x: - ( $icon-size-medium );
    }
  }

  &-eye {
    &--opened {
      @extend .wethod-icon-medium;
      background-position-x: 0;
      background-position-y: - ( $icon-size-medium * 85);
    }

    &--closed {
      @extend .wethod-icon-medium;
      background-position-x: 0;
      background-position-y: - ( $icon-size-medium * 84);
    }

    &--white {
      background-position-x: - ( $icon-size-medium * 2);
    }

    &--black {
      background-position-x: - ( $icon-size-medium );
    }
  }

  &-pause {
    @extend .wethod-icon-medium;
    background-position-x: 0;
    background-position-y: - ( $icon-size-medium * 88);

    &--white {
      background-position-x: - ( $icon-size-medium * 2);
    }

    &--black {
      background-position-x: - ( $icon-size-medium );
    }
  }

  &-folder {
    @extend .wethod-icon-medium;
    background-position-x: 0;
    background-position-y: - ( $icon-size-medium * 89);

    &--white {
      background-position-x: - ( $icon-size-medium * 2);
    }

    &--black {
      background-position-x: - ( $icon-size-medium );
    }
  }
}

.wethod-icon-small {
  width: $icon-size-small;
  height: $icon-size-small;
  background-image: url("../img/icon/icons.svg?v=1297345074981203799");
  background-size: $icon-size-small * $how-many-versions $icon-size-small * $how-many-icons;

  &-pm {
    @extend .wethod-icon-small;
    background-position-x: -$icon-size-small;
    background-position-y: - ( $icon-size-small * 19);

    &--white {
      background-position-x: - ( $icon-size-small * 2);
    }

    &--black {
      background-position-x: - ( $icon-size-small );
    }
  }

  &-period {
    @extend .wethod-icon-small;
    background-position-x: -$icon-size-small;
    background-position-y: - ( $icon-size-small * 23);
  }

  &-dropdown {
    @extend .wethod-icon-small;
    background-position-x: 0;
    background-position-y: - ( $icon-size-small * 16);
    transition: .2s;

    &--expanded {
      transform: rotate(-180deg);
    }
  }

  &-checked {
    @extend .wethod-icon-small;
    background-position-x: 0;
    background-position-y: - ( $icon-size-small * 27);

    &--white {
      background-position-x: - ( $icon-size-small * 2);
    }

    &--black {
      background-position-x: - ( $icon-size-small);
    }
  }

  &-delete {
    @extend .wethod-icon-small;
    background-position-x: 0;
    background-position-y: - ( $icon-size-small * 28);

    &--white {
      background-position-x: - ( $icon-size-small * 2);
    }

    &--black {
      background-position-x: - ( $icon-size-small);
    }
  }

  &-arrow-up {
    @extend .wethod-icon-small;
    background-position-x: -$icon-size-small;
    background-position-y: - ( $icon-size-small * 14);
    transform: rotate(-90deg);

    &--black {
      background-position-x: - ( $icon-size-small);
    }
  }

  &-arrow-down {
    @extend .wethod-icon-small;
    background-position-x: -$icon-size-small;
    background-position-y: - ( $icon-size-small * 14);
    transform: rotate(90deg);

    &--black {
      background-position-x: - ( $icon-size-small);
    }
  }

  &-back {
    @extend .wethod-icon-small;
    background-position-x: -$icon-size-small;
    background-position-y: - ( $icon-size-small * 16);
    transform: rotate(90deg);
  }

  &-discard {
    @extend .wethod-icon-small;
    background-position-x: 0;
    background-position-y: - ( $icon-size-small * 26);

    &--white {
      background-position-x: - ( $icon-size-small * 2);
    }

    &--black {
      background-position-x: - ( $icon-size-small);
    }
  }

  &-draggable {
    @extend .wethod-icon-small;
    background-position-y: - ( $icon-size-small * 51);

    &--black {
      background-position-x: - ( $icon-size-small);
    }
  }

  &-canvas {
    @extend .wethod-icon-small;
    background-position-x: 0;
    background-position-y: - ( $icon-size-small * 57);

    &--white {
      background-position-x: - ( $icon-size-small * 2);
    }

    &--black {
      background-position-x: - ( $icon-size-small );
    }
  }

  &-support {
    @extend .wethod-icon-small;
    background-position-x: 0;
    background-position-y: - ( $icon-size-small * 48);

    &--white {
      background-position-x: - ( $icon-size-small * 2);
    }

    &--black {
      background-position-x: - ( $icon-size-small );
    }
  }

  &-star {
    @extend .wethod-icon-small;
    background-position-x: 0;
    background-position-y: - ( $icon-size-small * 71);

    &--white {
      background-position-x: - ( $icon-size-small * 2);
    }

    &--black {
      background-position-x: - ( $icon-size-small );
    }
  }

  &-info {
    @extend .wethod-icon-small;
    background-position-x: 0;
    background-position-y: - ( $icon-size-small * 72);

    &--white {
      background-position-x: - ( $icon-size-small * 2);
    }

    &--black {
      background-position-x: - ( $icon-size-small );
    }
  }

  &-repeat {
    @extend .wethod-icon-small;
    background-position-x: 0;
    background-position-y: - ( $icon-size-small * 80);

    &--white {
      background-position-x: - ( $icon-size-small * 2);
    }

    &--black {
      background-position-x: - ( $icon-size-small );
    }
  }

  &-eye {
    &--opened {
      @extend .wethod-icon-small;
      background-position-x: 0;
      background-position-y: - ( $icon-size-small * 85);
    }

    &--closed {
      @extend .wethod-icon-small;
      background-position-x: 0;
      background-position-y: - ( $icon-size-small * 84);
    }

    &--white {
      background-position-x: - ( $icon-size-small * 2);
    }

    &--black {
      background-position-x: - ( $icon-size-small );
    }
  }

  &-folder {
    @extend .wethod-icon-small;
    background-position-x: 0;
    background-position-y: - ( $icon-size-small * 89);

    &--white {
      background-position-x: - ( $icon-size-small * 2);
    }

    &--black {
      background-position-x: - ( $icon-size-small );
    }
  }
}
