.navigation-button {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 12px 0;
  outline: none;
  border: 1px solid #D2D2D2;
  border-radius: 6px;
  width: 100%;
  padding: 13px 8px;
  cursor: pointer;
  background-color: transparent;
  text-align: left;

  &:hover {
    background: #ececec;
  }

  &__label {
    margin: 0 8px;
    flex: 1;
  }
  &__title {
    display: block;
    font-size: 14px;
    line-height: 19px;
    font-weight: $semiBold;
    color: initial;
  }
  &__subtitle {
    display: block;
    font-size: 12px;
    line-height: 17px;
    color: $dusty-gray;
  }
  &__arrow {
    opacity: 0.6;
  }
}
