@import "client";
@import "client-detail";

.clients {
  &__sidebar {
    &--multiple-input-row {
      display: table;
      width: 100%;
    }

    &--multiple-input-element {
      display: table-cell;
      vertical-align: middle;
    }

    &--name {
      width: 60%;
    }

    &--acronym {
      width: 40%;
    }

    &-province {
      .material-input__input {
        text-transform: capitalize;

        input {
          text-transform: capitalize;
        }
      }
    }
  }

  &__actions {
    height: 142px;
    position: relative;
    padding: 22px 0;
    &--add-client-button {
      float: right;
      line-height: 30px;
    }
  }
  &__table-button {
    display: inline-block;
    vertical-align: middle;
    border-radius: 50px;
    cursor: pointer;
    transition: .4s;
    height: 32px;
    width: 32px;
    border: none;
    margin: auto 8px;

    &--hidden {
      display: none;
    }
    &:hover {
      background-color: $bright-sky-blue;
    }

    .wethod-icon {
        display: block;
        //the icon should be white on hover, since the background gets blue
        &:hover {
          background-position-x: - 64px;
        }
    }
  }
  &-list {
    position: relative;
    height: calc(100vh - 263px);
    a {
      color:$black;
      &:hover {
        color: $bright-sky-blue;
      }
    }
  }
  &-people {
    &__column {
      &--profile-pic {
        width: 8%;

        .wethod-profile-pic {
          width: 32px;
          height: 32px;
          line-height: 32px;
          margin: 10px 0;
        }

        @include scaleToExtraSmall() {
          width: 15%;
        }
      }
      &--name {
        width: 15%;

        @include scaleToExtraSmall() {
          width: 20%;
        }
      }
      &--surname {
        width: 15%;

        @include scaleToExtraSmall() {
          width: 25%;
        }
      }
      &--role {
        width: 13%;

        @include scaleToExtraSmall() {
          display: none;
        }
      }
      &--company {
        width: 17%;

        @include scaleToExtraSmall() {
          width: 25%;
        }
      }
      &--email {
        width: 20%;

        @include scaleToExtraSmall() {
          display: none;
        }
      }
      &--actions {
        width: 12%;
        text-align: right;

        @include scaleToExtraSmall() {
          width: 14%;
        }
      }
    }
  }
  &-companies {
    &__column {
      &--profile-pic {
        width: 8%;

        .wethod-profile-pic {
          width: 32px;
          height: 32px;
          line-height: 32px;
          margin: 10px 0;
        }

        @include scaleToExtraSmall() {
          width: 15%;
        }
      }
      &--name {
        width: 32%;
      }
      &--acronym {
        width: 23%;

        @include scaleToExtraSmall() {
          width: 19%;
        }
      }
      &--group {
        width: 25%;

        @include scaleToExtraSmall() {
          width: 20%;
        }
      }
      &--actions {
        width: 12%;
        text-align: right;
        @include scaleToExtraSmall() {
          width: 14%;
        }
      }
    }
  }
  &-modal {
    &__actions {
      padding-top: 32px;
      text-align: center;
    }
  }
  .modal__actions {
    text-align: center;
  }

  .wethod-table__row:hover {
    .clients__table-button {
      display: inline-block;
    }
  }
}

.delete-client-company-modal__body {
  .wethod-search-select {
    .wethod-search-select__list {
      margin-top: 5px;
      max-height: 88px;
    }
  }
  .wethod-modal-list {
    list-style-type: none;
  }
}
