.wethod-widget {
  background:$white;
  border-radius: 12px;
  border: 1px solid $concrete-gray;
  overflow: hidden;

  &__empty {
    display: flex;
    align-items: center;
    height: 100%;

    &-message {
      text-align: center;
      color: #bbbbbb;
      font-size: 14px;
      width: 80%;
      margin: auto;
      line-height: 18px;

      &-link {
        color: $bright-sky-blue;
      }
    }
  }

  &__loading {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
  }

  &__header {
    border-bottom: 1px solid $concrete-gray;
    padding: 0 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 56px;
    background-color: $morning-fog-gray;
  }

  &__title {
    display: block;
    font-size: 16px;
    font-weight: $semiBold;
    line-height: 1.5;
  }

  &__subtitle {
    font-size: 12px;
  }

  &__body {
    height: 240px;
    overflow: auto;

    &--content {
      height: 100%;
      display: flex;
      flex-direction: column;
    }
  }

  &__search {
    max-height: 40px;

    .search-input {
      padding: 0;
      border: none;
    }

    input {
      font-size: inherit;
    }
  }
}

@import "invoices";
@import "revenue-clock";
@import "alerts";
@import "reviews";
@import "intercompany";
@import "rating-overview";
