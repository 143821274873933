.timeline {
  em {
    background: rgba(72, 165, 249, 0.4);
  }
  &__no-results {
    color: #AAAAAB;
    letter-spacing: 0.2px;
    line-height: 19px;
    padding-left: 30px;
    margin-top: 30px;
    text-transform: uppercase;
    font-weight: $semiBold;
  }
  &.wethod-section-body {
    margin: 16px auto;
    @include scaleToSmall() {
      margin-top: -30px;
    }
  }
  &__menu {
    text-align: left;
    white-space: nowrap;

    a {
      color: initial;
    }
  }
  &-project {
    &__actions, &__people, &__goals, &__client-contact {
      @include scaleToSmall() {
        display: none;
      }
    }
    &__about {
      &-item {
        margin-bottom: 8px;
      }
    }
    &__info {
      width: 30%;
      float: left;
      @include scaleToSmall() {
        width: 100%;
      }
    }
    &-actions {
      a, button {
        float: none;
        margin: 0 8px 0 0;
        width: 33px;
        height: 33px;

        &:hover {
          .wethod-icon {
            background-position-x: -64px;
          }
        }
      }
    }
    &__project-type {
      width: 4px;
      height: 100%;
      position: absolute;
      left: 0;
      top: 0;
      border-radius: 3px 0 0 3px;
    }
  }
  &-person {
    @extend .client-info-row;
    &__level {
      color: $dusty-gray;
      margin-top: 4px;
    }
  }
  &__history {
    width: 60%;
    margin-left: 10%;
    float: left;
    @include scaleToSmall() {
      width: 80%;
    }
  }
  &-history {
    &-actions {
      width: 50%;
      padding-left: 30px;
      @include scaleToSmall() {
        display: none;
      }
    }
  }
  &__events {
    border-left: 1px solid rgba(36, 37, 37, 0.2);
    margin-bottom: 30px;
  }
  &-events {
    &-future, &-past {
      padding-left: 30px;
    }
    &__title {
      color: #AAAAAB;
      font-size: 14px;
      letter-spacing: 0.2px;
      line-height: 19px;
    }
    &-future {
      margin-top: 24px;
      .timeline-events__list {
        margin-top: 10px;
        // Remove scrollbar, prefer nav arrows
        width: 582px;
        // height: 82px; needed when scrollbar visibile, otherwise content get partially covered
        overflow-x: auto;
        white-space: nowrap;
        overflow-y: hidden;
        @include scaleToSmall() {
          width: 100%;
        }
      }
      .timeline-add-event-form {
        margin-top: 8px;
      }
    }
  }
  &-event {
    &-past {
      margin-top: 40px;
      position: relative;
      transition: .3s ease-in;

      .wethod-button {
        display: inline-block;
      }
      &:hover {
        .timeline-event-past__actions {
          opacity: 1;
        }
        .timeline-followup__actions {
          opacity: 1;
        }
      }
      &__type {
        &-name {
          font-size: 14px;
          line-height: 19px;
        }
      }
      &__creation {
        font-size: 10px;
        line-height: 14px;
        color: $dusty-gray;
        margin-top: 4px;
      }
      &__creator {
        font-weight: $bold;
      }
      &__creation-time {

      }
      &__content {
        border-radius: 3px;
        background-color:$white;
        box-shadow: 0 2px 6px 0 rgba(184, 188, 192, 0.3);
        padding: 14px 24px;
        margin-top: 8px;
        min-height: 58px;
        &:hover {
          .timeline-event-past__actions {
            opacity: 1;
          }
        }
      }
      .timeline-add-event-form {
        margin-top: 10px;
      }
    }
    &-log {
      margin-top: 40px;
      position: relative;
      &__creation {
        font-size: 10px;
        line-height: 14px;
        color: $dusty-gray;
        margin-top: 4px;
      }
      &__creator {
        font-weight: $bold;
      }
      &__heatmap-description {
        font-weight: $bold;
        font-size: 10px;
        line-height: 14px;
        color: $dusty-gray;
        margin-top: 4px;
      }
      &__pin {
        background: #B8BCC0;
        width: 6px;
        height: 6px;
        display: inline-block;
        border-radius: 50%;
        line-height: 24px;
        &-container {
          position: absolute;
          display: inline-block;
          width: 24px;
          height: 24px;
          background: $maldivian-sand-white;
          border-radius: 50%;
          left: -42px;
          top: -7px;
          text-align: center;
          line-height: 24px;
        }
      }
      &__type-name {
        line-height: 20px;
      }
    }
  }
  &-add-event {
    position: relative;
    &__button {
      &-container {
        background: $maldivian-sand-white;
        position: absolute;
        display: inline-block;
        padding: 10px 0;
        left: -45px;
        top: -11px;
      }
      position: relative;
      width: 30px;
      height: 30px;
      cursor: pointer;
      color: $bright-sky-blue;
      border: 1px solid $bright-sky-blue;
      text-align: center;
      border-radius: 50%;
      line-height: 30px;
      font-size: 17px;
      outline: none;
      transition: 0.2s ease-in-out;
      &:hover {
        color: white !important;
        background: $bright-sky-blue;
      }
    }
    &__hint {
      opacity: 0;
      color: $bright-sky-blue;
      line-height: 30px;
      transition: 0.2s ease-in-out;
    }
    &-form {
      background:$white;
      width: fit-content;
      box-shadow: 0 2px 6px 0 rgba(184, 188, 192, 0.3);
      border-radius: 3px;
      position: relative;
      margin-top: 40px;
      .wethod-day-picker {
        box-shadow: none;
        vertical-align: top;
      }
    }
  }
  &-event {
    &-creator {
      padding-left: 30px;
      margin-top: 40px;
      &:hover {
        .timeline-add-event__hint {
          opacity: 1;
        }
      }
      @include scaleToSmall() {
        display: none;
      }
    }
    &-form {
      &-description {
        display: inline-block;
        margin: 0 1rem;
        margin-top: 1rem;
        vertical-align: top;
        &__title {
          font-size: 1.15rem;
          font-weight: 500;
          color: $bright-sky-blue;
          padding: 0 0.5rem;
          margin-bottom: 0.5rem;
        }
        textarea {
          margin-top: 6px;
          width: 250px;
          height: 130px;
          font-size: 12px;
          letter-spacing: 0.2px;
          line-height: 20px;
          border: none;
          outline: none;
          background: #F2F3F5;
          border-radius: 3px;
          padding: 8px;
        }
      }
      &-actions {
        text-align: right;
        padding: 0 1rem 1rem 1rem;
        .wethod-button {
          display: inline-block;
          margin-left: 12px;
        }
        &--left, &--right {
          width: 50%;
          display: inline-block;
        }
        &--left {
          text-align: left;
          padding: 0 0.5rem;
          color: $bright-sky-blue;
        }
      }
      &-datetime-picker {
        display: inline-block;
      }
      &-time-picker {
        margin: 0 1.5rem;
        color: $bright-sky-blue;
        .wethod-time-input {
          width: 55px;
        }
        input {
          text-align: center;
        }
      }
    }
    &-type {
      &-select {
        &-container {
          background: $maldivian-sand-white;
          position: absolute;
          left: -45px;
          padding: 10px 0;
          top: -10px;
        }
        background:$white;
        width: 32px;
        text-align: center;
        border-radius: 26px;
        padding: 16px 0;
        &__type {
          height: 32px;
          line-height: 32px;
          cursor: pointer;
          width: 32px;
          padding-top: 6px;
          &:hover {
            position: relative;
            background:$white;
            width: 41px;
            height: 41px;
            vertical-align: middle;
            border-radius: 50%;
            box-shadow: 0 2px 4px 0 #D4DCE4;
            left: -4px;
            padding-top: 5px;
            padding-left: 3px;
          }
          &--selected {
            position: relative;
            background:$white;
            width: 41px;
            height: 41px;
            vertical-align: middle;
            border-radius: 50%;
            box-shadow: 0 2px 4px 0 #D4DCE4;
            left: -4px;
            padding-top: 5px;
            padding-left: 3px;
          }
        }
      }
    }
    &-past {
      &__type {
        &-logo {
          &-container {
            position: absolute;
            display: inline-block;
            width: 32px;
            height: 32px;
            background: $maldivian-sand-white;
            border-radius: 50%;
            left: -46px;
            top: -5px;
            text-align: center;
            line-height: 32px;
          }
        }
      }
      &__actions {
        margin-top: 10px;
        opacity: 0;
        transition: 0.2s ease-in-out;
        &-left, &-right {
          width: 50%;
          display: inline-block;
        }
        .wethod-button {
          width: fit-content;
        }
        &-right {
          text-align: right;
          @include scaleToSmall() {
            display: none;
          }
          .wethod-button {
            display: inline-block;
          }
        }
      }
      &__description {
        white-space: pre-line;
        line-height: 20px;
        word-wrap: break-word;
      }
    }
    &-future {
      display: inline-block;
      height: 63px;
      width: 74px;
      box-shadow: 0 0 4px 0 #D4DCE4;
      background:$white;
      border-radius: 3px;
      margin-right: 4px;
      padding: 2px;
      text-align: center;
      vertical-align: top;
      cursor: pointer;
      &:hover, &--selected {
        border-bottom: 2px solid $bright-sky-blue;
      }
      &__date {
        color: #4A4A4A;
        font-size: 12px;
        line-height: 17px;
        text-transform: uppercase;
        background: $maldivian-sand-white;
        padding: 4px 0;
      }
      &__type {
        div {
          display: inline-block;
        }
      }
      &__jump-to-action {
        font-size: 10px;
        line-height: 14px;
        color: $dusty-gray;
        margin-bottom: 8px;
      }
      &__heatmap-description {
        white-space: pre-line;
        margin-bottom: 12px;
      }
      &__content {
        border-radius: 3px;
        background-color:$white;
        box-shadow: 0 2px 6px 0 rgba(184, 188, 192, 0.3);
        padding: 14px 24px;
        margin-top: 8px;
        min-height: 58px;
        &:hover {
          .timeline-event-past__actions {
            opacity: 1;
          }
        }
      }
      &__creation {
        font-size: 10px;
        line-height: 14px;
        color: $dusty-gray;
        margin-bottom: 8px;
      }
    }
  }
  &-followup {
    margin-top: 4px;
    &__info {
      &-important, &-secondary {
        display: inline-block;
      }
      &-important {
        height: 63px;
        width: 74px;
        box-shadow: 0 0 4px 0 #D4DCE4;
        background:$white;
        border-radius: 3px;
        padding: 2px;
        text-align: center;
      }
      &-secondary {
        min-height: 63px;
        width: calc(100% - 78px);
        box-shadow: 0 0 4px 0 #D4DCE4;
        background:$white;
        border-radius: 3px;
        margin-left: 4px;
        vertical-align: top;
        padding: 14px 24px;
      }
    }
    &__date {
      color: #4A4A4A;
      font-size: 12px;
      line-height: 17px;
      text-transform: uppercase;
      background: $maldivian-sand-white;
      padding: 4px 0;
    }
    &__type {
      text-align: center;
      div {
        display: inline-block;
      }
    }
    &__description {
      display: inline-block;
      width: calc(100% - 80px);
      white-space: pre-line;
      word-wrap: break-word;
      @include scaleToSmall() {
        width: 100%;
      }
    }
    &__actions {
      display: inline-block;
      opacity: 0;
      transition: 0.2s ease-in-out;
      @include scaleToSmall() {
        display: none;
      }
    }
  }
  &-company {
    .timeline__history {
      margin-left: 20%;
    }
  }
}
