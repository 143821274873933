@import "scss/colors";

.timesheet {
  &__actions {
    height: 142px;
    position: relative;
    @include scaleToExtraSmall() {
      height: 123px;
    }

    &--others-view {
      height: 230px;
    }
  }

  &__employee {
    display: flex;
    border-radius: 4px;
    border: 1px solid #FF5700;
    background: rgba(255, 87, 0, 0.1);
    margin-top: 16px;
    padding: 9px 17px;
    font-size: 14px;

    &-pic {
      width: 48px;
      height: 48px;
      line-height: 48px;
      display: inline-block;
      margin-right: 16px;
    }

    &-info {
      display: inline-flex;
      flex-direction: column;
      height: 100%;
      line-height: initial;

      > span:first-of-type {
        font-size: 17px;
        font-weight: $semiBold;
      }

      > span:last-of-type {
        color: $dusty-gray;
        text-transform: uppercase;
      }
    }
  }

  .search-input {
    width: 225px;
    margin-left: 16px;

    @include scaleToExtraSmall() {
      display: none;
    }
  }

  &-list {
    position: relative;

    &__header {
      position: fixed;
      max-width: 1024px;
    }

    &__projects {
      position: relative;
      top: 30px;

      &-loading {
        text-align: center;
      }

      .wethod-spinner--small {
        margin: auto;
        top: 50px;
      }
    }
  }

  &__column {
    &--favourite {
      display: inline-flex;
      width: 5%;

      @include scaleToExtraSmall() {
        width: 10%;
      }
    }

    &--feedback {
      position: absolute;
      line-height: initial;
      bottom: 4px;
      font-size: 10px;
      color: $bright-sky-blue;
    }

    &--archived {
      width: 8%;
      text-transform: uppercase;
      font-size: 11px;
      color: #ccc;
      text-align: center;
      @include scaleToExtraSmall() {
        width: 17%;
        padding: 0 4px;
        font-size: 9px;
      }
    }

    &--project-name {
      width: 27%;
      position: relative;
      display: inline-flex;
      flex-direction: column;
      justify-content: center;

      & span {
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        line-height: normal;

        &:not(:first-child) {
          color: $dusty-gray;
          margin-top: 2px;
        }
      }

      @include scaleToExtraSmall() {
        width: 55%;
      }
    }

    &--automatic {
      width: 8%;
      text-align: center;

      @include scaleToExtraSmall() {
        width: 15%;
      }
    }

    &--client {
      width: 15%;

      @include scaleToExtraSmall() {
        display: none;
      }
    }

    &--job-order {
      width: 15%;

      @include scaleToExtraSmall() {
        display: none;
      }
    }

    &--job-order-category {
      width: 20%;
      text-transform: capitalize;

      @include scaleToExtraSmall() {
        display: none;
      }
    }

    &--hours {
      width: 10%;
      text-align: center;

      .wethod-input input {
        text-align: center;
      }

      @include scaleToExtraSmall() {
        width: 20%;
      }
    }
  }

  &-project {
    border: 1px solid $concrete-gray;
    border-top: 0;

    &__collection {
      overflow: auto;
      height: calc(100vh - 287px);
      overflow-x: hidden;

      &--others-view {
        height: calc(100vh - 375px);
      }

      @include scaleToExtraSmall() {
        height: calc(100vh - 214px);
      }
    }

    &__info-list {
      height: 60px;
      line-height: 60px;
      background: $white;
      border-bottom: 1px solid $concrete-gray;

      &--detailed {
        cursor: pointer;

        &:hover {
          background: rgba(216, 216, 216, 0.3);
        }
      }

      &--empty {
        text-transform: uppercase;
        font-weight: $bold;
        color: $steel-gray;
        text-align: center;
        border: 1px solid $concrete-gray;
        border-top: 0;
        display: flex;
        align-items: center;
        align-content: center;
        justify-content: center;
        white-space: pre-wrap;
        line-height: 1.5;
        padding: 0 15px;
      }
    }

    &__hours {
      background: $white;
      margin-bottom: 2px;
      transition: .4s;
      max-height: 0;
      overflow: hidden;

      &-header {
        border-bottom: 1px solid $concrete-gray;
        height: 60px;
        overflow: hidden;
        display: flex;
        align-items: center;
        justify-content: flex-end;
      }

      &-icon {
        display: inline-block;
        position: relative;
        top: 0;
        width: 7%;
        transition: .4s;
        padding: 0 6px;
        text-align: center;
        height: 60px;
        line-height: 60px;

        &:last-child {
          margin-right: calc(12% + 64px);
        }

        &:hover {
          top: -60px;

          @include scaleToExtraSmall() {
            top: 0;
          }
        }

        @include scaleToExtraSmall() {
          width: calc(95% * 11 / 100);

          &:last-child {
            margin-right: calc(2.5%);
          }
        }

        &__label {
          font-size: 10px;
          font-weight: $semiBold;

          @include scaleToExtraSmall() {
            display: none;
          }
        }

        &__picture {
          display: inline-flex;
          line-height: 100%;
          height: 100%;
          vertical-align: top;
          align-items: center;
        }
      }
    }
  }

  &__date {
    right: 0;
    line-height: 30px;
    text-transform: uppercase;
    cursor: pointer;
    color: $bright-sky-blue;
    background-color: transparent;
    padding: 0 32px;
    border-radius: 100px;
    border: 1px solid $bright-sky-blue;
    outline: none;
    transition: 0.15s ease-in-out;

    &:hover {
      color: $white;
      background-color: $bright-sky-blue;
    }

    &--open {
      color: $white;
      background-color: $bright-sky-blue;
    }

    &-wrapper {
      position: relative;
      z-index: 3;

      .wethod-day-picker {
        position: absolute;
        left: 0;
        top: 38px;
      }
    }
  }

  &__job-order-category {
    &-color {
      height: 12px;
      display: inline-block;
      width: 12px;
      margin-right: 24px;
      border-radius: 50%;
    }

    @include scaleToExtraSmall() {
      display: none;
    }
  }

  &__budget-area {
    border-bottom: 1px solid $concrete-gray;
    height: 60px;

    @include scaleToExtraSmall() {
      padding-left: 5%;
    }

    &-name {
      float: left;
      width: calc(43% - 64px);
      min-height: 1px; // Avoid collapsing width if area name is empty
      line-height: 60px;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      padding: 0 6px 0 8%;

      @include scaleToExtraSmall() {
        padding: 0 4px;
        width: 28%;
      }

      &--generic {
        text-transform: capitalize;
        font-style: italic;
      }
    }

    &-feedback {
      float: left;
      padding: 0 6px;
      width: 10%;
      line-height: 60px;
      color: $bright-sky-blue;

      @include scaleToExtraSmall() {
        padding: 0 4px;
        width: 14%;
        font-size: 10px;
      }
    }

    &-hours {
      width: 7%;
      text-align: center;
      float: left;
      padding: 0 6px;
      padding-top: 14px;

      @include scaleToExtraSmall() {
        width: 11%;
      }
    }

    &-notes {
      float: left;
      margin: 14px 0 14px 32px;

      .wethod-icon-notes {
        cursor: pointer;
      }

      @include scaleToExtraSmall() {
        display: none;
      }
    }

    &-total {
      float: left;
      width: 10%;
      text-align: center;
      line-height: 60px;
      font-weight: $bold;

      @include scaleToExtraSmall() {
        display: none;
      }
    }
  }

  &-note {
    &__input {
      float: left;
      width: 100%;
      height: 100px;
      padding: 8px;
      margin-bottom: 8px;
      line-height: 1.5;
      border: 1px solid #eee;
      resize: none;
      outline: none;
    }

    &__actions {
      text-align: center;
    }

    &__button {
      width: 33%;
      display: inline-block;
      margin: 0 16px;

      &--archived {
        display: none;
      }
    }
  }

  &-weekly {
    &__bu-select {
      flex-shrink: 0;
      margin-right: 16px;
    }

    &__week-selector {
      flex-shrink: 0;
    }

    @include scaleToExtraSmall() {
      .wethod-table__search {
        display: none;
        height: 0;
      }
    }

    &__row {
      display: flex;

      &--body {
        @include scaleToExtraSmall() {
          display: block;
          height: 70px;
          line-height: 70px;
        }
      }
    }

    &__header {
      padding: 22px 0;
    }

    &__actions {
      display: flex;
      justify-content: space-between;

      &--left {
        display: inline-flex;
        align-items: center;
      }

      .planning-navigation {
        left: -32px;
      }

      &-left {
        width: 100%;
      }

      @include scaleToExtraSmall() {
        padding: 0 10px;
      }
    }

    &__periodicity {
      display: flex;
      justify-content: space-between;
      height: 32px;
      align-items: center;
      font-size: 14px;

      span:not(:first-child) {
        margin-left: 5px;
      }
    }

    &__loader {
      text-align: center;
      padding: 24px;

      &--icon {
        vertical-align: middle;
      }
    }

    &__button:not(.disabled) {
      cursor: pointer;
    }

    &__feedback {
      color: $bright-sky-blue;
      font-size: 12px;
    }

    &__archived {
      font-size: 12px;
      color: $dusty-gray;
      margin: auto;
    }

    &__cell {
      &-spacer {
        flex: 1;
      }

      &-options {
        padding-left: 0 !important;
        padding-right: 0;
        width: 5%;
        height: 100%;
        display: inline-flex;
        align-items: center;
        justify-content: space-between;

        &-icon {
          margin: auto;
        }

        @include scaleToExtraSmall() {
          width: 13%;
        }
      }

      &-project {
        flex: 3;
        max-width: 360px;
        position: relative;
        background: inherit;

        &:hover {
          overflow: initial;

          .timesheet-weekly__multirow-info {
            position: absolute;
            z-index: 1;
            box-shadow: 0 0 15px 0 rgba(155, 155, 155, 0.3);
            height: 80%;
            max-width: 650px;
            margin: 5px;
            padding: 0 8px 0;
            justify-content: space-evenly;
            line-height: 100%;
          }
        }

        @include scaleToExtraSmall() {
          width: 40%;
          height: 100%;
          flex: unset;
        }
      }

      &-job-order {
        flex: 2;
        max-width: 270px;
      }

      &-notes {
        flex: 1;
        height: 100%;
        display: inline-flex;
        align-items: center;
      }

      &-automatic {
        flex: 1;
        max-width: 120px;
        text-align: center;

        @include scaleToExtraSmall() {
          width: 17%;
        }
      }

      &-total {
        width: 15%;
        text-align: center;

        @include scaleToExtraSmall() {
          width: 30%;
        }

        &--content {
          height: 100%;
          position: relative;
        }

        &--feedback {
          width: 100%;
          font-size: 10px;
          line-height: 10px;
          position: absolute;
          bottom: 2px;
        }

        &--mobile {
          display: none;
          @include scaleToExtraSmall() {
            display: inline-block;
          }
        }
      }

      &-day {
        width: 7%;
        text-align: center;

        .wethod-input {
          width: 100% !important;
        }
      }

      &-hours {
        .wethod-input {
          width: 55%;
          margin: auto;
          padding: 4px 5px;

          input {
            font-size: inherit;
            text-align: center;
          }

          @include scaleToExtraSmall() {
            width: 75%;
            line-height: 30px;
          }
        }

        &--auto {
          margin-top: 8px;
          line-height: normal;
          white-space: pre-wrap;
          font-size: 12px;
          font-style: italic;

          .tooltipReact {
            top: 4px;
            right: 4px;
            width: 350px;
            line-height: 1.5;
            white-space: pre-wrap;
            @include scaleToExtraSmall() {
              right: 0;
            }
          }
        }

        &--holiday {
          .wethod-input {
            border: 1px solid $sunset-orange;
          }
        }
      }
    }

    &__summary {
      border-top: 1px solid $concrete-gray;
      display: flex;
      justify-content: space-between;
      align-items: center;
      line-height: 1;
      height: 22px;
      font-size: 12px;

      @include scaleToExtraSmall() {
        font-size: 14px;
        height: 40px;
      }
    }

    &__color-bar {
      height: 100%;
      width: 4px;

      @include scaleToExtraSmall() {
        width: 8px;
      }
    }

    &__day-select {
      display: none;

      @include scaleToExtraSmall() {
        margin-bottom: 20px;
        display: flex;
        justify-content: space-between;
      }
    }

    &__day {
      display: flex;
      flex-direction: column;
      align-items: center;
      flex: 1;
      text-transform: capitalize;
      color: $dusty-gray;

      & * {
        margin: 2px;
      }

      &-stamp {
        width: 4px;
        height: 4px;
        border-radius: 50%;
        background-color: transparent;

        &--completed {
          background-color: $black;
        }
      }

      button {
        font-size: 14px;
        line-height: 25px;
        max-width: 48px;
        width: 85%;
        outline: none;
        background-color: transparent;
        border: none;
        color: inherit;
        border-radius: 4px;
      }

      &--selected {
        color: $black;

        button {
          color: $white;
          background-color: $black;
        }
      }
    }

    &__multirow-info {
      display: flex;
      flex-direction: column;
      justify-content: center;
      line-height: 1.5;
      height: 100%;
      background: inherit;

      &-row {
        overflow: hidden;
        text-overflow: ellipsis;

        &-secondary {
          font-size: 12px;
          color: $dusty-gray;
        }
      }
    }

    &__modal {
      .wethod-modal2__container {
        min-width: 500px;
        font-size: 14px;
        line-height: 20px;
      }

      .timesheet-note__input {
        margin-bottom: 24px;
        font-size: inherit;
      }

      &-actions {
        display: block;
        text-align: right;

        & > :not(:last-child) {
          margin-right: 16px;
        }
      }

      &--delete {
        margin-bottom: 36px;
        line-height: 20px;
      }
    }
  }

  &-detailed {
    .timesheet__actions {
      padding: 16px 0 24px 0;
    }

    &__bu-select {
      margin-left: 16px;
      z-index: 3;
    }

    &__weekly-hours-modal {
      &-wrapper {
        .modal__actions {
          margin: 20px 0;
        }
      }

      &-row {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 12px 0;
      }

      &-value {
        font-size: 14px;
        font-weight: $semiBold;
      }
    }

    &__actions {
      &-container {
        display: flex;
        justify-content: space-between;
        @include scaleToExtraSmall() {
          display: block;
          padding: 0 10px;
        }

        &--left {
          display: flex;
          align-items: center;
          @include scaleToExtraSmall() {
            justify-content: space-between;
          }

          .date-selector {
            margin-left: -64px;
            @include scaleToExtraSmall() {
              margin-left: -32px;
            }
          }
        }

        &--right {
          display: flex;
          align-items: center;
          @include scaleToExtraSmall() {
            justify-content: space-between;
            margin-top: 16px;
          }
        }
      }
    }

    &-counter {
      border-radius: 4px;
      padding: 6px 22px;
      background: $white;
      border: 1px solid $bright-sky-blue;
      margin-left: 16px;
      display: flex;
      align-items: center;
      position: relative;

      .tooltipReact {
        display: none;
      }

      @include scaleToExtraSmall() {
        margin-left: 0;
        padding: 6px 12px;
      }

      &--clickable {
        transition: 0.3s;
        cursor: pointer;

        &:hover {
          background: rgba(72, 165, 249, 0.3);

          .tooltipReact {
            display: block;
          }
        }
      }

      &__label {
        font-size: 12px;
        line-height: 16px;
      }

      &__value {
        font-size: 20px;
        line-height: 27px;
        margin-left: 8px;
        @include scaleToExtraSmall() {
          font-size: 18px;
          line-height: 25px;
        }
      }
    }

    &__automatic-label {
      position: relative;
      height: 60px;
      line-height: 60px;
      text-align: center;
      font-style: italic;

      &-info-icon {
        display: inline-block;
        vertical-align: text-bottom;
        margin-left: 8px;
      }

      .tooltipReact {
        width: 300px;
        line-height: 1.5;
        white-space: pre-wrap;
        top: -12px;
        left: 50%;
        @include scaleToExtraSmall() {
          right: 12px;
          left: unset;
        }
      }
    }

    &__cannot-edit {
      height: 60px;
      background-color: $sunset-orange;
      display: flex;
      flex-direction: column;
      justify-content: center;

      .info-text {
        position: relative;
        left: 5%;

        & p {
          padding: 6px;
        }

        & span {
          padding: 6px;
        }
      }
    }
  }

  &__tag {
    border: 1px solid $black;
    border-radius: 4px;
    font-size: 10px;
    line-height: 10px;
    text-transform: uppercase;
    padding: 4px;
  }

  &__tooltip {
    max-width: 300px;
    line-height: 1.5;
    white-space: pre-wrap;
    top: 20%;
    left: 33%;
  }
}
