.desk {
  padding-bottom: 100px;
  @include scaleToExtraSmall() {
    padding: 0 16px;
  }

  .section-header {
    padding-top: 16px;
  }

  &-widgets {
    display: flex;
    justify-content: space-between;
    margin-bottom: 24px;
    @include scaleToExtraSmall() {
      display: block;
    }

    .wethod-widget:not(:last-child) {
      margin-bottom: 24px;
    }

    &-left {
      width: 65%;
      @include scaleToExtraSmall() {
        display: none;
      }
    }

    &-right {
      width: 33%;
      @include scaleToExtraSmall() {
        width: 100%;
      }
    }
  }

  &-widget {
    &--coming-soon {
      opacity: 0.6;
      @include scaleToExtraSmall() {
        display: none;
      }
    }

    &__item {
      height: 56px;
      padding: 0 16px;
      border-bottom: 1px solid $concrete-gray;
      font-size: 14px;
      line-height: initial;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    &__multirow-info {
      display: flex;
      flex-direction: column;
      line-height: 1.5;

      &--secondary {
        font-size: 12px;
        color: $dusty-gray;
      }
    }

    &__body {
      overflow: hidden;

      &--empty-text {
        font-size: 14px;
        color: $dusty-gray;
        padding: 20px;
        text-align: center;
      }

      &--list {
        overflow: auto;
        flex: 1;
      }
    }

    &__text {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      padding-right: 20px;
    }

    &-activities {
      &__body {
        height: 410px;
      }

      &__subheader {
        height: 30px;
        justify-content: initial;
        font-weight: $semiBold;
      }

      &__name {
        width: 243px;
      }

      &__project {
        width: 200px;
      }

      &__link {
        color: initial !important;
      }
    }

    &-empty {
      justify-content: center;
      flex-direction: column;
      text-align: center;

      &--activities {
        max-width: 60%;
        margin: auto;
      }

      &__image {
        margin-bottom: 12px;
      }

      &__text {
        &-title {
          font-size: 14px;
          line-height: 20px;
        }

        &-description {
          color: $dusty-gray;
          font-size: 11px;
          line-height: 16px;
          margin-bottom: 12px;
        }

        &-action {
          font-size: 14px;
          line-height: 20px;
        }
      }

      &__activities {
        background-image: url("../../../img/empty-states/activities.svg");
        width: 150px;
        height: 100px;
        background-repeat: no-repeat;
        margin-right: 32px;
      }

      &__pinboard {
        &-title {
          line-height: 10px;
          margin-bottom: 12px;
          width: 80%;
        }

        &-bookmark {
          opacity: 0.3;
          display: inline-block;
          vertical-align: middle;
        }

        &-description {
          width: 60%;
        }

        &--projects {
          background-image: url("../../../img/empty-states/pinboard-projects.svg");
          width: 110px;
          height: 100px;
          background-repeat: no-repeat;
          margin: 0 32px;
        }

        &--clients {
          background-image: url("../../../img/empty-states/pinboard-clients.svg");
          width: 110px;
          height: 100px;
          background-repeat: no-repeat;
          margin: 0 32px;
        }

        &--researches {
          background-image: url("../../../img/empty-states/pinboard-researches.svg");
          width: 110px;
          height: 100px;
          background-repeat: no-repeat;
          margin: 0 32px;
        }
      }
    }

    &-pinboard {
      &__tab {
        .wethod-button {
          margin: auto 5px;
          font-size: 14px;
          word-spacing: 8px;
        }
      }

      &__body {
        height: 410px;
      }

      &__item {
        &:hover {
          .desk-widget-pinboard__actions {
            opacity: 1;
          }
        }

        &--project {
          padding-left: 0;
        }
      }

      &__color-bar {
        height: 100%;
        width: 4px;
        margin-right: 16px;
      }

      &__info {
        flex: 1;
      }

      &__input {
        flex: 1;
        padding-right: 12px;

        .wethod-input {
          background-color: transparent;

          &:hover {
            background-color: #F2F3F5;
          }
        }

        &--focused {
          .wethod-input {
            background-color: #F2F3F5;
          }
        }
      }

      &__actions {
        display: flex;
        flex-direction: row;
        opacity: 0;
        transition: 0.3s;

        .wethod-icon-button {
          margin: auto 8px;
          width: 32px;
          height: 32px;

          &:hover:not(:last-child) {
            .wethod-icon {
              background-position-x: -64px;
            }
          }
        }

        &--bookmark {
          &:hover {
            background-color: transparent;

            .wethod-icon {
              background-position-x: 0;
            }
          }
        }
      }

      &__vertical-bar {
        border-left: 1px solid $concrete-gray;
      }

      &__pic {
        margin-right: 32px;

        .wethod-profile-pic {
          width: 32px;
          height: 32px;
          line-height: 32px;
        }
      }
    }

    &-timeline {
      &__body {
        height: 580px;
      }
    }
  }
}
