.financeInvoice {
  .recordList__record {
    &--no-permissions {
      & > *:not(.financeInvoice__delete) {
        opacity: 0.7;
      }
    }
  }

  &__more {
    background: $white;
    position: absolute;
    z-index: 2;
    right: 34px;
    padding: 8px 16px;
    line-height: normal;
    top: 50px;
    font-size: 12px;
    color: $steel-gray;
    font-weight: $semiBold;
    border-radius: 3px;
    text-transform: uppercase;
    box-shadow: 0 1px 7px 0 rgba(155, 155, 155, 0.5);
    display: none;

    &-item {
      white-space: pre;
      cursor: pointer;
      transition: .2s;
      padding: 8px 0;

      &:hover {
        color: $bright-sky-blue;
      }
    }
  }

  .financeInvoice__fixedContent {
    top: 0;
    position: fixed;
    z-index: 750;
    width: 100%;
    float: left;
    background: $alabaster-white;
    margin-top: 2px;
  }

  .financeInvoice__fixedContentPush {
    clear: both;
  }

  .financeInvoice__associatedResource {
    height: 150px;
    overflow: hidden;

    li {
      margin: 2px 0;
    }
  }

  .financeInvoice__frozenData {
    position:absolute;
    bottom:3px;
    right:93px;
    font-size:10px;
    display:none;
  }

  .financeInvoice__project {
    @include span(23 of 100);

    &-container {
      line-height: 59px;
      margin-top: 6px;
    }

    &-content {
      height: initial !important;
      line-height: inherit !important;
    }

    &-info {
      display: block;
      height: initial !important;
      line-height: initial;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;

      &--client {
        font-weight: 100;
      }

      &--jo {
        color: #999;
        font-size: 10px;
      }
    }

    &--editable {
      &:hover {
        background-color: rgba(158, 158, 158, 0.2);
        cursor: text;
      }

      &.financeInvoice__project-container {
        margin-top: 0;
        height: 100%;
      }

      .financeInvoice__project {
        &-content {
          vertical-align: middle;
          display: inline-block;
          width: 100%;
        }

        &-info {
          background: rgba(0, 0, 0, 0) !important;

          &:hover {
            background: rgba(0, 0, 0, 0) !important;
          }

          &--client {
            padding-left: 20px;
          }

          &--jo {
            padding-left: 20px;
          }
        }
      }
    }

    .recordList__record {
      &__hints {
        top: 4px;
        left: 12px;
      }

      &__feedBack {
        background: $white;
        line-height: initial;
        padding: 3px 6px;
        width: 20%;
      }
    }
  }

  .financeInvoice__area {
    @include span(12 of 100);
  }

  .financeInvoice__invoiceNumber {
    @include span(9 of 100);
    position: relative;

    .financeInvoice__invoiceCurrency-change {
      left: 6px;
    }
  }

  .financeInvoice__pm {
    @include span(12 of 100);
    &-text{
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .financeInvoice__invoiceValue {
    @include span(9 of 100);
    text-align: right;
    position: relative;

    .mdl-textfield__label {
      text-align: right;
    }
  }

  .financeInvoice__invoiceCurrency {
    @include span(4 of 100);

    &-acronym {
      font-size: 10px;
      text-align: left;
    }

    &-change {
      color: #999;
      font-size: 10px;
      position: absolute;
      bottom: 7px;
      right: 6px;
    }
  }

  .financeInvoice__date {
    @include span(9 of 100);
    text-align: center;

    span, input {
      text-align: center;
    }
  }

  .financeInvoice__note {
    @include span(4.5 of 100);
    text-align: center;

    .financeInvoice__note__button {
      display: block;
      width: 30px;
      height: 30px;
      margin: 15px auto;

      .financeInvoice__note__button__emptyIcon {
        width: 30px;
        height: 30px;
        @include retina-image($iconsPath + note_icon, 30px 30px);
      }

      .financeInvoice__note__button__filledIcon {
        width: 30px;
        height: 30px;
        @include retina-image($iconsPath + note_filled_icon, 30px 30px);
      }
    }
  }

  .financeInvoice__button {
    @include span(11 of 100);

    .financeInvoice__button__save {
      display: none;
      margin-top: 15px;
    }

    .financeInvoice__button__send {
      margin-top: 15px;
    }

    .financeInvoice__button__insert {
      margin-top: 15px;
    }
  }

  .financeInvoice__delete {
    @include span(6.5 of 100);

    .financeInvoice__delete__button {
      float: right;
      width: 30px;
      height: 30px;
      margin: 15px 20px 0 0;

      &.financeInvoice__delete__button--disabled {
        opacity: 0.5;
        cursor: default;
      }

      .financeInvoice__delete__button__icon {
        width: 30px;
        height: 30px;
        @include retina-image($iconsPath + delete_icon, 30px 30px);
      }
    }
  }

  .messageModal--exchange-rate {
    .wethod-input {
      border: none;
      width: 50%;
      margin: 0 8px;
      text-align: center;
      margin-bottom: 10px;
    }
  }
  .messageModal--edit-details {
    .wethod-input {
      margin: 10px 8px 0;
      border: none;
      outline: none;
      width: 95%;
    }

    &-row {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: 8px;
      padding: 4px 12px;

      &-label {
        width: 100%;
      }

      .wethod-input {
        margin: 0;
      }
    }
    &-comment {
      color: $dusty-gray;
    }
  }

  &__files-body {
    max-height: 60vh;
    overflow: auto;
    .wethod-sidebar__subtitle {
      justify-content: end;
    }
  }
}

.invoices__actions {
  padding-top: 20px;
}
