//SALE FUNNEL
.salesFunnelHead,.widgetInvoiceHead,.alertOverviewHead{
	position: relative;
	width: 100%;
	height:39px;
	.title{
		width: 100%;
		padding: 17px 45px 0 20px;
		color: #878B8E;
		font-size: 12px;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
		@include transition( 0.2s ease-in-out);
		.settings{
			display: none;
		}
		&:hover{
			.settings{
				display: block;
			}
		}
	}
}
.salesFunnelChart{
	position: relative;
	width: 335px;
	height: 228px;
	border-bottom: 1px solid #EBEEF1;

	.tooltipLabel{
		font-size: 10px;
		line-height: 1;
	}
	.tooltipPercentage{
		font-size: 20px;
		line-height: 1;
		font-weight: $light;
	}
}
.salesFunnelSettings{
	display: none;
	z-index: 100;
	position: absolute;
	top: 0;
	left:0;
	width: 100%;
	height: 100%;
	background: rgba( 72, 165, 244, .9);

	&.isVisible{
		z-index: 500 !important;
	}

	.title{
		padding: 17px 20px 0;
		color: $white;
		font-size: 12px;
	}

	.settingsTooltip{
		z-index: 999 !important;
		display: none;
		position: absolute;
		top: 42px;
    	left: 12px;
		width: 90%;
		max-height: 99px;

		overflow: hidden;
		overflow-y: scroll;

		&::-webkit-scrollbar {
		    width: 7px;
		}
		&::-webkit-scrollbar-track {
			width: 7px;
		    -webkit-box-shadow: inset 0 0 6px rgba(255,255,255,0);
		}
		&::-webkit-scrollbar-thumb {
			border-radius: 4px;
			background-color: rgba(0, 0, 0, .2);
		}

		outline: 5px solid rgba(72, 165, 244, 0.3);
		background: $white;

		.list{
			li{
				a{
					display: block;
					padding: 12px;
					color: #878B8E;
					text-transform: capitalize;
					&:hover{
						color: $bright-sky-blue;
					}
				}
			}
		}
	}

	.salesFunnelSettingsBody{
		padding: 38px;
    height: 265px;
    overflow-y: auto;

		&.fullSize{
			padding: 15px 38px;
		}

		.label{
			color:$white;
			text-transform: uppercase;
			font-size: 10px;
			margin-bottom: 8px;
		}

		.message {
			color:$white;
			font-size: 11px;
			margin-bottom: 16px;
			line-height: 16px;
			a {
				color: $white;
				text-decoration: underline;
			}
		}

		.settingsTargetInput{
			position: relative;
			margin-bottom: 21px;

			.unit{
				display: none;
				position: absolute;
				top: 11px;
				right:8px;
				color:$white;
				font-size: 25px;
				font-weight: $light;
				@include transition( 0.2s ease-in-out);
			}

			//MDL
			$labelColor      : $white;
			.mdl-textfield{
			    font-size: $baseFont !important;
			    width: 100%;
			    padding: 0;

			    .mdl-textfield__input{
			        padding: 6px 0 !important;
			        font-size: 25px !important;
					font-weight: $light;
					color: $white;
					border-color:$white !important;
					&.error{
						border-color: $raspberry-red;
					}
			    }
			    .mdl-textfield__label{
			        height: 16px;
			        top: 16px;
			        line-height: 1.3;

			        display: block;
			        font-size: 10px;
					color:$white !important;

					&.error{
						color: $raspberry-red !important;
					}

			        &::after{
			            display: none;
			        }
			    }

			    &.mdl-textfield--floating-label.is-focused,
			    &.mdl-textfield--floating-label.is-dirty  {
					.unit{
						display: block;
					}
					.mdl-textfield__label{
						top: -10px;
				        color: $white !important;
				        font-size: 10px !important;

						&.error{
							color: $raspberry-red !important;
						}
					}
			    }
			}
		}

		.settingsHintInput{
			display: none;
			position: relative;
			margin-top: 21px;

			//MDL
			$labelColor      : $white;
			.mdl-textfield{
			    font-size: $baseFont !important;
			    width: 100%;
			    padding: 0;

			    .mdl-textfield__input{
			        padding: 6px 0 !important;
			        font-size: 12px !important;
					color: $white;
					border-color:$white !important;
					&.error{
						border-color: $raspberry-red;
					}
			    }
			    .mdl-textfield__label{
			        height: 16px;
			        top: 5px;
			        line-height: 1.3;

			        display: block;
			        font-size: 10px;
					color:$white !important;

					&.error{
						color: $raspberry-red !important;
					}

			        &::after{
			            display: none;
			        }
			    }

			    &.mdl-textfield--floating-label.is-focused .mdl-textfield__label,
			    &.mdl-textfield--floating-label.is-dirty .mdl-textfield__label {
			        top: -10px;
			        color: $white !important;
			        font-size: 10px !important;

					&.error{
						color: $raspberry-red !important;
					}
			    }
			}
		}



	}

	// &::before{
	// 	content: "";
	// 	z-index: 90;
	// 	position: absolute;
	// 	top: 0;
	// 	left:0;
	// 	width: 100%;
	// 	height: 100%;
	// 	@include filter( blur( 5px ) );
	// 	//background: url('../img/widget.png');
	// }
}

.searchFeedback{
	padding: 12px;
	color: #878B8E;
	text-transform: capitalize;
}

.salesFunnelInfo{
	width: 100%;
	height: 68px;

	.infoBox{
		display: block;
		float: left;
		width: 33.3333%;
		height: 100%;
		&.center{
			border-left: 1px solid #EBEEF1;
			border-right: 1px solid #EBEEF1;
		}

		.label,.value{
			color: #878B8E;
		}
		.label{
			padding: 8px;
			font-size: 10px;
			&.blue{
				color:$bright-sky-blue;
			}
			&.green{
				color:$garden-green;
			}
		}
		.value{
			width: 100%;
			margin: 6px 0px;
			text-align: center;
			font-size: 18px;
			line-height: 1;
			font-weight: $light;
		}
	}
}
