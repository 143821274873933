//INVOICE
.invoicesList{
	overflow: hidden;
	overflow-y: scroll;

	display: block;
	height: 276px;

	// &::-webkit-scrollbar {
	// 	width: 7px;
	// }
	&::-webkit-scrollbar-track {
		width: 7px;
		-webkit-box-shadow: inset 0 0 6px rgba(255,255,255,0);
	}
	&::-webkit-scrollbar-thumb {
		border-radius: 4px;
		background-color: rgba(0, 0, 0, .2);
	}

	li{
		display: block;
		width: 100%;
		height: 60px;
		border-bottom: 1px solid #EBEEF1;
		color: #333333;

		.widgetMoreAction{
			display: none;
			z-index: 100;
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 59px;
			background: $white;
		}
		.widgetMonth{
			@extend .table;
			width: 100%;
			text-align: center !important;
			text-transform: uppercase;
			color: #878B8E;
			font-weight: $light;
		}

		.statusInfo{
			width: 12px;
			height: 12px;
			margin: 23px 0 0 15px;
			border-radius: 50%;

			&.red{
				background: $raspberry-red;
			}
			&.orange{
				background: $sunset-orange;
			}
		}

		.widgetWrapInvoice{
			height: 59px;
			cursor: pointer;

			&.notInvoiceable{
				cursor: default;

				&:hover{
					background: $maldivian-sand-white;

					.widgetMoreAction{
						display: block;
						background: $maldivian-sand-white;
					}
				}
			}

			&:hover, &.selectInvoice{
				background: $maldivian-sand-white;

				.widgetMoreAction{
					display: block;
					background: $maldivian-sand-white;
				}
			}

			&.selectInvoice{
				background: rgba(72,165,244, 1);
				color: $white;
				.info, .projectName, .widgetMonth{
					color: $white;
				}
				.widgetMoreAction{
					display: none;
				}
			}
		}

		.table{
			width: 100%;
			height: 60px;
		}

		.tableIcon{
			@extend .table;
			float: right;
			width: 30px;
			height: 60px;
			margin-right: 8px;
			text-align: center;
		}

		.invoiceAmout{
			text-align: right;
			font-weight: $semiBold;
		}

		.info, .projectName{
			display: inline-block;
			width: 100%;
		}

		.info{
			margin-top: 14px;
			text-transform: uppercase;
			font-size: 10px;
			color: #878B8E;
			font-weight: $light;
		}
		.invoiceInfo{
			width: 126px;
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
		}
		.projectName{
			width: 126px;
			margin-top: 2px;
			font-size: 12px;
			font-weight: $semiBold;
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
			line-height: 1.3;
		}



		&.noInvoice{
			height: 276px;
			border: none;
			margin-bottom: 0 !important;
			.table{
				width: 100%;
				height: 276px;
				text-align: center;
				text-transform: uppercase;
				font-size: 12px;
				color: #878B8E;
			}
		}
	}

	li:last-child {
        margin-bottom: 48px;
    }

}

.widgetInvoiceFooter{
	display: none;
	position: absolute;
	bottom: 9px;
	left: 0;
	width: 100%;
	height: 51px;
	background: rgba(255, 255, 255, .9);

	&.widgetInvoiceFooter{
		z-index: 1;
	}

	.totalPlanned, .totalInvoiced{
		color: #878B8E;
		font-size: 18px;
	}
	.totalsWrap{
		float: right;
		margin: 15px 23px 0 0;
	}
	.totalPlanned{
		font-weight: $light;
	}
	.totalInvoiced{
		font-weight: $bold;
	}

	.multipleInvoiceButton{
		margin: 14px 0 0 15px;
	}

	.multipleInvoiceLabel{
		position: absolute;
		top: 24px;
		left: 55px;
		color: $bright-sky-blue;
		font-size: 12px;
    	text-transform: uppercase;
	}

}
