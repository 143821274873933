.widget-reviews {
  &__item {
    list-style: none;
    border-bottom: 1px solid $concrete-gray;
    font-size: 14px;
    position: relative;
    background:$white;
    cursor: pointer;
    overflow: hidden;

    &--sliding {
      width: calc(100% + 130px);
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: $bright-sky-blue;
      transition: .3s;

      &:hover {
        transform: translateX(-130px);
      }
    }

    &-review {
      width: 100%;
      display: flex;
      align-items: center;
      background:$white;
      padding: 10px 16px;
    }

    &-project {
      width: 80%;
      line-height: 1.5;

      &-name {
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
      }

      &-date {
        color: $dusty-gray;
        font-size: 12px;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
      }
    }

    &-actions {
      width: 130px;
      padding: 10px 15px;
      font-size: 12px;

      .wethod-button {
        color:$white;
        border-color:$white;
        white-space: nowrap;
        padding: 0 15px;

        &:hover {
          color: $bright-sky-blue;
          border-color: $bright-sky-blue;
          background-color:$white;
        }
      }

      a {
        display: block;
      }
    }

    &-month {
      color: #CCCBCC;
      width: 20%;
      text-transform: uppercase;
      text-align: center;
      font-weight: $light;
    }
  }
}
