.rule-wrapper {
  background-color: $white;
  min-height: 100%;
  height: auto;
  padding-bottom: 80px;
}

.rule-wrapper__title {
  font-weight: $light;
  font-size: 30px;
  margin: 30px 0;
  color: $bright-sky-blue;
  display: inline-block;
  text-align: center;
}

.rule {
  margin: 30px 0;
  clear: both;
}

.rule__title {
  display: inline-block;
  color: $bright-sky-blue;
  font-size: 18px;
  margin-top: 40px;
}

.rule__name {
  position: relative;
}

.rule__if {
  text-align: center;
  display: inline-block;
  margin: 9px 0;
}

.condition-wrapper, .terminal-wrapper {
  border: 1px solid #CCCCCC;
  padding: 8px;
  &:hover {
    border-color: #91949f;
  }
}

.rule-section__rule-conditions {
  & > .condition-wrapper:first-child {
    border: none;
    padding: 0px;
  }
}

.absolute-wrapper {
  margin: auto;
  position: relative;
}

.terminal-wrapper {
  .rule-button-wrapper, .rule-select-wrapper {
    float: none;
  }
  .rule-condition {
    float: none;
    display: inline-block;
  }
}

.terminal-components-wrapper {
  text-align: center;
  .operator, .left, .right {
    display: inline;
  }
}

.tooltip.tooltipSelect {
  min-width: initial;
}

.rule-button-wrapper {
  position: relative;
  margin: auto;
  padding-top: 0px;
}

.rule-select-wrapper {
  position: relative;
  margin: auto;
}

.rule-condition {
  position: relative;
}

.rule-condition__input {
  height: 28px;
  margin: 0 3px;
  border: 1px solid $bright-sky-blue;
  text-align: center;
  padding: 3px;
}

.operator {
  margin: 8px auto;
}

.rule-manager {
  &__body {
    margin-top: 24px !important;
  }
}
