$graphBoxBackground: $white;

.threeYPSectionTitle {
  font-weight: $semiBold;
  color: $dusty-gray;
  margin-top: 24px;
  margin-bottom: 4px;
  line-height: 30px;
  @include scaleToSmall() {
    text-align: center;
  }
}

.graphBox {
  float: left;
  width: 100%;
  height: 400px;
  background: $graphBoxBackground;
  border: 1px solid $concrete-gray;
}

.threeYPHead {
  margin: 15px auto !important;

  .threeYPHeadTitle {
    font-weight: $bold;
    font-size: 30px;
    @include scaleToSmall() {
      text-align: center;
    }
    @include scaleToExtraSmall() {
      text-align: center;
    }
  }
}

.threeYPDetailYear {
  font-weight: $bold;
}

.threeYPColumnGraphContainer {
  height: 400px;
  border: 1px solid $concrete-gray;
}

.reportDetailWrapLabel {
  @include scaleToSmall() {
    margin-top: 7px;
  }
  @include scaleToExtraSmall() {
    margin-top: 7px;
  }
}

.threeYPDetailTitle {
  font-weight: $bold;
  color: $bright-sky-blue;
  text-transform: uppercase;

  .table {
    width: 100%;
    height: 40px;
  }
}

.reportDetailYear {
  font-size: 37px;
  color: rgba(0, 0, 0, .87) !important;
}


.threeYPDetail {
  display: none;
}

.threeYPDetailYearHead {
  background: $morning-fog-gray;
  color: $black;

  .table {
    width: 100%;
    height: 30px;
  }
}

.threeYPDetailYearTotal {
  font-weight: $bold;

  .highlight {
    color: $bright-sky-blue;
  }

  .table {
    width: 100%;
    height: 30px;
    padding: $gutter $gutter*2;
  }
}

.detailList {
  max-height: 250px;
  overflow: hidden;
  overflow-y: scroll;
  line-height: 1.3;

  &::-webkit-scrollbar {
    width: 7px;
  }

  &::-webkit-scrollbar-track {
    width: 7px;
    -webkit-box-shadow: inset 0 0 6px rgba(255, 255, 255, 0);
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: rgba(0, 0, 0, .2);
  }

  li {
    .table {
      width: 100%;
      height: 30px;
    }

    float: left;
    width: 100%;
    height: 37px;
    border-bottom: 1px solid #ebeef1;
  }
}

.detailListFull {
  @extend .detailList;
  height: 300px;
  max-height: 300px;
}

.threeYP {
  &__last-section {
    margin-bottom: 50px;
  }
}

.paddingBottom {
  padding-bottom: 100px;
}

.detailListMetadataEmpty {
  @extend .detailListFull;
  position: relative;

  &--message {
    top: 50%;
    left: 50%;
    position: absolute;
    transform: translate(-50%, -50%);
    font-size: 20px;
    text-align: center;
  }
}

.threeYPSectionMetadata {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 24px;
  margin-bottom: 4px;
  //flex-wrap: wrap;

  @include scaleToSmall() {
    align-items: center;
    align-content: center;
    flex-wrap: wrap;
    justify-content: center;
    width: 100% !important;
  }

  @include scaleToExtraSmall() {
    align-items: center;
    align-content: center;
    flex-wrap: wrap;
    justify-content: center;
    width: 100% !important;
  }

  &-title {
    font-weight: $semiBold;
    color: $dusty-gray;
    line-height: 30px;
    @include scaleToSmall() {
      text-align: center;
    }

    @include scaleToExtraSmall() {
      text-align: center;
    }
  }

  &-buttons {
    display: flex;
    justify-content: space-between;
    align-items: center;

    &__first {
      margin: 0 4px 0 0;

      @include scaleToSmall() {
        margin: 0 4px 0 8px;
      }

      @include scaleToExtraSmall() {
        margin: 0 4px 0 8px;
      }
    }
  }
}

.disabled-metadata {
  @extend .disabled;
  pointer-events: none;
  cursor: pointer;
}

.budget-breakdown {
  &__row {
    height: auto !important;
    border-bottom: none !important;

    &-header {
      border-bottom: 1px solid #ebeef1;

      .button {
        text-transform: capitalize;
        line-height: 24px;
        padding: 0 16px;
        margin-left: 8px;
      }

      &-right {
        display: flex;
        justify-content: flex-end;
      }
    }

    &-content {
      &-item {
        border-bottom: 1px solid #ebeef1;
        line-height: 37px;

        &-label {
          color: rgba(0, 0, 0, 0.5);
        }
      }
    }
  }
}
