.companyMetadata {
  @extend .pageSection;

  .companyMetadata__header__title {
    color: $linkColor;
    font-size: 18px
  }

  .companyMetadata__header_row {
    border-bottom: solid 1px #bfc0c6;

    .roundButton {
      margin: 8px 0
    }

    span {
      display: inline-block;
      padding: 18px 5px;
    }
  }

  .companyMetadata__row {
    border-bottom: solid 1px #bfc0c6;
    overflow: hidden;

    .companyMetadataInput {
      display: inline-block;
      margin: 11px 0;
      padding: 5px;
      border: none;
      background-color: inherit;
      font-size: 12px;
      outline: none;
      width: 100%;
    }

    .companyMetadataSpan {
      display: inline-block;
      padding: 19px 5px 18px 5px;
    }

    .roundButton {
      margin: 8px 0
    }
  }

  .companyMetadataCheck {
    margin-top: 17px;
    font-size: 20px;
  }
}

.company__timesheet-option-description {
  opacity: 0.5;
}