.orders .wethod-sidebar__body {
  padding: 0;
}

.orders {
  max-width: 1440px !important;
  padding: 0 24px;

  @include scaleToExtraSmall() {
    max-width: 1024px !important;
    padding: 0;
  }

  &__sidebar-body {
    padding: 11px 10px;
  }

  .wethod-section-actions {
    padding: 16px 0;
  }

  &__column {
    &-project {
      width: 16%;
      height: 100%;
    }

    &-area {
      width: 8%;
    }

    &-supplier, &-period {
      width: 11%;
    }

    &-date {
      width: 9%;
    }

    &-code, &-value {
      width: 9%;
      text-align: right;
      height: 100%;
    }

    &-status {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      height: 100%;
      width: 9%;

      .wethod-table__cell-icon-sort {
        margin-left: 0 !important;
      }
    }

    &-actions {
      width: 4.3%;
      text-overflow: unset;
    }
  }

  &__multirow-info {
    display: flex;
    flex-direction: column;
    justify-content: center;
    line-height: 1.5;
    height: 100%;

    &-row {
      overflow: hidden;
      text-overflow: ellipsis;

      &--secondary {
        font-size: 12px;
        color: $dusty-gray;
      }
    }
  }

  &__supplier-item {
    display: flex;
    justify-content: space-between;

    *:first-child {
      color: $dusty-gray;
    }
  }

  &__multicurrency {
    &-row {
      position: relative;
      align-items: center !important;

      .material-input__input {
        padding-bottom: 14px;
      }

      .material-input__helper-text {
        position: absolute;
      }
    }

    &-button {
      margin-left: 10px;

      * {
        color: initial;
        text-align: left;
      }
    }

    &-rate {
      &-box {
        margin: 0;
      }

      &-row {
        display: flex;
        align-items: center;

        .material-input {
          max-width: 150px;
          margin-right: 10px;

          input {
            text-align: right;
          }
        }
      }
    }
  }

  &__add-button {
    float: right;
    line-height: 30px;
  }

  &__secondary-info {
    font-size: 12px;
    color: $dusty-gray;
  }

  &__actions {
    display: flex;
    justify-content: space-between;
    align-items: baseline;

    .wethod-export-button {
      @include scaleToExtraSmall() {
        visibility: hidden;
      }
      margin: 0 32px 0 0;
    }
  }

  &__export-modal {
    &--radio-group {
      &label:last-child {
        margin-top: 17px;
      }
    }

    &--date-select {
      display: flex;
      margin-left: 30px;

      .material-input {
        width: inherit !important;
        margin-right: inherit !important;
      }

      .material-input .material-input__input {
        margin: 0 10px !important;
        width: 120px;

        .material-input-button {
          background-color: $white;
        }

        .wethod-date-picker .material-input-button {
          background-color: $white;
        }
      }
    }

    &--feedback {
      position: absolute;
      top: 485px;
    }

    .wethod-modal2__container {
      display: flex;
      flex-direction: column;
      width: 449px;
      height: 328px;
      max-height: 328px;
    }

    .wethod-modal2__header {
      flex: 0 0 auto;
    }

    .wethod-modal2__body {
      line-height: 20px;
      font-size: 14px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      flex: 1 1 auto;
      max-height: 300px;
    }


    b {
      font-weight: $bold;
    }
  }

  &__cancel-button {
    @extend .wethod-button;
    color: $raspberry-red;

    &:hover {
      color: $white;
      background-color: $raspberry-red;
    }
  }

  &__confirm-button {
    @extend .wethod-button;
    color: $garden-green;

    &:hover {
      color: $white;
      background-color: $garden-green;
    }
  }

  &__edit-notes-modal {
    .wethod-modal2__container {
      min-width: 500px;
      font-size: 14px;
      line-height: 20px;
    }

    &__confirm-button {
      @extend .wethod-button;

      &--disabled {
        opacity: 0.25 !important;
        pointer-events: none !important;
      }
    }

    &__input {
      margin-bottom: 24px;
      font-size: inherit;
      float: left;
      width: 100%;
      height: 100px;
      padding: 8px;
      line-height: 1.5;
      resize: none;
      outline: none;

      &--editing {
        @extend .orders__edit-notes-modal__input;
        border: 1px solid #F2F3F5;
        border-radius: 4px;
      }
    }

    &__actions {
      display: block;
      text-align: right;

      & > :not(:last-child) {
        margin-right: 16px;
        border: none;
      }
    }
  }

  &__approve-payment-modal {
    .wethod-modal2__container {
      min-width: 422px;
      font-size: 14px;
      line-height: 20px;

      .wethod-select {
        ul {
          margin-bottom: auto;
          padding: 0;
        }

        li {
          margin: 0;
        }
      }
    }

    &__body {
      margin-bottom: 52px;
    }

    &__actions {
      display: block;
      text-align: right;

      & > :not(:last-child) {
        margin-right: 16px;
      }
    }
  }

  &__delete-order-modal {
    .wethod-modal2__container {
      min-width: 422px;
      font-size: 14px;
      line-height: 20px;
    }

    &__body {
      margin-bottom: 72px;
    }

    &__actions {
      display: block;
      text-align: right;

      & > :not(:last-child) {
        margin-right: 16px;
      }
    }
  }

  &__send-order-modal {
    .wethod-modal2__container {
      min-width: 375px;
      font-size: 14px;
      line-height: 17px;
    }

    .wethod-modal2__body {
      padding: 0 32px;
    }

    &--item {
      color: $black !important;
      border-bottom: 1px solid $concrete-gray;
      padding: 20px 32px;
      margin: 0 -32px;
      transition: .3s;
      cursor: pointer;
      display: flex;
      justify-content: space-between;
      align-items: center;

      &:hover {
        background: #F2F3F5;
      }

      .wethod-icon-arrow-right {
        margin: 0 -5px 0 24px;
      }
    }
  }

  &__export-success-modal {
    .wethod-modal2__container {
      min-width: 422px;
      font-size: 14px;
      line-height: 20px;

      .wethod-modal2__body {
        display: flex;
        flex-flow: column;
        justify-content: space-between;
        height: 216px;
      }
    }

    &__confirm-button {
      @extend .wethod-button;
    }

    &__actions {
      display: block;
      text-align: right;
    }
  }
}
