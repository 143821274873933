.date-selector {
  font-size: inherit;

  .navigation__today {
    padding: 0 22px;
    height: 32px;
  }
  @include scaleToExtraSmall() {
    .navigation__today {
      padding: 0 16px;
    }
    .navigation__prev, .navigation__next {
      display: block;
    }
  }
  &__calendar-button {
    display: flex;
    align-items: center;
    text-transform: capitalize;
    padding-right: 8px;
    &:hover {
      .wethod-icon {
        background-position-x: -64px;
      }
    }
    @include scaleToExtraSmall() {
      padding: 0 5px;
      .wethod-icon {
        display: none;
      }
    }

    & * {
      margin: 0 4px;
    }
  }

  &-weekly {
    line-height: 16px;
    font-size: inherit;

    .DayPicker-Day {
      border-radius: 0 !important;
    }

    .DayPicker-Day--selected {
      background-color: $bright-sky-blue !important;
    }
    .DayPicker-Day--selected:not(.DayPicker-Day--outside) {
      color: white !important;
    }

    .DayPicker-Day--selectedRangeStart {
      border-top-left-radius: 50% !important;
      border-bottom-left-radius: 50% !important;
    }
    .DayPicker-Day--selectedRangeEnd {
      border-top-right-radius: 50% !important;
      border-bottom-right-radius: 50% !important;
    }

    .DayPicker-Day--hoverRange:not(.DayPicker-Day--selectedRange) {
      background-color: rgba(72, 165, 249, 0.15) !important;
      border-radius: 0 !important;
    }
    .DayPicker-Day--hoverRangeStart:not(.DayPicker-Day--selectedRangeStart) {
      border-top-left-radius: 50% !important;
      border-bottom-left-radius: 50% !important;
    }
    .DayPicker-Day--hoverRangeEnd:not(.DayPicker-Day--selectedRangeEnd) {
      border-top-right-radius: 50% !important;
      border-bottom-right-radius: 50% !important;
    }
  }
}
