//WIDGET
@import 'widget-alert';
@import 'widget-budget-consumption';
@import 'widget-invoice';
@import 'widget-revenue-clock';
@import 'widget-revenue-progress';
@import 'widget-sparkline-production';

.dashboardSectionHeader {
  padding-top: 16px;
}

.widgetWrap {
  @include span(12);

  @include scaleToMedium() {
    @include span(10);
    @include push(1);
  }

  @include scaleToSmall() {
    @include span(10);
    @include push(1);
  }

  @include scaleToExtraSmall() {
    @include span(10);
    @include push(1);
  }

}

.widget {
  @include span(4);
  height: 341px;
  padding: 6px;

  @include scaleToMedium() {
    @include span(6);
  }
  @include scaleToSmall() {
    @include span(6);
  }
  @include scaleToExtraSmall() {
    @include span(12);
  }

  .wrapAddWidget {
    @include calc('padding-top', '50% - 21px');
  }

  .addWidget {
    display: inline-block;

    &.loading {
      &::before {
        content: 'Loading...';
        font-size: 18px;
      }

      .icon {
        display: none;
      }
    }

    .icon {
      width: 40px;
      height: 40px;
      margin: 0;
      @include retina-image('../../../img/icon/widget_plus', 81px 40px);

      background-position: 0px 40px;
    }

    &:hover {
      .icon {
        background-position: 40px 40px;
      }
    }
  }

  .widgetNews {
    position: absolute;
    bottom: 20px;
    left: 20px;

    .countNews {
      float: left;
      width: 15px;
      height: 15px;
      margin-top: -1px;
      background: $garden-green;
      font-size: 10px;
      font-weight: $semiBold;
      text-align: center;
      border-radius: 50%;

      .numberOfNews {
        display: inline-block;
        width: 100%;
        margin: 2px 0 0 1px;
        color: $white;
      }
    }

    .contNewsLabel {
      float: left;
      margin-left: 6px;
      color: $garden-green;
      font-weight: $semiBold;
      font-size: 12px;
    }
  }

  .widgetSaveFeedback {
    display: none;
    position: absolute;
    top: 17px;
    right: 50px;
    color: $white;
    font-weight: $light;
  }

  .widgetBody {
    position: relative;
    overflow: hidden;
    background: $white;
    width: 100%;
    height: 100%;
    border-radius: 12px;
    border: 1px solid $concrete-gray;

    &.addItem {
      background: rgba(255, 255, 255, .4);
    }
  }

  .loadingWidget {
    position: relative;
    width: 100%;
    height: 100%;

    background: $white;

    text-align: center;
    @keyframes logoRotation {
      0% {
        @include transform(rotate(0deg) translateZ(0));
      }
      100% {
        @include transform(rotate(360deg) translateZ(0));
      }
    }

    .loadingImg {
      position: absolute;
      width: 30px;
      height: 30px;
      @include calc('top', '50% - 15px');
      @include calc('left', '50% - 15px');

      @include scaleToExtraSmall() {
        margin-top: -10px;
      }

      @include retina-image($iconsPath + mini_logo_wait, 30px 30px);
      @include animation(logoRotation 1.3s ease);
      @include animation-delay(0s);
      @include animation-iteration-count(infinite);
    }
  }

}

.actionWidget {
  position: absolute;
  top: 8px;
  right: 8px;
  width: 30px;
  height: 30px;
  border: 1px solid;
  border-radius: 50%;
  @include transition(0.2s ease-in-out);

  &.settings {
    display: none;
    border-color: rgba(72, 165, 244, 1);

    &:hover {
      background: rgba(72, 165, 244, 1);

      .settings {
        background-position: 30px -2px;
      }
    }
  }

  &.save {
    border-color: rgba(255, 255, 255, 1);

    &:hover {
      background: rgba(255, 255, 255, 1);

      .save {
        background-position: 0px -30px;
      }
    }
  }
}

.iconWidget {
  display: block;
  width: 30px;
  height: 30px;
  border: 1px solid;
  border-radius: 50%;
  @include transition(0.2s ease-in-out);
  border-color: rgba(72, 165, 244, 1);

  &:hover {
    background: rgba(72, 165, 244, 1);

    .iconWidget__icon {
      background-position-x: -64px;
    }
  }

  &__icon {
    cursor: pointer;
    display: inline-block;
    position: relative;
    top: -2px;
    left: -2px;
  }
}

.spriteWidget {
  width: 30px;
  height: 30px;
  @include retina-image($iconsPath + widget, 60px 150px);
  cursor: pointer;

  &.settings {
    width: 30px;
    height: 30px;
    background-position: 0px -2px;
  }

  &.save {
    width: 30px;
    height: 30px;
    background-position: 30px -30px;
  }

  &.invoice {
    width: 30px;
    height: 30px;
    background-position: -1px -61px;

    &:hover {
      background-position: 29px -61px;
    }
  }

  &.invoicePlan {
    width: 30px;
    height: 30px;
    background-position: -1px -91px;

    &:hover {
      background-position: 29px -91px;
    }
  }
}

.deleteWidget {
  position: absolute;
  bottom: 12px;
  right: 8px;
  color: rgba(255, 255, 255, .6) !important;
  @include transition(0.2s ease-in-out);

  &:hover {
    color: rgba(255, 255, 255, 1);
  }
}


.widgetTargetValue {
  z-index: 20;
  position: absolute;
  top: 140px;
  left: 0;
  width: 100%;
  text-align: center;
  color: #878B8E;
  font-size: 30px;
  font-weight: $light;
}

.widgetTargetLabel {
  z-index: 20;
  position: absolute;
  top: 120px;
  left: 0;
  width: 100%;
  text-align: center;
  color: #878B8E;
  font-size: 10px;
}

.widgetButton {
  display: block;
  padding: 8px 20px;
  border-radius: 30px;
  text-align: center;
  cursor: pointer;
  outline: none;
  //text-transform: uppercase;
  border: 1px solid $white;
  background: transparent;
  @include transition(0.2s ease-in-out);

  .text {
    color: $white;
  }

  &.addWidgetButton {
    display: block;
    position: relative;
    text-transform: uppercase;
    margin-top: 14px;

    .newBullet {
      position: absolute;
      left: 10px;
      top: 9px;
      width: 10px;
      height: 10px;
      background: $garden-green;
      border-radius: 50%;
    }
  }


  &.disabled {
    opacity: 0.5;

    &:hover, &:active {
      background: transparent;
      cursor: default;

      .text {
        color: $white;
      }
    }
  }
}


//ICONS
.spriteModules {
  width: 16px;
  height: 16px;
  @include retina-image($iconsPath + modules_icons, 128px 32px);
  cursor: pointer;


  // &.moduleDashboard{
  // 	background-position:0 16px;
  // }
  &.modulePipeline {
    background-position: -16px 0px;
  }

  &.modulePlanning {
    background-position: -32px 0px;
  }

  // .menuTimesheet{
  // 	background-position:-48px 16px;
  // }
  &.moduleFriday {
    background-position: -48px 0px;
  }

  &.moduleReport {
    background-position: -80px 0px;
  }

  &.moduleFinance {
    background-position: -96px 0px;
  }

  &.moduleBudget {
    background-position: -96px 0px;
  }

  // &.moduleUser{
  // 	background-position:-112px 16px;
  // }
}


/* highlight drag & drop */

/* Draggabilly adds is-dragging */
.grid-item.is-dragging,
  /* Packery adds class while transitioning to drop position */
.grid-item.is-positioning-post-drag {
  z-index: 2;
}

.packery-drop-placeholder {
  outline: 3px dashed #444;
  outline-offset: -6px;
  /* transition position changing */
  -webkit-transition: -webkit-transform 0.2s;
  transition: transform 0.2s;
}
