$canvas-board-input-color: #F2F3F5;

.project-canvas {
  padding-bottom: 100px;

  .section-header {
    padding-top: 16px;
  }

  &-alert {
    .wethod-modal2__container {
      max-width: 520px;
    }

    .wethod-modal2__body {
      line-height: 20px;
      font-size: 14px;
    }

    b {
      font-weight: $bold;
    }
  }

  &--coming-soon {
    opacity: 0.6;
  }

  &-info {
    padding: 18px 16px;
    background: $white;
    border: 1px solid $concrete-gray;
    border-radius: 12px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 24px;

    &__job-order-category {
      width: 4px;
      border-radius: 50px;
      margin-right: 16px;
    }

    &__description {
      display: flex;
      align-items: stretch;
    }

    &__name {
      font-size: 18px;
      font-weight: $semiBold;
    }

    &__secondary {
      margin-top: 4px;
    }

    &__client {
      font-size: 16px;
    }

    &__kudos {
      border: 1px solid $black;
      border-radius: 12px;
      margin: auto 0 auto 50px;
      padding: 3px 5px;
      font-size: 10px;
      position: relative;

      .tooltipReact {
        top: -22px;
        display: none;
        pointer-events: none;
        text-transform: none;
      }

      &:hover {
        .tooltipReact {
          display: inline-block;
        }
      }
    }

    &__button {
      display: inline-block;
      cursor: pointer;
      border-radius: 50%;
      vertical-align: middle;

      &:hover {
        background: rgba(0, 0, 0, 0.1);
        transition: 0.3s;
      }
    }
  }

  &__text-area {
    &-modal {
      width: 656px;

      &--readonly {
        .project-canvas__text-area-modal-content-input--description {
          margin-top: 48px;
        }

        .material-input__label--minimized {
          top: -4px;
          left: -5px;
        }
      }

      .wethod-input {
        white-space: pre-wrap;
        line-height: 20px !important;
        font-size: 14px;
        word-break: break-word;
      }

      &-wrapper {
        .wethod-modal2__container {
          margin-top: 50px;
          height: calc(100vh - 100px);
        }
      }

      &-content {
        height: calc(100vh - 282px);
        overflow: auto;

        &-input {
          &-counter {
            font-size: 11px;
            line-height: 15px;
            color: #CCCBCC;
            text-align: right;
            position: relative;
            top: -8px;
          }

          textarea {
            resize: none;
          }

          &--summary {
            textarea {
              height: 108px;
            }
          }

          &--description {
            textarea {
              height: calc(100vh - 487px);
            }
          }
        }
      }

      &-actions {
        text-align: right;
        margin-top: 32px;
        position: relative;

        .wethod-button {
          margin-left: 8px;
        }

        &--disabled {
          &:after {
            content: '';
            background: $white;
            width: 100%;
            height: 100%;
            display: block;
            position: absolute;
            top: 0;
            opacity: 0.5;
          }
        }
      }
    }
  }

  &__widget {
    &-container {
      &--left {
        width: calc(66.66% - 12px);
        display: inline-block;
        vertical-align: top;
      }

      &--right {
        width: calc(33.33% - 12px);
        display: inline-block;
        margin-left: 24px;
        vertical-align: top;
      }
    }

    &--brief {
      width: calc(50% - 12px);
      display: inline-block;
    }

    &--concept {
      width: calc(50% - 12px);
      display: inline-block;
      margin-left: 24px;
    }

    &--files {
      margin-top: 24px;
    }

    &--kudos {
      margin-top: 24px;
      font-size: 14px;

      &-body {
        overflow: hidden;
      }

      &-subheader {
        border-bottom: 1px solid $concrete-gray;
        height: 21px;
        line-height: 21px;
        vertical-align: middle;
        padding: 0 16px;
        font-size: 11px;
        color: $dusty-gray;
      }

      &-list {
        overflow: auto;
        height: calc(100% - 85px);
        padding: 8px 0;
      }

      &-criteria {
        justify-content: space-between;
        align-items: center;
        display: flex;
        height: 25px;
        padding: 0 16px;

        span:first-child {
          overflow: hidden;
          width: 90%;
          line-height: 1.5;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }

      &-footer {
        border-top: 1px solid $concrete-gray;
        height: 64px;
        padding: 5px 16px;

        & > * {
          justify-content: space-between;
          align-items: center;
          display: flex;
          height: 50%;
        }
      }
    }

    &--boards {
      margin-top: 24px;
    }

    &--goals {
      height: 606px;

      .wethod-widget__body {
        height: calc(100% - 47px);
      }
    }

    &-text-area {
      .wethod-input {
        white-space: pre-line;
        word-break: break-word;
      }

      &-body {
        height: 100%;

        &-wrapper {
          cursor: pointer;
          transition: 0.3s;
          background: $white;

          &:hover {
            background: rgba(0, 0, 0, 0.03);
          }
        }

        &-content {
          font-size: 14px;
          line-height: 20px;
          padding: 16px 16px 0 16px;
          height: calc(100% - 40px);
          overflow: hidden;
          margin-bottom: 16px;
          word-break: break-word;
        }

        &-footer {
          padding: 0 16px;
          color: #B8BCC1;
          font-size: 11px;
        }
      }
    }
  }

  &-boards {
    margin-top: 24px;

    &__empty {
      background: $white;
      padding: 24px;
      border-radius: 12px;
      text-align: center;
      transition: 0.3s;
      border: 1px solid $concrete-gray;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 250px;

      &-image {
        margin-right: 24px;
      }

      &-text {
        text-align: left;
        max-width: 300px;
      }

      &--editable {
        cursor: pointer;

        &:hover {
          background: rgba(255, 255, 255, 0.7);

        }
      }
    }

    &__loading {
      background: $white;
      padding: 24px;
      border-radius: 12px;
      text-align: center;
      transition: 0.3s;
      border: 1px solid $concrete-gray;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    &__header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 24px;

      h2 {
        font-size: 18px;
        line-height: 24px;
      }
    }
  }

  &-board {
    position: relative;

    &__type-icon {
      display: inline-block;
    }

    &-drag-handle {
      display: inline-block;
      transform: rotate(90deg);
      position: relative;
      top: 18px;
      left: -8px;
      cursor: move;

      &__container {
        position: absolute;
        left: 0;
        top: 0;
        height: 100%;
        width: 16px;
        overflow: hidden;
        opacity: 0;
        transition: 0.2s;
      }
    }

    .wethod-input {
      transition: 0.2s;
    }

    &--disabled {
      &:after {
        content: '';
        display: block;
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        opacity: 0.2;
      }
    }

    &__settings {
      background: $concrete-gray;
      border-radius: 12px;
      padding: 18px 30px;
      font-size: 14px;
      display: flex;
      justify-content: space-between;

      &-left {
        width: 50%;
      }

      &-right {
        width: 50%;
        display: flex;
        justify-content: flex-end;
      }

      &-item {
        display: flex;
        align-items: center;

        &-input {
          margin-left: 16px;
          position: relative;
        }
      }

      .wethod-select {
        &__list {
          min-width: 100%;
          text-align: center;
        }

        .wethod-button {
          color: $black;

          &:hover {
            color: $white;
            background-color: $black;
          }
        }
      }
    }

    &__header {
      position: relative;
      padding: 18px 16px;
      border: 1px solid $concrete-gray;
      border-radius: 12px;
      display: flex;
      align-items: center;
      justify-content: space-between;

      &-container {
        margin-top: 22px;
        margin-bottom: 24px;
      }

      &--disabled {
        &:after {
          content: '';
          background: $white;
          width: 100%;
          height: 100%;
          display: block;
          position: absolute;
          top: 0;
          left: 0;
          opacity: 0.5;
        }
      }

      &:hover {
        .project-canvas-board__title {
          .wethod-input {
            background: $concrete-gray;
          }
        }

        .project-canvas-board-drag-handle__container {
          opacity: 1;
        }
      }
    }

    &__title {
      font-size: 16px;
      line-height: 20px;
      flex-basis: 50%;

      &--focus {
        .wethod-input {
          background: $concrete-gray !important;
        }
      }

      .wethod-input {
        background: transparent;
        transition: .3s;

        input {
          font-size: inherit;
        }
      }
    }

    &__actions {
      display: flex;
      justify-content: space-between;
      align-items: center;

      &-type-switcher {
        margin-right: 16px;

        .wethod-tab-switcher {
          border-color: $black;

          &__item {
            color: $black;
            padding: 0 8px;
            overflow: hidden;

            &:hover, &--selected {
              background: $black;
              color: $white;

              .project-canvas-board__type-icon {
                background-position-x: -64px;
              }
            }

            &:not(:last-child) {
              border-color: $black;
            }
          }
        }
      }
    }

    &__content {
      overflow: auto;
      position: relative;
    }
  }

  &-spreadsheet {
    &--horizontal-scroll {
      .project-canvas-spreadsheet {
        &__row-creator {
          .wethod-table__cell {
            &:nth-child(2) {
              margin-left: 248px;
              position: absolute;
              left: 16px;
            }
          }
        }

        &__cell {
          &--name, &--creator-name {
            position: absolute;
            left: 0;
            width: 250px;
            border-left: 1px solid #ECECEC;
            border-bottom: 1px solid #ECECEC;
            border-right: 1px solid #ECECEC;
          }

          &--name {
            height: 52px;
          }

          &--header, &--creator-name {
            height: auto !important;
          }

          &:nth-child(2) {
            margin-left: 248px;
          }
        }
      }
    }

    &__row {
      display: flex;

      &-drag-handle {
        display: inline-block;
        transform: rotate(90deg);
        position: relative;
        top: 9px;
        left: -8px;
        cursor: move;

        &__container {
          background-color: $white;
          position: absolute;
          left: 0;
          top: 0;
          z-index: 1;
          height: 100%;
          width: 16px;
          overflow: hidden;
          opacity: 0;
          transition: 0.2s;
        }
      }

      &:hover {
        .project-canvas-spreadsheet__row-drag-handle__container {
          opacity: 1;
        }
      }

      &-more-button {
        padding: 3px !important;
        transition: 0s !important;
        border: none !important;

        &:hover {
          background: rgba(0, 0, 0, 0.1) !important;
          color: $black !important;
        }
      }

      &-creator {
        &-feedback {
          color: $bright-sky-blue;
          font-size: 12px;
          margin-left: 10px;
        }

        &-instructions {
          color: $bright-sky-blue;
          font-size: 12px;
          transition: 0.3s;
          opacity: 0;

          &--visible {
            opacity: 1;
          }
        }
      }

      &-summary {
        background: none;
      }
    }

    &__col {
      &-more-button {
        position: absolute;
        right: -12px;
        top: 10px;
        background: none !important;
        padding: 3px !important;
        transition: 0s !important;
        border: none !important;

        &-wrapper {
          display: inline-block;

          &:hover {
            .wethod-input {
              background: $canvas-board-input-color !important;
            }
          }

          .wethod-input {
            background: $white;

            input {
              font-size: inherit;
              text-align: inherit;
              font-weight: inherit;
            }
          }

          &--focus {
            .wethod-input {
              background: $canvas-board-input-color !important;
            }

            .project-canvas-spreadsheet__cell--header-name-label {
              display: none;
            }
          }
        }
      }
    }

    &__cell {
      flex-shrink: 0;
      flex-basis: 150px;
      text-align: center;

      &:hover {
        .wethod-input:first-child {
          background: $canvas-board-input-color !important;
        }
      }

      .wethod-input {
        background: $white;

        input {
          font-size: inherit;
          text-align: inherit;
          font-weight: inherit;
        }
      }

      &--focus {
        .wethod-input {
          background: $canvas-board-input-color !important;
        }
      }

      &:not(:first-child) {
        border-left: 1px solid #ECECEC;
      }

      &--name {
        flex-grow: 2;
        flex-basis: 250px;
        flex-shrink: 0;
        background: $white;
        z-index: 2;
        position: relative;
        padding-right: 0;

        .wethod-input input {
          font-size: inherit;
          text-align: inherit;
          font-weight: inherit;
        }

        &:hover {
          z-index: 3;

          .wethod-input:first-child {
            background: $canvas-board-input-color !important;
          }
        }

        .wethod-input {
          background: $white;

          input {
            font-size: inherit;
            text-align: inherit;
            font-weight: inherit;
          }
        }

        &--focus {
          .wethod-input {
            background: $canvas-board-input-color !important;
          }
        }
      }

      &-button {
        padding: 0 !important;
        position: relative;
        width: 100%;
        height: 100%;

        &-content {
          width: 100%;
          height: 100%;
          border: none;
          outline: none;
          font-size: inherit;
          background-color: transparent;
          cursor: pointer;

          display: flex;
          align-items: center;
          justify-content: center;

          &:hover {
            background: $concrete-gray;
          }

          &--status {
            &:hover {
              opacity: 0.9;
            }
          }

          &--text {
            padding: 0 8px;
            width: 100%;
            height: 100%;
            background-color: $white;
            overflow: hidden;
            text-overflow: ellipsis;
            cursor: initial;
          }

          &--sliding {
            width: calc(100% + 40px);
            background-color: $bright-sky-blue;
            transition: 0.3s;

            &-enabled {
              &:hover {
                transform: translateX(-40px);
                background-color: $bright-sky-blue;
              }
            }
          }

          &--date {
            width: calc(100% - 40px);
            background-color: $white;

            .wethod-icon {
              margin-right: 10px;
              margin-left: -5px;
            }
          }

          &--delete {
            display: inline-block;
            width: 40px;

            .wethod-icon-button {
              width: 28px;
              height: 28px;
              padding: 0 1px;
              border: 1px solid $white;
            }

            &:hover {
              .wethod-icon-button {
                background-color: $white;
              }

              .wethod-icon {
                background-position-x: 0;
              }
            }
          }

          &--milestone {
            display: flex;
            justify-content: center;
            align-items: center;

            .wethod-icon {
              margin-right: 10px;
              margin-left: -5px;
            }
          }
        }
      }

      &--actions {
        flex-grow: 0;
        flex-basis: 56px;
        flex-shrink: 0;
        border-left: 1px solid #ECECEC;
      }

      &--creator-name {
        border-right: none !important;

        .wethod-input {
          background: $white;

          input {
            font-size: inherit;
            text-align: inherit;
            font-weight: inherit;
          }
        }

        &--focus, &:hover {
          .wethod-input {
            background: $canvas-board-input-color !important;
          }
        }
      }

      &--header {
        padding: 0 16px;
        position: relative;

        &-drag-handle {
          display: inline-block;
          transform: rotate(90deg);
          position: relative;
          top: 10px;
          left: -8px;
          cursor: move;

          &-container {
            position: absolute;
            left: 0;
            top: 0;
            height: 100%;
            width: 16px;
            overflow: hidden;
            opacity: 0;
            transition: 0.2s;
          }
        }

        &-name {
          display: inline-flex;
          flex-direction: column;
          line-height: 1;
          vertical-align: middle;

          &-label {
            font-size: 12px;
            font-weight: $normal;
            color: $dusty-gray;
          }
        }

        &-menu-item {
          text-align: left;
        }

        &:hover {
          .project-canvas-spreadsheet__cell--header-drag-handle-container {
            opacity: 1;
          }
        }
      }

      &--summary {
        background: $white;
        position: relative;

        &-type {
          display: block;
          position: absolute;
          bottom: 2px;
          width: 100%;
          color: $dusty-gray;
          font-size: 11px;
          line-height: 16px;
        }

        &-empty {
          border: none;
        }

        &-name, &-actions {
          background: none;
          border: none !important;
        }

        &-status {
          height: 100%;

          &-container {
            display: inline-flex;
            width: 100%;
            padding: 0 !important;
          }
        }
      }

      &-menu {
        text-align: center;
      }
    }
  }

  &-kanban {
    display: flex;
    flex-wrap: nowrap;
    margin-bottom: 24px;

    &__list {
      width: 225px;
      margin-right: 24px;
      flex-shrink: 0;

      &-drag-handle {
        display: inline-block;
        transform: rotate(90deg);
        position: relative;
        top: 7px;
        left: -8px;
        cursor: move;

        &__container {
          position: absolute;
          left: 0;
          top: 0;
          height: 100%;
          width: 16px;
          overflow: hidden;
          opacity: 0;
          transition: 0.2s;
        }
      }

      &:hover {
        .project-canvas-kanban__list-drag-handle__container {
          opacity: 1;
        }
      }

      &-name {
        font-size: 14px;
        line-height: 22px;
        padding: 12px 16px;
        border-radius: 12px;
        min-height: 48px;
        border: 1px solid $concrete-gray;
        position: relative;
      }

      &-cards {
        min-height: 16px;
      }

      &-card-creator {
        font-size: 14px;
        line-height: 22px;
        padding: 9px 16px;
        margin-top: 8px;
        border-radius: 12px;
        border: 1px solid $concrete-gray;

        &:hover {
          .wethod-input {
            background: $canvas-board-input-color !important;
          }
        }

        .wethod-input {
          background: none;

          input {
            font-size: inherit;
            text-align: inherit;
            font-weight: inherit;
          }
        }
      }
    }

    &__card {
      background: $white;
      border-radius: 12px;
      font-size: 14px;
      line-height: 22px;
      padding: 10px 16px;
      margin-top: 8px;
      border: 1px solid $concrete-gray;
      cursor: pointer;

      &-members {
        margin-top: 16px;

        &-item {
          margin: 8px 0;

          &-pic {
            margin-right: 8px;
          }
        }
      }

      &-date {
        margin-top: 16px;
      }
    }

    &-sidebar {
      &-name {
        margin: 20px 5px 15px;
        line-height: 22px;
        font-size: 16px;
        font-weight: $semiBold;
      }

      &-status {
        display: flex;
        align-items: center;

        &__input {
          width: 85%;
        }

        &__dot {
          width: 16px;
          height: 16px;
          border-radius: 50%;
        }
      }

      &-member {
        padding: 5px 0;

        &__header {
          display: flex;
          align-items: center;
          margin-bottom: 5px;

          &--title {
            margin-left: 17px;
          }
        }

        &__element {
          margin: 5px 10px 5px 47px;
          display: flex;
          justify-content: space-between;
          align-items: center;

          &--empty {
            color: $dusty-gray;
          }
        }
      }

      &__button-input {
        justify-content: space-between;

        &--deletable {
          .wethod-icon-button {
            display: none;
          }

          &:hover {
            .wethod-icon-button {
              display: block;
            }
          }
        }
      }

      &__delete {
        border: none;

        &:hover {
          background-color: #ECECEC;
        }
      }
    }
  }

  &-gantt {
    overflow: auto;
    border: 1px solid $concrete-gray;
    border-radius: 12px;
    background: $white;

    &__actions {
      display: flex;
      margin-bottom: 24px;
      justify-content: flex-end;
    }

    &__type-switcher {
      margin-right: 16px;
      align-self: flex-end;

      .wethod-tab-switcher {
        border-color: $black;

        &__item {
          color: $black;
          padding: 0 12px;
          overflow: hidden;

          &:hover, &--selected {
            background: $black;
            color: $white;

            .project-canvas-board__type-icon {
              background-position-x: -64px;
            }
          }

          &:not(:last-child) {
            border-color: $black;
          }
        }
      }
    }

    &--weeks {
      .project-canvas-gantt__bar-icon {
        transform: scale(0.6);
        left: -7px;
      }
    }

    &-container {
      position: relative;
    }

    &__calendar {
      position: relative;
      overflow: hidden;

      &-column {
        &--day {
          width: 50px;
          flex-shrink: 0;

          &-weekend {
            background: repeating-linear-gradient(45deg, rgba(0, 0, 0, 0.05), rgba(0, 0, 0, 0.05) 2px, transparent 2px, transparent 5px);
          }
        }

        &--week {
          flex-shrink: 0;
          width: 140px;

          .project-canvas-gantt__calendar-header-month {
            width: 100%;
            text-align: center;
            left: 0;
          }
        }

        &--year {
          flex-shrink: 0;
          width: 200px;
        }

        &--month {
          flex-shrink: 0;
          width: 200px;
        }
      }

      &-content {
        display: flex;
        flex-wrap: nowrap;

        &-column {
          border-left: 1px solid $concrete-gray;
        }
      }

      &-header {
        display: flex;
        flex-wrap: nowrap;
        position: absolute;
        top: 0;
        background: $maldivian-sand-white;
        border-bottom: 1px solid $concrete-gray;

        &-columns {
          display: flex;
          align-items: center;
          margin-bottom: 4px;
          margin-top: 26px;
        }

        &-column {
          text-align: center;
          position: relative;

          &--today {
            color: $white;
            background: $black;
            border-radius: 12px;
            padding: 4px;
          }
        }

        &-month {
          position: absolute;
          top: -22px;
          white-space: nowrap;
          left: 17px;
          color: $black;
        }

        &-year {
          position: absolute;
          top: -22px;
          white-space: nowrap;
          color: $black;
          width: 100%;
          left: 0;
          text-align: center;
        }
      }
    }

    &__items {
      background: $white;
      position: absolute;
      top: 1px;
      left: 1px;
      border-radius: 4px 4px 0 0;
      overflow: hidden;
    }

    &__item {
      height: 50px;
      border-right: 1px solid $concrete-gray;
      border-top: 1px solid $concrete-gray;
      padding: 0 16px;
      text-align: left;
      width: 200px;
      font-size: 14px;
      display: flex;
      align-items: center;
      cursor: pointer;

      &:hover {
        background: $concrete-gray;
      }

      &--header {
        background: $maldivian-sand-white;
        border: none;
      }

      &--new {
        position: absolute;
        width: calc(100% - 1px);
        background-color: $white;
        padding-left: 6px;

        .wethod-input {
          background: $white;

          input {
            font-size: inherit;
            text-align: inherit;
            font-weight: inherit;
          }
        }

        &-input {
          margin-right: 25px;

          &--focus {
            .wethod-input {
              background: $canvas-board-input-color !important;
            }
          }
        }

        &:hover {
          background-color: $white;
          cursor: initial;

          .wethod-input {
            background: $canvas-board-input-color !important;
          }
        }
      }

      &-name {
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        vertical-align: top;
      }
    }

    &__row {
      width: auto;
      cursor: inherit;
      border: none;
      background: none !important;
    }

    &__rows {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;

      &--resizing {
        cursor: ew-resize;
      }
    }

    &__bar {
      height: 26px;
      border: 1px solid $black;
      border-radius: 12px;
      position: absolute;
      text-align: center;

      &:hover {
        .project-canvas-gantt__bar-resize-handler {
          opacity: 1;
        }
      }

      &-move-handler {
        display: block;
        position: absolute;
        top: 0;
        height: 100%;
        width: calc(100% - 20px);
        left: 10px;
        cursor: move;
      }

      &-resize-handler {
        display: block;
        position: absolute;
        height: 60%;
        top: 20%;
        width: 5px;
        transition: .3s;
        opacity: 0;
        cursor: ew-resize;

        &--begin {
          border-right: 1px solid;
          left: 0;
        }

        &--end {
          border-left: 1px solid;
          right: 0;
        }
      }

      &-icon {
        display: inline-block;
        position: relative;
        top: -3px;
      }
    }
  }

  &__goal {
    padding: 20px 16px;
    border-bottom: 1px solid $concrete-gray;

    &:hover {
      background: rgba(0, 0, 0, 0.03);
      cursor: pointer;
    }

    &--disabled {
      &:hover {
        background-color: inherit;
        cursor: initial;
      }
    }

    &-empty {
      justify-content: center;
      flex-direction: column;
      text-align: center;

      &--editable {
        &:hover {
          background: rgba(0, 0, 0, 0.03);
          cursor: pointer;
        }
      }
    }

    &-name {
      font-size: 14px;
      line-height: 20px;
      margin-bottom: 12px;
    }

    &-progress {
      text-align: right;
      font-size: 19px;
      font-weight: $semiBold;
      margin-bottom: 18px;

      &--progress {
        font-size: 19px;
        font-weight: $semiBold;
      }

      &--target {
        font-size: 14px;
      }
    }

    &-modal-input {
      display: flex;
      justify-content: space-between;

      &-wrapper {
        width: 656px;
      }

      &--target {
        width: 15%;
        display: inline-block;

        input {
          text-align: right;
        }
      }

      &--name {
        width: 80%;
        display: inline-block;
      }

      .material-input {
        &__input {
          .wethod-input {
            padding: 4px 10px;
          }
        }

        &__helper-text {
          display: none;
        }
      }
    }
  }

  &__folder {
    &-icon {
      .wethod-icon-folder--black {
        margin: 0 -8px;
      }
    }
  }

  &__file {
    font-size: 14px;
    line-height: 1.25;
    height: 50px;
    padding: 0 16px;
    border-bottom: 1px solid $concrete-gray;
    display: flex;
    align-items: center;
    justify-content: space-between;

    &--folder {
      cursor: pointer;

      &:hover {
        background: $concrete-gray;
        transition: 0.2s;
      }
    }

    &-picker {
      input[name=file] {
        display: none;
      }
    }

    &--list {
      overflow: auto;
      flex: 1;
    }

    &--no-results {
      font-size: 14px;
      color: $dusty-gray;
      padding: 20px;
      text-align: center;
    }

    &--name {
      width: 45%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    &--info {
      color: $dusty-gray;
      width: 45%;
      padding: 0 15px;
      font-size: 12px;
      line-height: 1.4;
      text-align: right;
      display: flex;
      flex-direction: column;
    }

    &--upload {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  &__info-button {
    width: 34px;
    height: 34px;

    &:hover {
      .wethod-icon {
        background-position-x: - 64px;
      }
    }
  }

  &__plus-button {
    &:hover {
      .wethod-icon {
        background-position-x: -48px;
      }
    }

    width: 28px;
    height: 28px;
    padding: 0 1px;
  }

  &-empty {
    justify-content: center;
    flex-direction: column;
    text-align: center;

    &__image {
      margin-bottom: 12px;
    }

    &__text {
      &-title {
        font-size: 14px;
        line-height: 20px;
      }

      &-description {
        color: $dusty-gray;
        font-size: 11px;
        line-height: 16px;
        margin-bottom: 12px;
      }

      &-action {
        font-size: 14px;
        line-height: 20px;
      }
    }

    &__concept {
      background-image: url("../../../img/empty-states/house-of-cards.svg");
      width: 120px;
      height: 108px;
      background-repeat: no-repeat;
    }

    &__brief {
      background-image: url("../../../img/empty-states/listening.svg");
      width: 101px;
      height: 102px;

      background-repeat: no-repeat;
    }

    &__goals {
      background-image: url("../../../img/empty-states/searching.svg");
      width: 101px;
      height: 118px;
      background-repeat: no-repeat;
    }

    &__files {
      background-image: url("../../../img/empty-states/storage.svg");
      width: 135px;
      height: 108px;
      background-repeat: no-repeat;
      margin: auto;
    }

    &__boards {
      background-image: url("../../../img/empty-states/things-to-do.svg");
      width: 101px;
      height: 118px;
      background-repeat: no-repeat;
    }

    &-board {
      &__header {
        display: flex;
        justify-content: space-between;
        border: 1px solid $concrete-gray;
        border-radius: 12px;
        padding: 16px;
      }

      &__title {
        font-size: 16px;
      }
    }
  }

  &-menu {
    max-height: 325px;
    overflow-y: auto;

    &-options {
      &__icon-item {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: -3px auto;
        font-weight: $normal;
        padding: 0 16px;

        &--icon {
          margin-left: 16px;
        }
      }
    }

    &-status {
      &-list {
        padding: 2px 0;
        border-bottom: 1px solid #ECECEC;

        &__label {
          position: absolute;
          right: 4px;
          bottom: 5px;
          line-height: 1;
          font-size: 12px;
        }

        &__edit {
          margin: 4px;

          &--icon {
            display: inline-block;
            vertical-align: middle;
            margin-left: -30px;
            margin-right: 5px;
          }
        }
      }

      &-edit {
        max-height: 260px;
        padding: 0 10px 8px;

        &__header {
          font-size: 12px;
          color: $dusty-gray;
          display: flex;
          justify-content: space-between;
          padding: 0 10px 0 15px;
          width: 290px;
          margin: 20px 0 20px 0;
        }

        &__info-tooltip {
          display: inline-block;
          vertical-align: text-bottom;
          margin-left: 5px;

          .tooltipReact {
            top: -45px;
            left: -10px;
          }
        }

        &__element {
          min-height: 45px;
          width: 270px;
          text-align: left;
          display: flex;
          align-items: center;

          .wethod-input {
            background-color: initial !important;
            border: 1px solid #ECECEC;
            line-height: 24px;
          }

          input {
            font-size: 14px;
          }

          &-label {
            width: calc(100% - 115px);
            margin: 0 12px;
          }
        }

        &__color-list {
          border-top: 1px solid #ECECEC;
          width: 290px;
          white-space: initial;
          line-height: 0;
          padding: 10px 20px;
        }

        &__color {
          width: 20px;
          height: 20px;
          border-radius: 50%;
          margin: 5px;
          vertical-align: middle;
          display: inline-block;

          &--button {
            cursor: pointer;

            &:hover {
              opacity: 0.9;
              transform: scale(1.2);
            }
          }

          &--empty {
            background-color: transparent;
            border: 1px solid #ECECEC;
          }
        }

        &__delete {
          cursor: pointer;
          margin: 5px;

          &:hover {
            background-color: $concrete-gray;
            border-radius: 50%;
          }
        }

        &__add-new {
          height: 36px;
          line-height: 36px;
          padding: 0 10px;
          border: 1px solid transparent;
          border-radius: 3px;
          color: $bright-sky-blue;

          &:hover {
            background-color: $canvas-board-input-color;
            border-color: #ECECEC;
          }
        }

        input[type=checkbox] {
          margin: 7px;
          cursor: pointer;
        }

        input[type=checkbox].disabled {
          cursor: initial;
          pointer-events: none;
        }
      }
    }

    &-members {
      padding: 10px;
      width: 250px;

      .members-result-list {
        display: flex;
        flex-direction: column;
      }

      &__tag-list {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        margin-bottom: 15px;
      }

      &__search {
        text-align: left;

        .search-input {
          border: 1px solid #ECECEC;
          border-radius: 12px;
          line-height: 14px;
          margin-bottom: 5px;
          padding-right: 10px;
        }
      }

      &__result {
        cursor: pointer;
        padding: 8px 5px;
        display: flex;
        align-items: center;

        &:hover {
          background-color: $concrete-gray;
        }

        &--empty {
          color: $dusty-gray;
          text-align: center;
          display: block;
        }

        &--name {
          display: inline-block;
          margin: 0 10px;
        }
      }

      &__pic {
        width: 32px;
        height: 32px;
        line-height: 32px;
        display: inline-block;
        vertical-align: middle;

        &--double {
          display: block;
          width: 52px;
          margin: auto;
        }

        &--over {
          position: relative;
          left: -12px;
        }
      }
    }

    &-milestone {
      &__list {
        padding: 5px;
        min-height: 300px;
        min-width: 200px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
      }

      &__element {
        width: 200px;
        height: 50px;
        padding: 0 10px;
        font-size: 14px;
        text-align: center;
        cursor: pointer;
        display: flex;
        justify-content: start;
        align-items: center;

        &:hover {
          background-color: $concrete-gray;
          border-radius: 12px;
        }

        &--selected {
          border: 1px solid #ECECEC;
          border-radius: 12px;
          margin: 8px auto;
          width: 200px;
        }

        .wethod-icon {
          margin-right: 10px;
        }
      }

      &__date-picker {
        line-height: 16px;
        margin: 6px;

        .wethod-day-picker {
          box-shadow: none;
        }
      }
    }

    &-text {
      width: 300px;
      padding: 8px;
      line-height: 1;
      text-align: left;
      white-space: normal;

      .wethod-input {
        display: inline-flex;
        padding: 0;

        textarea {
          background-color: transparent !important;
          margin: 0 !important;
          padding: 5px 16px !important;
          width: 100%;
        }
      }

      .material-input__input--readonly {
        .material-input__label--minimized {
          display: none;
        }

        .wethod-input:hover {
          background-color: transparent;
        }
      }
    }
  }
}
