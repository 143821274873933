.group-reports {
  .section-header {
    padding-top: 16px;
  }

  .clients__actions {
    padding: 0;
    height: 48px;
  }

  .profit-loss-target {
    margin-top: 20px;

    .resume-budget {
      &__budget-col, &__forecast-content-col {
        line-height: 45px;

        &:hover {
          .resume-budget__cell {
            background:$white;
          }
        }
      }
    }
  }

  .profit-loss-monthly {
    .recordList {
      margin-bottom: 50px;
    }
  }

  .miniLoadingWrap {
    margin-top: 10%;
  }

  .org-chart {
    &__node {
      margin: 16px 8px 0 8px;

      &-name {
        cursor: pointer;

        &:hover {
          background: rgba(72, 165, 249, 0.4);
        }

        &--filter {
          background: rgba(72, 165, 249, 0.4);
        }

        &-leaf {
          padding: 4px 8px;
        }
      }
    }

    &__level-labels {
      padding: 0 8px;
      margin-top: 7px;
      display: inline-block;
      width: 150px;

      div {
        margin: 16px 8px 0 8px;
        padding: 4px 8px;
        line-height: 17px;
        vertical-align: middle;
        font-weight: $bold;
      }
    }
  }

  .wethod-filter-select__list {
    display: block;
    width: 100%;
    top: 52px;
  }

  .group-company-filter {
    float: left;
  }

  .reloadCacheDateLabel {
    font-size: 11px;
    padding: 11px !important;
  }
}
