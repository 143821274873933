.widget-rating-overview {
  &__add-button {
    .wethod-icon {
      margin: 1px 2px;
    }

    &:hover {
      .wethod-icon {
        background-position-x: -48px;
      }
    }
  }

  &__body {
    height: calc(100% - 56px);
    min-height: 240px;
    position: relative;
    overflow: auto;

    .wethod-widget {
      &__empty {
        position: absolute;
        width: 100%;
      }

      &__loading {
        position: absolute;
        width: 100%;
      }
    }
  }

  &-summary {
    background: $alabaster-white;
    padding: 24px 16px;
    border-bottom: 1px solid $concrete-gray;

    &__totals {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    &__average-value {
      font-size: 64px;
    }

    &__counter {
      margin-top: 16px;
      margin-left: 4px;
    }

    &__distribution {
      margin-top: 16px;
    }

    &__histogram {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: 10px 0;

      .wethod-rating__icon {
        width: 16px;
        margin-left: 4px;
        margin-top: -4px;
      }

      .wethod-slider {
        position: relative;
        flex-grow: 1;
        margin: 0 12px;
      }

      &-value, &-percentage {
        color: $bright-sky-blue;
      }

      &-value {
        text-align: center;

        span {
          width: 16px;
          display: inline-block;
        }
      }

      &-percentage {
        width: 30px;
        text-align: right;
      }
    }
  }

  &__list {
    overflow: auto;
    height: calc(100% - 219px);
  }

  &-item {
    padding: 16px;
    font-size: 14px;

    &:not(:last-child) {
      border-bottom: 1px solid $concrete-gray;
    }

    &__header {
      display: flex;
    }

    &__actions {
      &-button-more {
        &:hover {
          background-color: $concrete-gray;
        }

        .wethod-icon {
          left: -1px;
          position: relative;
          top: -1px;
        }
      }
    }

    &__author {
      display: flex;
      flex-grow: 2;
      align-items: center;

      &-pic {
        margin-right: 16px;
        width: 48px;
        height: 48px;
        line-height: 48px;
        font-size: 18px;
      }

      &-info {
        &-first {
          font-weight: $semiBold;
          margin-bottom: 4px;
        }

        &-second {
          font-size: 11px;
          margin-bottom: 4px;
        }

        &-third {
          color: $dusty-gray;
          font-size: 11px;
          margin-bottom: 4px;
        }
      }
    }

    &__value {
      margin-top: 20px;
    }

    &__comment {
      margin-top: 8px;
      line-height: 24px;
    }

    &__footer {
      margin-top: 10px;
    }

    &__timestamp {
      color: $dusty-gray;
      font-size: 11px;
    }
  }
}
