.widget-intercompany {
  &__item {
    list-style: none;
    border-bottom: 1px solid $concrete-gray;
    position: relative;
    height: 70px;
    background:$white;
    font-size: 14px;
    overflow: hidden;

    &--sliding {
      width: calc(100% + 60px);
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
      background-color: $bright-sky-blue;
      transition: .3s;

      &:hover {
        transform: translateX(-60px);
      }
    }

    &-content {
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 100%;
      width: calc(100% - 60px);
      padding: 0 16px;
      background:$white;
    }
  }

  &__project {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 60%;
    line-height: 1.5;

    & > * {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      font-size: 12px;
    }

    span:first-child {
      font-size: 14px;
    }
    span:last-child {
      color: $dusty-gray;
    }
  }

  &__status {
    color: #CCCBCC;
    width: 20%;
    text-align: center;
    font-weight: $light;

    &--not-sent {
      color: #D94F6B;
    }
    &--pending {
      color: $sunset-orange;
    }
    &--accepted {
      color: $garden-green;
    }
  }

  &__actions {
    width: 60px;
    padding: 0 15px;
    font-size: 12px;

    .wethod-icon {
      border: 1px solid$white;
      border-radius: 50%;

      &:hover {
        background-color:$white;
        background-position-x: 0;
      }
    }

    a {
      display: block;
    }
  }
}
