.year-slider {
  .slider {
    &__prev, &__next {
      @extend .fullRoundButton;
      float: left;
      border: 1px solid $concrete-gray;
    }

    &__current-value {
      float: left;
      padding: 0 24px;
      line-height: 29px;
    }
  }
}
