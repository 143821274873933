.group-normalizer {
  .search-input {
    width: 200px;
    box-shadow: 0 2px 3px 0 rgba(155, 155, 155, 0.3);
  }
  &__container {
    background:$white;
    border-radius: 3px;
    padding: 8px;
    margin-top: 16px;
    box-shadow: 0 2px 3px 0 rgba(155, 155, 155, 0.3);
  }
  &-source {
    &__columns {
      height: 150px;
      white-space: nowrap;
      overflow-x: auto;
      overflow-y: hidden;
      padding: 8px 0;
    }
    &__column {
      display: inline-block;
      white-space: normal;
      vertical-align: top;
      padding: 0 16px;
      border-right: 1px solid lightgray;
      height: 150px;
      overflow-y: auto;
      &-name {
        text-align: center;
        margin-bottom: 16px;
        font-weight: $bold;
        margin-top: 8px;
        padding: 0 16px;
      }
      &-item {
        padding: 7px 16px;
        border-radius: 3px;
        margin: 4px;
        border: 1px dashed$black;
        cursor: pointer;
        background:$white;
        max-width: 150px;
        &.gu-transit {
          display: inline-block;
        }
      }
    }
  }
  &-target {
    margin-top: 16px;
    &__actions {
      position: relative;
      .wethod-tab-switcher {
        position: absolute;
        right: 0;
      }
    }
    &__create-cluster-button-feedback {
      display: inline-block;
      margin-left: 8px;
      font-size: 11px;
      color: $bright-sky-blue;
    }
    &--empty {
      font-size: 12px;
      margin-bottom: 16px;
      padding: 21px 32px;
      text-transform: uppercase;
      font-weight: $semiBold;
      color: $steel-gray;
      background:$white;
      border-radius: 3px;
      margin-top: 16px;
      box-shadow: 0 2px 3px 0 rgba(155, 155, 155, 0.3);
    }
  }
  &__clusters {
    margin-top: 16px;
    overflow-y: auto;
  }
  &-cluster {
    background:$white;
    border-radius: 3px;
    padding: 8px;
    margin-top: 8px;
    &__actions {
      position: relative;
    }
    &__delete-button {
      padding: 2px;
      position: absolute;
      right: 0;
      top: 0;
      opacity: 0;
      transition: .2s;
    }
    &__label {
      margin-bottom: 16px;
      font-weight: $bold;
      margin-top: 8px;
      padding: 0 8px;
      width: 150px;
      .wethod-input {
        box-shadow: 0 2px 3px 0 rgba(155, 155, 155, 0.3);
      }
    }
    &__list {
      min-height: 46px;
    }
    &__item {
      padding: 12px 16px;
      text-align: center;
      position: relative;
      border-radius: 3px;
      margin: 8px;
      border: 1px solid$black;
      background:$white;
      display: inline-block;
      &-parent {
        position: absolute;
        top: -7px;
        width: 100%;
        text-align: center;
        left: 0;
        opacity: 1;
        transition: .2s;
        span {
          background:$white;
          padding: 0 4px;
          color: $bright-sky-blue;
        }
      }
      &-button {
        position: absolute;
        display: inline-block;
        width: 20px;
        height: 20px;
        border-radius: 100px;
        background-color: $bright-sky-blue;
        box-shadow: 0 2px 2px 0 rgba(72, 59, 59, 0.5);
        color:$white;
        font-weight: $light;
        transition: .2s;
        border: none;
        outline: none;
        cursor: pointer;
        opacity: 0;
        z-index: 2;
        &:hover {
          transform: scale(1.2);
        }
      }
      &:hover &-parent {
        opacity: 0;
      }
      &:hover &-button {
        opacity: 1;
      }
    }
    &:hover {
      .group-normalizer-cluster__delete-button {
        opacity: 1;
        &:hover {
          div {
            background-position-x: -48px;
          }
        }
      }
    }
  }
}
