.planning {
  &-navigation {
    float: right;
    position: relative;
    padding: 0;
    right: -32px;
    @include scaleToExtraSmall() {
      right: auto;
    }
  }

  &__prev-icon, &__next-icon {
    background: url("/img/icon/edge_arrows_sprite.svg") no-repeat 0 0;
    background-size: 48px 24px;
    width: 12px;
    height: 12px;
    position: absolute;
    top: 10px;
    left: 8px;
  }

  &__prev-icon {
  }

  &__next-icon {
    background-position: -12px 0;
    left: 11px;
  }
}

.navigation {
  &__today {
    @extend .button;
    padding: 0 40px;
    @include scaleToExtraSmall() {
      padding: 0 17px;
    }
  }

  &__prev {
    display: block;
    right: -32px;
    @include scaleToExtraSmall() {
      display: none;
    }
  }

  &__next {
    display: block;
    left: -32px;
    @include scaleToExtraSmall() {
      display: none;
    }
  }

  &__prev, &__next {
    cursor: pointer;
    z-index: 2;
    border-radius: 50%;
    height: 32px;
    width: 32px;
    float: left;
    transition: 0.15s ease-in-out;
    position: relative;

    &:hover {
      background: $black;

      .planning__prev-icon {
        background-position: 0 -12px;
      }

      .planning__next-icon {
        background-position: -12px -12px;
      }

      div {
        background-position-x: -64px;
      }
    }
  }
}
