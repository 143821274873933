@import 'colors';
@import './node_modules/susy/sass/susy';
@import 'bourbon/bourbon';
@import 'settings';
@import 'reset';
@import 'breakpoint_old';
@import "breakpoints";
@import 'grid';
@import 'jquery/jqueryui';

html {
  height: 100%;
  box-sizing: border-box;
  font-size: $baseFont;
  font-family: $fontFamily;
  background: $maldivian-sand-white;
}

body {
  height: 100%;
}

@include border-box-sizing;

.clear {
  clear: both;
}

// used to prevent margin collapse
.clearCollapse {
  clear: both;
  min-height: 1px;
  margin-top: -1px;
}

.right {
  float: right;
}

.left {
  float: left;
}

.center {
  display: inline-flex;
  align-items: center;
  margin-left: 24px;
}

.table {
  box-sizing: border-box;
  display: table;
  text-decoration: none;
}

.cell {
  display: table-cell;
  vertical-align: middle;
}

.hide {
  display: none;
}

body .hidden-mobile {
  @include scaleToExtraSmall() {
    display: none;
  }
}

.verticalTop {
  vertical-align: top;
}

.verticalBottom {
  vertical-align: bottom;
}

a {
  text-decoration: none;
  color: $linkColor;

  &:hover, &:active, &:visited {
    color: $linkColor;
  }
}

//componenti comuni
@import 'menu_top';
@import 'button';
@import 'modal';

//NEW
@import 'new-button.scss';
@import 'new-modal.scss';

@import 'page';

#appBody {
  position: relative;
  //@include calc(height, "100% - 60px");
  width: 100%;
  /* Needed to avoiding margin collapse. Without this padding, CollectionView / LoadingView / EmptyCollectionView
     became scrollable, and this isn't a behaviour we want.*/
  padding-top: 1px;
  padding-bottom: 1px;
}

.appLoading {
  display: flex;
  width: 100%;
  height: 100vh;

  &--christmas {
    .loadingWrap {
      z-index: 99;

      // Hide the snowflakes behind the loader
      background: $maldivian-sand-white;
      box-shadow: 0 -50px 40px 20px $maldivian-sand-white, 0 30px 40px 20px $maldivian-sand-white;
    }
    .snowflakes {
      z-index: -1;
    }
  }
}

a.appLoading-button {
  padding-top: 10px;
  padding-bottom: 10px;

  color: black;
  font-size: 14px;
  font-weight: normal;
  text-transform: uppercase;
}

//POSITION
.relative {
  position: relative;
}

//TEXT
.textRight {
  text-align: right;
}

.textLeft {
  text-align: left;
}

.textCenter {
  text-align: center;
}

.truncateText {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.uppercase {
  text-transform: uppercase;
}

.bold {
  font-weight: $bold
}

.semiBold {
  font-weight: $semiBold
}

.truncate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.bottomSpacePush {
  width: 100%;
  height: 100px;

  &.white {
    background-color: $white;
  }
}

.capitalize {
  text-transform: capitalize;
}

//DISPLAY
.isVisible {
  z-index: 850 !important;
  opacity: 1 !important;
  display: block !important;
}

.showElement {
  @extend .isVisible;
}

.notVisible {
  opacity: 0;
  z-index: -1;
  display: none;
}

.fullHeight {
  height: auto;
}

//CURSOR
.pointer {
  cursor: pointer;
}

input, textarea, keygen, select, button {
  font-size: $baseFont;
  font-family: $fontFamily;
}

.exportLink {
  padding: 16px;

  &.topLink {
    padding: 0;
    margin-top: 23px;
  }

  &:hover {
    text-decoration: underline;
  }
}

.exportLinkBoxed {
  position: absolute;
  right: 10px;
  top: 23px;
}

@import 'pipeline';
@import 'budget';
@import 'core/planning';
@import 'core/planning/planning-people';
@import 'settings_module';
@import 'report';
@import '3yp';
@import '404';
@import 'permission';
@import 'payment';
@import 'report-timesheet';
@import 'project-charter';
@import 'pipeline-program';

@import 'finance-module/finance';
@import 'report-module/report';
@import 'dashboard-module/dashboard';
@import 'pipeline-module/pipeline';
@import 'settings-module/settings';

.wrapPermissionDeniedText {

  height: 100%;

  .permissionDeniedText {
    position: relative;
    @include calc(top, "50% - 100px");

    color: #2b2e38;
    text-align: center;
    font-size: 100px;
    font-weight: $light;
    line-height: 1.3;
  }
}

//LOADING
.loading {
  margin-top: 15px;
  color: #878B8E;
  text-align: center;
}

.loadingWrap {
  text-align: center;
  position: absolute;
  @include calc(top, "50% - 100px");

  .randomSentence {
    margin-top: 15px;
    color: $black;
    font-size: 30px;
    line-height: 1.3;
    font-weight: lighter;
  }

  @keyframes logoRotation {
    0% {
      @include transform(rotate(0deg) translateZ(0));
    }
    100% {
      @include transform(rotate(360deg) translateZ(0));
    }
  }

  .loadingImg {
    width: 100px;
    height: 100px;
    @include retina-image($iconsPath + logo_wait, 100px 100px);
    @include animation(logoRotation 1.5s ease);
    @include animation-delay(1s);
    @include animation-iteration-count(infinite);
  }
}

.miniLoadingWrap {
  margin: 40px 0;
  @include scaleToExtraSmall() {
    //margin:0;
    height: 80px;
  }

  text-align: center;
  @keyframes logoRotation {
    0% {
      @include transform(rotate(0deg) translateZ(0));
    }
    100% {
      @include transform(rotate(360deg) translateZ(0));
    }
  }

  &.modalCenter {
    margin-top: calc(38% - 30px);
  }

  .loadingImg {
    width: 30px;
    height: 30px;
    @include scaleToExtraSmall() {
      margin-top: -10px;
    }

    @include retina-image($iconsPath + mini_logo_wait, 30px 30px);
    @include animation(logoRotation 1.3s ease);
    @include animation-delay(0s);
    @include animation-iteration-count(infinite);
  }
}

.upperCase {
  text-transform: upperCase;
}

.modalBackground {
  display: none;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 900;
  background: rgba(72, 165, 249, 0.3);
}

//MESSAGE
.errorMessage {
  color: $errorColor;
}

.successMessage {
  color: $bright-sky-blue;
}

.iconStatus {
  display: none;
  position: absolute;
  width: 25px;
  height: 13px;
  bottom: 0;
  left: 3px;
  text-align: left;

  .requiredIcon, .errorIcon, .blockIcon {
    display: none;
  }

  &.required {
    display: block;

    .requiredIcon {
      display: block;
      color: $requiredColor;
      font-size: $baseFont;
      line-height: $baseFont;
    }
  }

  &.error {
    display: block;

    .errorIcon {
      display: block;
      color: $errorColor;
      font-size: $baseFont;
      line-height: $baseFont;
    }
  }

  &.block {
    display: block;

    .blockIcon {
      display: block;
      color: #ebeef1;
      font-size: 9px;
    }
  }

  ul {
    width: 100%;
    display: block;

    li {
      display: none;
      width: 100%;
      height: auto !important;
      background: transparent !important;
    }
  }
}

.noteImg {
  width: 30px;
  height: 30px;
  @include retina-image($iconsPath + note_icon, 30px 30px);
}

.noteFilledImg {
  width: 30px;
  height: 30px;
  @include retina-image($iconsPath + note_filled_icon, 30px 30px);
}

.deletedImg {
  width: 30px;
  height: 30px;
  @include retina-image($iconsPath + delete_icon, 30px 30px);
}

.invoicePlanImg {
  width: 30px;
  height: 30px;
  @include retina-image($iconsPath + button_invoiceplan, 30px 30px);
}

.searchWrap {
  border-radius: 30px;
  padding-right: 25px;
  background: $white;
  border: 1px solid $concrete-gray;
}

.searchIcon {
  width: 29px;
  height: 29px;
  @include retina-image($iconsPath + button_search, 30px 30px);
}

.searchInput {
  width: 150px;
  padding: 6px;
  margin: 0;
  border: none;
  outline: none;

  &--keyword {
    color: $garden-green;
    font-weight: $semiBold;
  }
}

//////////
///da rimuover
///usate in settings Team
///usate in project charter
///prima di rimuoverle vedre le icone mancati in new button
//////////
.spriteActionProject {
  @include retina-image($iconsPath + edit_icons, 150px 60px);
  //background-repeat: no-repeat;
}

.iconProject {
  width: 30px;
  height: 30px;
}

.projectBudget {
  background-position: 0 0;

  &:hover {
    background-position: 0px 30px;
  }
}

.projecPlanning {
  background-position: -30px 0;

  &:hover {
    background-position: -30px 30px;
  }
}

.projecReport {
  background-position: -60px 0;

  &:hover {
    background-position: -60px 30px;
  }
}

.projecModifay {
  background-position: -90px 0;

  &:hover {
    background-position: -90px 30px;
  }
}

.projecOthers {
  background-position: -120px 0;

  &:hover {
    background-position: -120px 30px;
  }
}

//////////////

//OVERWRITE JQUERY UI
.ui-corner-all, .ui-corner-bottom, .ui-corner-left, .ui-corner-bl {
  border-bottom-right-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
  border-top-left-radius: 0 !important;
}

.ui-state-default, .ui-widget-content .ui-state-default, .ui-widget-header .ui-state-default {
  font-weight: $light !important;
}

.ui-icon {
  display: none;
}

.ui-selectmenu-text {
  display: block;
}

.ui-menu {
  font-family: $fontFamily !important;
  z-index: 100;
  width: 200px;
  max-height: 200px;
  overflow-y: auto;
  overflow-x: hidden;
  background: $white;
  outline: 5px solid rgba(72, 165, 249, 0.3) !important;
  border: none !important;
  border-radius: 0 !important;
}

.ui-menu-item, .ui-selectmenu-optgroup {
  font-family: $fontFamily !important;
  font-size: $baseFont !important;
  font-weight: $light !important;
  padding: 10px !important;
  background: $white;

  &:hover, &.ui-state-focus {
    padding: 10px !important;
    margin: 0 !important;
    color: rgb(50, 52, 69) !important;
    font-size: $baseFont !important;

    cursor: pointer;
    background: $white !important;
    border: none !important;
  }

  &:hover {
    color: $bright-sky-blue;
  }
}

span.ui-selectmenu-text {
  font-family: $fontFamily;
  //width: 100% !important;
  height: 100% !important;
  padding: 0 !important;
  display: block !important;
  font-size: $baseFont !important;
  line-height: inherit !important;
  font-weight: $light !important;
  font-style: italic !important;
  width: 43px !important;

  .row.open, .new_project_row & {
    color: $linkColor;
  }
}

.ui-selectmenu-button.ui-widget.ui-state-default.ui-corner-all, .ui-selectmenu-button.ui-widget.ui-state-default.ui-corner-top {
  width: 100% !important;
  height: 100% !important;
  display: block !important;
  background: $white !important;
  border: none !important;
  font-size: $baseFont !important;

  &:focus {
    outline: none;
  }

  .ui-icon.ui-icon-triangle-1-s {
    display: none;
  }
}

.ui-selectmenu-optgroup .ui-menu-divider, .ui-selectmenu-menu .ui-menu .ui-selectmenu-optgroup {
  color: $bright-sky-blue !important;
  padding: 10px 10px 0 !important;
  font-size: $baseFont !important;
  margin: 0px !important;
  font-weight: $light !important;
  font-family: $fontFamily !important;
}

//override datepiker
.ui-widget-header {
  background: $bright-sky-blue !important;
  border: none;
}

.ui-widget-content {
  border: none !important;
}

.ui-state-default, .ui-widget-content .ui-state-default, .ui-widget-header .ui-state-default {
  text-align: center;
  color: #323445 !important;
  background: $white;
}

.ui-state-hover {
  border: 1px solid $bright-sky-blue !important;
  background: #00baff !important;
  color: $white !important;
}

.ui-state-active, .ui-widget-content .ui-state-active, .ui-widget-header .ui-state-active {
  border: 1px solid $bright-sky-blue !important;
  background: #00baff !important;
  color: $white !important;
}

.ui-state-highlight, .ui-widget-content .ui-state-highlight, .ui-widget-header .ui-state-highlight {
  border: 1px solid $bright-sky-blue !important;
  background: #00baff !important;
  color: $white !important;
}

.ui-datepicker-today {
  .ui-state-highlight {
    text-align: center;
    color: #323445 !important;
    background: $white !important;
  }
}

// ---- Fixed header
.fixedScroll {
  position: fixed;
  z-index: 801;
  width: 100%;
}

.secondary-nav {
  position: fixed;
  top: 60px;
  z-index: 850;
  width: 100%;
  background-color: #141519;
}

.fixedModuleScroll {
  @extend .fixedScroll;
  z-index: 700;
}

.marginTopScroll {
  //margin-top: 60px;
  width: 100%;
  float: left; // avoid margin collapse
  @include print() {
    display: none;
  }
}

.maxZindex {
  z-index: 825;
}

/*.marginTopPipelineScroll {
  @extend .marginTopScroll;
  margin-top: 174px;

  @include scaleToExtraSmall() {
    margin-top: 131px;
  }
}

.marginTopPipelineProgramScroll {
  @extend .marginTopScroll;
  margin-top: 89px;
}

.marginTopPlanningDateScroll {
  @extend .marginTopScroll;
  margin-top: 115px;
  @include scaleToSmall() {
    margin-top: 156px;
  }
  @include scaleToExtraSmall() {
    margin-top: 158px;
  }
}

.marginTopPlanningBodyScroll {
  @extend .marginTopScroll;
  margin-top: 36px;
}

.marginTopTimesheetScroll {
  @extend .marginTopScroll;
  margin-top: 129px;
  @include scaleToSmall() {
    margin-top: 175px;
  }
  @include scaleToExtraSmall() {
    margin-top: 144px;
  }
}

.marginTopProjectStatusScroll {
  @extend .marginTopScroll;
  margin-top: 115px;
  @include scaleToExtraSmall() {
    margin-top: 119px;
  }
}

.marginTopBubbleScroll, .marginTop3YPScroll {
  @extend .marginTopScroll;
  margin-top: 25px;

  @include scaleToExtraSmall() {
    margin-top: 0px;
  }
}

.marginTopInvoiceScroll, .marginTopOrderScroll {
  @extend .marginTopScroll;
  margin-top: 102px;
}

.marginTopBudgetHeadScroll {
  @extend .marginTopScroll;
  margin-top: 55px;
}

.marginTopBudgetTotScroll {
  @extend .marginTopScroll;
  margin-top: 0;
}

.marginTopBudgetRecapScroll {
  @extend .marginTopScroll;
  margin-top: 0;
}*/

// Project type colored dot
.projectTypeDotWrap {
  display: table-cell;
  vertical-align: middle;
  width: $baseFont;
  padding-top: 4px;
  padding-right: 24px;

  .projectTypeDot {
    display: inline-block;
    width: $baseFont;
    height: $baseFont;
    border-radius: 50%;
    background: $steel-gray;
  }
}

.noSelect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Chrome and Opera */
}

.tooltipReact {
  position: absolute;
  background: #616161;
  white-space: nowrap;
  padding: 4px 8px;
  top: -18px;
  display: inline-block;
  max-width: 400px;
  z-index: 1;
  opacity: 1;
  text-overflow: ellipsis;
  color: $white;
  font-size: 11px;
  font-weight: 100;
  transition: opacity 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, transform 133ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  border-radius: 4px;

  &--fixed {
    position: fixed;
    pointer-events: none;
    line-height: normal;
    z-index: 99999;
  }
}

.playground {
  &-banner {
    height: 40px;
    background-color: $garden-green;
    line-height: 40px;
    vertical-align: middle;
    @include scaleToExtraSmall() {
      padding: 0 8px;
    }

    &__content {
      max-width: 1440px;
      position: relative;
      margin: auto;
    }

    &__message {
      display: inline-block;
      position: relative;
      left: 24px;

      &-description {
        @include scaleToExtraSmall() {
          display: none;
        }
      }
    }

    &__button {
      color: $black !important;
      position: absolute;
      right: 24px;
      top: 5px;

      &:hover {
        color: $garden-green !important;
        background: $black !important;
      }
    }
  }
}

.update {
  &-banner {
    @include scaleToExtraSmall() {
      max-height: 0;
      overflow: hidden;
    }
    background: $sunset-orange;

    &__content {
      max-width: 1440px;
      position: relative;
      margin: auto;
      padding: 12px 24px;
    }

    &__text {
      display: inline-block;
      vertical-align: top;
      width: calc(100% - 304px);
    }

    &__actions {
      display: inline-block;
      vertical-align: bottom;
      width: 300px;
      text-align: right;
    }

    &__title {
      font-size: 14px;
      font-weight: $semiBold;

      &:before {
        content: 'NEW';
        font-size: 10px;
        padding: 0 4px;
        border: 1px solid;
        border-radius: 4px;
        margin-right: 6px;
      }
    }

    &__description {
      margin-top: 8px;
      line-height: 18px;

      &-text {
        margin-right: 10px;
      }
    }

    &__take-me-there {
      color: $black !important;
      text-decoration: underline;
      font-weight: $semiBold;
      display: block;
    }

    &__button {
      &.wethod-button {
        display: inline-block;
        color: $black;

        &:hover {
          background: $black;
          color: $sunset-orange;
        }
      }

      &--learn-more {
        margin-right: 8px;
      }
    }
  }
}

.appBanner {
  position: relative;
  z-index: 1000;
}

.bodyContent {
  position: relative;
}

@import "wethod";
@import "app-nav";
@import 'economics/main';
@import 'header/main';
@import 'bubble';
@import "year-slider";
@import "search-input";
@import "empty-row";
@import "core/settings/alerts-engine";
@import "core/friday/timesheet";
@import "core/friday/project-status";
@import "core/pipeline/basket";
@import "core/budget/budget";
@import "core/finance/clients";
@import "core/timeline/timeline";
@import "group/main";
@import "core/settings/profile";
@import "sidebar";
@import "material-input";
@import "widgets/widget";
@import "core/project-canvas/project-canvas";
@import "core/desk/desk";
@import "core/report/briefing";
@import "core/report/project-review-changelog";
@import "core/report/budget-changelog";
@import "core/report/demographics";
@import "core/report/chargeability";
@import "core/report/kudos";
@import "core/report/knowledge";
@import "date-selector";
@import "trend";
@import "core/project-review/project-review";
@import "core/budget-template/budget-template";
@import "core/budget-creation/budget-creation";
@import "core/travel/travel";
@import "navigation-button";
@import "core/finance/suppliers";
@import "core/finance/orders";
