
.paymentTitle {
  width: 100%;
  margin-bottom: 6px;
  font-size: 18px;
  text-align: center;
  font-weight: $semiBold;
  line-height: 1.3;
}

.paymentCreditCard {
  background: $white;
  padding: 6px 12px 8px !important;
  margin-top: 6px;
  border-radius: 4px;

  .paymentErrors, .paymentSuccess {
    display: none;
    padding: 12px 0px 0px;
    font-weight: $semiBold;
    text-align: center;
  }

  .paymentErrors {
    color: $raspberry-red;
  }

  .paymentSuccess {
    color: $garden-green;
  }

  .paymantLabel {
    margin-top: 7px;
  }

  .roundButton {
    float: left;
    margin-top: 12px;
  }

  .paymentRow {
    float: left;
    margin-bottom: 6px;
    width: 100%;
    margin-bottom: 6px;
  }

  input[type="text"] {
    width: 100%;
    padding: 3px 6px;
    border-radius: 3px;
    border: 1px solid #ccc;
  }
}

.paymentPlanList {
  float: left;
  width: 100%;
}

.paymentPlan {
  background: $white;
  padding: 10px 0;

  &.userPlan {
    border: 2px solid $bright-sky-blue;
    padding: 8px 0;
  }

  .name {
    width: 100%;
    text-transform: uppercase;
    font-size: 14px;
    text-align: center;
    font-weight: $semiBold;
    line-height: 1.3;
  }

  .priceWrap {
    width: 100%;
    font-size: 45px;
    font-weight: $bold;
    line-height: 1.3;
    text-align: center;

    .small {
      font-weight: $normal;
      font-size: 14px;
    }
  }

  .period {
    line-height: 1.3;
    float: left;
    width: 100%;
    margin-bottom: 5px;
  }

  .details {
    float: left;
    width: 100%;
    margin-top: 6px;

    .priceLabel, .priceData {
      float: left;
      width: 50%;
      padding: 6px;
      font-weight: $semiBold;
    }
  }
}

.paymentInfoLabel, .paymentInfoDate {
  float: left;
  padding: 6px;
  line-height: 1.3;
}

//NEW
.fullsizePayment {
  height: 100%;
  min-height: 100%;
  margin-bottom: -102px;
  background: $white;
}

.sectionPaymentTitle {
  color: $bright-sky-blue;
  font-size: 18px;
}

.sectionPaymentInfo {
  margin: 10px 0;
  color: rgb(153, 153, 153);
}

.footerPayment, .pushPayment {
  height: 102px;
}

.stripeLogo {
  opacity: 0.3;
  margin-top: 16px;
}

.paymentInfo {
  margin-top: 8px;
  color: #ccc;
}

.paymentButtonPushRight {
  margin-right: 8px;
}

.creditCardLabel, .creditCardData {
  &.pushBottom {
    margin-bottom: 16px;
  }
}

.creditCardData {
  color: #333333;
}

//promocode
.payment-promocode {
  float: left;
  margin: 40px 0;

  .payment-promocode-title {
    margin-bottom: 20px;
  }

  .payment-promocode-alert {
    display: none;
    margin-bottom: 20px;
    line-height: 1.3;
    color: $dusty-gray;
  }

  .payment-promocode-applayButton {
    margin-top: 14px;
  }

  .payment-promocode-feedback {
    height: 14px;
    padding-left: 4px;
    margin-top: 6px;
    font-size: 11px;
    line-height: 1.3;
    color: $bright-sky-blue;

    &.payment-promocode-feedback-error {
      color: $raspberry-red;
    }
  }

  .payment-promocode-info {
    display: none;
    float: left;
    width: 100%;
  }

  $labelColor: #bfc0c6;

  .mdl-textfield {
    font-size: $baseFont !important;
    width: 100%;
    //padding: 14px 0;
    padding: 0;

    .mdl-textfield__input {
      padding: 16px 8px !important;
      font-size: $baseFont !important;
      border: none;
      border-bottom: 1px solid $concrete-gray;;

      &.billingError {
        border-color: $raspberry-red;
      }
    }

    .mdl-textfield__label {
      height: 16px;
      top: 18px;
      line-height: 1.3;

      display: block;
      font-size: $baseFont;
      //    color: $placeholderColor !important;

      &.billingError {
        color: $raspberry-red !important;
      }

      &::after {
        display: none;
      }
    }


    &.mdl-textfield--floating-label.is-focused .mdl-textfield__label,
    &.mdl-textfield--floating-label.is-dirty .mdl-textfield__label {
      top: -2px;
      color: $bright-sky-blue !important;
      font-size: 10px !important;

      &.billingError, &.error {
        color: $raspberry-red !important;
      }
    }

    &.mdl-textfield--floating-label.is-upgraded .mdl-textfield__label {
      &.billingError, &.error {
        color: $raspberry-red !important;
      }
    }

  }
}

//MODAL
.paymentCardModalStructure, .paymentBillingInfoModalStructure {
  @extend .modalStructure;

  .statusWrapper {
    padding: 3px 46px 10px;
    text-align: center;

    .koMessage {
      color: $raspberry-red;
    }
  }
}

.paymentModalStructure {
  @extend .modalStructure;
}

.paymentBillingInfoModalStructure {
  width: 600px;
}


.modalWrapHeadInfo {
  padding: 16px;

  .modalName {
    font-size: 14px;
    text-transform: capitalize;
  }

  .colseModal {
    font-size: 16px;
    margin-top: 2px;
  }
}


.modalWrapAction {
  float: left;
  margin: 84px 0 6px;
}
