$company-order-plan-months: 12;

.company-order-plan {
  &__bu-select {
    display: inline-block;
    vertical-align: middle;
    margin-right: 16px;
  }

  &-totals-by-month {
    cursor: auto !important;
    background: white !important;

    .company-order-plan-project__info {
      cursor: auto !important;
      background: white !important;
      color: initial !important;
    }
  }

  &__currencies {
    border-top: 1px solid rgba(155, 155, 155, 0.28);

    &-item {
      background: $white;
      height: 60px;
      line-height: 60px;
      cursor: auto;

      &-info {
        display: inline-block;
        vertical-align: top;
        padding: 0 16px;
        width: 17%;
        color: inherit;

        &-content {
          width: 100%;
          line-height: initial;
          display: inline-block;
          vertical-align: middle;
        }

        &-name {
          width: 100%;
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
          font-weight: $semiBold;
        }
      }

      &-months {
        display: inline-block;
        width: 75%;
        position: relative;
        height: inherit;
      }
    }

    &-opener {
      display: inline-block;
      width: 83%;
      cursor: pointer;

      &:hover {
        color: inherit;
        background: rgba(158, 158, 158, 0.2);
      }

      .company-order-plan-project {
        &__months {
          width: 90%;
        }

        &__actions {
          width: 10%;
        }
      }
    }
  }

  &__legend {
    margin-top: -8px;
    margin-left: 4px;

    &-item {
      font-weight: 100;
      margin-right: 16px;
      margin-top: 8px;
      text-align: left;

      &::before {
        content: '';
        display: inline-block;
        width: 11px;
        height: 11px;
        border-radius: 50%;
        margin-right: 8px;
      }

      &--planned {
        &::before {
          background: $black;
        }
      }

      &--approved {
        &::before {
          background: #d5d5d5;
        }
      }

      &--lower {
        &::before {
          background: $raspberry-red;
        }
      }
    }
  }

  &-totals-by-month {
    .company-order-plan-project__info {
      color: $bright-sky-blue;
      font-weight: $bold;
      text-transform: uppercase;
    }

    .company-order-plan-project__months {
      font-weight: $bold;
    }
  }

  &-overview {
    display: flex;
    align-content: center;
    justify-content: space-between;
    text-align: right;
    margin: 16px 0;
    align-items: flex-end;
    padding: 0 2px;

    &__total {
      text-align: right;
      padding-right: 2px;
    }
  }

  &__actions {
    display: flex;
    align-content: center;
    justify-content: space-between;
    margin-bottom: 16px;

    &-wrapper {
      padding-top: 16px;
    }

    &--left {
      .wethod-select, .company-order-plan__probability-filter {
        display: inline-block;
        vertical-align: middle;
      }

      .search-input {
        display: inline-flex;
        vertical-align: middle;
      }
    }
  }

  .search-input {
    width: 300px;
  }

  &-project {
    &__collection {
      overflow: auto;
      // Let the collection takes all the remaining space (other than the space used by menus & headers)
      height: calc(100vh - 310px);
      overflow-x: hidden;
    }

    &-row {
      background: $white;
      border: 1px solid $concrete-gray;
      border-top: 0;
      max-height: 60px;
      line-height: 60px;
      transform: translate3d(0, 0, 0); // enable GPU calculation on change year to avoid scroll blocking
      transition: .3s;
      cursor: pointer;
      overflow: hidden;

      &--empty {
        text-transform: uppercase;
        font-weight: $semiBold;
        color: $steel-gray;
        text-align: center;
      }
    }

    &__info {
      display: inline-block;
      vertical-align: top;
      padding: 0 16px;
      width: 17%;
      color: inherit;

      &-content {
        width: 100%;
        line-height: initial;
        display: inline-block;
        vertical-align: middle;
      }

      &-name {
        width: 100%;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
      }

      &-client {
        color: #999;
        width: 100%;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
      }

      &-mode {
        text-transform: uppercase;
        font-size: 9px;
        color: $bright-sky-blue;
        opacity: 0.8;
        line-height: initial;
      }

      &:hover {
        color: inherit;
        background: rgba(158, 158, 158, 0.2);
      }

      &:visited {
        color: inherit;
      }
    }

    &__months {
      display: inline-block;
      width: 75%;
      position: relative;
      transform: translateX(0);
      opacity: 1;
      height: inherit;
    }

    &__month {
      position: relative;

      &-col {
        display: inline-block;
        vertical-align: top;
        width: percentage(1 / $company-order-plan-months);
        text-align: right;
        padding: 0 8px;
      }

      &-value {
        vertical-align: middle;
        display: inline-block;

        &-item {
          line-height: initial;
        }
      }

      &-ordered {
        margin-top: 6px;
        opacity: 0.5;
        display: inline-block;
      }
    }

    &__total {
      font-weight: $semiBold;
      display: inline-block;
      text-align: right;
      width: 100%;
      white-space: pre;
    }

    &__actions {
      display: inline-block;
      width: 8%;
      text-align: right;
      padding: 0 8px;
    }
  }

  &-header {
    border: 0;
    background: $morning-fog-gray;
    color: $black;
    font-weight: $semiBold;
    height: 30px;
    line-height: 30px;
    cursor: auto;
  }
}
