$col      : 16;     //numero di colonne che si vuole utilizzare
$maxWidth : 1024px; //larghezza massima contenuto
$minWidth : 320px;  //larghezza minima contenuto
$gutter   : 3px;    //padding interno ad ogni colonna
$baseFont : 12px;

//MIXIN: gestisce il box-sizing di un elemento
@mixin box-sizing($size:content-box) {
  -webkit-box-sizing    : $size;
       -moz-box-sizing  : $size;
        -ms-box-sizing  : $size;
            box-sizing  : $size;
}

//FUNC: determina la larghezza di una colonna in %
@function colWidth($n, $width:$maxWidth) {
    @return percentage( (($width/($col/$n)) ) / $width);
}
//FUNC: conversione in em
@function emConv($target, $context:$baseFont) {
    @return ($target/$context) * 1em ;
}
//FUNC: conversione in %
@function percentageConv($target, $context:$maxWidth) {
    @return percentage($target/$context) ;
}
//FUNC:
@function returnMixin($mixin){
  @return   $mixin;
}
//MIXIN: inserisce il contenuto all'interno di una media query
@mixin scaleTo($query, $val:max-width) {
  @media ($val: $query) {
    @content
  }
}

$extraSmall   : max-width 768px col_xs ;
$smallDevice  : min-width 600px max-width 768px col_sm ;
$mediumDevice : min-width 768px max-width 950px col_md ;
$largeDevice  : max-width 950px col_lg ;

@mixin scaleToExtraSmall($max:nth($extraSmall,2)) {
  @media (max-width: $max) {
    @content
  }
  @media (max-device-width : $max) {
    @content
  }
}
@mixin scaleToExtraSmallDevice($max:nth($extraSmall,2)) {
  @media (max-device-width : $max) {
    @content
  }
}
@mixin scaleToSmall($min:nth($smallDevice,2),$max:nth($smallDevice,4)) {
  @media (min-width: $min) and (max-width: $max) {
    @content
  }
  @media (max-device-width : $max) {
    @content
  }
}
@mixin scaleToSmallDevice($max:nth($smallDevice,4)) {
  @media (max-device-width : $max) {
    @content
  }
}
@mixin scaleToMedium($min:nth($mediumDevice,2),$max:nth($mediumDevice,4)) {
  @media (min-width: $min) and (max-width: $max) {
    @content
  }
}
@mixin scaleToLarge($min:nth($largeDevice,2)) {
  @media (min-width: $min) {
    @content
  }
}

.fluid{
  @include box-sizing(border-box);
  clear :   both;
  .grid{
    @include box-sizing(inherit);
    max-width   : $maxWidth;
    min-width   : $minWidth;
    width       : 100%;
    margin      : 0 auto;
  }
  .col_full{
      width:100%;
      @include box-sizing(inherit);
  }

  @for $n from 1 to ($col +1 ){
    .col_#{$n}{
        width: colWidth($n,$maxWidth);
        @include box-sizing(inherit);
        padding: $gutter;
        &.no_padding{
            padding:0;
        }
        &.padding_tb{
            padding: $gutter 0 $gutter;
        }
        &.padding_lr{
            padding: 0 $gutter;
        }
    }
    //creazioni classi di margine
    .col_offset_sx_#{$n}{
      margin-left: colWidth($n,$maxWidth);
    }
    .col_offset_dx_#{$n}{
      margin-right: colWidth($n,$maxWidth);
    }
  }

    //col_lg_#n# : colonne attive x > 1200
    @include scaleToLarge(){
        @for $n from 1 to ($col +1) {
          .#{nth($largeDevice,3)}_#{$n}{
            width: colWidth($n,nth($largeDevice,2));
            box-sizing:inherit;
            padding: emConv($gutter);
          }
          //creazioni classi di margine
          .#{nth($largeDevice,3)}_offset_sx_#{$n}{
            margin-left: colWidth($n,nth($largeDevice,2));
          }
          .#{nth($largeDevice,3)}_offset_dx_#{$n}{
            margin-right: colWidth($n,nth($largeDevice,2));
          }
        }

        .#{nth($largeDevice,3)}_hide{
          display:none;
        }
        .#{nth($largeDevice,3)}_show{
          display:block;
        }
    }

    //col_md_#n# : colonne attive tra   x > 950 &&  x < 1200
    @include scaleToMedium(){
        @for $n from 1 to ($col +1) {
          .#{nth($mediumDevice,5)}_#{$n}{
              width: colWidth($n,nth($mediumDevice,4));
              //box-sizing:inherit;
              @include box-sizing(inherit);
              padding: emConv($gutter);
          }
          //creazioni classi di margine
          .#{nth($mediumDevice,5)}_offset_sx_#{$n}{
              margin-left: colWidth($n,nth($mediumDevice,4));
          }
          //col_md_offset_dx_#n#
          .#{nth($mediumDevice,5)}_offset_dx_#{$n}{
              margin-right: colWidth($n,nth($mediumDevice,4));
          }

        }

        //col_md_offset_none
        .#{nth($mediumDevice,5)}_offset_none{
          margin-left:0;
        }
        //col_md_offset_dx_none
        .#{nth($mediumDevice,5)}_offset_dx_none{
          margin-right:0;
        }

        .#{nth($mediumDevice,5)}_hide{
          display:none;
        }
        .#{nth($mediumDevice,5)}_show{
          display:block;
        }
    }

    //col_sm_#n# : colonne attive tra   x > 768 &&  x < 950
    @include scaleToSmall(){
        @for $n from 1 to ($col +1) {
          .#{nth($smallDevice,5)}_#{$n}{
            width: colWidth($n,nth($smallDevice,4));
            //box-sizing:inherit;
            @include box-sizing(inherit);
            padding: emConv($gutter);

            &.no_padding{
                padding:0;
            }
          }
          //creazioni classi di margine
          .#{nth($smallDevice,5)}_offset_sx_#{$n}{
            margin-left: colWidth($n,nth($smallDevice,4));
          }
          //col_sm_offset_dx_#n#
          .#{nth($smallDevice,5)}_offset_dx_#{$n}{
            margin-right: colWidth($n,nth($smallDevice,4));
          }
        }

        //col_md_offset_none
        .#{nth($smallDevice,5)}_offset_none{
            margin-left:0;
        }
        //col_md_offset_dx_none
        .#{nth($smallDevice,5)}_offset_dx_none{
            margin-right:0;
        }


        .#{nth($smallDevice,5)}_hide{
          display:none;
        }
        .#{nth($smallDevice,5)}_show{
          display:block;
        }
    }

    //col_xs_#n# : colonne che si attivano a 768px
    @include scaleToExtraSmall(){
        @for $n from 1 to ($col +1) {
                .#{nth($extraSmall,3)}_#{$n}{
                width: colWidth($n,nth($extraSmall,2));
                //box-sizing:inherit;
                @include box-sizing(inherit);
                padding: emConv($gutter);

                &.no_padding{
                    padding:0;
                }
                &.padding_lr{
                    padding: 0 $gutter;
                }
            }

            //creazioni classi di margine
            .#{nth($extraSmall,3)}_offset_sx_#{$n}{
                margin-left: colWidth($n,nth($extraSmall,2));
            }
            .#{nth($extraSmall,3)}_offset_dx_#{$n}{
                margin-right: colWidth($n,nth($extraSmall,2));
            }
        }
        //col_xs_offset_none
        .#{nth($extraSmall,3)}_offset_none{
            margin-left:0;
        }
        //col_xs_offset_dx_none
        .#{nth($extraSmall,3)}_offset_dx_none{
            margin-right:0;
        }
        //col_xs_no_sx_dx_padding
        .#{nth($extraSmall,3)}_no_sx_dx_padding{
            padding-left:0;
            padding-right:0;
        }

        .#{nth($extraSmall,3)}_hide{
            display:none;
        }
        .#{nth($extraSmall,3)}_show{
            display:block;
        }
    }

}
