.budget-template {
  .section-header {
    padding-top: 16px;
  }
  .wethod-table__content {
    display: block;
  }

  &-table {
    &__column {
      &--name {
        width: 25%;
      }
      &--description {
        width: 42%;
      }
      &--info {
        width: 27%;
        font-size: 12px;
        color: $dusty-gray;
      }
      &--actions {
        width: 6%;
        text-align: right;
      }
    }
  }

  &__sidebar {
    &-body {
      padding-top: 16px;
    }
  }

  &__structure {
    strong {
      font-weight: $semiBold;
    }
    li > ul {
      li {
        margin-left: 30px;
        line-height: 1.5;
        &::before {
          content: "•";
          margin-left: -12px;
          padding-right: 7px;
          font-size: 15px;
        }
      }
    }
  }
}
