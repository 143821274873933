@import '../../colors';

.budget {
  position: relative;
  overflow-y: hidden;

  .wethod-button {
    display: inline-block;
    margin: 0 5px;
  }

  &__first-col {
    display: inline-block;
    width: 15%;
    @include scaleToSmall() {
      width: 40%;
    }
  }

  &__first-col-new {
    display: inline-block;
    width: 17%;
    @include scaleToSmall() {
      width: 40%;
    }
  }

  &__days-col {
    display: inline-block;
    width: 5%;
    text-align: center;
    font-weight: $semiBold;
    @include scaleToSmall() {
      width: 20%;
    }
  }

  &__days-col-new {
    display: inline-block;
    width: 19%;
    text-align: center;
    font-weight: $semiBold;
    @include scaleToSmall() {
      width: 20%;
    }
  }

  &__levels-col {
    display: inline-block;
    width: 45.5%;
    @include scaleToSmall() {
      display: none;
    }
  }

  &__level-col {
    display: inline-block;
    text-align: center;
    margin-left: 3px;
  }

  @include scaleToMedium() {
    width: 1024px;
  }

  &-section-header {
    .section-header__bottom-spacer {
      @include scaleToSmall() {
        height: 28px;
      }
    }
  }

  &-area {
    &__container {
      position: relative;

      &--fit-content {
        width: fit-content;
      }

    }

    &--horizontal-scroll {
      overflow-x: scroll;
      scrollbar-width: none;
    }

    &__pricelist {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    &__tasks {
      overflow-y: hidden;
      overflow-x: hidden;
      position: relative;
      &--disabled {
        opacity: 0.2;

      }
    }

    &__info {
      height: 52px;
      background-color: #696D80;
      color: $white;
      box-shadow: 0 2px 3px 0 rgba(155, 155, 155, 0.3);
      padding: 0 11px;
      margin-top: 10px;
      cursor: pointer;
      display: flex;
      flex-direction: column;
      justify-content: center;

      &--center-column {
        padding: 0;
      }

      &--new {
        cursor: auto;
      }

      &--disabled {
        background-color: $concrete-gray;
      }
    }

    &__name {
      display: inline-block;
      width: 15%;

      &--left-column {
        width: 100%;
      }

      @include scaleToSmall() {
        width: 40%;
      }
      font-weight: $semiBold;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    &__levels {
      display: inline-block;
      width: 45.5%;
      @include scaleToSmall() {
        display: none;
      }
      vertical-align: top;
    }

    &__level {
      display: inline-block;
      text-align: center;
      margin-left: 3px;
      width: 48px;
    }

    &__days {
      display: inline-block;
      width: 5%;
      text-align: right;
      font-weight: $semiBold;
      @include scaleToSmall() {
        width: 20%;
      }
      vertical-align: top;
    }

    &__days-new {
      display: inline-block;
      width: 100%;
      text-align: center;
      font-weight: $semiBold;
      @include scaleToSmall() {
        width: 20%;
      }
      vertical-align: top;
    }
  }

  &-task {
    height: 48px;
    background: $white;
    box-shadow: 0 2px 3px 0 rgba(155, 155, 155, 0.3);
    margin-top: 3px;
    padding: 0 11px;
    display: flex;
    align-items: center;
    line-height: initial;
    position: relative;
    transition: .4s;

    &--center-column {
      padding: 0;
    }

    &__name {
      display: inline-block;
      width: 15%;

      &--left-column {
        width: 100%;
      }

      @include scaleToSmall() {
        width: 40%;
      }
      font-weight: $semiBold;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;

      &:hover {
        .budget-task__name--complete {
          opacity: 1;
          display: inline-block;
        }
      }

      &--complete {
        position: absolute;
        top: 9px;
        left: 11px;
        background: $white;
        padding: 0 8px;
        z-index: 3;
        height: fit-content;
        line-height: 30px;
        border-radius: 5px;
        box-shadow: 0 0 15px 0 rgba(155, 155, 155, 0.3);
        opacity: 0;
        display: none;
      }

      input[type=text] {
        opacity: 0.5;
        font-size: 14px;
        font-weight: $semiBold;
        line-height: 19px;
        border: none;
        width: 100%;
        outline: none;
      }
    }

    &__levels {
      display: inline-block;
      width: 45.5%;
      @include scaleToSmall() {
        display: none;
      }
      vertical-align: top;

      .wethod-search-select {
        float: left;
        white-space: nowrap;
        padding: 0 6px;
      }
    }

    &__level {
      display: inline-block;
      text-align: center;
      margin-left: 3px;
      width: 48px;
    }

    &__days {
      display: inline-block;
      width: 5%;
      text-align: right;
      font-weight: $semiBold;
      @include scaleToSmall() {
        width: 20%;
      }
      vertical-align: top;
    }

    &__days-new {
      display: inline-block;
      width: 5%;
      text-align: center;
      font-weight: $semiBold;
      @include scaleToSmall() {
        width: 20%;
      }
      vertical-align: top;
    }

    &:hover &__actions {
      opacity: 1;
    }
  }

  &-actions {
    position: absolute;
    bottom: 20px;
    right: 30px;
    z-index: 3;

    &__button {
      border-radius: 100px;
      background-color: $bright-sky-blue;
      box-shadow: 0 2px 11px 0 rgba(72, 59, 59, 0.5), 0 2px 9px 0 rgba(0, 0, 0, 0.3);
      color: $white;
      font-size: 14px;
      font-weight: $light;
      letter-spacing: 0.25px;
      transition: .2s;
      line-height: 16px;
      padding: 10px 22px;
      cursor: pointer;
      text-align: center;
      opacity: 1;

      &:hover {
        background-color: #65aff9;
      }
    }

    &__delete-task {
      width: 36px;
      height: 36px;
      background: $raspberry-red;
      border-radius: 50%;
      position: absolute;
      bottom: 20px;
      left: 30px;
      margin-left: calc(50% - 36px);
      z-index: 2;
      display: inline-block;
      transition: .4s;
      text-align: center;
      box-shadow: 0 2px 11px 0 rgba(72, 59, 59, 0.5), 0 2px 9px 0 rgba(0, 0, 0, 0.3);
      transform: scale(1);
      padding: 5px;
      opacity: 0;
      visibility: hidden;

      &--show {
        opacity: 1;
        visibility: visible;
      }

      &--bigger {
        transform: scale(1.7);
      }

      .gu-transit, .budget-task {
        display: none;
      }
    }
  }

  &-modal {
    &__body {
      width: 350px;

      textarea {
        width: 100%;
        height: 100px;
        outline: none;
        border: 1px solid $concrete-gray;
        padding: 8px;
      }

      .material-input__input {
        .wethod-input {
          padding: 5px 10px;

          textarea {
            padding: 0 6px !important;
            font-size: 14px;
          }
        }
      }
    }

    &__actions {
      text-align: right;
      margin-top: 32px;

      button, a {
        margin-left: 10px;
      }

      a {
        display: inline-block;
        font-size: 12px;
      }

      &--no-ps-correction {
        text-align: center;
      }

      .roundButton {
        display: inline-block;
        margin: 0 6%;
      }

      &--approve {
        .roundButton {
          display: inline-block;
          margin: 0 2.5%;
        }
      }
    }

    &__select-receiver {
      position: relative;
      width: 90%;
      margin: auto;
    }

    &__selected-receiver {
      display: block;
      border-radius: 30px;
      font-size: 11px;
      text-align: center !important;
      font-weight: $semiBold;
      cursor: pointer;
      outline: none;
      text-transform: uppercase;
      padding: 8px 20px !important;
      border: 1px solid #bfc0c6;
      background: $white;
      color: #bfc0c6;

      &:hover {
        color: $bright-sky-blue;
      }
    }

    &__receivers {
      position: absolute;
      min-width: 125px;
      max-height: 350px;
      overflow: auto;
      background: $white;
      color: $black;
      padding: 3px;
      border: none;
      outline: 5px solid rgba(72, 165, 249, 0.3);
      width: calc(100% - 12px);
      margin: 8px 6px 0 6px;
    }

    &__receiver {
      padding: 6px;

      a {
        color: $black;
        display: block;

        &:hover {
          color: $bright-sky-blue;
        }
      }
    }
  }

  &-table-header {
    color: $dusty-gray;
    font-size: 14px;
    letter-spacing: 0.25px;
    line-height: 19px;
    margin-top: 20px;
    @include scaleToSmall() {
      margin-top: 8px;
    }

    &-row {
      background: #D4DCE4;
      border-radius: 30px;
      padding: 0 12px;

      &--totals {
        color: #4A4A4A;
        padding: 0 12px;
        height: 45px;
        line-height: 45px;
      }
    }

    &__price-list {
      @extend .budget__first-col;
    }

    &__price-list-new {
      @extend .budget__first-col-new;
    }

    &__levels {
      @extend .budget__levels-col;
    }

    &__level {
      @extend .budget__level-col;
      line-height: 30px;
      width: 48px;
      border-radius: 2px;
      transition: .4s;

      &--not-empty {
        color: #4A4A4A;
        font-weight: $semiBold;
      }

      &--no-shrink {
        flex-shrink: 0;
      }
    }

    &__days {
      @extend .budget__days-col;
      color: #4A4A4A;
      font-weight: $semiBold;
    }

    &__days-new {
      @extend .budget__days-col-new;
      color: #4A4A4A;
      font-weight: $semiBold;
    }

    &__total {
      &-label {
        @extend .budget__first-col;
        font-weight: $bold;
      }

      &-label-new {
        @extend .budget__first-col-new;
        font-weight: $bold;
      }

      &-levels {
        @extend .budget__levels-col;
      }

      &-level {
        @extend .budget__level-col;
        width: 48px;
        position: relative;

        &--no-shrink {
          flex-shrink: 0;
        }
      }

      &-days {
        @extend .budget__days-col;
      }

      &-days-new {
        @extend .budget__days-col-new;
      }
    }
  }
}

