$borderSize: 1px;
$borderColor: #adb0b7;

$headH: 30px - $gutter * 2;
$rowH: 60px - $gutter * 2;
$borderBudget: 1px;

.shareLinkAutocomplete {
  display: none;
  position: absolute;
  top: -25px;
  left: 0;
  width: 100%;
  outline: 5px solid rgba(72, 165, 249, 0.3);
  background: $white;
  max-height: 150px;

  overflow: hidden;
  overflow-y: scroll;

  &::-webkit-scrollbar {
    //width: 7px;
  }

  &::-webkit-scrollbar-track {
    width: 7px;
    -webkit-box-shadow: inset 0 0 6px rgba(255, 255, 255, 0);
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: rgba(0, 0, 0, .2);
  }
}

.budgetAutocompleteList {
  float: left;
  width: 100%;
  display: block;

  li {
    display: block;
    float: left;
    width: 100%;
    //border-bottom: 1px solid #ccc;

    a {
      display: block;
      width: 100%;
      padding: 12px;
      color: #878B8E;
      text-transform: capitalize;

      background: $white;

      &:hover {
        color: $bright-sky-blue;
      }
    }
  }
}

.headRow {
  background: $morning-fog-gray;

  @include print() {
    background: $concrete-gray !important;
  }
  float: left;
  color: $black;
  font-weight: $semiBold;

  .addTask {
    position: relative;

    .addTaskButton {
      cursor: pointer;
      height: 15px;
    }

    .addTaskModal {
      opacity: 0;
      z-index: -1 !important;
      position: absolute;
      top: ($headH - 4px);
      left: 5px;
      width: auto;
      min-width: 180px;

      background: $white;
      color: $black;

      &.isVisible {
        z-index: 750 !important;
        opacity: 1 !important;
        display: block !important;
      }

      //box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.14), 0 3px 20px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
      outline: 5px solid rgba(72, 165, 249, 0.3);

      .closeTaskModal {
        cursor: pointer;
      }

      .modalTaskHead, .modalTaskBody, .modalTaskCta {
        float: left;
        width: 100%;
        min-height: $rowH;
      }

      .modalTaskHead {
        .table {
          height: $rowH;
          padding: 6px;
        }

        .title {
          font-weight: $semiBold;
        }

        border-bottom: 1px solid #eee;
      }

      .modalTaskBody {
        padding: 6px;

        .body, .cta {
          float: left;
          width: 100%;
          min-height: $rowH;
        }

        .body {
          border-bottom: 1px solid #eee;

          input {
            margin: 10px 0 0;
            border: none;
            background: #eee;
            border-radius: 2px;
            padding: 8px;
            @include placeholder {
              color: $bright-sky-blue;
            }
          }
        }

        .cta {
          .addTask {
            color: $white;
            margin: 15px 10% 0;
          }
        }
      }

      input {
        border: 1px solid #eee;
        outline: none;
        border-radius: 2px;
        padding: 6px;
      }

      .taskLabel {
        text-transform: uppercase;
        color: $white;
        font-size: 12px;
        line-height: 11px;
      }

      .addButton {
        background: 0 0;
        border: none;
        border-radius: 2px;
        color: $bright-sky-blue;
        position: relative;
        padding: 8px 5px;
        display: inline-block;
        font-weight: $semiBold;
        text-transform: uppercase;
        overflow: hidden;
        will-change: box-shadow, transform;
        -webkit-transition: box-shadow .2s cubic-bezier(.4, 0, 1, 1), background-color .2s cubic-bezier(.4, 0, .2, 1), color .2s cubic-bezier(.4, 0, .2, 1);
        transition: box-shadow .2s cubic-bezier(.4, 0, 1, 1), background-color .2s cubic-bezier(.4, 0, .2, 1), color .2s cubic-bezier(.4, 0, .2, 1);
        outline: none;
        cursor: pointer;
        text-decoration: none;
        text-align: center;
        vertical-align: middle;

        &:hover {
          background-color: rgba(158, 158, 158, .2);
        }
      }
    }
  }

  .table {
    float: left;
    width: 100%;
    height: 30px;
    @include calc(height, $headH - $gutter * 2);

    &.fixHeight {
      height: $headH;
    }
  }

  .headLevelList {
    margin: 0;
    height: auto;

    td {
      position: relative;
      height: 30px;
      vertical-align: middle;

      .headLevelList-tooltip {
        font-size: 11px;
        visibility: hidden;
        position: absolute;
        bottom: 25px;
        background-color: $black;
        padding: 6px;
        opacity: 0.75;
      }
    }

    td:hover .headLevelList-tooltip {
      visibility: visible;
    }
  }
}


//JQUERY UI
.ui-widget-content a {
  color: $white !important;
}

//MODAL CREATION
$modalWidth: 450px;
$modalBgTitleColor: $bright-sky-blue;
.modalBgCreationStructure {
  //display: none;
  position: fixed;
  z-index: 9999;
  background: $white;

  width: $modalWidth;
  min-height: 300px;
  padding: 6px;

  background: $white;
  color: $black;

  &.smallScreen {
    display: block;
    //max-height: 300px;
    overflow-y: scroll;

    &::-webkit-scrollbar {
      width: 7px;
    }

    &::-webkit-scrollbar-track {
      width: 7px;
      -webkit-box-shadow: inset 0 0 6px rgba(255, 255, 255, 0);
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 4px;
      background-color: rgba(0, 0, 0, .2);
    }
  }

  .head {
    margin-bottom: 9px;

    &.col_16.no_padding {
      padding: 3px 6px;
    }
  }

  .footer {
    position: absolute;
    bottom: 8px;
    left: 0;
    padding: 0 12px !important;
  }

  .title {
    color: $modalBgTitleColor;
    text-transform: uppercase;
    font-weight: $semiBold;
    line-height: 31px;
  }

  .iconInsertBudget {
    .iconBudgetDefaulArea {
      width: 12px;
      height: 8px;
    }
  }

  .bgModalAreas {

    .budgetCreationAreaName {
      opacity: .2;
    }

    .row {
      &:hover {
        .iconInsertBudget {
          .iconBudgetDefaulArea {
            @include retina-image($iconsPath + check, 12px 8px);
          }
        }
      }
    }
  }

  .bgModalDefaulAreas {
    width: 80%;
    margin-left: 10%;

    .iconBudgetDefaulArea {
      @include retina-image($iconsPath + check, 12px 8px);
    }

    .row {
      &:hover {
        .iconInsertBudget {
          .iconBudgetDefaulArea {
            width: 8px;
            height: 8px;
            @include retina-image($iconsPath + x, 8px 8px);
          }
        }
      }
    }
  }

  .templatesSelect {
    .row {

      &:hover {
        .iconBudgetDefaulArea {
          width: 12px;
          height: 8px;
          @include retina-image($iconsPath + check, 12px 8px);
        }
      }

      &.selTemplate {
        .iconBudgetDefaulArea {
          width: 12px;
          height: 8px;
          @include retina-image($iconsPath + check, 12px 8px);
        }

        &:hover {
          .iconInsertBudget {
            .iconBudgetDefaulArea {
              width: 8px;
              height: 8px;
              @include retina-image($iconsPath + x, 8px 8px);
            }
          }
        }
      }
    }
  }

  .row {
    float: left;
    width: 100%;
    margin-bottom: 9px;
    cursor: pointer;
  }

  .rowWrapper {
    width: 80%;
    margin: 0 0 26px 10%;
    //margin-left: 10%;

    // min-height: 200px;
    // overflow: hidden;
    //
    // &::-webkit-scrollbar {
    //     width: 7px;
    // }
    // &::-webkit-scrollbar-track {
    // 	width: 7px;
    //     -webkit-box-shadow: inset 0 0 6px rgba(255,255,255,0);
    // }
    // &::-webkit-scrollbar-thumb {
    // 	border-radius: 4px;
    // 	background-color: rgba(0, 0, 0, .2);
    // }
  }

  .separetor {
    float: left;
    width: 80%;
    margin-left: 10%;
    height: 1px;
    margin-bottom: 12px;
    background: #e4e6e9;
  }

}
