//BUBBLES
.bubblesChart{
	position: relative;
	width: 335px;
	height: 296px;
}

.widgetBubblesFooter{
	display: none;
	position: absolute;
	bottom: 0px;
	left: 0;
	width: 100%;
	height: 60px;
	//background: rgba(255, 255, 255, .9);
	background: rgba(72,165,244,.9);

	.spriteModules {
		float: right;
		margin: 20px 15px 0 0;
		&.moduleReport{
			background-position:-80px 16px;
		}
	}

	&.widgetInvoiceFooter{
		z-index: 1;
	}
	.projectName, .info{
		display: inline-block;
		width: 100%;
		color:$white;
	}
	.projectName{
		// white-space: nowrap;
		// overflow: hidden;
		// text-overflow: ellipsis;

		margin: 2px 0 0 15px;
		font-size: 12px;
		font-weight: $bold;
		line-height: 1.3;
	}
	.info{
		margin: 17px 0 0 15px;
		text-transform: uppercase;
		font-size: 10px;
		//color: #878B8E;
		font-weight: $light;
	}

}
