.briefing {
  @include scaleToExtraSmall() {
    padding: 0 16px;
  }

  .section-header {
    margin-top: 16px;
  }

  .wethod-widget__empty {
    margin-top: 100px;
  }

  &-empty {
    &__future {
      background-image: url("../../../img/empty-states/briefing-future.svg");
      width: 100px;
      height: 120px;
      background-repeat: no-repeat;
    }

    &__past {
      background-image: url("../../../img/empty-states/briefing-past.svg");
      width: 100px;
      height: 120px;
      background-repeat: no-repeat;
    }
  }

  &-actions {
    height: 64px;
    position: relative;

    & > * {
      display: inline-block;
      vertical-align: top;
      font-size: 14px;
    }

    .wethod-filter-select__button {
      min-width: 165px;
      height: 32px;
      padding-right: 10px;

      .wethod-icon {
        transition: none;
      }

      &:hover {
        .wethod-icon {
          background-position-x: -64px;
        }
      }

      @include scaleToExtraSmall() {
        width: 165px;
        position: absolute;
        right: 0;
      }
    }
  }

  &__company-filter {
    display: flex;
    line-height: 32px;

    &--text {
      width: calc(100% - 32px);
      margin-right: 4px;
      font-size: 14px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }

  &-content {
    font-family: 'Domine', serif;
    padding-bottom: 100px;

    @include scaleToExtraSmall() {
      width: auto;
    }

    a {
      color: inherit;
      text-decoration: underline;

      &:hover {
        color: $bright-sky-blue;
      }
    }

    &__week-number {
      color: $bright-sky-blue;
      font-size: 32px;
      line-height: 28px;
      margin-bottom: 16px;

      span {
        @include scaleToExtraSmall() {
          display: block;
        }
      }

      @include scaleToExtraSmall() {
        text-align: center;
        line-height: 48px;
        padding: 0 32px;
      }
    }

    &__info {
      color: $dusty-gray;
      font-size: 13px;
      line-height: 24px;
      padding-right: 20px;
      margin-top: -100px;
      position: relative;
      @include scaleToExtraSmall() {
        text-align: center;
        padding: 0;
        margin: 0;
      }

      &--created {
        @include scaleToExtraSmall() {
          border-right: 1px solid;
          padding-right: 8px;
        }

        span {
          @include scaleToExtraSmall() {
            display: block;
          }
        }
      }

      &--time-to-read {
        display: inline-block;
        margin-top: 8px;
        border-top: 1px solid;
        padding-top: 8px;
        @include scaleToExtraSmall() {
          display: inline;
          margin-top: 0;
          border-top: none;
          padding-top: 0;
          margin-left: 8px;
        }
      }
    }

    &__intro {
      &-image {
        width: 100%;
        margin-top: 32px;

        @for $i from 1 through 5 {
          &--#{$i} {
            background-image: url("../../../img/illustrations/briefing_#{$i}.svg");
          }
        }
      }
    }
  }

  &-section {
    display: inline-flex;
    width: 100%;

    &:last-child .briefing-section__center {
      padding-bottom: 40px;
    }

    &__left {
      width: 300px;
      padding-right: 60px;
      padding-top: 100px;
    }

    &__center {
      width: 582px;
      @include scaleToExtraSmall() {
        width: 100%;
      }
    }

    &__right {
      width: 142px;
    }

    &__title {
      color: rgba(0, 0, 0, 0.87);
      font-size: 28px;
      font-weight: $bold;
      line-height: 40px;
      margin-bottom: 24px;
    }

    &__paragraph {
      color: $blackboard-black;
      font-size: 18px;
      line-height: 32px;
      margin-bottom: 40px;

      &-divider {
        display: block;
        border: 0;
        text-align: center;
        overflow: visible;
        height: 0;
        margin-top: 52px;
        margin-bottom: 42px;

        &:before {
          content: '...';
          display: inline-block;
          margin-left: .6em;
          color: rgba(0, 0, 0, .68);
          position: relative;
          top: -30px;
          font-size: 30px;
          letter-spacing: .6em;
        }
      }
    }
  }

  .planning {
    &-navigation {
      float: left;
      right: 0;
      left: -32px;

      .navigation {
        &__today {
          @include scaleToExtraSmall() {
            padding: 0 40px;
          }
        }

        &__prev {
          @include scaleToExtraSmall() {
            display: block;
          }
        }

        &__next {
          @include scaleToExtraSmall() {
            display: block;
          }
        }
      }
    }

    &__prev-icon, &__next-icon {
      background: url("../../../img/icon/edge_arrows_sprite.svg") no-repeat 0 0;
      background-size: 48px 24px;
      width: 12px;
      height: 12px;
      position: absolute;
      top: 10px;
      left: 8px;
    }

    &__next-icon {
      background-position: -12px 0;
      left: 11px;
    }
  }

  &-aside-chart {
    font-family: 'Rubik', sans-serif;
    margin-bottom: 63px;

    @include scaleToMedium() {
      width: 40%;
      margin: 0 auto 40px auto;
    }

    &__wrapper {
      @include scaleToMedium() {
        display: flex;
      }
    }

    &-table {
      width: 100%;

      &__value {
        &--good {
          color: $garden-green;
        }

        &--bad {
          color: $raspberry-red;
        }
      }

      &__col {
        &--first {
          width: 33% !important;
          text-align: left !important;
        }
      }

      &__row--sum {
        display: block;
        border-top: 1px solid #CACACA !important;
        margin-bottom: 8px;
      }

      thead {
        tr {
          font-weight: $bold;
        }
        border-top: 1px solid rgba(0, 0, 0, 0.87);
        border-bottom: 1px solid rgba(0, 0, 0, 0.87);
      }

      tr {
        border: none;
        padding: 0;
      }

      td, th {
        padding: 4px 2px;
        text-align: right !important;
        white-space: unset;
        font-size: 11px;
        width: 22%;
        display: inline-block;
        vertical-align: middle;
      }
    }

    &__title {
      font-size: 14px;
      font-weight: $bold;
      line-height: 20px;
    }

    &__subtitle {
      font-size: 11px;
      line-height: 12px;
      margin-top: 4px;
      border-bottom: 1px solid $dusty-gray;
      display: inline-block;
      padding-bottom: 8px;
      margin-bottom: 16px;
    }

    &__content {
      height: 300px;
    }

    &__legend {
      position: relative;
      top: -8px;

      &-item {
        font-size: 11px;
        line-height: 14px;
        margin-bottom: 4px;

        &::before {
          content: '';
          display: inline-block;
          width: 16px;
          height: 1px;
          vertical-align: middle;
          margin-right: 8px;
        }

        &--target {
          &::before {
            border-top: 1px dashed #F8A01C;
          }
        }

        &--dynamic-target {
          &::before {
            border-top: 1px dashed #ef5d60;
          }
        }

        &--sales {
          &-this-year {
            &::before {
              border-top: 1px solid #804E49;
            }
          }

          &-same-week-last-year {
            &::before {
              border-top: 1px solid #cec3c4;
            }
          }
        }

        &--pipeline {
          &-this-year {
            &::before {
              border-top: 1px solid #3F5C81;
            }
          }

          &-same-week-last-year {
            &::before {
              border-top: 1px solid #98C1D9;
            }
          }
        }

        &--rpe {
          &-this-year {
            &::before {
              border-top: 1px solid #096B72;
            }
          }

          &-same-week-last-year {
            &::before {
              border-top: 1px solid #BED3D7;
            }
          }
        }

        &--gmpe {
          &-this-year {
            &::before {
              border-top: 1px solid #BC9EC1;
            }
          }

          &-same-week-last-year {
            &::before {
              border-top: 1px solid rgba(188,158,193,0.3);
            }
          }
        }

        &--max-forecast {
          &::before {
            width: 7px;
            height: 7px;
            border: 1px solid #6622CC;
            border-radius: 50px;
            margin-right: 16px;
          }
        }

        &--last-forecast {
          &::before {
            width: 7px;
            height: 7px;
            background: #6622CC;
            border-radius: 50px;
            margin-right: 16px;
          }
        }
      }
    }
  }

  &-bottom-actions {
    display: flex;
    justify-content: flex-end;

    &__button {
      width: 32px;
      height: 32px;
      border: none;

      .tooltipReact {
        opacity: 0;
        left: -20px;
        top: -30px;
        z-index: -1;
      }

      &:hover {
        background-color: #D7DEE5;

        .tooltipReact {
          opacity: 1;
          z-index: 1;
        }
      }
    }
  }

  &-group {

    .briefing-content a {
      text-decoration: none;
      pointer-events: none;
    }
  }
}

#briefing-aside-chart {
  &-weekly {

  }

  &-monthly {
    &__revenues-per-employee, &__gross-margin-per-employee, &__profit-loss {
      margin-bottom: 50px;

      .briefing-aside-chart__content {
        height: 200px;

        @include scaleToMedium() {
          height: 168px;
        }
      }
    }
  }
}
