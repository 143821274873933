.nav-wrapper-primary {
  background-color: #2b2e38;
}

.nav-wrapper-secondary {
  background-color: #131418;
}

.app-header {
  z-index: 850;
  width: 100%;
  position: relative;

  .nav {
    height: 60px;
    max-width: 1024px;
    margin: 0 auto;
  }

  .nav-left {
    float: left;
    margin-left: 30px;
  }

  .nav-right {
    float: right;
  }

  .nav__logo {
    display: inline-block;
    padding: 10px;
    height: 60px;
    float: left;
    width: 160px;
  }

  .nav__list {
    height: 60px;
    display: table;
  }

  .nav__list-left {
    @extend .nav__list;
    float: left;
  }

  .nav__list-right {
    @extend .nav__list;
    float: right;
  }

  .nav__item {
    display: table-cell;
    vertical-align: middle;
    padding: 0 15px;
    color: #91949f;
    font-weight: $semiBold;
  }

  .navSel {
    color: $white;

    img {
      background-position-y: 16px;
    }
  }

  .nav__item:hover {
    @extend .navSel;

    .wethod-profile-pic {
      background: $white;

      &__placeholder {
        color: $white;
      }
    }
  }

  .nav__item.selHeaderNav {
    @extend .navSel;
    background-color: #131418;

    .wethod-profile-pic {
      background: $white;

      &__placeholder {
        color: $white;
      }
    }
  }

  .wethod-profile-pic {
    width: 32px;
    height: 32px;
    margin: 12px auto;
    line-height: 30px;
    background: #91949f;

    &__placeholder {
      color: #91949f;
    }
  }
}

.appsNav, .nav__item {
  &:hover, &:active, &:focus {
    .header_apps-icon {
      background-position-y: -16px !important;
    }
  }
}

.header_apps-icon {
  background: url('/img/icon/apps.svg') no-repeat;
  background-position: 0 0;
  margin: 1px auto;
}

