.project-review {
  .wethod-table__search {
    border-bottom: 1px solid $concrete-gray;
    margin-bottom: 12px;
  }

  .wethod-modal2__container {
    min-width: 500px;
    font-size: 14px;
    line-height: 20px;
  }

  &__row-feedback {
    &--negative {
      color: $raspberry-red;
    }
  }

  &__loading {
    padding: 24px;
    border-radius: 4px;
    text-align: center;
    transition: 0.3s;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__modal {
    &-content {
      margin-bottom: 100px;
    }

    &-actions {
      display: block;
      text-align: right;

      .wethod-button {
        display: inline-block;
      }

      & > :not(:last-child) {
        margin-right: 16px;
      }
    }
  }

  .wethod-icon-notes {
    cursor: pointer;
  }

  .section-header {
    padding-top: 16px;
  }

  &__profile-pic {
    &-container {
      width: 54px;
      height: 54px;
      margin-left: -16px;
      font-size: 20px;
      line-height: 52px;
    }
  }

  &__steps {
    overflow: hidden;
    white-space: nowrap;
  }

  &__step {
    &-content {
      padding-bottom: 78px;
    }

    display: inline-block;
    width: 100%;
    vertical-align: top;
    transition: .4s;
    position: relative;
    white-space: normal;

    &-final {
      text-align: center;
      margin-top: 100px;

      &-message {
        font-size: 16px;
        line-height: 24px;
        margin-bottom: 48px;
      }

      &-actions {
        a {
          display: inline-block;
        }
      }
    }

    &-footer {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 1024px;
      box-shadow: 0 -8px 10px -8px rgba(155, 155, 155, 0.2);
      padding: 24px 16px;
      margin: auto;

      &-container {
        bottom: 0;
        background-color: #EBEEF1;
        position: fixed;
        width: 100%;
        left: 0;
        opacity: 0;

        &--visible {
          opacity: 1;
          z-index: 2;
        }
      }

      &-action {
        margin-left: 16px;
      }
    }

    &-counter {
      color: $dusty-gray;
      font-size: 14px;
      line-height: 17px;
      text-align: right;
    }

    &-header {
      margin-bottom: 8px;

      &-title {
        height: 24px;
        font-size: 16px;
        line-height: 24px;
      }

      &-subtitle {
        height: 22px;
        margin-top: 8px;
        color: $dusty-gray;
        font-size: 12px;
        line-height: 22px;
        margin-bottom: 24px;

        &-atom {
          padding-right: 8px;
          margin-right: 8px;

          &:nth-child(1), &:nth-child(2) {
            border-right: 1px solid;
          }

          &--anonymous {
            display: inline-block;

            &:before {
              display: inline-block;
              background: $dusty-gray;
              height: 2px;
              width: 2px;
              border-radius: 50%;
              content: '';
              position: relative;
              right: 7px;
              top: -3px;
            }
          }
        }
      }
    }

    &-table {
      &-head {
        border-color: #CACACA;
        border-style: solid;
        border-top-width: 1px;
        padding: 8px 0;
        border-bottom-width: 1px;

        .project-review__step-table-cell--vote-container {
          padding: 0;
        }
      }

      &-row {
        border: 1px solid $concrete-gray;
        border-radius: 4px;
        margin-bottom: 8px;

        &-header {
          border-bottom: 1px solid $concrete-gray;
          padding: 8px 16px;
        }

        &-content {
          padding: 8px 16px;
        }

        &-feedback {
          &--negative {
            text-transform: uppercase;
            color: $raspberry-red;
          }
        }
      }

      &-cell {
        display: inline-block;
        vertical-align: middle;
        padding: 0 16px;

        &--name {
          width: calc(70% - 70px);
          position: relative;
          padding-left: 20px;
        }

        &--feedback {
          width: calc(30% - 64px);
          text-align: right;
          padding: 0;
          text-transform: uppercase;

          &-project {
            width: 30%;
          }
        }

        &--vote {
          display: inline-block;
          vertical-align: middle;
          width: (100%/11);
          text-align: center;

          &-container {
            width: 56%;
          }
        }
      }
    }

  }

  &__person {
    &-name {
      font-size: 14px;
      font-weight: $semiBold;
      line-height: 22px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    &-tag {
      font-size: 11px;
      line-height: 16px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      display: inline-block;

      &:not(:first-child) {
        margin-left: 4px;
        padding-left: 4px;
        border-left: 1px solid;
      }
    }

    &-level {
      font-size: 11px;
      line-height: 16px;
      color: $dusty-gray;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }

  &__project {
    &-name {
      font-size: 14px;
      font-weight: $semiBold;
      line-height: 22px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    &-client {
      font-size: 11px;
      line-height: 16px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    &-pm {
      font-size: 11px;
      line-height: 16px;
      color: $dusty-gray;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    &-joc {
      width: 4px;
      border-radius: 4px;
      position: absolute;
      left: 0;
      top: 10%;
      height: 80%;
    }
  }

  &-criterion {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 8px 0;
    font-size: 14px;

    &__name {
      width: 65%;
    }

    &__progress {
      width: 35%;

      &-input {
        width: calc(100% - 95px);
        display: inline-block;
        vertical-align: middle;
      }

      &-value {
        padding: 0 16px;
        display: inline-block;
        vertical-align: middle;
        text-align: center;
        width: 95px;
      }
    }
  }
}
