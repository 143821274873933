
.pipeline-iconButton-space {
  margin: 6px 4px;
  @include scaleToSmall() {
    margin: 6px 3px;
  }
  @include scaleToExtraSmall() {
    margin: 6px 3px;
  }
}

.pipeline-iconButton-header-space {
  margin: 6px 5px 0 15px;
}

//NEW PIPELINE
$headH: 30px;
$rowH: 60px;
$borderBudget: 1px;

$linkColor: $bright-sky-blue;

$requiredColor: $bright-sky-blue;
$errorColor: $raspberry-red;
$successColor: #d9ecfc;

$placeholderColor: $steel-gray;

.pipeline {
  &-projects {
    &__intercompany {
      &-details {
        &-content {
          text-align: left;

          p {
            margin: 8px 0;
            line-height: 18px;
          }
        }
      }

      &-link {
        ul {
          list-style: disc !important;
        }

        max-width: 600px;

        .wethod-search-select {
          margin-bottom: 16px;
        }

        &-actions {
          text-align: right;
          margin-top: 32px;
        }

        .wethod-button {
          display: inline-block;
          margin-left: 8px;
        }
      }

      &-label {
        font-size: 10px;
        line-height: inherit;
        vertical-align: top;
        font-weight: $bold;
        color: rgba(0, 0, 0, 0.4) !important;
        margin-left: 8px;
        text-transform: uppercase;
        background-color: $white;
        position: absolute;
        bottom: 2px;
        left: 10px;

        &:hover {
          color: $bright-sky-blue !important;
        }
      }
    }
  }

  &__project-action {
    float: right;
    width: 32px;
    display: inline-block;
    vertical-align: middle;
    margin: 6px 4px;
    transition: 0.2s ease-in-out;
    border: 1px solid $bright-sky-blue;
    border-radius: 50%;
    height: 32px;
    cursor: pointer;
    position: relative;

    &--hidden {
      display: none;
    }

    &:hover {
      background-color: $bright-sky-blue;

      div {
        background-position-x: -64px;
      }
    }

    div {
      position: absolute;
      left: -1px;
      top: -1px;
    }
  }
}

.pipelineHead {
  padding: $gutter * 6 0;
}

.pushTop {
  margin-top: $gutter * 2;
}

.pushLeft {
  margin-left: $gutter * 2;
}

.flex-actions{
  display: flex;
  gap: 6px;
}

.pushRight {
  margin-right: $gutter * 2;
}

.pipePushLeft {
  padding-left: 15px !important;

  &.mdl-textfield {
    padding-left: 15px;

    .mdl-textfield__label {
      left: 15px;
    }
  }
}

.roundButton {

  &.saveButton, &.cancelButton {
    float: right;
    min-width: 95px;
    margin-top: 6px;
  }

  &.saveButton {
    margin-left: 6px;
  }
}

//intestazione liste pipeline
.listheader {
  z-index: 600;
  position: relative;
  height: $headH;
  background: $alabaster-white;

  .table {
    width: 100%;
    height: $headH - $gutter * 2;
  }
}

//FILTERS
$primaryBg: $bright-sky-blue;
$primaryColor: #d9ecfc;
$primaryBgHover: $bright-sky-blue;
$primaryColorHover: $white;
$primaryBgActive: $bright-sky-blue;
$primaryColorActive: $white;

//layer che nasconde i progetti non attivi
.hideNotActive {
  display: none;
  height: 100%;
  background: rgba(235, 238, 241, 0.6);
  z-index: 500;
  position: absolute;
  top: 0;
  left: 0;
  @include transition(0.4s ease-in-out);

  &.active {
    display: block;
  }
}

.pipeEmptyRow {
  float: left;
  width: 100%;
  height: $rowH;
  background: $white;
  border-bottom: 1px solid $concrete-gray;
  line-height: 1.3;

  .table {
    width: 100%;
    height: $rowH - $gutter * 2;
  }
}

a.pipeLink {
  color: $bright-sky-blue !important;
}

.pipeLink {
  display: inline-block;
  float: right;
  height: 46px;
  padding: 8px 6px;
  cursor: pointer;

  &.circle {
    width: 30px;
    height: 30px;
    margin: 8px 6px;
    border: 1px solid #ccc;
    border-radius: 50%;

    .text {
      display: inline-block;
      color: $bright-sky-blue;
      text-align: center;
    }

    @include transition(0.2s ease-in-out);

    &:hover {
      background: $bright-sky-blue;
      border-color: $bright-sky-blue;

      .text {
        color: $white;
      }
    }
  }

  &.pushRight {
    margin-right: 8px;
    @include scaleToExtraSmall() {
      margin-right: 0px;
    }
  }

  &.noActionPipe {
    cursor: default;
  }
}

.upperText {
  text-transform: uppercase;
  font-weight: $semiBold;
  color: $steel-gray;
}

.tooltip {
  &.tooltipSelect {
    max-height: 180px;
    overflow: hidden;
    overflow-y: scroll;

    float: left;

    &::-webkit-scrollbar {
      width: 7px;
    }

    &::-webkit-scrollbar-track {
      width: 7px;
      -webkit-box-shadow: inset 0 0 6px rgba(255, 255, 255, 0);
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 4px;
      background-color: rgba(0, 0, 0, .2);
    }
  }
}

//ICON

.iconBudget {
  float: left;
  width: 11px !important;
  height: 11px;
  margin: 18px 0 0 $gutter;
}

//modal
.pipelinemodalStructure {
  @extend .modalStructure;
}

//tooltip
.moreActionSelect {
  width: 170px;

  .tooltipLink {
    &:hover {
      .upperText {
        color: $linkColor;
      }
    }

    &.disabled {
      &:hover {
        cursor: default !important;

        .upperText {
          opacity: .5 !important;
          color: $steel-gray;
        }
      }

      .upperText {
        opacity: .5 !important;
      }
    }
  }

  @include scaleToSmall() {
    li {
      width: 100% !important;
    }
  }

  .borderTop {
    border-top: 1px solid rgba(72, 165, 249, 0.3);
  }
}

//INVOCE PLANE
.tooltip {
  &.tooltipInvoice {
    height: auto;

    .pipelineInvoiceMessageRow {
      height: 29px;
      padding-left: 15px;
      display: table;

      .okMessage {
        color: $linkColor;
      }

      .koMessage {
        b {
          font-weight: $bold;
        }

        color: $errorColor;
      }
    }
  }
}

//MDL
$labelColor: #bfc0c6;
.mdl-textfield {
  font-size: $baseFont !important;
  width: 100%;
  padding: 14px 0;

  .mdl-textfield__input {
    padding: 0 !important;
    font-size: $baseFont !important;
  }

  .mdl-textfield__label {
    height: 16px;
    top: 15px;
    line-height: 1.3;

    display: none;
    font-size: $baseFont;
    color: $placeholderColor !important;

    &::after {
      display: none;
    }
  }

  &.mdl-textfield--floating-label.is-focused .mdl-textfield__label,
  &.mdl-textfield--floating-label.is-dirty .mdl-textfield__label {
    top: -2px;
    color: $bright-sky-blue !important;
    font-size: 10px !important;
  }
}
