@import "event";

#planningProjectsRoot {
  margin-top: -17px;

  .header {
    &__week {
      background: $morning-fog-gray;
    }

    &__edge-placeholder {
      background: $morning-fog-gray;
    }
  }

  .planning__project {
    &:nth-child(even) {
      .project__details, .project__week {
        background: $spring-wood-white;
      }
    }

    &:nth-child(odd) {
      .project__details, .project__week {
        background: $white;
      }
    }
  }
}

.project {
  &__week {
    @extend .employee__week;
    background: $white;
  }

  &__day {
    @extend .employee__day;
    position: relative;
    height: 22px;
    cursor: pointer;

    &-content {
      height: inherit;

      &:hover {
        background: #EBEEF1 !important;
      }
    }

    &--event {
      box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.3);
    }
  }

  &__details {
    @extend .employee__details;
    width: 150px;
    border-radius: 0;
    height: auto;
  }

  &__hide {
    color: $dusty-gray;
    float: left;
    padding: 5px;
    cursor: pointer;

    &:hover {
      color: $bright-sky-blue;
    }
  }

  &__info {
    padding: 3px 6px;

    .employee__name {
      font-weight: $normal;
    }
  }

  &__days {
    @extend .employee__days;
    height: auto;
  }
}

.planned {
  &-people {
    float: left;
    width: 100%;
    padding: 12px 10px;
    border-top: 1px solid #EBEEF1;

    &__list {
      max-height: 50px;
      overflow-y: scroll;

      &::-webkit-scrollbar {
        -webkit-appearance: none;
        width: 7px;
      }

      &::-webkit-scrollbar-thumb {
        border-radius: 4px;
        background-color: rgba(0, 0, 0, .5);
        -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, .5);
      }

    }

    &__size {
      text-transform: uppercase;
      font-weight: $semiBold;
      margin-bottom: 5px;
    }
  }

  &-person {
    margin-bottom: 5px;
  }
}

.error-banner {
  position: fixed;
  bottom: -36px;
  z-index: 1000;
  background: $raspberry-red;
  color: $white;
  width: 100%;
  left: 0;
  transition: .3s;

  &--visible {
    bottom: 0;
  }

  &__content {
    max-width: 1024px;
    position: relative;
    margin: auto;
    padding: 12px 0;
  }

  &__title {
    font-weight: $bold;
  }
}
