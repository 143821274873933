.material-input {
  display: inline-flex;
  margin: 2px 0;
  width: 100%;

  &--sidebar {
    margin: 6px 0 !important;

    .material-input__icon {
      margin-top: -11px;
    }
  }

  .wethod-search-select__add-button {
    font-size: 20px;
    line-height: 28px;
    padding: 0 8px;
    top: -36px;
  }

  &[data-input-type="textarea"] {
    .wethod-input {
      line-height: 1;
      padding-top: 8px;
      padding-bottom: 8px;
    }
  }

  &[data-input-type="range"] {
    width: 50%;
    vertical-align: middle;

    .material-input__input {
      width: 100%;
    }

    &:first-of-type { //we take into account that the first one usually is smaller because of the icon
      width: calc(50% + 8px);
    }

    &:last-of-type {
      width: calc(50% - 16px);

      .wethod-day-picker {
        right: 0;
        left: unset;
      }
    }
  }

  &__input {
    margin: 0 10px;
    width: inherit;

    .wethod-input {
      padding: 5px 10px;
      border: 1px solid #ECECEC;
      line-height: 30px;
      background-color: #FAFAFA;

      input, textarea {
        font-size: 14px;
      }

      &--required {
        &:before {
          content: none;
        }
      }

      &:hover {
        background-color: #FAFAFA;
      }
    }

    .wethod-date-picker {
      position: relative;

      > .wethod-day-picker {
        position: absolute;
        left: 0;
        top: 50px;
        z-index: 2;
      }
    }

    &--readonly {
      .wethod-input:first-child { //keep input spacing when no label is shown
        padding: 11px 10px;
      }

      .wethod-input {
        white-space: pre-line;
        word-break: break-word;
        border: none;
        padding: 4px 15px;
      }

      .material-input__helper-text {
        top: -5px;
      }
    }

    &--focused {
      .wethod-input, .material-input-button {
        border: 1px solid $bright-sky-blue;
      }

      .material-input__label--minimized {
        color: $bright-sky-blue;
      }
    }

    &--error {
      .wethod-input, .material-input-button {
        border: 1px solid $raspberry-red;
      }

      .material-input__label:after,
      .material-input__label--minimized,
      .material-input__helper-text {
        color: $raspberry-red;
      }
    }
  }

  &__icon {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 -4px;
    min-width: 32px;
    max-height: 72px;

    .wethod-icon {
      display: block;
    }
  }

  &__label {
    display: inline-block;
    position: relative;
    top: 28px;
    left: 16px;
    padding-right: 5px;
    color: $dusty-gray;
    pointer-events: none;
    transition: 0.2s;
    z-index: 1;

    &--minimized {
      padding: 0 5px;
      top: 6px;
      left: 11px;
      font-size: 12px;
      background-color: #FAFAFA;
    }

    &--required {
      &:after {
        content: '*';
        display: inline-block;
        color: inherit;
      }
    }
  }

  &__helper-text {
    padding: 0 15px;
    position: relative;
    min-height: 14px;
    color: $dusty-gray;
    font-size: 10px;
    line-height: 14px;
  }

  &__frame {
    padding: 5px 0;
    border-bottom: 1px solid #ECECEC;
    border-top: 1px solid #ECECEC;
  }

  &__dot {
    width: 16px;
    height: 16px;
    border-radius: 50%;
  }

  &--dotted {
    display: flex;
    align-items: center;

    .material-input {
      width: 85%;
    }
  }

  &-box {
    border: 1px solid #ECECEC;
    margin: 0 10px;
    padding: 10px 5px;
    width: inherit;
    line-height: normal;

    &__title {
      margin: 10px;
      font-weight: $semiBold;
    }

    &__content {
      margin: 10px;
    }

    &__actions {
      float: right;
      padding: 8px 8px 15px;
    }

    .wethod-button {
      margin-left: 15px;
    }
  }

  &-button {
    width: 100%;
    height: 42px;
    padding: 5px 15px;
    border: 1px solid #ECECEC;
    outline: none;
    border-radius: 3px;
    background-color: #FAFAFA;
    text-align: left;
    font-size: 14px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &--borderless {
    .wethod-input {
      border: none;
    }
  }
}
