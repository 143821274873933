.group-revenues {
  .section-header {
    padding-top: 16px;
  }

  .wethod-modal2__container {
    width: 400px;
  }
  .group-company-filter {
    float: left;
  }
  &__actions {
    padding-bottom: 16px;
    position: relative;
    &:after {
      @extend .wethod-clearfix;
    }

    .reloadCacheDateLabel {
      font-size: 11px;
      padding: 11px !important;
    }
  }
  &-monthly {
    margin-top: 16px;
    &__charts {
      &:after {
        @extend .wethod-clearfix;
      }
    }
  }
  &-stacked-chart {
    padding: 16px;
    background:$white;
    width: calc(80% - 8px);
    margin-right: 8px;
    float: left;
    height: 432px;
  }
  &-gauge-chart {
    padding: 16px;
    background:$white;
    width: 20%;
    float: left;
    position: relative;
    height: 432px;
    &__content {
      height: 200px;
    }
    &__target {
      position: absolute;
      top: 80px;
      margin: auto;
      left: 0;
      text-align: center;
      color: #878B8E;
      width: 100%;
      &-content {
        margin-bottom: 10px;
      }
      &-name {
        font-size: 10px;
        margin-bottom: 3px;
      }
      &-value {
        font-size: 20px;
        font-weight: $light;
      }
    }
    &__data {
      text-align: center;
      &-row {
        margin-top: 16px;
        padding: 0 10px;
        &-label {
          text-transform: uppercase;
          font-size: 10px;
        }
        &-value {
          margin-top: 4px;
          font-size: 18px;
        }
      }
    }
  }

  &-clients {
    margin: 40px 0;
    &__legend {
      text-align: right;
      margin-bottom: 16px;
      &-item {
        font-weight: 100;
        display: inline-block;
        margin-left: 16px;
        &::before {
          content: '';
          display: inline-block;
          width: 11px;
          height: 11px;
          border-radius: 50%;
          margin-right: 8px;
        }
        &--prod {
          &::before {
            background:$black;
          }
        }
        &--total {
          &::before {
            background: #d5d5d5;
          }
        }
      }
    }
    &__header {
      color: $dusty-gray;
      text-transform: uppercase;
      margin-bottom: 12px;
      position: sticky;
      top: 0;
      z-index: 3;
      .group-revenues-clients__col {
        cursor: pointer;
        vertical-align: top;
        &:hover {
          background: rgba(0, 0, 0, 0.05);
        }
      }
    }
    &__row {
      background:$white;
      border-bottom: 1px solid rgba(155, 155, 155, 0.28);
      height: 60px;
      line-height: 60px;
      &--empty {
        color: #adb0b7;
        text-align: center;
      }
    }
    &__list {
      height: calc(100vh - 304px);
      overflow: auto;
      &-wrapper {
        position: relative;
      }
    }
    &__companies {
      width: calc(100% - 400px);
      display: inline-block;
      vertical-align: top;
    }
    &__col {
      display: inline-block;
      vertical-align: top;
      padding: 0 16px;
      &-order {
        width: 16px;
        height: 100%;
        position: absolute;
        left: 0;
        &-arrow {
          top: 21px;
          position: relative;
        }
      }
      &-client {
        font-weight: $bold;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        width: 180px;
        height: 100%;
        background:$white;
        position: sticky;
        left: 0;
        z-index: 2;
        &:hover {
          .group-revenues-clients__col-client-actions {
            left: 16px;
          }
        }
        &-actions {
          position: absolute;
          left: -100%;
          background:$white;
          width: 100%;
          z-index: 3;
          transition: .3s;
          button {
            text-transform: uppercase;
            font-size: 11px;
            line-height: 22px;
            padding: 0 12px;
          }
        }
        &-name {
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
        }
        &-type {
          position: absolute;
          top: 5px;
          line-height: initial;
          font-weight: $normal;
          text-transform: uppercase;
          font-size: 9px;
          color: $bright-sky-blue;
          opacity: 0.8;
        }
      }
      &--fixed-right {
        font-weight: $bold;
        height: 100%;
        background:$white;
        position: sticky;
        right: 0;
        text-align: center;
        display: inline-block;
        vertical-align: top;
      }
      &-csi {
        font-weight: $bold;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        width: 60px;
        height: 100%;
        position: relative;
      }
      &-count {
        font-weight: $bold;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        width: 60px;
        height: 100%;
        position: relative;
      }
      &-total {
        width: 100px;
        position: relative;
        &-content {
          display: inline-block;
          vertical-align: middle;
        }
      }
      &-company {
        text-align: center;
        width: 85px;
        display: inline-block;
        vertical-align: middle;
        position: relative;
        &-production {
          line-height: initial;

        }
        &-planned {
          line-height: initial;
          opacity: 0.5;
        }
        &-item {
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
        }
      }
    }
    &__companies {
      &:after {
        @extend .wethod-clearfix;
      }
    }
  }
}
