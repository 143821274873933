.empty-row {
  display: flex;
  align-items: center;
  justify-content: center;
  align-content: center;
  white-space: pre-wrap;
  width: 100%;
  height: 100%;
  line-height: 1.5;
  padding: 0 15px;
  font-size: 12px;
  background: $white;
  color: $steel-gray;
  font-weight: $semiBold;
  text-transform: uppercase;

  &--editable{
    // height: 60px;
    // line-height: 60px;
  }
}
