.travel {
  max-width: 1440px !important;
  padding: 0 24px;

  &__empty-row {
    @include scaleToExtraSmall() {
      visibility: hidden;
    }

    &:before {
      @include scaleToExtraSmall() {
        content: "THERE'S NOTHING HERE!";
        visibility: visible;
        display: block;
      }
    }
  }

  &-disabled {
    cursor: not-allowed;
    pointer-events: none;
    opacity: 0.25;
  }

  &-favorite-button {
    @extend .wethod-icon-heart;
    position: relative;
    float: right;
    margin-right: 5px;
    //font-size: 20px;
    //line-height: 28px;
    padding: 0 8px;
    top: -36px;

    &:hover {
      @extend .wethod-icon-heart-full;
    }
  }

  &-type-icons {
    display: flex;
  }

  &-li {
    list-style-type: none;
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;

    &__icon {
      @extend .wethod-icon-heart-full;

      &:hover {
        @extend .wethod-icon-heart;
      }
    }
  }

  .wethod-table__content {
    display: block;
  }

  &-table {
    &__checked {
      vertical-align: middle;
      display: inline-block;
    }

    &__row {
      display: flex;
      justify-content: space-between;
      align-content: center;
      align-items: center;

      &--project {
        flex: 2 0 15rem;
        display: flex;
        flex-direction: column;
        justify-content: center;
        line-height: 1.5;
        height: 100%;

        &-joborder {
          font-size: 12px;
          color: $dusty-gray;
        }

        @include scaleToExtraSmall() {
          flex: 2 2 5rem;
        }
      }

      &--joborder {
        flex: 0 1 11rem;
      }

      &--date {
        flex: 0 1 16rem;

        &-title {
          font-size: 12px;
          color: $dusty-gray;
        }
      }

      &--destination {
        flex: 2 0 10rem;
        text-transform: capitalize;

        @include scaleToExtraSmall() {
          flex: 2 2 5rem;
        }
      }

      &--type {
        flex: 0 0 15rem;
      }

      &--to {
        flex: 0 1 10rem;
        text-transform: capitalize;
        text-align: center;
      }

      &--status {
        flex: 0 0 9rem;
        text-transform: capitalize;
        text-align: center;

        @include scaleToExtraSmall() {
          flex: 2 2 5.5rem;
          padding: 0 6px;
          .wethod-button {
            padding: 0 8px;
          }
        }
      }

      &--actions {
        flex: 0 0 5rem;
        text-align: right;

        @include scaleToExtraSmall() {
          flex: 2 0 3rem;
          padding-left: 5px;
          padding-right: 8px !important;
        }
      }

      &--ownership {
        height: 46px;
        line-height: 46px;
        background: transparent;
        vertical-align: middle;

        .ownerLabel {
          border: 1px solid$black;
          border-radius: 4px;
          font-size: 10px;
          line-height: 10px;
          text-transform: uppercase;
          padding: 4px;
        }
      }
    }
  }

  &-office {
    padding: 0 10px;

    &--highlight {
      background-color: rgba(254, 184, 83, 0.1);
    }

    &--readonly {
      .material-input__label {
        background-color: transparent;
      }

      .material-input__input--readonly {
        .wethod-input {
          background-color: transparent;
        }
      }
    }
  }

  .wethod-sidebar__body {
    padding: 0;

    .material-input {
      padding: 0 10px;
    }
  }

  &__sidebar {
    &-title{
      .wethod-icon-button {
        &:hover {
          .wethod-icon {
            background-position-x: -64px;
          }
        }
      }
    }

    &-employee {
      font-size: 11px;
      color: $dusty-gray;
      margin: 12px 0 20px 16px;
    }

    &-subtitle {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 12px 0;
      font-size: 12px;
      font-weight: $semiBold;
      text-transform: uppercase;
      color: $dusty-gray;
    }

    &-multiple-input {
      &-row {
        display: table;
        width: 100%;
      }

      &-element {
        display: table-cell;
        vertical-align: middle;
      }
    }
  }

  &__alert-bar {
    .wethod-button {
      float: right;
      margin-top: 12px;
    }
  }

  &__category {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 70px;
    border-bottom: 1px solid $concrete-gray;
    padding: 0 10px;
    cursor: pointer;

    &.disabled {
      cursor: default;
    }

    &:first-child {
      border-top: 1px solid $concrete-gray;
    }

    &:not(.disabled):hover {
      background: #F2F3F5;
    }

    &-dot {
      width: 24px;
      height: 24px;
      margin: 0 16px;
      border-radius: 50%;
      background-color: $bright-sky-blue;
      line-height: 24px;
      text-align: center;
      color:$white;

      &--error {
        width: 16px;
        height: 16px;
        background-color: #D94F6B;
      }
    }

    &-name {
      margin-left: 30px;
      flex: 1;
    }
  }

  .wethod-modal2__body {
    .material-input[data-input-type='range'] {
      width: 30%;
      margin-right: 16px;
    }
  }

  &__download-modal {
    .wethod-modal2__body {
      max-height: 55vh;
      overflow: auto;

      .wethod-icon-button {
        padding: 0;
      }
    }
  }

  &__modal-multi-choice {
    min-width: 300px;
    font-size: 16px;

    &-modal {
      .wethod-modal2__body {
        padding: 0px 32px;
      }
    }

    &-item {
      color:$black !important;
      border-bottom: 1px solid $concrete-gray;
      padding: 20px 32px;
      margin: 0 -32px;
      transition: .3s;
      cursor: pointer;
      display: flex;
      justify-content: space-between;
      align-items: center;

      &:hover {
        background: #F2F3F5;
      }

      .wethod-icon-arrow-right {
        margin: 0 -5px 0 24px;
      }
    }
  }

  &-files {
    &__empty {
      padding: 12px;
      color: $dusty-gray;
      font-size: 12px;
      line-height: 1.4;
      text-align: center;
    }

    &__item {
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 49px;
      border: 1px solid $concrete-gray;
      padding-left: 16px;
      padding-right: 8px;

      &:not(:last-child) {
        margin-bottom: 12px;
      }

      button {
        &:hover {
          background: $concrete-gray;
        }
      }

      &--name {
        flex: 1;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        line-height: 1.5;
      }

      &--actions {
        max-width: 80px;
        display: flex;
      }
    }
  }

  &__actions {
    height: 33px;
    display: flex;
    justify-content: flex-end;
    align-items: baseline;

    &-wrapper {
      padding: 16px 0;
    }

    .wethod-export-button {
      @include scaleToExtraSmall() {
        visibility: hidden;
      }
      background-color: transparent;
      margin: 0 32px 0 0;
    }
  }

  &__export-modal {

    &--radio-group {
      &label:last-child {
        margin-top: 17px;
      }
    }

    &--date-select {
      display: flex;
      margin-left: 30px;

      .material-input {
        width: inherit !important;
        margin-right: inherit !important;
      }

      .material-input .material-input__input {
        margin: 0 10px !important;
        width: 120px;

        .material-input-button {
          background-color: $white;
        }

        .wethod-date-picker .material-input-button {
          background-color: $white;
        }
      }
    }

    .wethod-modal2__container {
      display: flex;
      flex-direction: column;
      width: 450px;
      height: 330px;
      max-height: 330px;
    }

    .wethod-modal2__header {
      flex: 0 0 auto;
    }

    .wethod-modal2__body {
      line-height: 20px;
      font-size: 14px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      flex: 1 1 auto;
      max-height: 300px;
    }


    b {
      font-weight: $bold;
    }
  }

  &__cancel-button {
    @extend .wethod-button;
    color: $raspberry-red;

    &:hover {
      color: $white;
      background-color: $raspberry-red;
    }
  }

  &__confirm-button {
    @extend .wethod-button;
    color: $garden-green;

    &:hover {
      color: $white;
      background-color: $garden-green;
    }
  }

  &__add-button {
    float: right;
    font-size: 14px;
    line-height: 30px;
  }

  &__type {
    &-not-booked {
      filter: invert(96%) sepia(19%) saturate(256%) hue-rotate(173deg) brightness(98%) contrast(92%);
    }
  }

  &__circle-icon {
    border-radius: 100%;
    border: 0.1px solid $black;
    cursor: pointer;
    transition: .2s;

    &:hover {
      background-color: #EBEBEB;

      .wethod-icon {
        @extend .wethod-icon-download;
        @extend .wethod-icon-download--black;
      }
    }
  }

  &__status {
    font-size: 16px;

    &-booked {
      color: $garden-green;
    }

    &-pending {
      color: $sunset-orange;
    }
  }

  .wethod-icon-button {
    width: 32px;
    height: 32px;
  }

  &__carrier {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 70px;
    background: $white;
    border-bottom: 1px solid $concrete-gray;

    &--category-icons {
      display: none;
    }

    &--disabled {
      opacity: 0.25 !important;
      pointer-events: none;
    }

    &:hover {
      .travel__carrier--category-icons {
        display: inline;
      }
    }

    &--title {
      padding: 11px 1em;

      &-edit {
        flex: 1 2 0;
        margin: 0 -5px 0 -3px;

        & > span.wethod-input {
          border: 1px solid $white;
          background-color: $white;
        }

        &-rename {
          & > span.wethod-input {
            border: 1px solid $concrete-gray;
            background-color: #FAFAFA;
          }
        }
      }
    }

    &--body {
      padding-top: 15px;
    }

    &--body-end {
      padding: 0 10px 15px;
    }
  }

  &__editable-icon {
    border-radius: 50%;
    cursor: pointer;

    &:hover {
      background-color: #EBEBEB;
    }
  }

  &__selection-labels {
    margin: 0 0.5em 0 3em;
  }

  &__selection-groups {
    display: flex;
    margin: 10px 0 15px 0;
  }

  &__dropdown {
    &--readonly {
      padding-left: 30px;
    }
  }

  &__no-hover {
    pointer-events: none;
  }

  &__people-amount-select {
    padding: 1em;

    &--row {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 15px;
    }

    &--label {
      margin-right: 20px;
    }

    &--buttons {
      display: inline-flex;
      width: 100%;
      justify-content: flex-end;
      margin-top: 10px;
    }

    &--quantity {
      margin: 0 5px 0 5px;
    }

    &--done-btn {
      margin-left: 10px;
    }
  }
}

div.wethod-sidebar__body > div > div.travel__category-elements:first-child {
  margin: -1px 0 0 0;
}
