
.peopleHeader {
  margin: 0 auto 8px !important;

  .peopleTitleSelectedWeekWrap {
    margin-top: 6px;
  }

  @include scaleToSmall() {
    .exportLink {
      display: none;
    }
  }
}

.peopleExportModal {
  &__body {
    line-height: 1.5;
  }

  .month-label {
    width: 150px;
    margin: 0 auto;
    display: block;
    padding: 5px;
    cursor: pointer;
    outline: none;
  }

  .month-input {
    width: 140px;
    position: absolute;
    left: 105px;
    margin-top: 4px;
  }

  .modalWrapAction {
    margin-top: 32px;
  }
}

.peopleTitle {
  font-weight: $bold;
  font-size: 30px;
  margin-top: 6px;
  line-height: 1;
  text-transform: uppercase;

  @include scaleToSmall() {
    text-align: center;
  }
  @include scaleToExtraSmall() {
    text-align: center;
  }
}

.wrapEmployeeList {
  float: left;
  width: 100%;
  padding: 16px 0 10px;
  background: $white;
  overflow: hidden;
  border-radius: 12px;
  border: 1px solid $concrete-gray;
}

.reportSectionHeader {
  .section-header {
    padding-top: 16px;
  }
}

.peopleTitleSelectedWeek {
  text-align: center;
  margin-top: 9px;
  font-weight: $semiBold;
}

.projectTypeRow {
  float: left;
  margin: 0px 8px 4px;
  @include scaleToSmall() {
    margin: 0px 8px 6px;
  }
  @include scaleToExtraSmall() {
    margin: 0px 8px 6px;
  }

  .peopleProjectTypeColor {
    float: left;
    width: 10px;
    height: 10px;
    margin-right: 4px;
  }

  .peopleProjectTypeName {
    float: left;
    text-transform: uppercase;
    color: $steel-gray;
  }
}

.reportPeopleListRow {
  overflow: hidden;
  overflow-y: scroll;

  //stile scroll
  &::-webkit-scrollbar {
    width: 7px;
  }

  &::-webkit-scrollbar-track {
    width: 7px;
    -webkit-box-shadow: inset 0 0 6px rgba(255, 255, 255, 0);
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: rgba(0, 0, 0, .2);
  }
}

.reportPeopleRow {
  float: left;
  width: 100%;
  margin-bottom: 6px;

  a {
    display: block;
    color: #323445;

    &.noClick {
      cursor: default;
    }
  }

  .employeeNme {
    @extend .table;
    height: 36px;
    width: 100%;
    padding-right: 8px !important;
    text-align: right;
    font-weight: $semiBold;
  }

  .peopleWorkedHours {
    //@extend .table;
    position: relative;
    height: 36px;
    text-align: center;
    color: $white;

    .cell {
      display: block;
      position: relative;
      height: 11px;
      top: 12px;
    }
  }

  .peopleWorkedExtraHours {
    @extend .table;
    width: 100%;
    height: 36px;
    text-align: center;
    font-weight: $bold;
  }
}

.reportPeopleDetails {
  background: $white;
  padding: 16px 0 0px;
  border-radius: 12px;
  border: 1px solid $concrete-gray;

  @include scaleToExtraSmall() {
    width: calc(100vw - 0.5em);
  }

  .reportPeopleDetails-name {
    padding: 0 8px 16px;
    font-size: 18px;
    font-weight: $semiBold;
  }

  .reportPeopleDetails-empty {
    display: block;
    padding: 10px;
    color: $dusty-gray;
  }

  .reportPeopleDetails-header {
    padding: 9px 8px;
    background: $morning-fog-gray;
    color: $black;
    font-weight: $semiBold;
    text-transform: uppercase;

    .reportPeopleDetails-header-name {
      float: left;
      width: 50%;
    }

    .reportPeopleDetails-header-job-order{
      float: left;
      width: 30%;
    }

    .reportPeopleDetails-header-hours {
      float: left;
      width: 20%;
      text-align: right;
      padding-right: 8px;
    }
  }

  .reportPeopleDetails-projectList {
    list-style: none;

    li {
      display: block;
      padding: 8px;
      border-bottom: 1px solid rgb(235, 238, 241);

      .reportPeopleDetails-projectList-name {
        float: left;
        width: 50%;
      }

      .reportPeopleDetails-projectList-job-order {
        float: left;
        width: 30%;
      }

      .reportPeopleDetails-projectList-hours {
        float: left;
        width: 20%;
        text-align: right;
        padding-right: 8px;
      }
    }
  }

  .reportPeopleDetails-timesheetLink {
    display: block;
    background: $bright-sky-blue;
    line-height: 48px;
    text-align: right;
    text-decoration: none;
    color: $white;
    font-weight: $semiBold;
    cursor: pointer;

    .reportPeopleDetails-timesheetLink-icon {
      border-radius: 50%;
      margin-left: 16px;
      margin-right: 12px;
      display: inline-block;
      vertical-align: middle;
      height: 32px;
    }

    .wethod-icon {
      border-radius: 50%;
      display: inline-block;
    }

    &:hover {
      span:first-child {
        text-decoration: underline;
      }

      .reportPeopleDetails-timesheetLink-icon {
        background: $white;
      }

      .wethod-icon {
        background-position-x: 0;
      }
    }
  }
}
