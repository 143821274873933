$iconButtonPath: '../../images/icons/';

// ICON BUTTON
$iconButton-width: 30px;
$iconButton-height: 30px;

.iconButton {
  position: relative;
  display: block;
  width: $iconButton-width;
  height: $iconButton-height;
  cursor: pointer;
  border: 1px solid $bright-sky-blue;
  text-align: center;
  border-radius: 50%;
  overflow: hidden;
  background: $white;
  outline: none;

  @include transition(0.2s ease-in-out);

  .iconButton__feedback {
    width: $iconButton-width * 2;
    height: $iconButton-height * 2;
    position: absolute;
    left: 0;
    top: 0;
    background-color: rgba(255, 255, 255, 0.5);
    border-radius: 50%;
    margin-left: - $iconButton-width;
    margin-top: - $iconButton-height;
    transform: scaleX(0) scaleY(0);
  }

  .iconButton__icon {
    display: block;
    width: 30px;
    height: 30px;
    position: relative;
    top: -1px;
    left: -1px;

    background-position: 0 0;

    //TODO
    // - delete button
    // - note button
    // - template button
    &.iconButton__icon--budget {
      @include retina-image($iconButtonPath + budget, 60px 30px, png, null, '@2x');
    }
    &.iconButton__icon--planning {
      @include retina-image($iconButtonPath + planning, 60px 30px, png, null, '@2x');
    }
    &.iconButton__icon--finance {
      @include retina-image($iconButtonPath + finance, 60px 30px, png, null, '@2x');
    }
    &.iconButton__icon--moreOption {
      @include retina-image($iconButtonPath + moreoption, 60px 30px, png, null, '@2x');
    }
    &.iconButton__icon--projectCharter {
      @include retina-image($iconButtonPath + projchar, 90px 30px, png, null, '@2x');
    }
    &.iconButton__icon--report {
      @include retina-image($iconButtonPath + report, 60px 30px, png, null, '@2x');
    }
    &.iconButton__icon--duration {
      @include retina-image($iconButtonPath + duration, 60px 30px, png, null, '@2x');
    }
    &.iconButton__icon--share {
      @include retina-image($iconButtonPath + share, 60px 30px, png, null, '@2x');
    }
    &.iconButton__icon--program {
      @include retina-image($iconButtonPath + projchar, 90px 30px, png, null, '@2x');
    }
    &.iconButton__icon--pipeline {
      @include retina-image($iconsPath + modules_icons, 128px 32px);
      width: 16px;
      height: 16px;
      margin: 7px;
      background-position: -16px 0px;
    }
  }

  .wethod-icon-medium-timeline {
    position: relative;
    top: 3px;
  }

  &:hover {
    background: $bright-sky-blue;

    .iconButton__icon {
      background-position: 30px 0;
      &--pipeline {
        background-position: -16px 16px;
      }
    }
    .wethod-icon-medium-timeline {
      background-position-x: -48px;
    }
  }
}
