$borderColor: #bfc0c6;
$borderSize: 1px;

$buttonColor: #d9ecfc;
$buttonBg: $bright-sky-blue;
$buttonColorHover: $white;
$buttonBgHover: $bright-sky-blue;

$primaryBg: $bright-sky-blue;
$primaryColor: #d9ecfc;
$primaryBgHover: $bright-sky-blue;
$primaryColorHover: $white;
$primaryBgActive: $bright-sky-blue;
$primaryColorActive: $white;

$secondaryBg: $white;
$secondaryColor: $steel-gray;
$secondaryBgHover: $white;
$secondaryColorHover: $bright-sky-blue;
$secondaryBgActive: $white;
$secondaryColorActive: $bright-sky-blue;

$switchColor: $steel-gray;
$switchColorHover: $black;
$switchColorActive: $black;

$switchBorderColor: #ebeef1;
$switchBorderSize: 1px;

.switchButton {
  width: auto !important;
  list-style: none;
  display: block;

  li {
    display: table;
    float: left;
    width: auto;
    border: none;
    border-right: $switchBorderSize solid $switchColorActive;
    overflow: hidden;
    text-align: center !important;
    background: transparent;
    cursor: pointer;
    clear: none !important;

    .text {
      display: block;
      font-weight: $semiBold;

      &.sel {
        background: $switchColorActive;
        color: $white;
      }
    }

    &:hover {
      .text {
        background: $switchColorActive;
        color: $white;
      }
    }
  }

  a {
    color: $switchColorActive;
    @include transition(0.2s ease-in-out);
  }

  li:first-child {
    border-radius: 30px 0 0 30px;
  }

  li:last-child {
    border-right: none;
    border-radius: 0 30px 30px 0;
  }

  li:only-child {
    border-radius: 30px;;
  }

  li {
    a {
      padding: 8px 18px !important;
    }

    border-top: $borderSize solid $switchColorActive;
    border-bottom: $borderSize solid $switchColorActive;
  }

  li:first-child {
    border-left: $borderSize solid $switchColorActive;
  }

  li:last-child {
    border-right: $borderSize solid $switchColorActive;
  }

  &.marginRight {
    margin-right: 18px;
  }
}

.roundButton {
  display: block;
  border-radius: 30px;
  color: rgb(109, 112, 126);
  font-size: $upperCaseFont;
  padding: 9px 18px !important;
  text-align: center !important;
  font-weight: $semiBold;
  cursor: pointer;
  outline: none;
  background: $white;
  border: none;
  text-transform: uppercase;

  &.hide {
    display: none;
  }

  .text {
    display: block;

    &.sel {
      color: $bright-sky-blue;
    }
  }

  .pipe {
    border-left: 1px solid $borderColor;
  }

  &.border {
    border: $borderSize solid $borderColor;
    padding: 8px 20px !important;

    &.borderAction {
      .text {
        color: $buttonBgHover;
      }

      &:hover, &:active {
        border-color: $buttonBgHover;
      }
    }
  }

  &.okAction {
    @include transition(0.2s ease-in-out);
    border: $borderSize solid $garden-green;
    padding: 8px 18px !important;
    background: transparent;

    .text {
      color: $garden-green;
    }

    &:hover, &:active {
      background: $garden-green;

      .text {
        color: $white;
      }
    }

    &.disabled {
      opacity: 0.5;

      &:hover, &:active {
        background: transparent;
        cursor: default;

        .text {
          color: $garden-green;
        }
      }
    }
  }

  &.standardAction {
    @include transition(0.2s ease-in-out);
    border: $borderSize solid $black;
    padding: 8px 20px !important;
    background: transparent;

    .text {
      color: $black;
    }

    &:hover, &:active {
      background: $black;

      .text {
        color: $white;
      }
    }

    &.disabled {
      opacity: 0.5;

      &:hover, &:active {
        background: transparent;
        cursor: default;

        .text {
          color: $black;
        }
      }
    }
  }

  &.koAction {
    @include transition(0.2s ease-in-out);
    border: $borderSize solid $raspberry-red;
    padding: 8px 20px !important;
    background: transparent;

    .text {
      color: $raspberry-red;
    }

    &:hover, &:active {
      background: $raspberry-red;

      .text {
        color: $white;
      }
    }

    &.disabled {
      opacity: 0.5;

      &:hover, &:active {
        background: transparent;
        cursor: default;

        .text {
          color: $raspberry-red;
        }
      }
    }
  }

  &.action {
    background: $buttonBg;

    .text {
      color: $buttonColor;
    }

    &:hover, &.sel {
      background: $buttonBgHover;

      .text {
        color: $buttonColorHover;
      }
    }

    &.disabled {
      opacity: .7;
      cursor: default;

      &:hover {
        background: $buttonBg;

        .text {
          color: $buttonColor;
        }
      }
    }
  }

  &.danger {
    background: $raspberry-red;

    .text {
      color: $buttonColor;
    }

    &:hover, &.sel {
      background: $raspberry-red;

      .text {
        color: $buttonColorHover;
      }
    }

    &.disabled {
      opacity: .7;
      cursor: default;

      &:hover {
        background: $raspberry-red;

        .text {
          color: $buttonColor;
        }
      }
    }
  }

  &.buttonSecondary {
    background: $secondaryBg;

    .text {
      color: $secondaryColor;
    }

    &:hover {
      background: $secondaryBgHover;

      .text {
        color: $secondaryColorHover;
      }
    }

    &:active, &.sel {
      background: $secondaryBgActive;

      .text {
        color: $secondaryColorActive;
      }
    }

    &.disabled {
      opacity: .7;
      cursor: default;

      &:hover {
        background: $secondaryBg;

        .text {
          color: $secondaryColor;
        }
      }
    }
  }

  &.cancel {
    padding: 8px 20px !important;
    border: $borderSize solid $borderColor;
    background: $white;
    color: $borderColor;

    &:hover {
      .text {
        color: $bright-sky-blue;
      }
    }
  }

  &.systemMessage {
    border: $borderSize solid $white;
    padding: 8px 20px !important;
    background: transparent;
    color: $white;
    margin-top: 8px;
    @include transition(0.2s ease-in-out);

    &:hover {
      background: $white;
      color: $garden-green;
    }
  }
}

.fullRoundButton {
  @extend .roundButton;
  padding: 9px 11px !important;

  &.border {
    border: $borderSize solid $borderColor;
    padding: 8px 10px !important;
  }
}

.searchButton {
  @extend .fullRoundButton;
  padding: 0px 1px !important;
}
