.projectCharter {
  .section-header {
    padding-top: 16px;
  }
}

.pcPushMarginLeft {
  margin-left: 15px !important
}

.pcPushPaddingLeft {
  padding-left: 15px !important
}

.pcLoadingSection {
  text-align: center;
  color: $steel-gray;
  background: $white;
  margin-top: 45px;

  .table {
    width: 100%;
    height: 100px;
    text-transform: uppercase;
  }
}

//HEAD
.pcGlobalInfo {
  padding: 16px 0 0;
  background: $white;
  border: 1px solid $concrete-gray;
  border-radius: 12px;
}

.projectCharterHead {
  padding-top: 16px;
  //MDL
  .mdl-textfield__label {
    height: 16px;
    top: 15px;
    line-height: 1.3;

    display: block;
    font-size: $baseFont;

    &.billingError {
      color: $raspberry-red !important;
    }

    &.pcBig {
      top: 34px;
    }

    &.pcMedium {
      top: 31px;
    }
  }

  .mdl-textfield .mdl-textfield__input {
    padding: 10px 8px !important;

    &.pcPushPaddingLeft {
      padding-left: 15px !important;
    }

    &.pcBig {
      font-size: 20px !important;
    }

    &.pcMedium {
      font-size: 14px !important;
    }

    &.mission {
      height: 125px;
      color: $dusty-gray;
      font-size: 15px !important;
      font-style: italic;
    }
  }
}

.pcShowMoreitems {
  float: right;
  margin-right: 17px;
  color: $bright-sky-blue;
}

.projectCharterList {
  float: left;
  min-height: 61px;
  width: 100%;
  margin: 8px 0;

  li {
    height: 60px;
    position: relative;
    float: left;
    position: relative;
    background: $white;
    border: 1px solid $concrete-gray;

    .projectStripe {
      z-index: 100;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      width: 4px;
      background: #E6E6E6;
    }

    &.tentative {
      .projectStripe {
        &::before {
          content: "";
          width: 100%;
          height: 100%;
          z-index: 100;
          position: absolute;
          background: rgba(255, 255, 255, 0.6);
        }
      }
    }
  }

  .icon {
    margin: 0 !important;
  }

  .resourcLink {
    color: $bright-sky-blue;

    &:hover {
      text-decoration: underline;
    }
  }

  a.resourceTypeSelectLabel {
    color: $steel-gray;
  }

  .selectWrap {
    padding: 19px $gutter;
    line-height: 1.3;
    position: relative;

    .mdl-textfield {
      top: 0px;
      font-size: $baseFont !important;
      width: 100%;

      padding: 2px 0;

      .mdl-textfield__input {
        border: none;
      }

      .mdl-textfield__label {
        height: 19px;
        top: 2px;
        line-height: 1.3;
        display: block;
      }

      &.mdl-textfield--floating-label.is-focused .mdl-textfield__label,
      &.mdl-textfield--floating-label.is-dirty .mdl-textfield__label {
        top: -15px;
        color: $bright-sky-blue !important;
        font-size: 10px !important;
      }

    }
  }

  .alignButton {
    margin-top: 12px;
  }

  .circularSpriteModules {
    float: right;
    margin: 14px 4px 0;
  }

  .pcProjectListLink {
    float: right;
    margin: 0 4px;

    &.table {
      width: 30px;
      height: 60px;
      padding: 0;

      &.pcPushPaddingLeft {
        padding-left: 15px !important
      }
    }
  }

  .table {
    width: 100%;
    height: 60px;
    padding: $gutter $gutter * 2;

    &.pcPushPaddingLeft {
      padding-left: 15px !important
    }
  }

  .projectEmptyRow {
    float: left;
    width: 100%;
    height: $rowH;
    background: $white;
    border-bottom: 1px solid $concrete-gray;
    line-height: 1.3;
  }
}

//TITLE
.projectCharterTitle {
  font-size: 14px;
  text-transform: uppercase;
  padding: 45px 0px 16px 15px;
  font-weight: $semiBold;
  color: $bright-sky-blue;

  &.pushBottom {
    margin-bottom: 16px;
  }
}

.pcPushBottom {
  margin-bottom: 16px;
}

.settingsTooltip {
  &.pcCreateInput {
    top: 50px;
    left: 0px;
    width: 100%;
  }

  &.pcResourceTop {
    bottom: 60px;
    left: 0;
    width: 100%;
    top: initial;
  }

  &.pcProjectBottom {
    left: 0;
    width: 100%;
    top: 65px;
  }
}

//ECONOMICS
.economicsTable {
  padding: 16px 0;
  background: $white;
  border-radius: 12px;
  border: 1px solid $concrete-gray;
}

.pcEconomicsHeadRow {
  color: $bright-sky-blue;

  .economicsCol {
    width: 20%;
    line-height: 1.3;
  }
}

.pcEconomicsRow {
  border-bottom: 1px solid rgb(220, 220, 222);

  .economicsCol {
    width: 20%;
    padding: 9px 6% 8px 8px;
  }

  &.borderStrong {
    border-color: $dusty-gray;
  }

  &.smallRow {
    margin-top: -10px;

    .economicsCol {
      width: 20%;
      padding: 0px 6% 0px 8px;
    }

    &.bottomSpace {
      .economicsCol {
        padding-bottom: 8px;
      }
    }
  }

  .rowTitle {
    padding: 9px 6% 8px 8px;
    text-transform: uppercase;
    font-size: 11px;
  }

  &.noBorder {
    border: none;
  }

  .marginPerc {
    color: rgb(187, 187, 187);
  }
}

//PLANNING
.pcWrapMonthHeadList {
  background: $white;
  margin-bottom: 20px;
}

.pcYearHead {
  width: 1024px;
  padding: 8px 3px;

  .pcYearHeadTitle {
    padding: 8px 0;
    font-weight: $semiBold;
    color: rgb(135, 139, 142);
    background: $concrete-gray;
    // border-left:2px solid $concrete-gray;
    // border-right:2px solid $concrete-gray;
  }
}

.pcMonthHead4 {
  @include calc('width', 1024px / 4);

  .col_16 {
    padding: 1px !important;
  }
}

.pcMonthHead8 {
  @include calc('width', 1024px / 8);

  .col_16 {
    padding: 1px !important;
  }
}

.pcMonthHead12 {
  @include calc('width', 1024px / 12);

  .col_16 {
    padding: 1px !important;
  }

  display: inline-table;
}

.pcWrapProjectList {
  overflow: hidden;
}

.projectProgramWrapper {
  height: 50px;
  margin-bottom: 3px;
  overflow: hidden;
  background: $white;
  border: 1px solid $concrete-gray;

  &.charterPlanningVisibleDetail {
    z-index: 750;
    position: relative;
    background: $white;

    &:hover {
      background: $white !important;
    }
  }

  &:hover {
    background: rgba(216, 216, 216, .3);
  }
}

.pcProjectNameLink {
  display: block;
  padding: 16px 0 3px;

  .pcProjectName {
    display: block;
    padding-left: 15px;
    //margin: 8px 0 4px;
    margin-bottom: 7px;
    font-weight: $semiBold;
    color: #333;
  }
}


.projectProgramEmployeeWrapper {
  background: $white;
  padding: 12px 0 4px !important;
  border-top: 1px solid rgb(235, 238, 241);

  .pcProjectName {
    font-weight: $normal;
    padding-left: 26px;
    margin-bottom: 4px;
    color: #333;
  }
}

.pcWrapMonthList {
  overflow: hidden;
  position: relative;
  float: left;


  .pcArrowWrapLeft, .pcArrowWrapRight {
    z-index: 100;
    width: 50%;
    height: 30px;
    position: absolute;
    top: 0;
  }

  .pcArrowWrapLeft {
    left: 0;

    &:hover {
      .pcPreviousMonth {
        display: block;
      }
    }
  }

  .pcArrowWrapRight {
    right: 0;

    &:hover {
      .pcNextMonth {
        display: block;
      }
    }
  }

  .pcPreviousMonthLoading, .pcNextMonthLoading {
    display: none;
    z-index: 100;
    position: absolute;
    top: 5px;
    padding: 4px;
    color: $bright-sky-blue;
    background: rgba(255, 255, 255, .9);
  }

  .pcPreviousMonthLoading {
    left: 8px;
  }

  .pcNextMonthLoading {
    right: 8px;
  }

  .pcPreviousMonth, .pcNextMonth {
    display: none;
    z-index: 100;
    position: absolute;
    top: 5px;
    color: $bright-sky-blue;
    font-weight: $bold;
    font-size: 14px;
    border: 1px solid $bright-sky-blue;
    border-radius: 50%;
    padding: 2px 5px;
    text-align: center;
    background: $white;

    &:hover {
      color: $white;
      background: $bright-sky-blue;
    }
  }

  .pcPreviousMonth {
    left: 8px;
  }

  .pcNextMonth {
    right: 8px;
  }
}

.wrapActive {
  margin-top: -1px;
  height: 31px;
  background: $bright-sky-blue;
}

.pcMonthName {
  padding: 8px;
  color: rgb(74, 74, 74);
  background: $white;

  &.active {
    color: $white;
    background: $bright-sky-blue;
  }
}


.projectPlanningMonthSmallSize {
  &.timesheetTime {
    height: 5px;
  }

  &.planningTime {
    height: 5px;
    background: $steel-gray;
  }

  &.pipelineTime {
    height: 5px;
    background: $steel-gray;

    .holeEmpty {
      height: 3px;
      width: 100%;
      margin-top: 1px;
      background: $white;

      &.first {
        @include calc('width', '100% - 1px');
        margin-left: 1px;
      }

      &.last {
        @include calc('width', '100% - 1px');
        margin-right: 1px;
      }

      &.both {
        @include calc('width', '100% - 2px');
        margin-right: 1px;
        margin-left: 1px;
      }
    }

  }

  &.emptyTime {
    height: 5px;
    background: transparent;
  }
}

.projectPlanningMonthBigSize {
  &.timesheetTime {
    height: 10px;
  }

  &.planningTime {
    height: 10px;
    background: $steel-gray;
  }

  &.pipelineTime {
    height: 10px;
    background: $steel-gray;

    .holeEmpty {
      height: 8px;
      width: 100%;
      margin-top: 1px;
      background: $white;

      &.first {
        @include calc('width', '100% - 1px');
        margin-left: 1px;
      }

      &.last {
        @include calc('width', '100% - 1px');
        margin-right: 1px;
      }

      &.both {
        @include calc('width', '100% - 2px');
        margin-right: 1px;
        margin-left: 1px;
      }
    }
  }

  &.emptyTime {
    height: 10px;
    background: transparent;
  }
}

.moreActionsSelectProgram {
  overflow: inherit;

  li {
    height: 30px;
    border-bottom: none;

    a {
      height: 30px !important;
      white-space: nowrap;
    }
  }
}
