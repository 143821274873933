@import 'finance-invoice';
@import 'finance-order';
@import 'finance-supplier';
@import 'finance-production-plan';

.financeMenu {
  margin-left: 53%;
}

.finance {
  &-modal {
    &__recipient {
      &-item {
        color:$black !important;
        border-bottom: 1px solid $concrete-gray;
        padding: 20px 16px;
        margin: 0 -16px;
        transition: 0.3s;
        cursor: pointer;
        display: flex;
        justify-content: space-between;
        align-items: center;

        &:hover {
          background: #F2F3F5;
        }
      }

      &-body {
        padding: 0 16px;
      }
    }
  }
}
