/**
 * All Projects Report
 */
@import "scss/colors";

$headH: 30px;
$rowH: 60px;
$borderReportColor: #ebeef1;
$borderReportSize: 1px;
$linkColor: $bright-sky-blue;
$requiredColor: $bright-sky-blue;
$errorColor: $raspberry-red;
$successColor: #d9ecfc;
$inputColor: $bright-sky-blue;
//FILTERS
$primaryBg: $bright-sky-blue;
$primaryColor: #d9ecfc;
$primaryBgHover: $bright-sky-blue;
$primaryColorHover: $white;
$primaryBgActive: $bright-sky-blue;
$primaryColorActive: $white;
$reportFilterColor: $steel-gray;

.report-detail__label-week {
  border: 1px solid $raspberry-red;

  &::before {
    border-color: $raspberry-red transparent transparent transparent;
  }

  &--bottom {
    &::after {
      bottom: 100%;
      top: auto !important;
      border-color: transparent transparent white transparent !important;
    }

    &::before {
      bottom: 100%;
      top: auto !important;
      border-color: transparent transparent $raspberry-red transparent;
    }
  }
}

.report-detail__label-week--days-left {
  border: 1px solid $garden-green;

  &::before {
    border-color: $garden-green transparent transparent transparent;
  }

  &--bottom {
    &::after {
      bottom: 100%;
      top: auto !important;
      border-color: transparent transparent white transparent !important;
    }

    &::before {
      bottom: 100%;
      top: auto !important;
      border-color: transparent transparent $garden-green transparent;
    }
  }
}

.report-detail__label-week--worked-hours {
  border: 1px solid $bright-sky-blue;

  &::before {
    border-color: $bright-sky-blue transparent transparent transparent;
  }

  &--bottom {
    &::after {
      bottom: 100%;
      top: auto !important;
      border-color: transparent transparent white transparent !important;
    }

    &::before {
      bottom: 100%;
      top: auto !important;
      border-color: transparent transparent $bright-sky-blue transparent;
    }
  }
}

.buffer15 {
  margin-top: 15px;
  clear: both;
}

.pushRight {
  margin-right: $gutter * 4;
}

.sectionTitle {
  padding: $gutter;
}

.reportFilter {
  float: left;
  list-style: none;
  background: $white;
  border: 1px solid $concrete-gray;
  border-radius: 12px;

  input {
    border: none;
    padding: 0;
    outline: 0;
    width: 100%;
    height: 100%;
  }

  input:disabled {
    cursor: not-allowed;
  }

  li {
    width: 100%;
    clear: both;
    float: left;
    display: block;
    padding: $gutter * 2;
    background: $white;
    border-bottom: $borderReportSize solid $borderReportColor;
  }

  li:last-child {
    border-bottom: none;
  }
}

.infoContainer {
  margin-top: -6px;

  &__header {
    margin-right: 8px;
    padding-top: 16px;
  }

  &__baseline {
    @extend .reportRow;
    padding-top: 4px !important;
  }
}

.reportInfoContainer {
  background-color: $primaryColorActive;
  margin-top: 6px;
  padding: 3px;

  .reportInfoHeader {
    margin-top: 4px;

    li {
      padding: 6px;

      &.bold {
        font-weight: $bold;
      }
    }
  }

  .reportInfoContent {
    margin-top: 12px;

    ul {
      border-bottom: 1px solid $borderReportColor;
      overflow: hidden;
    }

    ul:last-of-type {
      border-bottom: none;
    }

    li {
      margin: 3px 0;
      padding: 6px;
    }
  }
}

.wrapReportRow {
  width: 100%;
  max-height: 155px;
  overflow: hidden;
  overflow-y: scroll;

  border-bottom: 2px solid #DCDCDE;
  margin-bottom: 8px;

  float: left;

  &::-webkit-scrollbar {
    width: 7px;
  }

  &::-webkit-scrollbar-track {
    width: 7px;
    -webkit-box-shadow: inset 0 0 6px rgba(255, 255, 255, 0);
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: rgba(0, 0, 0, .2)
  }
}

.reportInfoLink {
  margin: 5px 0 4px 0;
  padding: 0 15px;
  background-color: $white;
}

/**
 * Project Report Details
 */

//modal
.reportDetailModalStructure {
  @extend .modalStructure;
}

/* Utility classes */
$main-color: $bright-sky-blue;
$line-color: #DCDCDE;

// Margin buffers
@for $n from 1 to 8 {
  $buffer: $n * 10;
  .bufferTop#{$buffer} {
    margin-top: $buffer + px !important;
  }
  .bufferBottom#{$buffer} {
    margin-bottom: $buffer + px !important;
  }
  .buffer#{$buffer} {
    @extend .bufferTop#{$buffer};
    @extend .bufferBottom#{$buffer};
  }
}

.noBorder {
  border: 0 !important;
}

.blockElement {
  display: block;
}

.leftAlign {
  @extend .blockElement;
  text-align: left;
}

.rightAlign {
  @extend .blockElement;
  text-align: right;
}

.detailAlignTotal {
  padding-right: 10px !important;
}

.centerAlign {
  @extend .blockElement;
  text-align: center;
}

.chartWrap {
  height: 508px;
  border: 1px solid $concrete-gray;
  border-radius: 12px;
  @include scaleToExtraSmall() {
    height: 400px;
  }

  &--report {
    height: 200px;

    .highcharts {
      &-subtitle {
        @include scaleToExtraSmall() {
          display: none;
        }
        @include scaleToSmall() {
          display: none;
        }
      }
    }
  }
}

.report-iconButton {
  &--transparent {
    background-color: transparent;
  }

  &--space {
    margin: 6px;
  }

  &--hidden {
    display: none;
  }
}

#prjReportContainer {
  #prjReportHead {
    padding-top: 25px;
    padding-bottom: 18px;
    padding-left: 8px;

    & > span {
      display: inline-block;
      vertical-align: middle;
      cursor: pointer;
    }

    .vertical-divider {
      width: 2px;
      background-color: $line-color;
      height: 2.5em;
      margin: 0px 5px;
    }

    .back {
      font-size: 1.3em;
      font-weight: $semiBold;
      line-height: 1.5em;
    }
  }

  .projectContainer {
    background-color: $white;
    border-radius: 12px;
    border: 1px solid $concrete-gray;

    .icon-button-program {
      @include scaleToExtraSmall() {
        display: none;
      }
      @include scaleToSmall() {
        display: none;
      }
    }

    & > .row {
      padding: 9px;
    }

    .bold {
      font-weight: $semiBold;
    }

    .blue {
      color: $main-color;
    }

    .title {
      @extend .bold;
      font-size: 1.5em;
      margin-bottom: 9px;
      @include scaleToExtraSmall() {
        width: 100%;
      }
      @include scaleToSmall() {
        width: 100%;
      }
    }

    hr {
      margin: 0px 10px;
      height: 0;
      border: 0;
      border-top: 1px solid $line-color;
    }

    .reportRow {
      border-bottom: 2px solid $line-color;
      padding: 8px 0;

      &--total {
        border-top: 2px solid $line-color !important;
      }

      span {
        //font-size: 1.1em;
        &.rightAlign {
          margin-right: 8px;
        }
      }
    }

    .hoursDetails {
      .headingInfos > .info {
        @extend .title;
        @extend .blue;
        line-height: 1em;
        margin-bottom: 10px;
        font-size: 1.4em;
      }
    }

    .hoursUsersRows .colGroup {
      margin: auto;
    }

    #tableHeader {
      padding-top: 0px;
    }

    .subTableHeader .colGroup {
      border-top: 1px solid $main-color;
    }

    .hoursUsersRows .reportRow {
      padding: 0px;

      span {
        &.rightAlign {
          margin-right: 10px;
        }

        &.leftAlign {
          margin-left: 10px;
        }
      }
    }

    .hoursUsersRows .reportRow:last-of-type {
      border-bottom: none;
    }

    .hoursUsersRows .footer {
      font-weight: $bold;
    }

    .sidebar {
      //font-size: 1.1em;
      .clientName,
      .projectName,
      .pmName, .joName {
        line-height: 1.5em;
      }

      .clientName {
        font-weight: lighter;
      }

      .projectName {
        font-size: 1.4em;
      }

      .economicsContainer {
        margin-left: -3px;
        margin-right: -3px;

        .reportRow span {
          font-size: 1em;
        }

        ul:last-of-type {
          border-bottom: none;
        }
      }

      .controlsContainer .control {
        display: inline-block;
        float: right;
        height: 42px;
        padding: 6px 6px;
        cursor: pointer;
      }
    }

    .invoicesContainer, .ordersContainer {
      ul {
        border-bottom: 1px solid $line-color;
        padding-top: 8px;
        padding-bottom: 8px;
      }

      ul:last-of-type {
        border-bottom: none;
      }
    }

    .invoiceRow, .orderRow {
      padding: 8px;
    }

  }
}

.report {
  &__invoices, &__orders {
    &--empty-row {
      text-align: center;
      color: #bbb;
      padding-top: 8px;
      padding-bottom: 8px;
    }
  }
}

.invoices, .orders {
  &-grid {
    &__title {
      @extend .uppercase;
      @extend .blue;
      font-weight: $semiBold;
      padding: 9px 9px 0 9px;
    }

    &__total {
      margin-top: 20px;
      padding: 0 6px;
    }

    &__list {
      border-top: 2px solid #DCDCDE;
      @extend .ordersCollection;
      margin-top: 10px;
    }

    &__remaining {
      padding: 8px 6px;
    }
  }

  &-list__row {
    position: relative;
    border-bottom: 1px solid #DCDCDE;

    &:hover {
      .orders__row--to-details-button {
        display: block;
      }

      .invoices__row--to-details-button {
        display: block;
      }
    }

    &:last-of-type {
      border-bottom: none;
    }
  }

  &__row {
    &--info {
      padding: 12px 12px 12px 9px;
      &-status {
        position: absolute;
        left: 12px;
        bottom: 1px;
        font-size: 11px;
        color: $dusty-gray;
      }
    }

    &--to-details-button {
      padding: 12px 12px 12px 9px;
      display: none;
      position: absolute;
      background: rgba(72, 165, 244, 0.9);
      color: $white;
      line-height: 1.3;
      cursor: pointer;
      top: 0;
      width: 100%;
      height: 100%;

      .message {
        color: $white;
      }

      .spriteModules {
        &.moduleFinance {
          background-position: -96px 16px;
        }
      }
    }
  }
}

.order {
  &-code {
    &--special {
      @extend .left;
      @extend .leftAlign;
      @extend .namefixedWidth;
      text-transform: capitalize;
      font-style: italic;
    }
  }
}

.moreActionSelectReport {
  @extend .moreActionSelect;
  overflow: inherit;
}

.ordersCollection, .invoicesCollection {
  max-height: 255px;
  overflow: hidden;
  overflow-y: auto;
  line-height: 1.3;

  .namefixedWidth {
    // width: 108px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}

.detailReportTotal {
  border-top: 2px solid #DCDCDE;
  padding-top: 8px;
  margin-top: 10px;
}

.reportDetailDataupdated {
  color: #CCC;
  font-size: 12px;
  text-align: right;
  font-weight: $normal;
  margin-top: 5px;
  @include scaleToExtraSmall() {
    width: 100% !important;
    text-align: left;
    margin-top: -5px;
  }
  @include scaleToSmall() {
    width: 100% !important;
    text-align: left;
    margin-top: -5px;
  }
}

.cachedReloadButton {
  padding-left: 9px !important;
}

.reloadCacheDateLabel {
  padding: 9px !important;
  color: #878B8E;
}

//ECONOMICS
.reportDetailEconomicsHeadRow {
  color: $bright-sky-blue;

  .economicsCol {
    width: 20%;
    line-height: 1.3;
    @include scaleToExtraSmall() {
      width: 33.33%;
    }
    @include scaleToSmall() {
      width: 33.33%;
    }
  }
}

.reportDetailEconomicsRow {
  border-bottom: 1px solid rgb(220, 220, 222);

  .economicsCol {
    width: 20%;
    padding: 12px 6% 8px 8px;
    @include scaleToExtraSmall() {
      width: 33.33%;
    }
    @include scaleToSmall() {
      width: 33.33%;
    }
  }

  &.borderStrong {
    border-color: $dusty-gray;
  }

  &.smallRow {
    margin-top: -10px;

    .economicsCol {
      width: 20%;
      padding: 0px 6% 0px 8px;
      @include scaleToExtraSmall() {
        width: 33.33%;
      }
      @include scaleToSmall() {
        width: 33.33%;
      }
    }

    &.bottomSpace {
      .economicsCol {
        padding-bottom: 8px;
        @include scaleToExtraSmall() {
          width: 33.33%;
        }
        @include scaleToSmall() {
          width: 33.33%;
        }
      }
    }
  }

  .rowTitle {
    padding: 12px 6% 8px 8px;
    text-transform: uppercase;
    font-size: 11px;
  }

  &.noBorder {
    border: none;
  }

  .marginPerc {
    color: rgb(187, 187, 187);
  }
}

.hoursUsersContainer {
  margin-bottom: 40px;
}
