.month-input {
  display: none;
  position: relative;
  width: 100%;
  max-height: 240px;
  background: $white;
  color: $black;
  height: 330px;
  padding: 3px;
  border: none;
  outline: 5px solid rgba(72, 165, 249, 0.3);
  float: left;
  overflow: hidden;
  &__month {
    padding: 8px;
    height: 30px;
    cursor: pointer;
    &:hover {
      color: $bright-sky-blue;
    }
  }
  &__years {
    float: left;
    overflow-y: scroll;
    height: 100%;
  }
  &__year {
    border-bottom: 1px solid $concrete-gray;
    float: left;
    width: 100%;
    &-label {
      padding: 8px;
      background:$white;
      width: 100%;
      position: relative;
      height: 30px;
      color: $bright-sky-blue;
    }
  }
}

.push {
  position: absolute;
  top: 0;
}

.stretch {
  padding-top: 30px;
}

.pipeline {
  &-row {
    &__client-po {
      width: 11%;
      padding: 5px 0;
    }
    &__job-order {
      width: 10.5% !important;
      @include scaleToSmall() {
        width: 31.25% !important;
      }
    }
    &__category {
      width: 15.2% !important;
      @include scaleToSmall() {
        width: auto;
      }
    }
    &__buttons-area {
      width: 32%;
      padding: 3px 0;
      @include scaleToSmall() {
        width: auto;
      }
      &-content {
        width: 66%;
      }
      &-label {
        width: 33%;
      }
      .iconButton {
        width: 32px;
        height: 32px;
        .iconButton__icon {
          top: 0;
          left: 0;
        }
      }
    }
  }
}
