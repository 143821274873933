$mobile  : max-width 768px;
$small-laptop  : max-width 1280px;
$laptop  : max-width 1439px;
$desktop : min-width 1440px;

@mixin scaleToMobile($max:nth($mobile,2)) {
  @media (max-width: $max) {
    @content
  }
  @media (max-device-width : $max) {
    @content
  }
}

@mixin scaleToLaptop($max:nth($laptop,2)) {
  @media (max-width: $max) {
    @content
  }
  @media (max-device-width : $max) {
    @content
  }
}

@mixin scaleToSmallLaptop($max:nth($small-laptop,2)) {
  @media (max-width: $max) {
    @content
  }
  @media (max-device-width : $max) {
    @content
  }
}
