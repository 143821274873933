.full404{
	position: fixed;
	height: 100vh;
	width: 100%;
	top: 0;
	background: #2b2e38;
	font-size: 30px;
	color: $white;

	@include scaleToExtraSmall(){
		font-size: 25px;
	}
}

.full403{
	position: fixed;
	height: 100vh;
	width: 100%;
	top: 0;
	left: 0;
	background: #2b2e38;
	font-size: 30px;
	color: $white;
	@include scaleToExtraSmall(){
		font-size: 25px;
	}
}
.background403{
	position: fixed;
	top: 0;
	left: 0;
	height: 100%;
	width: 100%;
	background: #2b2e38;
}


.centerBox404{
	position: relative;
	top:30%;
	line-height: 1.2;
}

.text404Highlighted{
	color:$bright-sky-blue;
	margin-bottom:30px;
	line-height: 1.3;
}

.fix404Link{
	position: absolute;
	bottom:45px;
	font-size: 12px;
	color:#adb0b7;
	left: 48%;

	@include scaleToExtraSmall(){
		left: 40%;
	}
}
