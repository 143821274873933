.basket {
  &-modal {
    &__body {
      line-height: 1.7;

      .wethod-input {
        margin-top: 5px;
      }

      .wethod-search-select {
        margin: 25px 0;

        .wethod-search-select__list {
          margin-top: 5px;
          max-height: 68px;
        }
      }
    }

    &__actions {
      text-align: center;

      .wethod-button {
        margin: 0 8%;
      }
    }
  }

  &__actions {
    height: 33px;
    position: relative;

    &-wrapper {
      padding: 16px 0;
    }
  }

  &__add-button {
    float: right;
    font-size: 14px;
    line-height: 30px;
  }

  &__due-date {
    margin-left: 25px;

    &-priority-dot {
      content: '';
      width: 11px;
      height: 11px;
      border-radius: 50%;
      position: absolute;
      margin-top: 20px;

      &--low {
        background-color: $sunset-orange;
      }

      &--high {
        background-color: $raspberry-red;
      }
    }
  }

  &-table {
    top: 76px;
    position: relative;

    &__column {
      &--name {
        width: 25%;

        line-height: initial;
        display: inline-block;
        vertical-align: middle;

        &-company {
          font-size: 11px;
        }
      }

      &--account {
        width: 16%;
      }

      &--target {
        width: 13%;
        text-align: right;

        &-value {
          &:after {
            content: 'K';
            margin: 0 3px;
          }
        }
      }

      &--days {
        width: 7%;
      }

      &--costs {
        width: 9%;
      }

      &--follow-up {
        width: 22%;
        position: relative;
        text-align: center;
      }

      &--state {
        width: 16%;
        text-align: center;
      }

      &--actions {
        width: 7%;
        text-align: right;
      }
    }

    &__checked {
      vertical-align: middle;
      display: inline-block;

      div {
        margin: auto;
      }
    }
  }

  &__sidebar {
    &--spacer {
      height: 30px;
    }

    &--multiple-input-row {
      display: table;
      width: 100%;
    }

    &--multiple-input-element {
      display: table-cell;
      vertical-align: middle;
    }

    &--client-name {
      width: 65%;
    }

    &--client-acronym {
      width: 35%;
    }

    &--target {
      &-editable {
        width: 48%;
        float: left;

        .wethod-input {
          padding-right: 20px !important;
        }

        input {
          text-align: right;
        }
      }

      &-readonly {
        span {
          display: block;
        }

        .material-input__input {
          margin-right: 5px;
        }

        .basket__sidebar--target-measure {
          bottom: 27px;
          right: 0;
        }
      }

      &-measure {
        color: $dusty-gray;
        position: relative;
        bottom: 43.5px;
        right: 25px;
        float: right;
      }
    }

    &--days {
      width: 25%;
    }

    &--ext-costs {
      width: 32%;
    }
  }
}
