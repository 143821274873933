.event {
  &-create-button {
    line-height: 25px;
    text-transform: uppercase;
    cursor: pointer;
    color: $bright-sky-blue;
    background-color: transparent;
    border-radius: 100px;
    border: 1px solid $bright-sky-blue;
    outline: none;
    text-align: center;
    transition: 0.15s ease-in-out;
    &:hover {
      color: $white;
      background-color: $bright-sky-blue;
    }
    &--adding {
      @extend .event-create-button;
      color: $white;
      background-color: $bright-sky-blue;

    }
  }
  &-container {
    padding: 10px;
  }
  &__delete {
    background-image: url(/img/icon/recycle_bin.svg);
    width: 24px;
    height: 24px;
    background-repeat: no-repeat;
    background-size: 18px;
    background-position: 2px 2px;
    float: left;
    border: 1px solid $bright-sky-blue;
    border-radius: 50%;
    cursor: pointer;
    &:hover{
      background-color: $bright-sky-blue;
      background-position-y: -16px;
    }
  }
  &__title {
    line-height: 25px;
    text-transform: uppercase;
    color: #4A4A4A;
    background-color: transparent;
    border: none;
    border-bottom: 1px solid #EBEEF1;
    outline: none;
    text-align: center;
    width: 100%;
    margin-bottom: 16px;
    float: left;
    &::placeholder {
      text-transform: none;
    }
  }
  &__description {
    float: left;
    width: 100%;
    margin-bottom: 16px;
    border: 0;
    color: #4A4A4A;
    font-size: 14px;
    font-style: italic;
    line-height: 20px;
    padding: 2px 8px;
    resize: none;
    &:focus {
      background: rgba(235, 238, 241, 0.5);
    }
    &::-webkit-scrollbar {
      -webkit-appearance: none;
      width: 7px;
    }
    &::-webkit-scrollbar-thumb {
      border-radius: 4px;
      background-color: rgba(0, 0, 0, .5);
      -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, .5);
    }
  }
  &-dot {
    background-image: url(/img/icon/event_types.svg);
    width: 24px;
    height: 24px;
    background-repeat: no-repeat;
    background-size: 128px;
    float: left;
    position: absolute;
    top: 0;
    left: 11px;
    &--light {
      @extend .event-dot;
      background-position-y: -21px;
    }
    &:hover ~ .project__day-content {
      background: #EBEEF1 !important;
    }
  }
}

@import "type-select";
