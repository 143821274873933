//PAGE COMMON STYLE
$susy: (
        columns: 12,
        container: 1024px,
        gutters : 0,
        gutter-position : inside,
        global-box-sizing: border-box,
);

$colorError: $raspberry-red;

.moneyAlign {
  text-align: right;
}

.textCenter {
  text-align: center;
}


.pageSection {
  @include container;
  clear: both;
}

.exportModule {
  clear: both;
  float: right;
  color: $bright-sky-blue;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
}

//page records header
//intestazione delle collection
.recordsHeader {
  clear: both;
  float: left;
  width: 100%;
  height: 30px;
  margin: 16px 0 3px;
  background-color: $morning-fog-gray;
  color: $black;
  font-weight: $semiBold;

  .recordsHeader__recordName {
    float: left;
    display: table;
    height: 30px;
    padding: 0 6px;

    &:hover, &:focus, &.active {
      background: rgba(0, 0, 0, 0.11);
      cursor: pointer;
    }
    &.active {
      .wethod-icon-medium-arrow-up{
        display: table-cell;

      }
      .wethod-icon-medium-arrow-down{
        display: table-cell;
      }
    }

    .wethod-icon-medium-arrow-up{
      display: none;

    }.wethod-icon-medium-arrow-down{
      display: none;
    }

    //classe che serve per spostare il contenuto della prima cella e
    //allinearlo con il resto della pagina
    &.recordsHeader__recordName--leftAlign {
      padding-left: 20px;
    }

    &.recordsHeader__recordName--rightAlign {
      padding-right: 20px;
    }

    span {
      display: table-cell;
      vertical-align: middle;
    }
  }

  .recordsHeader__recordName--static {
    float: left;
    display: table;
    height: 30px;
    padding: 0 6px;
    line-height: 30px;
  }
}

//page records
//lista dei risultati mostrati dalle collection
.recordList {
  clear: both;
  width: 100%;
  list-style: none;

  .recordList__record {
    clear: both;
    position: relative;
    width: 100%;
    height: 60px;
    background: $white;
    border: 1px solid $concrete-gray;
    border-top: 0;

    //stile associato a tutti gli elementi non modificabili
    .recordList__record__content {
      //width: 100%;
      height: 59px;
      padding: 0 6px;
      line-height: 1.3;

      &.recordList__record__content--leftAlign {
        padding-left: 20px;
      }

      &.recordList__record__content--table {
        display: table;
        width: 100%;
      }

      .recordList__record__content__text {
        display: table-cell;
        vertical-align: middle;

        // Fix: if one single word il longer than the
        // container width apply ellipsis
        text-overflow: ellipsis;
        overflow: hidden;
        max-width: 0;

        &.recordList__record__content__text--ellipsis{
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        &.recordList__record__content__text--invoiceNumber{
          max-width: 1px;
        }

        &.recordList__record__content__text--leftAlign {
          padding-left: 20px;
        }

        &.recordList__record__content__text--rightAlign {
          padding-right: 20px;
        }
      }

      //classe per le empty view
      &.recordList__record__content--empty {
        display: table;

        .recordList__record__content--empty__text {
          display: table-cell;
          vertical-align: middle;
          text-transform: uppercase;
          font-weight: $semiBold;
          color: $steel-gray;
        }

      }
    }

    //stile associato agli input
    .recordList__record__editInput {
      width: 100%;
      height: 59px;
      padding: 0 6px;
      border: none;
      outline: none;
      background: $white;
      text-overflow: ellipsis;

      &:hover, &:focus {
        background-color: rgba(158, 158, 158, 0.2);
      }

      //override della libreria mdl
      &.mdl-textfield {
        width: 100%;
        padding: 0;

        .mdl-textfield__input {
          height: 59px;
          padding: 0 6px !important;
          font-size: 12px !important;
          border: none;
          text-overflow: ellipsis;

          &.mdl-textfield__input--error {
            border-color: $colorError;
          }
        }

        .mdl-textfield__label {
          height: 16px;
          top: 22px;
          left: 6px;
          line-height: 1.3;
          display: block;
          font-size: 12px !important;

          &.mdl-textfield__label--error {
            color: $colorError !important;
          }

          &::after {
            display: none;
          }
        }

        &.mdl-textfield--floating-label.is-focused .mdl-textfield__label,
        &.mdl-textfield--floating-label.is-dirty .mdl-textfield__label {
          top: 8px;
          color: $bright-sky-blue !important;
          font-size: 10px !important;

          &.mdl-textfield__label--error {
            color: $colorError !important;
          }
        }

        &.mdl-textfield--floating-label.is-upgraded .mdl-textfield__label {
          &.mdl-textfield__label--error {
            color: $colorError !important;
          }
        }

      }

      //classe per l'allineamento del primoelemento
      &.recordList__record__editInput--leftAlign {
        padding-left: 20px;

        &.mdl-textfield {

          .mdl-textfield__input {
            padding: 0 !important;
          }

          .mdl-textfield__label {
            left: 20px;
          }
        }
      }
    }

    //stile feedback
    .recordList__record__feedBack {
      display: none;
      position: absolute;
      bottom: 0px;
      left: 14px;
      font-size: 11px;
      background: rgba(255, 255, 255, .6);
      padding: 6px;
      border-radius: 3px;

      &.recordList__record__feedBack--message {
        display: block;
        color: $steel-gray;
      }

      &.recordList__record__feedBack--success {
        color: $bright-sky-blue;
      }

      &.recordList__record__feedBack--error {
        color: $raspberry-red;
      }

    }

    //stile hints
    .recordList__record__hints {
      display: none;
      position: relative;

      .recordList__record__hints__list {
        position: absolute;
        z-index: 100;
        top: 0px;
        left: 0;
        width: 100%;
        max-height: 150px;
        background: $white;
        outline: 5px solid rgba(72, 165, 249, 0.3);

        overflow: hidden;
        overflow-y: scroll;

        //stile scroll
        &::-webkit-scrollbar {
          width: 7px;
        }

        &::-webkit-scrollbar-track {
          width: 7px;
          -webkit-box-shadow: inset 0 0 6px rgba(255, 255, 255, 0);
        }

        &::-webkit-scrollbar-thumb {
          border-radius: 4px;
          background-color: rgba(0, 0, 0, .2);
        }

        .recordList__record__hints__list__empty {
          width: 100%;
          padding: 12px;
          display: block;
          color: $bright-sky-blue;
        }

        li {
          width: 100%;
          display: block;

          a {
            display: block;
            padding: 6px 8px;
            line-height: 1.3;
            color: $black;

            &:hover {
              color: $bright-sky-blue;
            }
          }
        }
      }

      .recordList__record__hints__message {
        position: absolute;
        z-index: 100;
        top: 0px;
        left: 0;
        width: 100%;
        padding: 12px;
        background: $white;
        outline: 5px solid rgba(72, 165, 249, 0.3);

        color: $bright-sky-blue;
      }
    }

    .recordList__record__tooltip {
      position: absolute;
      z-index: 100;
      top: 0px;
      left: 0;
      width: 100%;
      background: $white;
      outline: 5px solid rgba(72, 165, 249, 0.3);

      .recordList__record__tooltip__title {
        @include span(12);

        padding: 12px 6px;
        border-bottom: 1px solid rgba(72, 165, 249, .3);
        color: $bright-sky-blue;
        line-height: 1.3;

        b {
          font-weight: $bold;
        }
      }

      .recordList__record__tooltip__input {
        @include span(10);
        @include push(1);
        border-bottom: 1px solid $concrete-gray;
      }

      .recordList__record__tooltip__button {
        @include span(10);
        @include push(1);
        margin-top: 12px;
        margin-bottom: 12px;
      }

      .recordList__record__tooltip__feedback {
        @include span(12);
        display: none;
        //height: 24px;
        padding: 0 6px 6px;
        line-height: 1.3;
        font-size: 11px;

        &.recordList__record__tooltip__feedback--success {
          color: $bright-sky-blue;
        }

        &.recordList__record__tooltip__feedback--error {
          color: $raspberry-red;
        }
      }

    }


  }

}


//caricamento infinite scroll
.recordListLoading {
  width: 100%;
  height: 60px;
  margin: 40px 0;
  text-align: center;

  @keyframes logoRotation {
    0% {
      @include transform(rotate(0deg) translateZ(0));
    }
    100% {
      @include transform(rotate(360deg) translateZ(0));
    }
  }


  .recordListLoading__image {
    width: 30px;
    height: 30px;
    @include scaleToExtraSmall() {
      margin-top: -10px;
    }

    @include retina-image($iconsPath + mini_logo_wait, 30px 30px);
    @include animation(logoRotation 1.3s ease);
    @include animation-delay(0s);
    @include animation-iteration-count(infinite);
  }
}
