.client {
  &__title {
    color: $bright-sky-blue;
    font-size: 14px;
    font-weight: $semiBold;
    padding: 16px;
  }

  &-info {
    &--primary {
      font-size: 18px;
      font-weight: $semiBold;
    }

    &--secondary {
      color: $dusty-gray;
    }

    &-row {
      margin: 8px 0;

      &__title {
        color: $bright-sky-blue;
        padding: 4px 0;
      }

      &__error {
        padding: 4px 0;
      }

      &__value {
        a {
          color: $black;

          &:hover {
            color: $bright-sky-blue;
          }
        }
      }
    }
  }

  &-person, &-company {
    .section-header {
      margin-top: 16px;
    }

    &__info {
      width: 30%;
      display: inline-block;
      vertical-align: top;

      a {
        color: $black;

        &:hover {
          color: $bright-sky-blue;
        }
      }
    }

    &__links {
      width: 68%;
      display: inline-block;
      vertical-align: top;
      margin-left: 2%;
    }

    &-important-info, &-secondary-info, &-company-info, &-tags, &-notes {
      margin-bottom: 8px;
      border-radius: 12px;
      border: 1px solid $concrete-gray;
      overflow: hidden;
    }

    &__notes {
      font-size: 12px;
      letter-spacing: 0.2px;
      line-height: 20px;
      background: $white;
      width: 100%;
      border: none;
      min-height: 200px;
      outline: none;

      &--editable {
        background: #F2F3F5;
        border-radius: 3px;
        padding: 8px;
      }
    }

    &__resources-list {
      &-items {
        margin-top: 5px;
        overflow-y: auto;
        max-height: 242px;

        a {
          color: $black;

          &:hover {
            color: $bright-sky-blue;
          }
        }
      }

      &-header {
        float: none;
      }

      &--name {
        width: 70%;
      }

      &--actions {
        width: calc(30% - 12px);
        text-align: right;
      }

      &--alias {
        width: 30%;
      }

      &--url {
        width: 40%;
      }
    }

    &-resource {
      background: $white;
      margin-bottom: 8px;
      height: 48px;
      line-height: 40px;
      padding: 4px;
      border-radius: 3px;
      cursor: pointer;
      border: 1px solid $concrete-gray;

      &--new {
        height: 58px;

        .client-info-row__error {
          line-height: 0;
        }
      }
    }

    .empty-row {
      float: none;
      border: 1px solid $concrete-gray;
    }

    &__projects-list {
      &-items {
        margin-top: 5px;
        overflow-y: auto;
        max-height: 242px;
      }

      &-header {
        float: none;
      }

      &--name {
        width: 30%;
      }

      &--pm {
        width: 20%;
      }

      &--account {
        width: 20%;
      }

      &--contact {
        width: 20%;
      }

      &--value {
        width: 11%;
        text-align: right;
      }

      &--probability {
        width: 18%;
        text-align: right;
      }
    }

    &-project {
      background: $white;
      margin-bottom: 8px;
      height: 48px;
      line-height: 40px;
      padding: 4px;
      border-radius: 3px;
    }
  }

  &-company {
    &__projects-list {
      &-items {
        margin-top: 5px;
        overflow-y: auto;
        max-height: 242px;
      }

      &-header {
        float: none;
      }

      &--name {
        width: 20%;
      }

      &--pm {
        width: 20%;
      }

      &--account {
        width: 19%;
      }

      &--contact {
        width: 20%;
      }

      &--value {
        width: 10%;
      }

      &--probability {
        width: 10%;
      }
    }

    &-project {
      background: $white;
      margin-bottom: 8px;
      height: 48px;
      line-height: 40px;
      padding: 4px;
      border-radius: 3px;
    }

    &__contacts-list {
      &-items {
        margin-top: 5px;
        overflow-y: auto;
        max-height: 242px;
      }

      &-header {
        float: none;
      }

      &--name {
        width: 30%;
      }

      &--surname {
        width: 30%;
      }

      &--role {
        width: 20%;
      }

      &--unit {
        width: 20%;
      }
    }

    &-contact {
      background: $white;
      margin-bottom: 8px;
      height: 48px;
      line-height: 40px;
      padding: 4px;
      border-radius: 3px;
    }
  }
}
