$headH: 30px;
$rowH: 60px - $gutter * 2;
$borderBudget: 1px;
$modalWidth: 448px;

.modal {
  @extend .notVisible;

  position: absolute;
  width: auto;
  min-width: 180px;
  background: $white;
  color: $black;
  padding: $gutter;

  &.bottomRight {
    top: 100%;
    right: 0;
  }

  .button {
    width: 100% !important;
    margin: 0;
    background: 0 0;
    border: none;
    border-radius: 2px;
    color: $bright-sky-blue !important;
    position: relative;
    padding: 8px 6px;
    display: inline-block;
    font-weight: $semiBold;
    text-transform: uppercase;
    overflow: hidden;
    -webkit-transition: box-shadow 0.2s cubic-bezier(0.4, 0, 1, 1), background-color 0.2s cubic-bezier(0.4, 0, 0.2, 1), color 0.2s cubic-bezier(0.4, 0, 0.2, 1);
    transition: box-shadow 0.2s cubic-bezier(0.4, 0, 1, 1), background-color 0.2s cubic-bezier(0.4, 0, 0.2, 1), color 0.2s cubic-bezier(0.4, 0, 0.2, 1);
    outline: none;
    cursor: pointer;
    text-decoration: none;
    text-align: left;
    vertical-align: middle;

    &:hover {
      background-color: rgba(158, 158, 158, .2) !important;
    }

  }
}

//TOOLTIP
.settingsTooltip {
  z-index: 999 !important;
  display: none;
  position: absolute;
  top: 42px;
  left: 12px;
  width: 90%;
  max-height: 200px;

  overflow: hidden;
  overflow-y: scroll;

  &::-webkit-scrollbar {
    width: 7px;
  }

  &::-webkit-scrollbar-track {
    width: 7px;
    -webkit-box-shadow: inset 0 0 6px rgba(255, 255, 255, 0);
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: rgba(0, 0, 0, .2);
  }

  outline: 5px solid rgba(72, 165, 244, 0.3);
  background: $white;

  .list {
    li {
      width: 100%;
      height: auto;

      a {
        display: block;
        padding: 12px;
        color: #878B8E;
        text-transform: capitalize;

        &:hover {
          color: $bright-sky-blue;
        }
      }
    }
  }
}

.tooltip {
  @extend .notVisible;

  position: absolute;
  width: auto;
  min-width: 125px;
  max-height: 350px;
  overflow: auto;
  background: $white;
  color: $black;
  padding: $gutter;
  border: none;
  outline: 5px solid rgba(72, 165, 249, 0.3);

  &.bottomRight {
    top: calc(100% + 6px);
    right: 0;
  }

  &.bottomLeft {
    top: calc(100% + 6px);
    left: 0;
  }

  &.topLeft {
    bottom: calc(100% + 6px);
    right: 0;
  }

  .hr {
    clear: both;
    float: left;
    width: 100%;
    height: 1px;
    margin: $gutter * 2 0 $gutter;
    background: $concrete-gray;

    &.full {
      width: calc(100% + 6px);
      margin-left: - $gutter;
    }

    &.noTop {
      margin-top: 0;
    }
  }

  ul {
    float: left;
    width: 100%;

    li {
      float: left;
      clear: both;
      width: 100%;
      @include scaleToSmall() {
        width: auto;
      }
    }
  }

  .tooltipLink {
    @extend .table;
    width: 100%;
    height: $headH;
    color: #4A4A4A;

    &:hover {
      color: $bright-sky-blue !important;
    }
  }

  .tooltipHead {
    .table {
      height: $rowH !important;
    }

    .title {
      font-weight: $semiBold;
      margin-bottom: $gutter;
    }
  }

  .tooltipBody {
    input {
      height: $rowH;
      border-bottom: 1px solid $concrete-gray;
    }

    .cta {
      height: auto !important;
      margin: $gutter * 2 auto $gutter;
    }
  }

  .statusWrapper {
    color: $bright-sky-blue;

    .errorMessage {
      color: $raspberry-red;
    }
  }
}


//MODAL
.modalWrapper {
  &::before {
    content: "";
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 900;
    background: rgba(#ccc, 0.3);
  }
}

.modalWrapHeadInfo {
  padding: 16px;
  border-bottom: 1px solid $concrete-gray;
  margin-bottom: 16px;

  .modalName {
    font-size: 14px;
    text-transform: capitalize;
  }

  .colseModal {
    font-size: 16px;
    margin-top: 2px;
    cursor: pointer;
  }
}

.modalStructure {
  display: none;
  position: fixed;
  z-index: 950;
  background: $white;
  width: $modalWidth;
  min-height: 150px;
  color: $black;
  border: 1px solid $concrete-gray;
  box-shadow: 0px 2px 20px rgb(0 0 0 / 10%);
  border-radius: 12px;

  .threeDivision {
    //width: (100% / 3) ;
    width: 30%;

    &.center {
      margin: 0 5%;
    }

  }

  .modalHead, .modalBody, .statusWrapper {
    float: left;
    width: 100%;
    line-height: 1.3;
  }

  .statusWrapper {
    color: $bright-sky-blue;
    min-height: $rowH / 2;
    padding: 6px 16px;
    line-height: 1.3;

    &.statusHide {
      display: none;
    }

    .error {
      color: $raspberry-red !important;
    }
  }

  .modalHiddenAction {
    display: none;
    padding: 0 $gutter * 2 $gutter * 2;
  }

  .modalHead {
    min-height: $rowH;

    .table {
      height: $rowH;
      padding: $gutter * 2;
      width: 100%;
      padding: 16px;
    }

    .title {
      font-weight: $semiBold;
    }

    border-bottom: 1px solid #eee;
  }

  .bodyWrapper {
    float: left;
    width: 100%;
    padding: $gutter * 2;

    .modalMessage {
      width: 100%;
      height: 100px;
      padding: $gutter * 2;
      border: 1px solid #eee;
      resize: none;
      line-height: 1.3;
      outline: none;
    }

    .body {
      padding: $gutter * 4 0;
      clear: both;
      float: left;
      width: 100%;
      padding: 10px;
      //min-height: $rowH;
      line-height: 1.3;

      &.borderBottom {
        border-bottom: 1px solid #eee;
      }

      input {
        margin: 10px 0 0;
        border: none;
        background: #eee;
        border-radius: 2px;
        padding: 8px;
        @include placeholder {
          color: $bright-sky-blue;
        }
      }
    }
  }

}

.modalText {
  padding: 16px 16px 32px;
  text-align: center;

  &--review {
    line-height: 18px;
  }
}


.modalWrapContent {

  .modalInputField {
    position: relative;
    margin-bottom: 8px;

    &.moreBottomSpace {
      margin-bottom: 32px;
    }
  }

  .subtitle {
    text-transform: uppercase;
    line-height: 1.3;
  }

  .text {
    padding: 16px;
    line-height: 1.3;
  }

  .changeLogTitle {
    margin-bottom: 7px;
  }
  .changeLogSubTitle {
    margin-bottom: 13px;
    color: grey;
  }

  .changeLogsContainer {
    padding: 16px;
    height: 243px;
    overflow: auto;
  }

  textarea {
    width: 100%;
    height: 200px;
    border: 1px solid $concrete-gray;
    padding: 16px;
    line-height: 1.3;
    outline: none;
  }

  //MDL
  $labelColor: #bfc0c6;

  .mdl-textfield {
    font-size: $baseFont !important;
    width: 100%;
    //padding: 14px 0;
    padding: 0;

    .mdl-textfield__input {
      padding: 16px 8px !important;
      font-size: $baseFont !important;
      border: none;
      border-bottom: 1px solid $concrete-gray;;

      &.billingError {
        border-color: $raspberry-red;
      }
    }

    .mdl-textfield__label {
      height: 16px;
      top: 15px;
      line-height: 1.3;

      display: block;
      font-size: $baseFont;
      //    color: $placeholderColor !important;

      &.billingError {
        color: $raspberry-red !important;
      }

      &::after {
        display: none;
      }
    }


    &.mdl-textfield--floating-label.is-focused .mdl-textfield__label,
    &.mdl-textfield--floating-label.is-dirty .mdl-textfield__label {
      top: -2px;
      color: $bright-sky-blue !important;
      font-size: 10px !important;

      &.billingError, &.error {
        color: $raspberry-red !important;
      }
    }

    &.mdl-textfield--floating-label.is-upgraded .mdl-textfield__label {
      &.billingError, &.error {
        color: $raspberry-red !important;
      }
    }

  }
}
