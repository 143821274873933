//ALERT
.alertsList {
  overflow: hidden;
  overflow-y: scroll;

  display: block;
  height: 276px;

  &::-webkit-scrollbar-track {
    width: 7px;
    -webkit-box-shadow: inset 0 0 6px rgba(255, 255, 255, 0);
    border: none;
    background: trasparent;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: rgba(0, 0, 0, .2);
  }

  li {
    display: block;
    width: 100%;
    height: 60px;
    border-bottom: 1px solid #EBEEF1;
    color: #333333;

    .alertWrap {
      position: relative;
      width: 100%;
      height: 100%;
      .helper-button__content {
        text-align: center;
        font-size: 14px;
        line-height: 22px;
        border: 1px solid $bright-sky-blue;
        border-radius: 50%;
        height: 22px;
        width: 22px;
        font-weight: $light;

        &:hover {
          cursor: pointer;
          background: $bright-sky-blue;
          color:$white;
        }
      }

      .message {
        display: none;
        z-index: 10;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 60px;
        background: rgba(72, 165, 244, .9);
        color: $white;
        line-height: 1.3;
        cursor: pointer;
        &.noLink {
          cursor: default
        }
        .table {
          height: 60px;
          padding-left: 15px;
        }
      }

      &:hover {
        .helper-button__content {
          text-align: center;
          font-size: 14px;
          line-height: 22px;
          border: 1px solid$white;
          color:$white;
          border-radius: 50%;
          height: 22px;
          width: 22px;
          font-weight: $light;
          &:hover {
            cursor: pointer;
            background:$white;
            color: $bright-sky-blue;

          }
        }
        .message {
          display: block;
        }
        .alertAction {
          z-index: 110;
          position: relative;
          .spriteModules {

            // &.moduleDashboard{
            // 	background-position:0 16px;
            // }
            &.modulePipeline {
              background-position: -16px 16px;
            }
            &.modulePlanning {
              background-position: -32px 16px;
            }
            // .menuTimesheet{
            // 	background-position:-48px 16px;
            // }
            &.moduleFriday {
              background-position: -48px 16px;
            }
            &.moduleReport {
              background-position: -80px 16px;
            }
            &.moduleFinance {
              background-position: -96px 16px;
            }
            &.moduleBudget {
              background-position: -96px 16px;
            }
            // &.moduleUser{
            // 	background-position:-112px 16px;
            // }
          }
        }

      }
    }

    .statusInfo {
      width: 12px;
      height: 12px;
      margin: 23px 0 0 15px;
      border-radius: 50%;

      &.red {
        background: $raspberry-red;
      }
      &.orange {
        background: $sunset-orange;
      }
    }

    .details {
      margin-top: 14px;
      // width: 100%;
      width: 188px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;

      .projectName {
        // white-space: nowrap;
        // overflow: hidden;
        // text-overflow: ellipsis;

        display: inline-block;
        width: auto;
        font-size: 12px;
        font-weight: $bold;
        line-height: 1.3;
      }
      .clientName {
        display: inline-block;
        line-height: 1.3;
      }
    }

    .info, .projectName {
      display: inline-block;
      width: 100%;
    }
    .info {
      margin-top: 8px;
      text-transform: uppercase;
      font-size: 10px;
      color: #878B8E;
      font-weight: $light;
    }

    .alertAction {
      float: right;
      display: block;
      width: auto;
      margin: 20px 15px 0 0;
    }

    &.noAlert {
      height: 276px;
      border: none;
      .table {
        width: 100%;
        height: 276px;
        text-align: center;
        text-transform: uppercase;
        font-size: 12px;
        color: #878B8E;
      }
    }

  }
}

//ALERT OVERVIEW
.alertOverviewHead {
  .backToList {
    display: none;
    float: left;
    width: 20px;
    height: 20px;
    margin: -4px 15px 0 0;
    border: 1px solid $bright-sky-blue;
    border-radius: 50%;
    text-align: center;

    .backToListIcon {
      display: inline-block;
      margin-top: 3px;
      color: $bright-sky-blue;
      line-height: 1;
      font-size: 11px;
    }

    &:hover {
      background: $bright-sky-blue;
      .backToListIcon {
        color: #fff
      }
    }
  }
}

.overviewWrap {
  width: 335px;
  height: 296px;
  overflow: hidden
}

.overviewSlider {
  float: left;
  position: relative;
  width: 670px;
  height: 296px
}

.alertsOverviewDetails, .alertsOverviewWrapList {
  float: left;
  position: relative;
  width: 335px;
  height: 296px;
  overflow: hidden
}

.alertsOverviewList {
  display: block;
  float: left;
  overflow: hidden;
  overflow-y: scroll;
  width: 100%;
  height: 276px;

  li {
    display: block;
    width: 100%;
    height: 60px;

    a {
      display: block;
      width: 100%;
      height: 60px;

      border-bottom: 1px solid #EBEEF1;
      color: #333;

      &:hover {
        background: #EBEEF1
      }

      .details {
        margin: 24px 0 0 20px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      .statusInfo {
        visibility: hidden;
        width: 15px;
        height: 15px;
        margin: 22px 20px 0 0;
        border-radius: 50%;
        font-size: 10px;
        text-align: center;

        .alertNumber {
          color: $white;
          margin: 2px 0 0 1px;
          display: inline-block;
        }

        &.red {
          background: $raspberry-red;
        }
        &.orange {
          background: $sunset-orange
        }
      }
    }

    &.noAlert {
      height: 276px;
      border: none;
      .table {
        width: 100%;
        height: 276px;
        text-align: center;
        text-transform: uppercase;
        font-size: 12px;
        color: #878B8E;
      }
    }
  }

}
