.supplier {
  max-width: 1440px !important;
  padding: 0 24px;

  &-info {
    &__status {
      &--not-editable {
        cursor: initial;
        pointer-events: none;
      }
    }
  }

  &__actions {
    padding-top: 22px;
  }

  &__modal {
    &-rating {
      .wethod-modal2__body {
        width: 370px;
        max-width: 100%;
        padding-top: 8px;
      }

      .wethod-sidebar__subtitle {
        padding-bottom: 0;
      }

      &-input {
        margin-top: 16px;
      }

      .wethod-rating {
        margin-top: 10px;
      }
    }
  }

  &-widget-rating {
    &-item {
      &__project {
        margin-top: 16px;

        &-name {
          font-weight: $bold;
        }

        &-client {
          color: $dusty-gray;
          margin-top: 4px;
        }
      }
    }
  }

  &__content {
    height: calc(100vh - 249px);
    display: flex;
    justify-content: space-between;

    &-side {
      height: calc(100% - 30px);
      width: 307px;
      margin-right: 24px;
    }

    &-center {
      flex-grow: 2;
      height: calc(100% - 30px);
    }
  }

  .widget-rating-overview {
    height: 100%;
  }

  &-projects {
    &__header {
      margin-bottom: 28px;
      display: flex;
      justify-content: space-between;
      align-items: center;

      &-title {
        font-size: 18px;
        font-weight: 600;
      }
    }
  }

  &-project {
    &__multirow-info {
      display: flex;
      flex-direction: column;
      justify-content: center;
      line-height: 1.5;
      height: 100%;

      span {
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    &__job-order {
      font-size: 12px;
      color: $dusty-gray;
    }

    &__column {
      &--name {
        width: calc(30% - 67px);
        height: 100%;
      }

      &--pm {
        width: 20%;
      }

      &--type {
        width: 17%;
      }

      &--period {
        width: 15%;
      }

      &--ordered {
        width: 18%;
        text-align: right;
      }

      &--actions {
        width: 64px;
      }
    }
  }
}
